// @flow
import type { Action } from "../../types/store";
import type { SchoolsState } from "../../types/school";
import {
  SEARCH_SCHOOL_REQUESTING,
  SEARCH_SCHOOL_SUCCESS,
  SEARCH_SCHOOL_FAILURE,
  CREATE_SCHOOL_REQUESTING,
  CREATE_SCHOOL_SUCCESS,
  CREATE_SCHOOL_FAILURE,
  EDIT_SCHOOL_REQUESTING,
  EDIT_SCHOOL_SUCCESS,
  EDIT_SCHOOL_FAILURE,
  ENABLE_DISABLE_SCHOOL_REQUESTING,
  ENABLE_DISABLE_SCHOOL_SUCCESS,
  ENABLE_DISABLE_SCHOOL_FAILURE,
} from "../../types/school";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";

const INITIAL_STATE: SchoolsState = {
  schoolByID: {},
  searchSchoolRequest: null,
  createSchoolRequest: null,
  editSchoolRequest: null,
  enableDisableSchoolRequest: null,
};

export function reducer(state: SchoolsState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case SEARCH_SCHOOL_REQUESTING: {
      return {
        ...state,
        searchSchoolRequest: makeRequesting(),
      };
    }
    case SEARCH_SCHOOL_SUCCESS: {
      const cloned = { ...state.schoolByID };
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        schoolByID: cloned,
        searchSchoolRequest: makeSuccess(),
      };
    }
    case SEARCH_SCHOOL_FAILURE: {
      return {
        ...state,
        searchSchoolRequest: makeFailure(action.payload),
      };
    }
    case CREATE_SCHOOL_REQUESTING: {
      return {
        ...state,
        createSchoolRequest: makeRequesting(),
      };
    }
    case CREATE_SCHOOL_SUCCESS: {
      const cloned = { ...state.schoolByID };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        schoolByID: cloned,
        createSchoolRequest: makeSuccess(),
      };
    }
    case CREATE_SCHOOL_FAILURE: {
      return {
        ...state,
        createSchoolRequest: makeFailure(action.payload),
      };
    }
    case EDIT_SCHOOL_REQUESTING: {
      return {
        ...state,
        editSchoolRequest: makeRequesting(),
      };
    }
    case EDIT_SCHOOL_SUCCESS: {
      const cloned = { ...state.schoolByID };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        schoolByID: cloned,
        editSchoolRequest: makeSuccess(),
      };
    }
    case EDIT_SCHOOL_FAILURE: {
      return {
        ...state,
        editSchoolRequest: makeFailure(action.payload),
      };
    }
    case ENABLE_DISABLE_SCHOOL_REQUESTING: {
      return {
        ...state,
        enableDisableSchoolRequest: makeRequesting(),
      };
    }
    case ENABLE_DISABLE_SCHOOL_SUCCESS: {
      const cloned = { ...state.schoolByID };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        schoolByID: cloned,
        enableDisableSchoolRequest: makeSuccess(),
      };
    }
    case ENABLE_DISABLE_SCHOOL_FAILURE: {
      return {
        ...state,
        enableDisableSchoolRequest: makeFailure(action.payload),
      };
    }
    default:
      return state;
  }
}
