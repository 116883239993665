// @flow
import type { RequestState } from "./request";

export const SEARCH_ACTOR_ADMIN: "admin" = "admin";
export const SEARCH_ACTOR_SCHOOL: "school" = "school";
export type SEARCH_ACTOR =
  | typeof SEARCH_ACTOR_ADMIN
  | typeof SEARCH_ACTOR_SCHOOL;

export type AuditTrail = {|
  +id: string,
  +createdAt: Date,
  +category: string,
  +actor: string,
  +action: string,
  +content: any,
|};

export type AuditTrailResponse = {|
  +_id: string,
  +created_at: string,
  +category: string,
  +actor: string,
  +action: string,
  +content: any,
|};

export type AuditTrailsState = {
  +auditTrailById: { [id: string]: AuditTrail },
  +searchAuditTrailsRequest: ?RequestState<void>,
};

export const SEARCH_AUDIT_TRAILS_REQUESTING: "AUDIT_TRAILS.SEARCH.REQUESTING" =
  "AUDIT_TRAILS.SEARCH.REQUESTING";
export const SEARCH_AUDIT_TRAILS_SUCCESS: "AUDIT_TRAILS.SEARCH.SUCCESS" =
  "AUDIT_TRAILS.SEARCH.SUCCESS";
export const SEARCH_AUDIT_TRAILS_FAILURE: "AUDIT_TRAILS.SEARCH.FAILURE" =
  "AUDIT_TRAILS.SEARCH.FAILURE";

export type SearchAuditTrailsRequesting = {|
  +type: typeof SEARCH_AUDIT_TRAILS_REQUESTING,
|};
export type SearchAuditTrailsSuccess = {|
  +type: typeof SEARCH_AUDIT_TRAILS_SUCCESS,
  +payload: $ReadOnlyArray<AuditTrail>,
|};
export type SearchAuditTrailsFailure = {|
  +type: typeof SEARCH_AUDIT_TRAILS_FAILURE,
  +payload: mixed,
|};
