// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import IconButton from "../IconButton/IconButton";
import editIcon from "../../images/template_edit.svg";
import activitiesIcon from "../../images/template_activities.svg";
import Arrow from "../ART/Arrow";
import CreateTemplateButton from "../CreateTemplateButton/CreateTemplateButton";
import CreateSessionButton from "../CreateSessionButton/CreateSessionButton";
import EditTemplateLink from "../Link/EditTemplateLink";
import TemplatesLink from "../Link/TemplatesLink";
import ActivityRecordsLink from "../Link/ActivityRecordsLink";
import TruncatedText from "../TruncatedText/TruncatedText";
import type { Template } from "../../types/template";
import styles from "./TemplateHeader.module.scss";

type Props = {
  template: Template,
  onCreateTemplate: (templateId: string, newName: string) => void,
};

class TemplateHeader extends PureComponent<Props> {
  renderBreadcrumbs() {
    const { template } = this.props;
    const { year, displayCode, name } = template;

    return (
      <div className={styles.breadcrumbsContainer}>
        <Arrow
          className={styles.arrowIcon}
          width={9}
          height={19}
          strokeWidth={1}
          strokeColor="#979797"
          direction="left"
        />
        <TemplatesLink
          className={classnames(styles.breadcrumbsText, styles.templateYear)}
          year={year}
        >
          <Text translationKey={`teacher.template_${year}`} />
        </TemplatesLink>
        <Arrow
          className={styles.arrowIcon}
          width={9}
          height={19}
          strokeWidth={1}
          strokeColor="#979797"
          direction="left"
        />
        <div
          className={classnames(styles.breadcrumbsText, styles.templateName)}
        >
          <TruncatedText
            lines={1}
            lineHeight={23}
            fontSize={18}
            fontWeight={500}
            className=""
          >
            {`${displayCode} ${name}`}
          </TruncatedText>
        </div>
      </div>
    );
  }

  renderEditTemplateButton() {
    return (
      <EditTemplateLink template={this.props.template}>
        <IconButton
          className={styles.editTemplateButton}
          icon={editIcon}
          labelTranslationKey="teacher.edit_template"
        />
      </EditTemplateLink>
    );
  }

  renderViewActivityButton() {
    const { template } = this.props;
    const disabled = template.activityRecordsCount === 0;
    return (
      <ActivityRecordsLink
        templateId={this.props.template.id}
        disabled={disabled}
      >
        <IconButton
          className={classnames(styles.activityRecordsButton, {
            [styles.disabledButton]: disabled,
          })}
          icon={activitiesIcon}
          labelTranslationKey="teacher.activity_records"
        />
      </ActivityRecordsLink>
    );
  }

  renderButtons() {
    const { template } = this.props;

    return (
      <div className={styles.buttonsContainer}>
        {template.isImported && (
          <CreateTemplateButton
            className={styles.createTemplateButton}
            template={template}
            onCreateTemplate={this.props.onCreateTemplate}
          />
        )}
        {!template.isImported && this.renderEditTemplateButton()}
        <CreateSessionButton
          className={styles.createSessionButton}
          template={template}
        />
        {this.renderViewActivityButton()}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.headerContainer}>
        {this.renderBreadcrumbs()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default TemplateHeader;
