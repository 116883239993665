// @flow
import URL from "url-parse";

// This function exists because the image URL in our app
// is signed. If we naively compare them with ===, then
// different in signature will return false even the
// URL is actually referring the same asset.
// We solve this by ignoring the querystring with
// comparing image URLs.
export default function isImageURLEqual(a: string, b: string): boolean {
  const aa = new URL(a);
  const bb = new URL(b);
  const ret = aa.origin === bb.origin && aa.pathname === bb.pathname;
  return ret;
}
