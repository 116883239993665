// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import styles from "./IconButton.module.scss";

type Props = {
  className: string,
  icon: string,
  labelTranslationKey: string,
  onClick?: (Event, mixed) => void,
  onClickInfo?: mixed,
  disabled: boolean,
};

class IconButton extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  onClick = (e: Event) => {
    const { onClick, onClickInfo, disabled } = this.props;

    if (onClick && !disabled) {
      onClick(e, onClickInfo);
    }
  };

  render() {
    const { className, icon, labelTranslationKey, disabled } = this.props;
    return (
      <div
        className={classnames(
          styles.iconButton,
          { [styles.disabled]: disabled },
          className
        )}
        onClick={this.onClick}
      >
        <div className={styles.icon}>
          <img src={icon} alt="" />
        </div>
        <p>
          <Text translationKey={labelTranslationKey} />
        </p>
      </div>
    );
  }
}

export default IconButton;
