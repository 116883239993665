// @flow
import type { Action } from "../../types/store";
import type { UsersState } from "../../types/user";
import {
  SEARCH_USERS_REQUESTING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  CREATE_USER_REQUESTING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_REQUESTING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  ENABLE_DISABLE_USER_REQUESTING,
  ENABLE_DISABLE_USER_SUCCESS,
  ENABLE_DISABLE_USER_FAILURE,
} from "../../types/user";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";

const INITIAL_STATE: UsersState = {
  userById: {},
  searchUsersRequest: null,
  createUserRequest: null,
  editUserRequest: null,
  enableDisableUserRequest: null,
};

export function reducer(state: UsersState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case SEARCH_USERS_REQUESTING: {
      return {
        ...state,
        searchUsersRequest: makeRequesting(),
      };
    }
    case SEARCH_USERS_SUCCESS: {
      const cloned = { ...state.userById };
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        userById: cloned,
        searchUsersRequest: makeSuccess(),
      };
    }
    case SEARCH_USERS_FAILURE: {
      return {
        ...state,
        searchUsersRequest: makeFailure(action.payload),
      };
    }
    case CREATE_USER_REQUESTING: {
      return {
        ...state,
        createUserRequest: makeRequesting(),
      };
    }
    case CREATE_USER_SUCCESS: {
      const cloned = { ...state.userById };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        userById: cloned,
        createUserRequest: makeSuccess(),
      };
    }
    case CREATE_USER_FAILURE: {
      return {
        ...state,
        createUserRequest: makeFailure(action.payload),
      };
    }
    case EDIT_USER_REQUESTING: {
      return {
        ...state,
        editUserRequest: makeRequesting(),
      };
    }
    case EDIT_USER_SUCCESS: {
      const cloned = { ...state.userById };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        userById: cloned,
        editUserRequest: makeSuccess(),
      };
    }
    case EDIT_USER_FAILURE: {
      return {
        ...state,
        editUserRequest: makeFailure(action.payload),
      };
    }
    case ENABLE_DISABLE_USER_REQUESTING: {
      return {
        ...state,
        enableDisableUserRequest: makeRequesting(),
      };
    }
    case ENABLE_DISABLE_USER_SUCCESS: {
      const cloned = { ...state.userById };
      const s = action.payload;
      cloned[s.id] = s;
      return {
        ...state,
        userById: cloned,
        enableDisableUserRequest: makeSuccess(),
      };
    }
    case ENABLE_DISABLE_USER_FAILURE: {
      return {
        ...state,
        enableDisableUserRequest: makeFailure(action.payload),
      };
    }
    default:
      return state;
  }
}
