// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Synchronization from "../../components/Synchronization/Synchronization";
import TabBar from "../../components/TabBar/TabBar";
import Sessions from "./Sessions";
import Templates from "./Templates";
import type { RootState, Dispatch } from "../../types/store";
import type { TabItem } from "../../components/TabBar/TabBar";
import SessionsLink from "../../components/Link/SessionsLink";
import TemplatesLink from "../../components/Link/TemplatesLink";
import WindowSize from "../../components/WindowSize";
import {
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_YEAR_FORM_2,
  TEMPLATE_YEAR_FORM_3,
} from "../../types/template";
import type { AuthUser } from "../../types/auth";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import styles from "./styles/Dashboard.module.scss";

export const ACTIVE_TAB_SESSIONS: "sessions" = "sessions";
export const ACTIVE_TAB_UNIT_TEMPLATES: "unitTemplates" = "unitTemplates";

type Props = {
  +history: RouterHistory,
  +user?: AuthUser,
  +tab: typeof ACTIVE_TAB_SESSIONS | typeof ACTIVE_TAB_UNIT_TEMPLATES,
  +state?: any,
};

type LocalOwnProp = {
  +tab: typeof ACTIVE_TAB_SESSIONS | typeof ACTIVE_TAB_UNIT_TEMPLATES,
  +location: Location,
};

const TAB_BAR_ITEMS: $ReadOnlyArray<TabItem> = [
  {
    id: ACTIVE_TAB_UNIT_TEMPLATES,
    labelId: "teacher.unit_templates",
    LinkComponent: TemplatesLink,
  },
  {
    id: ACTIVE_TAB_SESSIONS,
    labelId: "teacher.in_progress",
    LinkComponent: SessionsLink,
  },
];

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// layout tab bar: 56px;
// layout footer: 44px;
const MAGIC_HEIGHT_OFFSET = 238;

class DashBoard extends PureComponent<Props> {
  render() {
    const { tab, state, history } = this.props;
    let year = TEMPLATE_YEAR_FORM_1;
    if (state != null) {
      if (
        state.year &&
        (state.year === TEMPLATE_YEAR_FORM_1 ||
          state.year === TEMPLATE_YEAR_FORM_2 ||
          state.year === TEMPLATE_YEAR_FORM_3)
      ) {
        year = state.year;
      }
    }

    return (
      <Fragment>
        <TabBar items={TAB_BAR_ITEMS} activeTabId={tab} />
        <Synchronization />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            return (
              <div
                style={{ minHeight: h + "px" }}
                className={styles.dashboardContainer}
              >
                {tab === ACTIVE_TAB_SESSIONS && <Sessions history={history} />}
                {tab === ACTIVE_TAB_UNIT_TEMPLATES && (
                  <Templates year={year} history={history} />
                )}
              </div>
            );
          }}
        </WindowSize>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: LocalOwnProp) {
  return {
    user: state.auth.user,
    tab: ownProps.tab,
    state: ownProps.location.state,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

const ConnectedDashBoard = connect(mapStateToProps, mapDispatchToProps)(
  DashBoard
);

export default ConnectedDashBoard;
