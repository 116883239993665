// @flow
import React, { PureComponent } from "react";
import qs from "qs";
import { Switch } from "react-router-dom";
import AuthRoute from "../teacher/AuthRoute";
import Layout from "./Layout";
import Login from "./Login";
import Dashboard from "./Dashboard";
import EditTemplate from "./EditTemplate";
import CreateTemplate from "./CreateTemplate";
import ViewActivityRecord from "./ViewActivityRecord";
import ForgotPassword from "./ForgotPassword";
import {
  SCHOOL_ACCOUNT_MANAGEMENT,
  TEMPLATE_MANAGEMENT,
  RECORDS_MANAGEMENT,
  ADMIN_USER_MANAGEMENT,
  AUDIT_TRAIL,
  SYSTEM_CONFIGURATION,
} from "./Dashboard";
import {
  SCHOOL_ACCOUNT_MANAGEMENT_PATH,
  TEMPLATE_MANAGEMENT_PATH,
  RECORDS_MANAGEMENT_PATH,
  ADMIN_USER_MANAGEMENT_PATH,
  AUDIT_TRAIL_PATH,
  SYSTEM_CONFIGURATION_PATH,
} from "../../types/paths";
import type { Match } from "react-router-dom";

type Props = {
  +match: Match,
};

type RouteInfo = {|
  +path: string,
  +tabId: string,
|};

const adminRoutes: $ReadOnlyArray<RouteInfo> = [
  { path: SCHOOL_ACCOUNT_MANAGEMENT_PATH, tabId: SCHOOL_ACCOUNT_MANAGEMENT },
  { path: TEMPLATE_MANAGEMENT_PATH, tabId: TEMPLATE_MANAGEMENT },
  { path: RECORDS_MANAGEMENT_PATH, tabId: RECORDS_MANAGEMENT },
  { path: ADMIN_USER_MANAGEMENT_PATH, tabId: ADMIN_USER_MANAGEMENT },
  { path: AUDIT_TRAIL_PATH, tabId: AUDIT_TRAIL },
  { path: SYSTEM_CONFIGURATION_PATH, tabId: SYSTEM_CONFIGURATION },
];

class AdminRoute extends PureComponent<Props> {
  render() {
    const { url } = this.props.match;
    return (
      <Switch>
        <AuthRoute exact authAdmin={true} path={url + "/"} useDefaultPath />
        {adminRoutes.map(adminRoute => (
          <AuthRoute
            exact
            authAdmin={true}
            path={adminRoute.path}
            key={adminRoute.tabId}
            render={props => (
              <Layout {...props}>
                <Dashboard tab={adminRoute.tabId} {...props} />
              </Layout>
            )}
          />
        ))}
        <AuthRoute
          exact
          authAdmin={true}
          path="/admin/template_management/create"
          render={props => (
            <Layout {...props}>
              <CreateTemplate {...props} />
            </Layout>
          )}
        />
        <AuthRoute
          exact
          authAdmin={true}
          path="/admin/template_management/:id/edit"
          render={props => (
            <Layout {...props}>
              <EditTemplate {...props} />
            </Layout>
          )}
        />
        <AuthRoute
          exact
          authOnly={false}
          authAdmin={true}
          path={url + "/login"}
          render={props => (
            <Layout {...props}>
              <Login {...props} />
            </Layout>
          )}
        />
        <AuthRoute
          exact
          authOnly={false}
          authAdmin={true}
          path={url + "/forgot-password"}
          render={props => (
            <Layout {...props}>
              <ForgotPassword {...props} />
            </Layout>
          )}
        />
        <AuthRoute
          exact
          authAdmin={true}
          path="/admin/records_management/view"
          render={props => {
            const parsed = qs.parse(props.location.search, {
              ignoreQueryPrefix: true,
            });
            const initialSessionID = parsed["sessionID"] || "";
            return (
              <Layout {...props}>
                <ViewActivityRecord
                  initialSessionID={initialSessionID}
                  history={props.history}
                />
              </Layout>
            );
          }}
        />
      </Switch>
    );
  }
}

export default AdminRoute;
