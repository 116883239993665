// @flow
import type { ActivityResponse, Activity } from "../types/activity";

export function activitySerializer(activity: Activity): ActivityResponse {
  const serializedActivity: ActivityResponse = {
    type: activity.type,
    name: activity.name,
    question: activity.question,
    image_id: activity.imageId,
    image_url: undefined,
    polling_multiple_choice: undefined,
    polling_named: undefined,
    polling_options: [],
    whiteboard_background: activity.whiteboardBackground,
    whiteboard_orientation: activity.whiteboardOrientation,
    whiteboard_image_id: activity.whiteboardImageId,
    whiteboard_stickers: activity.whiteboardStickers.map(s => ({
      image_id: s.imageId,
      image_url: s.imageUrl,
    })),
    // Server does not need this
    whiteboard_image_url: undefined,
  };

  switch (activity.type) {
    case "polling":
      let pollingOptions = [];
      if (activity.pollingOptions != null) {
        pollingOptions = activity.pollingOptions.map(o => ({
          text: o.text,
          image_id: o.imageId,
          image_url: undefined,
        }));
      }
      return {
        ...serializedActivity,
        polling_named:
          activity.pollingNamed != null ? activity.pollingNamed : false,
        polling_multiple_choice:
          activity.pollingMultipleChoice != null
            ? activity.pollingMultipleChoice
            : false,
        polling_options: pollingOptions,
      };
    default:
      return serializedActivity;
  }
}
