// @flow
import React from "react";
import { connect } from "react-redux";
import type { IntlShape } from "react-intl";
import type { RootState } from "../../types/store";
import Text from "../Text/Text";

type OwnProps = {
  +className?: string,
};

type ConnectedProps = {
  +intl: IntlShape,
};

type Props = OwnProps & ConnectedProps;

function UserGuideLink(props: Props) {
  const { className, intl } = props;
  const { locale } = intl;
  let link = "";
  switch (locale) {
    case "en":
      link =
        (process.env.PUBLIC_URL || "") +
        "/teacher-portal-user-manual-en-20181101.pdf";
      break;
    case "zh-Hans-CN":
      link =
        (process.env.PUBLIC_URL || "") +
        "/teacher-portal-user-manual-sc-20181101.pdf";
      break;
    case "zh-Hant-HK":
      link =
        (process.env.PUBLIC_URL || "") +
        "/teacher-portal-user-manual-tc-20181101.pdf";
      break;
    default:
      link =
        (process.env.PUBLIC_URL || "") +
        "/teacher-portal-user-manual-tc-20181101.pdf";
      break;
  }
  return (
    <a
      href={link}
      alt=""
      className={className}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Text translationKey="teacher.user_guide" />
    </a>
  );
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGuideLink);
