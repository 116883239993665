// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import ModalContainer from "./ModalContainer";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Text from "../Text/Text";
import Button from "../Button/Button";
import Option from "../Option/Option";
import Select from "../Select/Select";
import { deepClone } from "../../utils/deepClone";
import closeIcon from "../../images/modal_close.svg";
import {
  validateAdminUser,
  ErrorEmptyAdminName,
  ErrorEmptyEmail,
  ErrorIncorrectEmail,
  ErrorEmptyPassword,
  ErrorPasswordViolatesRules,
} from "../../utils/validate";
import { UserTypeSuperAdmin, UserTypeEditor } from "../../types/user";
import type { User, UserType } from "../../types/user";
import type { ValidateAdminUserError } from "../../utils/validate";
import styles from "./AdminModal.module.scss";

type Props = {
  type: "add" | "edit",
  user?: User,
  onSave: (user: User, password: string) => void,
  onClose: Event => void,
  currentUserId?: string,
};

type LocalState = {
  editedUser: User,
  password: string,
  showPassword: boolean,
  errorLabels: $ReadOnlyArray<ValidateAdminUserError>,
};

function userTypeToTranslationKey(u: UserType): string {
  switch (u) {
    case UserTypeSuperAdmin:
      return "admin.admin_user.super_admin";
    case UserTypeEditor:
      return "admin.admin_user.editor";
    default:
      return "";
  }
}

export default class AdminUserModal extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editedUser:
        props.user != null
          ? deepClone(props.user)
          : {
              id: "",
              name: "",
              email: "",
              userType: UserTypeEditor,
              disabled: false,
            },
      password: "",
      showPassword: false,
      errorLabels: [],
    };
  }

  onSave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { editedUser, password } = this.state;
    const errorLabels = validateAdminUser({
      user: editedUser,
      password,
      allowEmptyPassword: this.isEditModal(),
    });

    if (errorLabels.length === 0) {
      this.props.onSave(editedUser, password);
    } else {
      this.setState({ errorLabels: errorLabels });
    }
  };

  onChange = (e: Event, attr: mixed) => {
    const { target } = e;
    if (target instanceof HTMLInputElement && typeof attr === "string") {
      this.setState({
        editedUser: {
          ...this.state.editedUser,
          [attr]: target.value,
        },
      });
    }
  };

  onChangePassword = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({ password: target.value });
    }
  };

  onChangeShowPassword = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({ showPassword: target.checked });
    }
  };

  onUserTypeChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { target } = event;
    const userTypeUnsafe = target.value;
    if (
      userTypeUnsafe === "PolyupathsSuperAdmin" ||
      userTypeUnsafe === "PolyupathsEditor"
    ) {
      const value: UserType = (userTypeUnsafe: UserType);
      this.setState({
        editedUser: {
          ...this.state.editedUser,
          userType: value,
        },
      });
    }
  };

  onClickModal = (e: Event) => {
    e.stopPropagation();
  };

  isAddModal() {
    return this.props.type === "add";
  }

  isEditModal() {
    return this.props.type === "edit";
  }

  nameErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorEmptyAdminName)
      ? "error.admin.admin_user.modal.name_empty"
      : null;
  }

  emailErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorEmptyEmail)
      ? "error.empty_email"
      : errorLabels.includes(ErrorIncorrectEmail)
        ? "error.incorrect_email"
        : null;
  }

  passwordErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorPasswordViolatesRules)
      ? "error.admin.modal.password_rules_violation"
      : errorLabels.includes(ErrorEmptyPassword)
        ? "error.empty_password"
        : null;
  }

  render() {
    const { onClose, currentUserId } = this.props;
    const { editedUser, password, showPassword } = this.state;

    return (
      <ModalContainer onClose={onClose}>
        <form
          className={classnames(
            styles.modalContainer,
            { [styles.addContainer]: this.isAddModal() },
            { [styles.editContainer]: this.isEditModal() }
          )}
          onClick={this.onClickModal}
          onSubmit={this.onSave}
        >
          <p className={styles.title}>
            <Text
              translationKey={
                this.isAddModal()
                  ? "admin.admin_user.modal.add_admin_account"
                  : "admin.admin_user.modal.edit_admin_account"
              }
            />
          </p>
          <p className={styles.labelRow}>
            <Text translationKey="admin.admin_user.modal.admin_name" />
          </p>
          <div className={styles.inputRow}>
            <div className={styles.inputCol}>
              <InputWithErrorMessage
                placeholderId="admin.admin_user.modal.input_admin_name"
                labelId="admin.admin_user.modal.input_admin_name"
                type="text"
                value={editedUser.name}
                className={styles.input}
                onChange={this.onChange}
                onChangeInfo="name"
                errorTextKey={this.nameErrorTextKey()}
              />
            </div>
            <Select
              className={styles.userTypeSelect}
              disabled={
                currentUserId != null && currentUserId === editedUser.id
              }
              onChange={this.onUserTypeChange}
              value={editedUser.userType}
            >
              <span className={styles.userTypeSelectedOption}>
                <Text
                  translationKey={userTypeToTranslationKey(editedUser.userType)}
                />
              </span>
              <Option
                translationKey="admin.admin_user.super_admin"
                value={UserTypeSuperAdmin}
              />
              <Option
                translationKey="admin.admin_user.editor"
                value={UserTypeEditor}
              />
            </Select>
          </div>
          <p className={styles.labelRow}>
            <Text translationKey="admin.modal.email" />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.modal.input_email"
              labelId="admin.modal.input_email"
              type="email"
              value={editedUser.email}
              className={styles.input}
              onChange={this.onChange}
              onChangeInfo="email"
              errorTextKey={this.emailErrorTextKey()}
            />
          </div>
          <p className={styles.labelRow}>
            <Text
              translationKey={
                this.isAddModal()
                  ? "admin.modal.create_password"
                  : "admin.modal.reset_password"
              }
            />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.modal.password_rules"
              labelId="admin.modal.password_rules"
              type={showPassword ? "text" : "password"}
              value={password}
              className={styles.input}
              onChange={this.onChangePassword}
              errorTextKey={this.passwordErrorTextKey()}
            />
          </div>
          <div className={styles.showPasswordRow}>
            <input
              name="showPassword"
              type="checkbox"
              checked={showPassword}
              onChange={this.onChangeShowPassword}
            />
            <Text translationKey="admin.modal.show_password" />
          </div>
          <div className={styles.buttonRow}>
            <Button
              color={this.isAddModal() ? "blue" : "green"}
              type="submit"
              className={styles.saveButton}
              onClick={this.onSave}
            >
              <Text
                translationKey={
                  this.isAddModal()
                    ? "admin.modal.add_account"
                    : "admin.modal.save_account"
                }
              />
            </Button>
          </div>
          <button className={styles.closeButton} onClick={this.props.onClose}>
            <img className={styles.closeIcon} src={closeIcon} alt="close" />
          </button>
        </form>
      </ModalContainer>
    );
  }
}
