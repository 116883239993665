// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import type { Node } from "react";
import type { IntlShape } from "react-intl";
import type { RootState } from "../../types/store";

type Props = {
  +to: string,
  +altId: string,
  +intl: IntlShape,
  +children?: Node,
};

class IntlLink extends PureComponent<Props> {
  render() {
    const { to, altId, intl, children, ...rest } = this.props;

    return (
      <Link to={to} alt={intl.formatMessage({ id: altId })} {...rest}>
        {children == null && intl.formatMessage({ id: altId })}
        {children}
      </Link>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(IntlLink);
