// @flow
import { Component } from "react";
import { connect } from "react-redux";
import type { AppLocale } from "../types/app";
import type { RootState } from "../types/store";

export type LocaleLayoutProps = {
  isEn: boolean,
  isCN: boolean,
  isHK: boolean,
};

type Props = {|
  +locale: AppLocale,
  +children: (props: LocaleLayoutProps) => React$Node,
|};

class LocaleLayout extends Component<Props> {
  render() {
    const { children, locale } = this.props;
    return children({
      isEn: locale === "en",
      isCN: locale === "zh-Hans-CN",
      isHK: locale === "zh-Hant-HK",
    });
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.app.locale,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleLayout);
