// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import type { Node } from "react";
import type { DrawingState, Shape } from "./DrawingState";
import {
  AVAILABLE_SHAPES,
  isSelectedObjectText,
  getAvailableColors,
} from "./DrawingState";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import ResponsiveLayout from "../ResponsiveLayout";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import Arrow from "../ART/Arrow";
import Image from "../Image/Image";
import Text from "../Text/Text";
import styles from "./Toolbox.module.scss";

type Props = {
  onTransitionEnd: (e: Event) => void,
  drawingState: DrawingState,
  onSelectPencilColorIndex: (i: number) => void,
  onSelectStrokeColorIndex: (i: number) => void,
  onSelectFillColorIndex: (i: number) => void,
  onSelectStrokeWidthIndex: (i: number) => void,
  onSelectTool: (tool: "pencil" | "eraser" | null) => void,
  onDeleteButtonClick: (e: Event) => void,
  onClearButtonClick: (e: Event) => void,
  onAddTextButtonClick: (e: Event) => void,
  onAddImageButtonClick: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onAddShape: (shape: Shape) => void,
  onAddSticker: (url: string) => void,
  onUserInteraction: () => void,
};

type LocalState = {
  isCollapsed: boolean,
};

// These classes control the button appearance
const STORKE_WIDTH_CLASSES = [styles.w1, styles.w2, styles.w3];

function ensureOpaqueColor(color: string): string {
  if (color === "transparent") {
    return "white";
  }
  return color;
}

function PencilButton(props: {
  disabled: boolean,
  selected: boolean,
  children: Node,
  onClick: (e: Event) => void,
}) {
  const { disabled, selected, children, onClick } = props;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames(styles.pencilButton, {
        [styles.selected]: selected,
        [styles.unselected]: !selected,
      })}
    >
      <span
        class={classnames(styles.pencilButtonText, {
          [styles.selected]: selected,
          [styles.unselected]: !selected,
        })}
      >
        {children}
      </span>
    </button>
  );
}

function PointerButton(props: {
  disabled: boolean,
  selected: boolean,
  children: Node,
  onClick: (e: Event) => void,
}) {
  const { disabled, selected, children, onClick } = props;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classnames(styles.pointerButton, {
        [styles.selected]: selected,
        [styles.unselected]: !selected,
      })}
    >
      <span
        class={classnames(styles.pointerButtonText, {
          [styles.selected]: selected,
          [styles.unselected]: !selected,
        })}
      >
        {children}
      </span>
    </button>
  );
}

function StrokeWidthButton(props: {
  color: string,
  selected: boolean,
  widthClass: string,
}) {
  const { color, selected, widthClass } = props;
  return (
    <div
      className={classnames(styles.strokeWidthButtonOuter, widthClass, {
        [styles.selected]: selected,
      })}
    >
      <div
        className={classnames(styles.strokeWidthButtonInner, widthClass)}
        style={{
          backgroundColor: color,
        }}
      />
    </div>
  );
}

function StrokeWidthButtonGroup(props: {
  disabled: boolean,
  color: string,
  selectedIndex: number,
  onSelectIndex: number => void,
}) {
  const { disabled, color, selectedIndex } = props;
  return (
    <div className={styles.strokeWidthButtonContainer}>
      <div className={styles.strokeWidthButtonGroup}>
        {STORKE_WIDTH_CLASSES.map((className, index) => {
          return (
            <button
              disabled={disabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                props.onSelectIndex(index);
              }}
              key={index}
              className={classnames(styles.strokeWidthButtonWrapper)}
            >
              <StrokeWidthButton
                color={color}
                selected={selectedIndex === index}
                widthClass={className}
              />
            </button>
          );
        })}
      </div>
      <span className={styles.pickColorButtonText}>
        <Text translationKey="drawing.pencil_size" />
      </span>
    </div>
  );
}

function ToolGroup(props: {
  disabled: boolean,
  selectedTool: "pencil" | "eraser" | null,
  onClickPencilButton: (e: Event) => void,
  onClickEraserButton: (e: Event) => void,
  onClickPointerButton: (e: Event) => void,
}) {
  const {
    disabled,
    selectedTool,
    onClickPointerButton,
    onClickPencilButton,
  } = props;
  return (
    <div className={styles.toolGroup}>
      <div className={styles.pencilEraserButton}>
        <PointerButton
          disabled={disabled}
          onClick={onClickPointerButton}
          selected={selectedTool === null}
        >
          <Text translationKey="drawing.select" />
        </PointerButton>
        <PencilButton
          disabled={disabled}
          onClick={onClickPencilButton}
          selected={selectedTool === "pencil"}
        >
          <Text translationKey="drawing.pencil" />
        </PencilButton>
      </div>
    </div>
  );
}

function PencilGroup(props: {
  disabled: boolean,
  pencilColorIndex: number,
  strokeWidthIndex: number,
  onSelectPencilColorIndex: (i: number) => void,
  onSelectStrokeWidthIndex: (i: number) => void,
}) {
  const {
    disabled,
    pencilColorIndex,
    strokeWidthIndex,
    onSelectPencilColorIndex,
    onSelectStrokeWidthIndex,
  } = props;
  return (
    <div className={styles.pencilGroup}>
      <PickColorButton
        disabled={disabled}
        variant="pencil"
        selectedColorIndex={pencilColorIndex}
        onSelectColorIndex={onSelectPencilColorIndex}
      />
      <StrokeWidthButtonGroup
        disabled={disabled}
        color={getAvailableColors("pencil")[pencilColorIndex]}
        selectedIndex={strokeWidthIndex}
        onSelectIndex={onSelectStrokeWidthIndex}
      />
    </div>
  );
}

class AddShapeButton extends PureComponent<
  {
    disabled: boolean,
    onUserInteraction: () => void,
    onAddShape: (shape: Shape) => void,
  },
  {
    isOpened: boolean,
  }
> {
  state = {
    isOpened: false,
  };

  onClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => {
      return {
        isOpened: !prevState.isOpened,
      };
    });
    this.props.onUserInteraction();
  };

  onClickOutside = (e: Event) => {
    this.setState({ isOpened: false });
  };

  renderSelectionPanel() {
    const { isOpened } = this.state;
    if (!isOpened) {
      return null;
    }
    const { disabled, onAddShape } = this.props;
    return (
      <div className={styles.shapeSelectionPanel}>
        <p className={styles.shapeSelectionPanelTitleText}>
          <Text translationKey="drawing.select_shape" />
        </p>
        {AVAILABLE_SHAPES.map(shape => {
          return (
            <button
              disabled={disabled}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onAddShape(shape);
                this.setState({ isOpened: false });
              }}
              key={shape}
              className={styles.shapeButtonWrapper}
            >
              <div className={classnames(styles.shapeButton, styles[shape])} />
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { disabled } = this.props;
    const activeStyle = {
      [styles.active]: this.state.isOpened,
    };
    return (
      <PopupWrapper onClickOutside={this.onClickOutside}>
        <div className={styles.addShapeButtonContainer}>
          <button
            disabled={disabled}
            onClick={this.onClick}
            className={classnames(styles.addShapeButton, activeStyle)}
          >
            <span
              className={classnames(styles.addShapeButtonText, activeStyle)}
            >
              <Text translationKey="drawing.shape" />
            </span>
          </button>
          {this.renderSelectionPanel()}
        </div>
      </PopupWrapper>
    );
  }
}

class AddStickerButton extends PureComponent<
  {
    disabled: boolean,
    stickerImageURLs: $ReadOnlyArray<string>,
    onUserInteraction: () => void,
    onAddSticker: (url: string) => void,
  },
  {
    isOpened: boolean,
  }
> {
  state = {
    isOpened: false,
  };

  onClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => {
      return {
        isOpened: !prevState.isOpened,
      };
    });
    this.props.onUserInteraction();
  };

  onClickOutside = (e: Event) => {
    this.setState({ isOpened: false });
  };

  renderSelectionPanel() {
    const { isOpened } = this.state;
    if (!isOpened) {
      return null;
    }
    const { disabled, stickerImageURLs, onAddSticker } = this.props;
    const stickerWidth = 84;
    const stickerHeight = 84;
    return (
      <div className={styles.stickerSelectionPanel}>
        <p className={styles.stickerSelectionPanelTitleText}>
          <Text translationKey="drawing.select_sticker" />
        </p>
        <div className={styles.stickerSelectionPanelScrollView}>
          {stickerImageURLs.map(url => {
            return (
              <button
                key={url}
                disabled={disabled}
                className={styles.stickerButton}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onAddSticker(url);
                  this.setState({ isOpened: false });
                }}
              >
                <Image
                  imageUrl={url}
                  width={stickerWidth}
                  height={stickerHeight}
                  placeholderWidth={stickerWidth}
                  placeholderHeight={stickerHeight}
                />
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { disabled } = this.props;
    const activeStyle = {
      [styles.active]: this.state.isOpened,
    };
    return (
      <PopupWrapper onClickOutside={this.onClickOutside}>
        <div className={styles.addStickerButtonContainer}>
          <button
            disabled={disabled}
            onClick={this.onClick}
            className={classnames(styles.addStickerButton, activeStyle)}
          >
            <span
              className={classnames(styles.addStickerButtonText, activeStyle)}
            >
              <Text translationKey="drawing.sticker" />
            </span>
          </button>
          {this.renderSelectionPanel()}
        </div>
      </PopupWrapper>
    );
  }
}

function AddTextButton(props: {
  disabled: boolean,
  onAddTextButtonClick: (e: Event) => void,
  isSelectedObjectText: boolean,
}) {
  const { disabled, onAddTextButtonClick, isSelectedObjectText } = props;
  const selectedStyle = {
    [styles.selected]: isSelectedObjectText,
  };
  return (
    <button
      disabled={disabled}
      onClick={onAddTextButtonClick}
      className={classnames(styles.addTextButton, selectedStyle)}
    >
      <span className={classnames(styles.addTextButtonText, selectedStyle)}>
        <Text translationKey="drawing.text" />
      </span>
    </button>
  );
}

function AddImageButton(props: {
  disabled: boolean,
  onUserInteraction: () => void,
  onAddImageButtonClick: (e: SyntheticInputEvent<HTMLInputElement>) => void,
}) {
  const { disabled, onUserInteraction, onAddImageButtonClick } = props;
  return (
    <div className={styles.addImageButton}>
      <span className={styles.addImageButtonText}>
        <Text translationKey="drawing.image" />
      </span>
      <input
        disabled={disabled}
        type="file"
        className={styles.addImageButtonInput}
        onClick={(e: SyntheticInputEvent<HTMLInputElement>) => {
          // Always reset the value every time the element is clicked
          // so that onChange will fire even the same file is selected.
          e.target.value = "";
          onUserInteraction();
        }}
        onChange={onAddImageButtonClick}
      />
    </div>
  );
}

class PickColorButton extends PureComponent<
  {
    disabled: boolean,
    variant: "fill" | "stroke" | "pencil",
    selectedColorIndex: number,
    onSelectColorIndex: number => void,
  },
  {
    isPaletteOpened: boolean,
  }
> {
  state = {
    isPaletteOpened: false,
  };

  onClick = (e: Event) => {
    const { disabled } = this.props;
    e.preventDefault();
    e.stopPropagation();

    // should use pointer-events: none to disable mouse action
    // but at the same time, cursor couldn't stay in not-allowed style
    if (!disabled) {
      this.setState(prevState => {
        return {
          isPaletteOpened: !prevState.isPaletteOpened,
        };
      });
    }
  };

  onClickOutside = (e: Event) => {
    this.setState({
      isPaletteOpened: false,
    });
  };

  onSelectColorIndex = (i: number) => {
    this.setState({
      isPaletteOpened: false,
    });
    this.props.onSelectColorIndex(i);
  };

  renderPalette() {
    const { isPaletteOpened } = this.state;
    if (!isPaletteOpened) {
      return null;
    }
    const { variant, selectedColorIndex } = this.props;
    return (
      <ColorPalette
        variant={variant}
        selectedIndex={selectedColorIndex}
        onSelectColorIndex={this.onSelectColorIndex}
      />
    );
  }

  render() {
    const { disabled, variant, selectedColorIndex } = this.props;
    const color = getAvailableColors(variant)[selectedColorIndex];
    const fill = variant === "fill";
    const stroke = variant === "stroke";
    const pencil = variant === "pencil";
    const strokeColor = ensureOpaqueColor(color);
    const translationKey = "drawing." + variant;
    const shortTranslationKey = "drawing.short." + variant;
    return (
      <PopupWrapper onClickOutside={this.onClickOutside}>
        <div
          onClick={this.onClick}
          className={classnames(styles.pickColorButtonContainer, {
            [styles.pickPencilColorButtonContainer]: pencil,
            [styles.pickColorButtonContainerDisabled]: disabled,
          })}
        >
          <div
            className={styles.pickColorButtonContent}
            style={{
              zIndex: this.state.isPaletteOpened ? 1 : undefined,
            }}
          >
            <div
              style={{
                backgroundColor: fill || pencil ? color : undefined,
                border: stroke ? `5px solid ${strokeColor}` : undefined,
              }}
              className={classnames(styles.pickColorButton, {
                [styles.fill]: fill,
                [styles.pencil]: pencil,
                [styles.stroke]: stroke,
                [styles.transparent]: color === "transparent",
              })}
            >
              <ResponsiveLayout>
                {deviceType =>
                  deviceType === "desktop" ? this.renderPalette() : null
                }
              </ResponsiveLayout>
            </div>
            <span className={styles.pickColorButtonText}>
              <ResponsiveLayout>
                {deviceType =>
                  deviceType === "desktop" ? (
                    <Text translationKey={translationKey} />
                  ) : (
                    <Text translationKey={shortTranslationKey} />
                  )
                }
              </ResponsiveLayout>
            </span>
            <ResponsiveLayout>
              {deviceType =>
                deviceType === "desktop" ? null : this.renderPalette()
              }
            </ResponsiveLayout>
          </div>
        </div>
      </PopupWrapper>
    );
  }
}

function PickColorButtonGroup(props: {
  disabled: boolean,
  selectedStrokeColorIndex: number,
  onSelectStrokeColorIndex: number => void,
  selectedFillColorIndex: number,
  onSelectFillColorIndex: number => void,
}) {
  const {
    disabled,
    selectedStrokeColorIndex,
    onSelectStrokeColorIndex,
    selectedFillColorIndex,
    onSelectFillColorIndex,
  } = props;
  return (
    <div className={styles.pickColorButtonGroup}>
      <PickColorButton
        disabled={disabled}
        variant="stroke"
        selectedColorIndex={selectedStrokeColorIndex}
        onSelectColorIndex={onSelectStrokeColorIndex}
      />
      <PickColorButton
        disabled={disabled}
        variant="fill"
        selectedColorIndex={selectedFillColorIndex}
        onSelectColorIndex={onSelectFillColorIndex}
      />
    </div>
  );
}

class DeleteButtonClearButton extends PureComponent<
  {
    disabled: boolean,
    isSelectingSomeObject: boolean,
    onDeleteButtonClick: (e: Event) => void,
    onClearButtonClick: (e: Event) => void,
  },
  {
    isModalShown: boolean,
  }
> {
  state = {
    isModalShown: false,
  };

  onClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: false,
    });
  };

  onClearButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: true,
    });
  };

  onConfirmClearClick = (e: Event) => {
    this.setState({
      isModalShown: false,
    });
    this.props.onClearButtonClick(e);
  };

  render() {
    const { disabled, isSelectingSomeObject, onDeleteButtonClick } = this.props;
    return (
      <div className={classnames(styles.clearButtonOrDeleteButtonGroup)}>
        <button
          disabled={disabled || isSelectingSomeObject}
          onClick={this.onClearButtonClick}
          className={classnames(styles.clearButtonOrDeleteButton)}
        >
          <span className={styles.clearButtonOrDeleteButtonText}>
            <Text translationKey="drawing.clear" />
          </span>
        </button>
        <button
          disabled={disabled || !isSelectingSomeObject}
          onClick={onDeleteButtonClick}
          className={classnames(styles.clearButtonOrDeleteButton)}
        >
          <span className={styles.clearButtonOrDeleteButtonText}>
            <Text translationKey="drawing.delete" />
          </span>
        </button>
        {this.state.isModalShown && (
          <ModalContainer onClose={this.onClose}>
            <AlertModal
              colorVariant="red"
              titleKey="drawing.clear_confirmation"
              buttonKey="phrase.confirm"
              onButtonClick={this.onConfirmClearClick}
              onClose={this.onClose}
            />
          </ModalContainer>
        )}
      </div>
    );
  }
}

function CollapseButton(props: {
  onClick: (e: Event) => void,
  isCollapsed: boolean,
}) {
  const { onClick, isCollapsed } = props;
  return (
    <button onClick={onClick} className={styles.collapseButton}>
      <Arrow
        className={classnames(styles.collapseButtonArrow, {
          [styles.collapsed]: isCollapsed,
        })}
        width={9}
        height={19}
        strokeColor="white"
        strokeWidth={2}
        direction="left"
      />
    </button>
  );
}

function ColorPalette(props: {
  variant: "stroke" | "fill" | "pencil",
  selectedIndex: number,
  onSelectColorIndex: number => void,
}) {
  const { variant, selectedIndex } = props;
  let translationKey = "";
  const colors = getAvailableColors(variant);
  if (variant === "stroke") {
    translationKey = "drawing.stroke_color";
  } else if (variant === "fill") {
    translationKey = "drawing.fill_color";
  } else if (variant === "pencil") {
    translationKey = "drawing.pencil_color";
  }
  return (
    <div
      className={classnames(styles.colorPalette, {
        [styles.pencilColorPalette]: variant === "pencil",
      })}
    >
      <p className={styles.colorPaletteTitleText}>
        <Text translationKey={translationKey} />
      </p>
      {colors.map((color, index) => {
        const isTransparent = color === "transparent";
        const displayColor = ensureOpaqueColor(color);
        return (
          <button
            key={color}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.onSelectColorIndex(index);
            }}
            className={classnames(styles.colorPaletteItemContainer, {
              [styles.selected]: selectedIndex === index,
              [styles.transparent]: isTransparent,
            })}
          >
            <div
              style={{
                backgroundColor: displayColor,
              }}
              className={classnames(styles.colorPaletteItem, {
                [styles.selected]: selectedIndex === index,
              })}
            />
          </button>
        );
      })}
    </div>
  );
}

export default class Toolbox extends PureComponent<Props, LocalState> {
  containerRef: ?HTMLElement;

  state = {
    isCollapsed: false,
  };

  onTransitionEnd = (e: Event) => {
    this.props.onTransitionEnd(e);
  };

  onCollapseButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => {
      return {
        isCollapsed: !prevState.isCollapsed,
      };
    });
  };

  onSelectPencilColorIndex = (i: number) => {
    this.props.onSelectPencilColorIndex(i);
  };

  onSelectStrokeColorIndex = (i: number) => {
    this.props.onSelectStrokeColorIndex(i);
  };

  onSelectFillColorIndex = (i: number) => {
    this.props.onSelectFillColorIndex(i);
  };

  onSelectStrokeWidthIndex = (i: number) => {
    this.props.onSelectStrokeWidthIndex(i);
  };

  onClickPencilButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelectTool("pencil");
    this.props.onUserInteraction();
  };

  onClickEraserButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelectTool("eraser");
    this.props.onUserInteraction();
  };

  onClickPointerButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelectTool(null);
    this.props.onUserInteraction();
  };

  onClearButtonClick = (e: Event) => {
    this.props.onClearButtonClick(e);
    this.props.onUserInteraction();
  };

  onDeleteButtonClick = (e: Event) => {
    this.props.onDeleteButtonClick(e);
    this.props.onUserInteraction();
  };

  onAddTextButtonClick = (e: Event) => {
    this.props.onAddTextButtonClick(e);
    this.props.onUserInteraction();
  };

  onAddImageButtonClick = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onAddImageButtonClick(e);
    this.props.onUserInteraction();
  };

  onContainerRef = (r: HTMLElement | null) => {
    if (r != null) {
      r.addEventListener("transitionend", this.onTransitionEnd);
    } else if (this.containerRef != null) {
      this.containerRef.removeEventListener(
        "transitionend",
        this.onTransitionEnd
      );
    }
    this.containerRef = r;
  };

  render() {
    const { isCollapsed } = this.state;
    const {
      stickerImageURLs,
      readOnly,
      pencilColorIndex,
      strokeColorIndex,
      fillColorIndex,
      strokeWidthIndex,
      selectedTool,
      selectedObject,
    } = this.props.drawingState;
    const disabled = readOnly;
    const { onAddTextButtonClick, onAddImageButtonClick } = this;
    const { onAddShape, onAddSticker, onUserInteraction } = this.props;
    const isSelectingSomeObject = selectedObject != null;
    const pickColorButtonGroup = (
      <PickColorButtonGroup
        disabled={disabled}
        selectedStrokeColorIndex={strokeColorIndex}
        selectedFillColorIndex={fillColorIndex}
        onSelectStrokeColorIndex={this.onSelectStrokeColorIndex}
        onSelectFillColorIndex={this.onSelectFillColorIndex}
      />
    );
    const addTextButton = (
      <AddTextButton
        disabled={disabled}
        onAddTextButtonClick={onAddTextButtonClick}
        isSelectedObjectText={isSelectedObjectText(this.props.drawingState)}
      />
    );
    const addShapeButton = (
      <AddShapeButton
        disabled={disabled}
        onUserInteraction={onUserInteraction}
        onAddShape={onAddShape}
      />
    );
    const addStickerButton = (
      <AddStickerButton
        disabled={disabled}
        stickerImageURLs={stickerImageURLs}
        onUserInteraction={onUserInteraction}
        onAddSticker={onAddSticker}
      />
    );
    const addImageButton = (
      <AddImageButton
        disabled={disabled}
        onUserInteraction={onUserInteraction}
        onAddImageButtonClick={onAddImageButtonClick}
      />
    );
    const desktopLayout = (
      <Fragment>
        <div className={styles.addTextAndAddShapeGroup}>
          {addTextButton}
          {addShapeButton}
        </div>
        {pickColorButtonGroup}
        <div className={styles.addStickerAndImageGroup}>
          {addStickerButton}
          {addImageButton}
        </div>
      </Fragment>
    );
    const mobileLayout = (
      <Fragment>
        <div className={styles.addStickerAndImageGroup}>
          {addStickerButton}
          {addImageButton}
        </div>
        <div className={styles.addTextAndAddShapeGroup}>
          {addTextButton}
          {addShapeButton}
        </div>
        {pickColorButtonGroup}
      </Fragment>
    );
    return (
      <div
        ref={this.onContainerRef}
        className={classnames(styles.toolbox, {
          [styles.collapsed]: isCollapsed,
        })}
      >
        <ToolGroup
          disabled={readOnly}
          selectedTool={selectedTool}
          onClickPencilButton={this.onClickPencilButton}
          onClickEraserButton={this.onClickEraserButton}
          onClickPointerButton={this.onClickPointerButton}
        />
        <PencilGroup
          disabled={readOnly}
          pencilColorIndex={pencilColorIndex}
          strokeWidthIndex={strokeWidthIndex}
          onSelectPencilColorIndex={this.onSelectPencilColorIndex}
          onSelectStrokeWidthIndex={this.onSelectStrokeWidthIndex}
        />
        <ResponsiveLayout>
          {deviceType =>
            deviceType === "desktop" ? desktopLayout : mobileLayout
          }
        </ResponsiveLayout>
        <DeleteButtonClearButton
          disabled={readOnly}
          isSelectingSomeObject={isSelectingSomeObject}
          onClearButtonClick={this.onClearButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
        />
        <CollapseButton
          onClick={this.onCollapseButtonClick}
          isCollapsed={isCollapsed}
        />
      </div>
    );
  }
}
