// @flow
import React, { PureComponent } from "react";
import { toCodePoints } from "../../utils/string";

type Props = {
  lines: number,
  lineHeight: number,
  fontWeight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
  fontSize: number,
  children: string,
  ellipsis: string,
  // Note that padding is not respected
  className: string,
};

type LocalState = {
  needTruncate: ?boolean,
  codePoints: $ReadOnlyArray<string>,
  codePointOffset: ?number,
  maxHeight: ?number,
};

const SHADOW_TEXT_VIEW_STATIC_STYLE = {
  // Make shadow text view invisible.
  // We cannot use display: none since
  // We do want browser to layout it.
  opacity: "0",
  // Remove all contraint on heights
  // so that the text is free to grow
  height: "auto",
  maxHeight: "none",
  minHeight: "none",
  // We must allow breaking all words
  // because our current algorithm makes
  // this assumption.
  wordBreak: "break-all",
};

const ACTUAL_TEXT_VIEW_STATIC_STYLE = {
  // So that it will draw over the shadow text view
  position: "absolute",
  // Same as shadow text view
  wordBreak: "break-all",
};

function getDerivedStateFromProps(props: Props): LocalState {
  return {
    codePoints: toCodePoints(props.children),
    needTruncate: null,
    codePointOffset: null,
    maxHeight: null,
  };
}

export default class TruncatedText extends PureComponent<Props, LocalState> {
  static defaultProps = {
    ellipsis: "...",
  };

  shadowTextView: ?HTMLElement;
  actualTextView: ?HTMLElement;

  constructor(props: Props) {
    super(props);
    this.state = getDerivedStateFromProps(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    const { shadowTextView } = this;
    if (shadowTextView == null) {
      return;
    }
    this._deriveState(shadowTextView, this.props, this.state);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.children !== nextProps.children) {
      this.setState(getDerivedStateFromProps(nextProps));
    }
  }

  componentDidUpdate() {
    const { shadowTextView, actualTextView } = this;
    if (shadowTextView == null) {
      return;
    }
    this._deriveState(shadowTextView, this.props, this.state);
    if (actualTextView == null) {
      return;
    }
    const offsetHeight = actualTextView.offsetHeight;
    if (offsetHeight !== this.state.maxHeight) {
      this.setState({
        maxHeight: offsetHeight,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onShadowTextViewRef = (r: HTMLElement | null) => {
    this.shadowTextView = r;
    const { shadowTextView } = this;
    if (shadowTextView == null) {
      return;
    }
    this._deriveState(shadowTextView, this.props, this.state);
  };

  onActualTextViewRef = (r: HTMLElement | null) => {
    this.actualTextView = r;
  };

  onWindowResize = () => {
    const { shadowTextView } = this;
    if (shadowTextView == null) {
      return;
    }
    this._deriveState(shadowTextView, this.props, this.state);
  };

  render() {
    const {
      lineHeight,
      fontWeight,
      fontSize,
      children,
      className,
    } = this.props;
    const { needTruncate, maxHeight } = this.state;
    const textStyle = {
      fontSize: fontSize + "px",
      lineHeight: lineHeight + "px",
      fontWeight,
    };
    return (
      <div
        style={{
          // The shadow text view can be very tall
          // so we have to ensure it will not overflow
          overflow: "hidden",
          position: "relative",
          maxHeight:
            needTruncate === true && maxHeight != null ? maxHeight : undefined,
        }}
        className={className}
      >
        <span
          ref={this.onShadowTextViewRef}
          style={{
            ...SHADOW_TEXT_VIEW_STATIC_STYLE,
            ...textStyle,
          }}
        >
          {children}
        </span>
        <span
          ref={this.onActualTextViewRef}
          style={{
            top: "0",
            left: "0",
            ...ACTUAL_TEXT_VIEW_STATIC_STYLE,
            ...textStyle,
          }}
        >
          {this.getText()}
        </span>
      </div>
    );
  }

  getText() {
    const { children, ellipsis } = this.props;
    const { needTruncate, codePoints, codePointOffset } = this.state;
    if (needTruncate == null) {
      return null;
    }
    if (needTruncate === false) {
      return children;
    }
    if (codePointOffset != null) {
      const text = codePoints.slice(0, codePointOffset).join("") + ellipsis;
      return text;
    }
    return null;
  }

  _deriveState(el: HTMLElement, props: Props, state: LocalState) {
    const { lines, ellipsis, lineHeight } = props;
    const desiredHeight = lines * lineHeight;
    const textContent = el.textContent;
    const { codePoints } = state;
    if (el.offsetHeight <= desiredHeight) {
      this.setState({
        needTruncate: false,
      });
      return;
    }

    // Binary search
    let min = 0;
    let max = codePoints.length - 1;
    let mid = 0;

    // Prevent infinite loop
    const MAX_ITER = 100;
    let i = 0;

    while (min < max && i < MAX_ITER) {
      mid = Math.floor((min + max) / 2);
      el.textContent = codePoints.slice(0, mid).join("") + ellipsis;
      const offsetHeight = el.offsetHeight;
      if (offsetHeight > desiredHeight) {
        max = mid;
      } else if (offsetHeight < desiredHeight) {
        min = mid;
      } else {
        break;
      }
      i += 1;
    }
    // Recover the orginal content
    el.textContent = textContent;

    if (state.codePointOffset !== mid) {
      this.setState({
        needTruncate: true,
        codePointOffset: mid,
      });
    }
  }
}
