// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOne } from "../../redux/actions/templates";
import TemplateHeader from "../../components/TemplateHeader/TemplateHeader";
import ActivitiesPanel from "../../components/ActivitiesPanel/ActivitiesPanel";
import ActivityPanel from "../../components/ActivityPanel/ActivityPanel";
import WindowSize from "../../components/WindowSize";
import Text from "../../components/Text/Text";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ErrorModal from "../../components/ErrorModal";
import { createTemplate } from "../../redux/actions/createTemplate";
import type { RootState, Dispatch } from "../../types/store";
import type { ContextRouter } from "react-router-dom";
import type { Template, CreateTemplateParameter } from "../../types/template";
import type { RouterHistory } from "react-router-dom";
import styles from "./styles/Template.module.scss";

type Props = {
  +history: RouterHistory,
  +id: string,
  +isRequesting: boolean,
  +error: mixed,
  +templatesById: { [id: string]: Template },
  +fetchOne: string => void,
  +createTemplate: CreateTemplateParameter => Promise<Template>,
};

type LocalState = {|
  activeActivityIdx: number,
|};

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
const MAGIC_HEIGHT_OFFSET = 138;

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// templateContainer border-top: 8px
// templateContainer header: 86px
// templateContainer header border-bottom: 1px
// templateContainer paddingTop: 28px;
// layout footer: 44px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 305;

class TemplateComponent extends PureComponent<Props, LocalState> {
  state = {
    activeActivityIdx: 0,
  };

  componentDidMount() {
    this.props.fetchOne(this.props.id);
  }

  getTemplate(): Template {
    const { id, templatesById } = this.props;
    return templatesById[id];
  }

  onClickActivityPanelItem = idx => {
    const template = this.getTemplate();

    if (idx >= 0 && idx < template.activities.length) {
      this.setState({ activeActivityIdx: idx });
    }
  };

  onCreateTemplate = (templateId: string, newName: string) => {
    this.props
      .createTemplate({
        baseId: templateId,
        newName,
      })
      .then(createdTemplate => {
        this.props.history.push({
          pathname: `/template/${createdTemplate.id}`,
        });
      });
  };

  renderActivitiesPanel() {
    const template = this.getTemplate();
    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.activitiesPanel}
            >
              <p className={styles.activitiesTitle}>
                <Text
                  translationKey="teacher.number_of_activities2"
                  values={{ numberOfActivities: template.activities.length }}
                />
              </p>
              <ActivitiesPanel
                variant="teacherPortal"
                activities={template.activities}
                activeActivityIdx={this.state.activeActivityIdx}
                onClick={this.onClickActivityPanelItem}
              />
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderActivityDetailPanel() {
    const template = this.getTemplate();
    const { activeActivityIdx } = this.state;

    return (
      <div className={styles.activityDetailPanel}>
        <ActivityPanel
          templateName={template.name}
          templateDisplayCode={template.displayCode}
          templateLang={template.lang}
          activityIndex={activeActivityIdx}
          activity={this.getTemplate().activities[activeActivityIdx]}
        />
      </div>
    );
  }

  render() {
    const { isRequesting, error } = this.props;
    const template: Template = this.getTemplate();

    // layout top margin: 19px;
    // layout header: 100px;
    // layout header bottom margin: 19px;

    if (!template) {
      return (
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            return (
              <div
                style={{
                  minHeight: h + "px",
                }}
                className={styles.templateContainer}
              >
                {isRequesting && <LoadingIndicator />}
              </div>
            );
          }}
        </WindowSize>
      );
    }

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.templateContainer}
            >
              <TemplateHeader
                template={template}
                onCreateTemplate={this.onCreateTemplate}
              />
              <div className={styles.activitiesContainer}>
                {this.renderActivitiesPanel()}
                {this.renderActivityDetailPanel()}
              </div>
              <ErrorModal error={error} />
            </div>
          );
        }}
      </WindowSize>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: ContextRouter) {
  return {
    id: ownProps.match.params.id,
    isRequesting: state.templates.isRequesting,
    error: state.templates.error,
    templatesById: state.templates.templatesById,
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: Props) {
  return {
    fetchOne: bindActionCreators(fetchOne, dispatch),
    createTemplate: bindActionCreators(createTemplate, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateComponent);
