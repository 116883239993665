//@flow
import skygear from "skygear";
import {
  templateDeserializer,
  deserializePageInfo,
} from "../../utils/deserializer";
import { activitySerializer } from "../../utils/serializer";
import {
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_YEAR_FORM_2,
  TEMPLATE_YEAR_FORM_3,
  TEMPLATE_YEAR_FORM_ALL,
  TEMPLATE_LANG_ALL,
} from "../../types/template";
import type {
  Template,
  TEMPLATE_YEAR_FORM,
  TEMPLATE_LANG,
  FetchAllTemplatesRequesting,
  FetchAllTemplatesSuccess,
  FetchAllTemplatesFailure,
  FetchOneTemplateRequesting,
  FetchOneTemplateSuccess,
  FetchOneTemplateFailure,
  EditCustomTemplateRequesting,
  EditCustomTemplateSuccess,
  EditCustomTemplateFailure,
  DeleteCustomTemplateRequesting,
  DeleteCustomTemplateSuccess,
  DeleteCustomTemplateFailure,
  SearchImportedTemplatesRequesting,
  SearchImportedTemplatesSuccess,
  SearchImportedTemplatesFailure,
  CreateImportedTemplateRequesting,
  CreateImportedTemplateSuccess,
  CreateImportedTemplateFailure,
  EditImportedTemplateRequesting,
  EditImportedTemplateSuccess,
  EditImportedTemplateFailure,
  DeleteImportedTemplateRequesting,
  DeleteImportedTemplateSuccess,
  DeleteImportedTemplateFailure,
  TEMPLATE_YEAR,
} from "../../types/template";
import type { Dispatch, GetState } from "../../types/store";
import type { PageInfo } from "../../types/request";
import { ongoingRequestError } from "../../types/error";

export const FETCH_ALL_REQUESTING: "TEMPLATES.FETCH_ALL.REQUESTING" =
  "TEMPLATES.FETCH_ALL.REQUESTING";
export const FETCH_ALL_SUCCESS: "TEMPLATES.FETCH_ALL.SUCCESS" =
  "TEMPLATES.FETCH_ALL.SUCCESS";
export const FETCH_ALL_FAILURE: "TEMPLATES.FETCH_ALL.FAILURE" =
  "TEMPLATES.FETCH_ALL.FAILURE";

export const FETCH_ONE_REQUESTING: "TEMPLATES.FETCH_ONE.REQUESTING" =
  "TEMPLATES.FETCH_ONE.REQUESTING";
export const FETCH_ONE_SUCCESS: "TEMPLATES.FETCH_ONE.SUCCESS" =
  "TEMPLATES.FETCH_ONE.SUCCESS";
export const FETCH_ONE_FAILURE: "TEMPLATES.FETCH_ONE.FAILURE" =
  "TEMPLATES.FETCH_ONE.FAILURE";

export const EDIT_CUSTOM_REQUESTING: "TEMPLATES.EDIT_CUSTOM.REQUESTING" =
  "TEMPLATES.EDIT_CUSTOM.REQUESTING";
export const EDIT_CUSTOM_SUCCESS: "TEMPLATES.EDIT_CUSTOM.SUCCESS" =
  "TEMPLATES.EDIT_CUSTOM.SUCCESS";
export const EDIT_CUSTOM_FAILURE: "TEMPLATES.EDIT_CUSTOM.FAILURE" =
  "TEMPLATES.EDIT_CUSTOM.FAILURE";

export const DELETE_CUSTOM_REQUESTING: "TEMPLATES.DELETE_CUSTOM.REQUESTING" =
  "TEMPLATES.DELETE_CUSTOM.REQUESTING";
export const DELETE_CUSTOM_SUCCESS: "TEMPLATES.DELETE_CUSTOM.SUCCESS" =
  "TEMPLATES.DELETE_CUSTOM.SUCCESS";
export const DELETE_CUSTOM_FAILURE: "TEMPLATES.DELETE_CUSTOM.FAILURE" =
  "TEMPLATES.DELETE_CUSTOM.FAILURE";

export const SEARCH_IMPORTED_REQUESTING: "TEMPLATES.SEARCH_IMPORTED.REQUESTING" =
  "TEMPLATES.SEARCH_IMPORTED.REQUESTING";
export const SEARCH_IMPORTED_SUCCESS: "TEMPLATES.SEARCH_IMPORTED.SUCCESS" =
  "TEMPLATES.SEARCH_IMPORTED.SUCCESS";
export const SEARCH_IMPORTED_FAILURE: "TEMPLATES.SEARCH_IMPORTED.FAILURE" =
  "TEMPLATES.SEARCH_IMPORTED.FAILURE";

export const CREATE_IMPORTED_REQUESTING: "TEMPLATES.CREATE_IMPORTED.REQUESTING" =
  "TEMPLATES.CREATE_IMPORTED.REQUESTING";
export const CREATE_IMPORTED_SUCCESS: "TEMPLATES.CREATE_IMPORTED.SUCCESS" =
  "TEMPLATES.CREATE_IMPORTED.SUCCESS";
export const CREATE_IMPORTED_FAILURE: "TEMPLATES.CREATE_IMPORTED.FAILURE" =
  "TEMPLATES.CREATE_IMPORTED.FAILURE";

export const EDIT_IMPORTED_REQUESTING: "TEMPLATES.EDIT_IMPORTED.REQUESTING" =
  "TEMPLATES.EDIT_IMPORTED.REQUESTING";
export const EDIT_IMPORTED_SUCCESS: "TEMPLATES.EDIT_IMPORTED.SUCCESS" =
  "TEMPLATES.EDIT_IMPORTED.SUCCESS";
export const EDIT_IMPORTED_FAILURE: "TEMPLATES.EDIT_IMPORTED.FAILURE" =
  "TEMPLATES.EDIT_IMPORTED.FAILURE";

export const DELETE_IMPORTED_REQUESTING: "TEMPLATES.DELETE_IMPORTED.REQUESTING" =
  "TEMPLATES.DELETE_IMPORTED.REQUESTING";
export const DELETE_IMPORTED_SUCCESS: "TEMPLATES.DELETE_IMPORTED.SUCCESS" =
  "TEMPLATES.DELETE_IMPORTED.SUCCESS";
export const DELETE_IMPORTED_FAILURE: "TEMPLATES.DELETE_IMPORTED.FAILURE" =
  "TEMPLATES.DELETE_IMPORTED.FAILURE";

export function fetchAllTemplatesRequesting(): FetchAllTemplatesRequesting {
  return {
    type: FETCH_ALL_REQUESTING,
  };
}

export function fetchAllTemplatesSuccess(
  templatesById: { [id: string]: Template },
  form1TemplateIds: $ReadOnlyArray<string>,
  form2TemplateIds: $ReadOnlyArray<string>,
  form3TemplateIds: $ReadOnlyArray<string>
): FetchAllTemplatesSuccess {
  return {
    type: FETCH_ALL_SUCCESS,
    payload: {
      templatesById,
      form1TemplateIds,
      form2TemplateIds,
      form3TemplateIds,
    },
  };
}

export function fetchAllTemplatesFailure(
  error: ?Error
): FetchAllTemplatesFailure {
  return {
    type: FETCH_ALL_FAILURE,
    payload: error,
  };
}

export function fetchOneTemplateRequesting(): FetchOneTemplateRequesting {
  return {
    type: FETCH_ONE_REQUESTING,
  };
}

export function fetchOneTemplateSuccess(
  template: Template
): FetchOneTemplateSuccess {
  return {
    type: FETCH_ONE_SUCCESS,
    payload: template,
  };
}

export function fetchOneTemplateFailure(
  error: ?Error
): FetchOneTemplateFailure {
  return {
    type: FETCH_ONE_FAILURE,
    payload: error,
  };
}

export function editCustomTemplateRequesting(): EditCustomTemplateRequesting {
  return {
    type: EDIT_CUSTOM_REQUESTING,
  };
}

export function editCustomTemplateSuccess(
  template: Template
): EditCustomTemplateSuccess {
  return {
    type: EDIT_CUSTOM_SUCCESS,
    payload: template,
  };
}

export function editCustomTemplateFailure(
  error: ?mixed
): EditCustomTemplateFailure {
  return {
    type: EDIT_CUSTOM_FAILURE,
    payload: error,
  };
}

export function deleteCustomTemplateRequesting(): DeleteCustomTemplateRequesting {
  return {
    type: DELETE_CUSTOM_REQUESTING,
  };
}

export function deleteCustomTemplateSuccess(
  template: Template
): DeleteCustomTemplateSuccess {
  return {
    type: DELETE_CUSTOM_SUCCESS,
    payload: template,
  };
}

export function deleteCustomTemplateFailure(
  error: ?mixed
): DeleteCustomTemplateFailure {
  return {
    type: DELETE_CUSTOM_FAILURE,
    payload: error,
  };
}

function templateIdsByYear(
  templates: $ReadOnlyArray<Template>,
  year: TEMPLATE_YEAR
) {
  const templatesByYear = templates.filter(t => t.year === year);
  return templatesByYear.map(t => t.id);
}

export function fetchAll() {
  return (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAllTemplatesRequesting());
    const args = {};

    return skygear
      .lambda("polyupaths:unit_template:query_all", [args])
      .then(result => {
        const templates = result.unit_templates.map(templateDeserializer);
        const templatesById = templates.reduce(
          (accu, cur) => ({
            ...accu,
            [cur.id]: cur,
          }),
          {}
        );
        const form1TemplateIds = templateIdsByYear(
          templates,
          TEMPLATE_YEAR_FORM_1
        );
        const form2TemplateIds = templateIdsByYear(
          templates,
          TEMPLATE_YEAR_FORM_2
        );
        const form3TemplateIds = templateIdsByYear(
          templates,
          TEMPLATE_YEAR_FORM_3
        );
        dispatch(
          fetchAllTemplatesSuccess(
            templatesById,
            form1TemplateIds,
            form2TemplateIds,
            form3TemplateIds
          )
        );
      })
      .catch(error => {
        dispatch(fetchAllTemplatesFailure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchOne(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(fetchOneTemplateRequesting());
    const args = {
      template_id: id,
    };

    skygear
      .lambda("polyupaths:unit_template:query_one", [args])
      .then(result =>
        dispatch(
          fetchOneTemplateSuccess(templateDeserializer(result.unit_template))
        )
      )
      .catch(error => {
        dispatch(fetchOneTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}

export function editCustom(template: Template) {
  return (dispatch: Dispatch, getState: GetState): Promise<Template> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(editCustomTemplateRequesting());
    const args = {
      template_id: template.id,
      template_name: template.name.trim(),
      activities: template.activities.map(activitySerializer),
    };

    return skygear
      .lambda("polyupaths:unit_template:edit_custom", [args])
      .then(result => {
        const template = templateDeserializer(result.unit_template);
        dispatch(editCustomTemplateSuccess(template));
        return template;
      })
      .catch(error => {
        dispatch(editCustomTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}

export function deleteCustom(templateId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(deleteCustomTemplateRequesting());
    const args = {
      template_id: templateId,
    };

    return skygear
      .lambda("polyupaths:unit_template:delete_custom", [args])
      .then(result => {
        const template = templateDeserializer(result.unit_template);
        dispatch(deleteCustomTemplateSuccess(template));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(deleteCustomTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}

function searchImportedTemplatesRequesting(): SearchImportedTemplatesRequesting {
  return {
    type: SEARCH_IMPORTED_REQUESTING,
  };
}

function searchImportedTemplatesSuccess(
  templates: $ReadOnlyArray<Template>
): SearchImportedTemplatesSuccess {
  return {
    type: SEARCH_IMPORTED_SUCCESS,
    payload: templates,
  };
}

function searchImportedTemplatesFailure(
  error: mixed
): SearchImportedTemplatesFailure {
  return {
    type: SEARCH_IMPORTED_FAILURE,
    payload: error,
  };
}

export function searchImportedTemplates(data: {
  keyword: string,
  year: TEMPLATE_YEAR_FORM,
  lang: TEMPLATE_LANG,
  pageSize: number,
  pageNumber: number,
}) {
  const { keyword, year, lang, pageSize, pageNumber } = data;
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{
    templates: $ReadOnlyArray<Template>,
    pageInfo: PageInfo,
  }> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(searchImportedTemplatesRequesting());
    const args = {
      keyword,
      year: year === TEMPLATE_YEAR_FORM_ALL ? null : year,
      lang: lang === TEMPLATE_LANG_ALL ? null : lang,
      page_args: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    };

    return skygear
      .lambda("polyupaths:unit_template:search", [args])
      .then(result => {
        const templates = result.unit_templates.map(templateDeserializer);
        const pageInfo = deserializePageInfo(result.page_info);
        dispatch(searchImportedTemplatesSuccess(templates));
        return { templates, pageInfo };
      })
      .catch(error => {
        dispatch(searchImportedTemplatesFailure(error));
        return Promise.reject(error);
      });
  };
}

export function createImportedTemplateRequesting(): CreateImportedTemplateRequesting {
  return {
    type: CREATE_IMPORTED_REQUESTING,
  };
}

export function createImportedTemplateSuccess(
  template: Template
): CreateImportedTemplateSuccess {
  return {
    type: CREATE_IMPORTED_SUCCESS,
    payload: template,
  };
}

export function createImportedTemplateFailure(
  error: ?mixed
): CreateImportedTemplateFailure {
  return {
    type: CREATE_IMPORTED_FAILURE,
    payload: error,
  };
}

export function createImported(template: Template) {
  return (dispatch: Dispatch, getState: GetState): Promise<Template> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(createImportedTemplateRequesting());
    const args = {
      code: template.displayCode,
      name: template.name,
      year: template.year,
      lang: template.lang,
      activities: template.activities.map(activitySerializer),
    };

    return skygear
      .lambda("polyupaths:unit_template:create_imported", [args])
      .then(result => {
        const template = templateDeserializer(result.unit_template);
        dispatch(createImportedTemplateSuccess(template));
        return template;
      })
      .catch(error => {
        dispatch(createImportedTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}

export function editImportedTemplateRequesting(): EditImportedTemplateRequesting {
  return {
    type: EDIT_IMPORTED_REQUESTING,
  };
}

export function editImportedTemplateSuccess(
  template: Template
): EditImportedTemplateSuccess {
  return {
    type: EDIT_IMPORTED_SUCCESS,
    payload: template,
  };
}

export function editImportedTemplateFailure(
  error: ?mixed
): EditImportedTemplateFailure {
  return {
    type: EDIT_IMPORTED_FAILURE,
    payload: error,
  };
}

export function editImported(template: Template) {
  return (dispatch: Dispatch, getState: GetState): Promise<Template> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(editImportedTemplateRequesting());
    const args = {
      template_id: template.id,
      code: template.displayCode,
      name: template.name,
      year: template.year,
      lang: template.lang,
      activities: template.activities.map(activitySerializer),
    };

    return skygear
      .lambda("polyupaths:unit_template:edit_imported", [args])
      .then(result => {
        const template = templateDeserializer(result.unit_template);
        dispatch(editImportedTemplateSuccess(template));
        return template;
      })
      .catch(error => {
        dispatch(editImportedTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}

export function deleteImportedTemplateRequesting(): DeleteImportedTemplateRequesting {
  return {
    type: DELETE_IMPORTED_REQUESTING,
  };
}

export function deleteImportedTemplateSuccess(
  template: Template
): DeleteImportedTemplateSuccess {
  return {
    type: DELETE_IMPORTED_SUCCESS,
    payload: template,
  };
}

export function deleteImportedTemplateFailure(
  error: ?mixed
): DeleteImportedTemplateFailure {
  return {
    type: DELETE_IMPORTED_FAILURE,
    payload: error,
  };
}

export function deleteImported(templateId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const currentState = getState();
    if (currentState.templates.isRequesting) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(deleteImportedTemplateRequesting());
    const args = {
      template_id: templateId,
    };

    return skygear
      .lambda("polyupaths:unit_template:delete_imported", [args])
      .then(result => {
        const template = templateDeserializer(result.unit_template);
        dispatch(deleteImportedTemplateSuccess(template));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(deleteImportedTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}
