// @flow
import React, { PureComponent } from "react";
import { DragSource, DropTarget } from "react-dnd";
import classnames from "classnames";
import Text from "../Text/Text";
import { collectTarget, dragTarget } from "./ActivityPanelItemDragTarget";
import { collectSource, dragSource } from "./ActivityPanelItemDragSource";
import { ActivityPanelItemDnDType } from "../../types/activity";
import type { Activity } from "../../types/activity";
import type { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import styles from "./ActivityPanelItem.module.scss";

export type ComponentProps = {
  className: string,
  activityDisplayIndex?: number,
  isActive: boolean,
  isError: boolean,
  activity: Activity,
  onClickInfo?: mixed,
  onClick?: (Event, mixed) => void,
};

export type DefaultProps = {
  showActivityDisplayIndex: boolean,
  canDrag: boolean,
  isActive: boolean,
  isError: boolean,
};

export type ConnectedProps = {
  isDragging: boolean,
  index: number,
  onChangeOrder?: (number, number) => void,
  connectDragSource: ConnectDragSource,
  connectDropTarget: ConnectDropTarget,
};

export type Props = ComponentProps & DefaultProps & ConnectedProps;

class ActivityPanelItem extends PureComponent<Props> {
  static defaultProps: DefaultProps = {
    showActivityDisplayIndex: false,
    canDrag: false,
    isActive: false,
    isError: false,
  };

  onClick = (e: Event) => {
    if (this.props.onClick) {
      this.props.onClick(e, this.props.onClickInfo);
    }
  };

  renderActivityPanelItem() {
    const {
      isActive,
      isError,
      activity,
      className,
      canDrag,
      isDragging,
    } = this.props;

    return (
      <div
        className={classnames(
          styles.activityContainer,
          {
            [styles.active]: isActive,
            [styles.error]: isError,
            [styles.draggable]: canDrag,
            [styles.isDragging]: isDragging,
          },
          className
        )}
        tabIndex={isActive ? 0 : -1}
        onClick={this.onClick}
      >
        <p
          className={classnames(styles.activityName, {
            [styles.active]: isActive,
            [styles.draggable]: canDrag,
          })}
        >
          <span>{this.getActivityName()}</span>
        </p>
        <p className={styles.activityType}>
          <Text translationKey={`teacher.activity_type_${activity.type}`} />
        </p>
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;

    return connectDragSource(connectDropTarget(this.renderActivityPanelItem()));
  }

  getActivityName() {
    const {
      showActivityDisplayIndex,
      activityDisplayIndex,
      activity,
    } = this.props;
    if (showActivityDisplayIndex && activityDisplayIndex != null) {
      return activityDisplayIndex + " " + activity.name;
    }
    return activity.name;
  }
}

export default ((DropTarget(
  ActivityPanelItemDnDType,
  dragTarget,
  collectTarget
)(
  DragSource(ActivityPanelItemDnDType, dragSource, collectSource)(
    ActivityPanelItem
  )
): any): Class<React$Component<ComponentProps>>);
