// @flow
import type { Action } from "../../types/store";
import type { AuthState } from "../../types/auth";
import { REQUESTING, SUCCESS, FAILURE } from "../actions/auth";

const INITIAL_STATE: AuthState = {
  user: null,
  isRequesting: false,
  error: null,
};

export function reducer(state: AuthState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case REQUESTING:
      return {
        user: null,
        isRequesting: true,
        error: null,
      };
    case SUCCESS:
      return {
        user: action.payload,
        isRequesting: false,
        error: null,
      };
    case FAILURE:
      return {
        user: null,
        isRequesting: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
