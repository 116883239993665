// @flow
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import type { IntlShape } from "react-intl";
import type { RootState } from "../../types/store";

type Props = {|
  +intl: IntlShape,
  +translationKey: string,
  // eslint-disable-next-line flowtype/no-weak-types
  values?: Object,
|};

class Option extends PureComponent<Props> {
  static childContextTypes = {
    intl: PropTypes.any,
  };

  getChildContext() {
    return {
      intl: this.props.intl,
    };
  }

  render() {
    const { translationKey, values, ...rest } = this.props;

    if (!translationKey) {
      return <option {...rest}>UNDEFINED</option>;
    }

    return (
      <FormattedMessage
        id={translationKey}
        values={values}
        defaultMessage={translationKey || "UNDEFINED"}
      >
        {txt => <option {...rest}>{txt}</option>}
      </FormattedMessage>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Option);
