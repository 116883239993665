// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import type { Activity } from "../../types/activity";
import ActivityPanelItem from "./ActivityPanelItem";
import styles from "./ActivitiesPanel.module.scss";

type Props = {
  // required
  +variant: "teacherPortal" | "teacherSession" | "adminPortal",
  +activities: $ReadOnlyArray<Activity>,
  +activeActivityIdx: number,
  +errorActivityIndices?: $ReadOnlyArray<number>,
  // defaults
  +canDrag?: boolean,
  +listenKeyboardEvents?: boolean,
  // actions
  +onClick?: (idx: number) => void,
  +onChangeOrder?: (number, number) => void,
};

class ActivitiesPanel extends PureComponent<Props> {
  static defaultProps = {
    listenKeyboardEvents: true,
    canDrag: false,
  };

  onActivityPanelItemClick = (e: Event, idx: mixed) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof idx === "number" && this.props.onClick) {
      this.props.onClick(idx);
    }
  };

  handleOnKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    if (this.props.listenKeyboardEvents !== true) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (this.props.onClick) {
      switch (event.key) {
        case "ArrowDown":
          this.props.onClick(this.props.activeActivityIdx + 1);
          break;
        case "ArrowUp":
          this.props.onClick(this.props.activeActivityIdx - 1);
          break;
        case "Tab":
          this.props.onClick(
            (this.props.activeActivityIdx + 1) % this.props.activities.length
          );
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { activities, variant, onChangeOrder, canDrag } = this.props;

    const activityPanelItems = activities.map((activity, idx) => {
      const isActive = this.props.activeActivityIdx === idx;
      const isError =
        this.props.errorActivityIndices != null &&
        this.props.errorActivityIndices.includes(idx);
      return (
        <ActivityPanelItem
          className={classnames({
            [styles.teacherPortal]: variant === "teacherPortal",
            [styles.teacherSession]: variant === "teacherSession",
            [styles.adminPortal]: variant === "adminPortal",
          })}
          key={idx}
          onClick={this.onActivityPanelItemClick}
          onClickInfo={idx}
          activity={activity}
          isActive={isActive}
          isError={isError}
          canDrag={canDrag}
          index={idx}
          onChangeOrder={onChangeOrder}
        />
      );
    });

    return (
      <div
        onKeyDown={this.handleOnKeyDown}
        className={classnames({
          [styles.teacherSessionContainer]: variant === "teacherSession",
        })}
      >
        {activityPanelItems}
      </div>
    );
  }
}

export default ((DragDropContext(HTML5Backend)(ActivitiesPanel): any): Class<
  React$Component<Props>
>);
