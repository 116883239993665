// @flow
import React, { PureComponent } from "react";
import FormattedDate from "../FormattedDate/FormattedDate";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import Text from "../Text/Text";
import EditImportedTemplateLink from "../Link/EditImportedTemplateLink";
import PreviewSessionLink from "../Link/PreviewSessionLink";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import moreIcon from "../../images/template_item_more.svg";
import modifyIcon from "../../images/template_item_modify.svg";
import previewIcon from "../../images/session_preview.svg";
import deleteIcon from "../../images/template_item_delete.svg";
import type { Template } from "../../types/template";
import type { PreviewSessionInfo } from "../../types/session";
import styles from "./ImportedTemplateItem.module.scss";

type Props = {
  +templateIdx: number,
  +template: Template,
  +onDeleteTemplate: string => void,
};

type LocalState = {|
  isPopupMenuOpen: boolean,
  alertModalShown: boolean,
|};

function toPreviewSessionInfo(props: Props): PreviewSessionInfo {
  return {
    templateName: props.template.name,
    templateDisplayCode: props.template.displayCode,
    templateLang: props.template.lang,
    activities: props.template.activities,
  };
}

class ImportedTemplateItem extends PureComponent<Props, LocalState> {
  state = {
    isPopupMenuOpen: false,
    alertModalShown: false,
  };

  onClickMoreActionsButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      isPopupMenuOpen: !prevState.isPopupMenuOpen,
    }));
  };

  onClickMoreActionsOutside = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isPopupMenuOpen: false });
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
  };

  onDeleteTemplate = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ alertModalShown: true, isPopupMenuOpen: false });
  };

  onConfirmDeleteTemplate = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { template } = this.props;
    this.props.onDeleteTemplate(template.id);
    this.setState({ alertModalShown: false });
  };

  renderAlertModal() {
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="admin.template.sure_to_delete_template"
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmDeleteTemplate}
          onClose={this.onCloseAlertModal}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderPopupMenu() {
    const { isPopupMenuOpen } = this.state;

    if (!isPopupMenuOpen) {
      return null;
    }

    return (
      <PopupWrapper onClickOutside={this.onClickMoreActionsOutside}>
        <div className={styles.moreActionsPopupMenu}>
          <IconButton
            className={styles.popupMenuItem}
            icon={deleteIcon}
            labelTranslationKey="teacher.delete_template"
            onClick={this.onDeleteTemplate}
          />
        </div>
      </PopupWrapper>
    );
  }

  renderEditButton() {
    return (
      <EditImportedTemplateLink template={this.props.template}>
        <div className={styles.editButtonContainer}>
          <IconButton
            className={styles.editButton}
            icon={modifyIcon}
            labelTranslationKey="admin.template.view_edit_button"
          />
        </div>
      </EditImportedTemplateLink>
    );
  }

  renderPreviewButton() {
    return (
      <PreviewSessionLink
        className={styles.previewButtonContainer}
        previewSessionInfo={toPreviewSessionInfo(this.props)}
      >
        <IconButton
          className={styles.previewButton}
          icon={previewIcon}
          labelTranslationKey="admin.template.preview_button"
        />
      </PreviewSessionLink>
    );
  }

  renderMoreActionsButton() {
    const { isPopupMenuOpen } = this.state;

    return (
      <Button
        styled={false}
        color={null}
        className={styles.moreButton}
        onClick={this.onClickMoreActionsButton}
        disabled={isPopupMenuOpen}
      >
        <img
          src={moreIcon}
          className={styles.moreButtonIcon}
          alt="button form more"
        />
      </Button>
    );
  }

  render() {
    const { template } = this.props;
    return (
      <div className={styles.templateContainer}>
        <div className={styles.displayCode}>{template.displayCode}</div>
        <div className={styles.nameContainer}>
          <p className={styles.name}>{template.name}</p>
          <p className={styles.info}>
            <Text
              translationKey="admin.template.template_activities_length"
              values={{
                numberOfActivities: template.activities.length,
              }}
            />
            {template.createdBy && " • "}
            {template.createdBy && (
              <Text
                translationKey="admin.template.template_created_by"
                values={{
                  createdBy: template.createdBy,
                }}
              />
            )}
          </p>
        </div>
        <div className={styles.createdAt}>
          <FormattedDate
            value={template.createdAt}
            year="numeric"
            month="numeric"
            day="numeric"
            hour12={false}
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
          />
        </div>
        {this.renderPreviewButton()}
        {this.renderEditButton()}
        {this.renderMoreActionsButton()}
        {this.renderPopupMenu()}
        {this.renderAlertModal()}
      </div>
    );
  }
}

export default ImportedTemplateItem;
