// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import type { Node } from "react";
import Arrow from "../ART/Arrow";
import cssVariables from "../../variables.module.scss";
import styles from "./PagerModal.module.scss";

type Props<T> = {
  className: string,
  initialIndex: number,
  data: $ReadOnlyArray<T>,
  children: (data: T, index: number) => Node,
  hideArrows?: boolean,
};

type LocalState = {
  index: number,
};

export default class PagerModal<T> extends PureComponent<Props<T>, LocalState> {
  static defaultProps = {
    hideArrows: false,
  };

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      index: props.initialIndex,
    };
  }

  onLeftArrowClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const index = this.getLeftIndex();
    if (index != null) {
      this.setState({ index });
    }
  };

  onRightArrowClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const index = this.getRightIndex();
    if (index != null) {
      this.setState({ index });
    }
  };

  render() {
    const { children, data, className } = this.props;
    const { index } = this.state;
    const datum = data[index];
    const leftIndex = this.getLeftIndex();
    const rightIndex = this.getRightIndex();
    const hideArrows = this.props.hideArrows === true;
    return (
      <div className={classnames(styles.rootContainer, className)}>
        {!hideArrows && (
          <div
            onClick={this.onLeftArrowClick}
            className={classnames(styles.arrowContainer, styles.left, {
              [styles.disabled]: leftIndex == null,
            })}
          >
            <Arrow
              className={styles.arrow}
              direction="left"
              width={34}
              height={72}
              strokeWidth={4}
              strokeColor={leftIndex == null ? cssVariables.gray5 : "white"}
            />
          </div>
        )}
        {children(datum, index)}
        {!hideArrows && (
          <div
            onClick={this.onRightArrowClick}
            className={classnames(styles.arrowContainer, styles.right, {
              [styles.disabled]: rightIndex == null,
            })}
          >
            <Arrow
              className={styles.arrow}
              direction="right"
              width={34}
              height={72}
              strokeWidth={4}
              strokeColor={rightIndex == null ? cssVariables.gray5 : "white"}
            />
          </div>
        )}
      </div>
    );
  }

  getLeftIndex(): ?number {
    const { index } = this.state;
    if (index > 0) {
      return index - 1;
    }
    return null;
  }

  getRightIndex(): ?number {
    const { index } = this.state;
    const { data } = this.props;
    if (index < data.length - 1) {
      return index + 1;
    }
    return null;
  }
}
