// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  SelectionState,
} from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import StyleButton from "./StyleButton";
import editorUlIcon from "../../images/editor_ul.svg";
import editorOlIcon from "../../images/editor_ol.svg";
import editorBlockquoteIcon from "../../images/editor_blockquote.svg";
import styles from "./RichEditor.module.scss";

type Props = {
  initialContentString: string,
  readOnly: boolean,
  showToolbar: boolean,
  onChange?: string => void,
  className?: string,
};

type LocalState = {
  editorState: EditorState,
};

const INLINE_STYLES = [
  { label: "B", style: "BOLD", className: styles.buttonBold },
  { label: "U", style: "UNDERLINE", className: styles.buttonUnderline },
  { label: "I", style: "ITALIC", className: styles.buttonItalic },
];

const BLOCK_TYPES = [
  {
    label: "unordered list",
    icon: editorUlIcon,
    style: "unordered-list-item",
    className: styles.buttonUl,
  },
  {
    label: "ordered list",
    icon: editorOlIcon,
    style: "ordered-list-item",
    className: styles.buttonOl,
  },
  {
    label: "blockquote",
    icon: editorBlockquoteIcon,
    style: "blockquote",
    className: styles.buttonBlockQuote,
  },
];

class RichEditor extends PureComponent<Props, LocalState> {
  domEditor: ?Editor;

  static defaultProps = {
    readOnly: false,
    showToolbar: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: this.genEditorState(props.initialContentString),
    };
  }

  setDomEditorRef = (ref: Editor) => (this.domEditor = ref);

  componentDidMount() {
    if (this.domEditor != null) {
      this.domEditor.focus();
    }
    this.setState({
      editorState: this.moveCursorToTheEnd(this.state.editorState),
    });
  }

  moveCursorToTheEnd(editorState: EditorState) {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });

    return EditorState.forceSelection(editorState, selection);
  }

  onChange = (editorState: EditorState) => {
    const rawContentString = this.getRawContentString(editorState);

    this.setState({ editorState: editorState }, () => {
      if (this.props.onChange) {
        this.props.onChange(rawContentString);
      }
    });
  };

  onClick = (e: Event) => {
    const { domEditor } = this;
    if (domEditor == null) {
      return;
    }
    if (!this.props.readOnly) {
      e.preventDefault();
      e.stopPropagation();
      domEditor.focus();
    }
  };

  toggleInlineStyle = (style: string) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  };

  toggleBlockStyle = (style: string) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, style));
  };

  genEditorState(rawContentString: string): EditorState {
    const contentState = convertFromHTML(rawContentString);
    return EditorState.createWithContent(contentState);
  }

  getRawContentString(editorState: EditorState) {
    const contentState = editorState.getCurrentContent();
    return convertToHTML(contentState);
  }

  renderInlineStyle() {
    const currentStyle = this.state.editorState.getCurrentInlineStyle();

    return (
      <Fragment>
        {INLINE_STYLES.map(type => (
          <StyleButton
            key={type.label}
            style={type.style}
            active={currentStyle.has(type.style)}
            onToggle={this.toggleInlineStyle}
          >
            <p className={type.className}>{type.label}</p>
          </StyleButton>
        ))}
      </Fragment>
    );
  }

  handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  mapKeyToEditorCommand = (e: Event) => {
    // TODO: handle tab to indent list level
    return getDefaultKeyBinding(e);
  };

  renderBlockStyle() {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <Fragment>
        {BLOCK_TYPES.map(type => (
          <StyleButton
            key={type.label}
            style={type.style}
            active={type.style === blockType}
            onToggle={this.toggleBlockStyle}
          >
            <img src={type.icon} alt={type.label} className={type.className} />
          </StyleButton>
        ))}
      </Fragment>
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.showToolbar && (
          <div className={styles.formatButtons}>
            {this.renderInlineStyle()}
            <div className={styles.separator} />
            {this.renderBlockStyle()}
          </div>
        )}
        <div
          onClick={this.onClick}
          className={classnames(styles.editorContainer, {
            [styles.editable]: !this.props.readOnly,
            [styles.readOnly]: this.props.readOnly,
          })}
        >
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            ref={this.setDomEditorRef}
            readOnly={this.props.readOnly}
          />
        </div>
      </div>
    );
  }
}

export default RichEditor;
