// @flow

export function simpleFormat(date: ?Date): string {
  if (date == null) {
    return "";
  }
  const day = `${date.getDate()}`.padStart(2, "0");
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const hours = `${date.getHours()}`.padStart(2, "0");
  const minutes = `${date.getMinutes()}`.padStart(2, "0");
  const seconds = `${date.getSeconds()}`.padStart(2, "0");
  return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
}

export function jsTimezoneOffsetToISO8601(timezoneOffset: number): string {
  const sign = timezoneOffset > 0 ? "-" : "+";
  const hour = Math.floor(Math.abs(timezoneOffset) / 60);
  const minute = Math.abs(timezoneOffset) % 60;
  return `${sign}${("00" + hour).slice(-2)}:${("00" + minute).slice(-2)}`;
}
