// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Select from "../Select/Select";
import Option from "../Option/Option";
import { changeType } from "../../utils/activity";
import { ErrorActivityNameEmpty } from "../../utils/validate";
import type { CommonProps } from "./types/ActivityPanelProps";
import styles from "./ActivityPanelRenderer.module.scss";

class ActivityPanelBasicInfo extends PureComponent<CommonProps> {
  onNameChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;
    const { activity, onActivityChange } = this.props;

    if (target instanceof HTMLInputElement && onActivityChange) {
      onActivityChange({
        ...activity,
        name: target.value,
      });
    }
  };

  onTypeChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const { target } = event;
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      const typeUnsafe = target.value;
      if (
        typeUnsafe === "qa" ||
        typeUnsafe === "polling" ||
        typeUnsafe === "whiteboard"
      ) {
        onActivityChange(changeType(activity, typeUnsafe));
      }
    }
  };

  renderBasicInfo() {
    const { activity } = this.props;

    return (
      <div className={styles.activityBasicInfo}>
        <p className={styles.activityName}>{activity.name}</p>
        <p className={styles.activityType}>
          <Text translationKey="teacher.activity_type_label" />
          <Text translationKey={`teacher.activity_type_${activity.type}`} />
        </p>
      </div>
    );
  }

  renderEditableBasicInfo() {
    const { activity, validationErrors } = this.props;
    const activityTypeOptions = [
      <Option translationKey="teacher.activity_type_qa" value="qa" key="qa" />,
      <Option
        translationKey="teacher.activity_type_polling"
        value="polling"
        key="polling"
      />,
      <Option
        translationKey="teacher.activity_type_whiteboard"
        value="whiteboard"
        key="whiteboard"
      />,
    ];

    return (
      <div className={styles.activityBasicInfo}>
        <p className={styles.nameInputTitle}>
          <Text translationKey="teacher.activity_name" />
        </p>
        <div className={styles.headerContainer}>
          <div className={styles.nameInputContainer}>
            <InputWithErrorMessage
              placeholderId="teacher.activity_name_placeholder"
              labelId="teacher.activity_name"
              className={styles.nameInput}
              value={activity.name}
              type="text"
              onChange={this.onNameChange}
              errorTextKey={
                validationErrors != null &&
                validationErrors.includes(ErrorActivityNameEmpty)
                  ? "error.activity_name_empty"
                  : null
              }
            />
          </div>
          <div className={styles.typeSelectorContainer}>
            <p className={styles.typeSelectorLabel}>
              <Text translationKey="teacher.activity_type_label" />
            </p>
            <Select
              name="template-language-selector"
              className={styles.typeSelector}
              value={activity.type}
              onChange={this.onTypeChange}
            >
              <p className={styles.selectOptionText}>
                <Text
                  translationKey={`teacher.activity_type_${activity.type}`}
                />
              </p>
              {activityTypeOptions}
            </Select>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { editable } = this.props;

    if (!editable) {
      return this.renderBasicInfo();
    }

    return this.renderEditableBasicInfo();
  }
}

export default ActivityPanelBasicInfo;
