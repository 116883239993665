// @flow
import React from "react";
import type { Activity } from "../../types/activity";
import WindowSize from "../WindowSize";
import ResponsiveLayout from "../ResponsiveLayout";
import RichContent from "../RichEditor/RichContent";
import Image from "../Image/Image";
import styles from "./StudentSessionActivity.module.scss";

function renderQuestionImage(
  imageUrl: string,
  deviceType: "desktop" | "mobileLandscape" | "mobilePortrait"
) {
  const width = deviceType === "desktop" ? 180 : "100%";
  const placeholderHeight = deviceType === "desktop" ? 180 * 0.75 : "75%";
  return (
    <Image
      className={styles.questionImage}
      imageUrl={imageUrl}
      width={width}
      placeholderWidth={width}
      placeholderHeight={placeholderHeight}
    />
  );
}

export default function QuestionView(props: {
  activity: Activity,
  suppressImage?: boolean,
}) {
  const { activity, suppressImage } = props;
  const { question } = activity;
  const imageUrl = suppressImage === true ? null : activity.imageUrl;
  const questionClassName =
    imageUrl != null ? styles.questionTextWithImage : styles.questionText;
  return (
    <WindowSize>
      {props => {
        return (
          <div className={styles.questionContainer}>
            {imageUrl != null ? (
              <ResponsiveLayout>
                {deviceType => renderQuestionImage(imageUrl, deviceType)}
              </ResponsiveLayout>
            ) : null}
            <div className={questionClassName}>
              <RichContent rawContentString={question} />
            </div>
          </div>
        );
      }}
    </WindowSize>
  );
}
