// @flow
import React, { PureComponent } from "react";
import ModalContainer from "./ModalContainer";
import Text from "../Text/Text";
import Button from "../Button/Button";
import styles from "./NetworkDownModal.module.scss";

type Props = {
  onClose: Event => void,
};

export default class NetworkDownModal extends PureComponent<Props> {
  onClickModal = (e: Event) => {
    e.stopPropagation();
  };

  render() {
    return (
      <ModalContainer onClose={this.props.onClose}>
        <div className={styles.alertContainer} onClick={this.onClickModal}>
          <div className={styles.leftContainer} />
          <div className={styles.rightContainer}>
            <p className={styles.title}>
              <Text translationKey="error.connection_failed" />
            </p>
            <p className={styles.message}>
              <Text translationKey="error.please_check_your_connect" />
            </p>
            <Button
              color="red"
              className={styles.button}
              onClick={this.props.onClose}
            >
              <Text translationKey="phrase.i_see" />
            </Button>
            <button className={styles.closeButton} onClick={this.props.onClose}>
              <span className={styles.closeIcon} />
            </button>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
