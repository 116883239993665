// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Text from "../../components/Text/Text";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ErrorModal from "../../components/ErrorModal";
import InputWithErrorMessage from "../../components/Input/InputWithErrorMessage";
import Button from "../../components/Button/Button";
import ImageUploadButton from "../../components/ImageUploadButton/ImageUploadButton";
import ImageChooser from "../../components/ImageChooser/ImageChooser";
import { isRequesting, extractError } from "../../utils/request";
import { getAllSysConfs, setSysConfs } from "../../redux/actions/sysConf";
import {
  CUSTOM_TEMPLATE_MAX_NUMBER,
  FORGOT_PASSWORD_EMAIL_LIST,
  ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH,
  DEFAULT_STICKER_LIST,
} from "../../types/sysConf";
import {
  validateSysConfs,
  ErrorInvalidMaxCustomTemplateNumber,
  ErrorInvalidForgotPasswordEmailList,
  ErrorInvalidVisitableActivityRecordDuration,
} from "../../utils/validate";
import {
  WHITEBOARD_STICKER_SIZE,
  STICKER_FIXED_SIZE,
} from "../../utils/constants";
import type { Sticker } from "../../types/activity";
import type { RequestState } from "../../types/request";
import type { RootState, Dispatch } from "../../types/store";
import type { SysConf, SYS_CONF_ID } from "../../types/sysConf";
import type { ValidateSysConfError } from "../../utils/validate";
import styles from "./SysConfManagement.module.scss";

type OwnProps = {};

type ConnectedProps = {
  sysConfById: { [id: SYS_CONF_ID]: SysConf },
  getAllSysConfsRequest: ?RequestState<void>,
  setSysConfsRequest: ?RequestState<void>,
  getAllSysConfs: void => $ReadOnlyArray<SysConf>,
  setSysConfs: ($ReadOnlyArray<SysConf>) => $ReadOnlyArray<SysConf>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {|
  customTemplateMaxNumber: string,
  forgotPasswordEmailList: string,
  activityRecordsDuration: string,
  defaultStickerList: $ReadOnlyArray<Sticker>,
  errors: $ReadOnlyArray<ValidateSysConfError>,
|};

class SysConfManagement extends PureComponent<Props, LocalState> {
  state = {
    customTemplateMaxNumber: "",
    forgotPasswordEmailList: "",
    activityRecordsDuration: "",
    defaultStickerList: [],
    errors: [],
  };

  componentDidMount() {
    this.props.getAllSysConfs();
    this.resetLocalSysConfById(this.props.sysConfById);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      JSON.stringify(this.props.sysConfById) !==
      JSON.stringify(nextProps.sysConfById)
    ) {
      this.resetLocalSysConfById(nextProps.sysConfById);
    }
  }

  getCustomTemplateMaxNumber(sysConfById) {
    const c = CUSTOM_TEMPLATE_MAX_NUMBER;
    const customTemplateMaxNumber =
      sysConfById[c] && sysConfById[c].content != null
        ? `${sysConfById[c].content}`
        : "";
    return customTemplateMaxNumber;
  }

  getForgotPasswordEmailList(sysConfById) {
    const f = FORGOT_PASSWORD_EMAIL_LIST;
    const forgotPasswordEmailList =
      sysConfById[f] && sysConfById[f].content != null
        ? `${sysConfById[f].content.join()}`
        : "";
    return forgotPasswordEmailList;
  }

  getActivityRecordsDuration(sysConfById) {
    const a = ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH;
    const activityRecordsDuration =
      sysConfById[a] && sysConfById[a].content != null
        ? `${sysConfById[a].content}`
        : "";
    return activityRecordsDuration;
  }

  getDefaultStickerList(sysConfById) {
    const s = DEFAULT_STICKER_LIST;
    const defaultStickerList =
      sysConfById[s] && Array.isArray(sysConfById[s].content)
        ? sysConfById[s].content.map(s => ({
            imageId: s.image_id,
            imageUrl: s.image_url,
          }))
        : [];
    return defaultStickerList;
  }

  resetLocalSysConfById(sysConfById) {
    const customTemplateMaxNumber = this.getCustomTemplateMaxNumber(
      sysConfById
    );
    const forgotPasswordEmailList = this.getForgotPasswordEmailList(
      sysConfById
    );
    const activityRecordsDuration = this.getActivityRecordsDuration(
      sysConfById
    );
    const defaultStickerList = this.getDefaultStickerList(sysConfById);

    this.setState({
      customTemplateMaxNumber,
      forgotPasswordEmailList,
      activityRecordsDuration,
      defaultStickerList,
    });
  }

  getChanges() {
    const { sysConfById } = this.props;
    const {
      customTemplateMaxNumber,
      forgotPasswordEmailList,
      activityRecordsDuration,
      defaultStickerList,
    } = this.state;
    const c = CUSTOM_TEMPLATE_MAX_NUMBER;
    const f = FORGOT_PASSWORD_EMAIL_LIST;
    const a = ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH;
    const s = DEFAULT_STICKER_LIST;
    const changes = [];

    if (
      this.getCustomTemplateMaxNumber(sysConfById) !== customTemplateMaxNumber
    ) {
      changes.push({ id: c, content: parseInt(customTemplateMaxNumber, 10) });
    }

    if (
      this.getForgotPasswordEmailList(sysConfById) !== forgotPasswordEmailList
    ) {
      changes.push({
        id: f,
        content: forgotPasswordEmailList.split(",").map(e => e.trim()),
      });
    }

    if (
      this.getActivityRecordsDuration(sysConfById) !== activityRecordsDuration
    ) {
      changes.push({ id: a, content: parseInt(activityRecordsDuration, 10) });
    }

    if (
      JSON.stringify(this.getDefaultStickerList(sysConfById)) !==
      JSON.stringify(defaultStickerList)
    ) {
      changes.push({
        id: s,
        content: defaultStickerList.map(s => ({
          image_id: s.imageId,
          image_url: s.imageUrl,
        })),
      });
    }

    return changes;
  }

  onInputChange = (e: Event, key: mixed) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement && typeof key === "string") {
      this.setState({
        [key]: target.value,
      });
    }
  };

  onStickerImageUploaded = (id: string, url: string) => {
    this.setState({
      defaultStickerList: [
        ...this.state.defaultStickerList,
        { imageId: id, imageUrl: url },
      ],
    });
  };

  onStickerUnSelect = (idx: mixed) => {
    if (typeof idx === "number") {
      const stickerList = Array.from(this.state.defaultStickerList);
      stickerList.splice(idx, 1);
      this.setState({ defaultStickerList: stickerList });
    }
  };

  onValidate() {
    const {
      customTemplateMaxNumber,
      forgotPasswordEmailList,
      activityRecordsDuration,
    } = this.state;
    const errors = validateSysConfs({
      customTemplateMaxNumber,
      forgotPasswordEmailList,
      activityRecordsDuration,
    });
    return errors;
  }

  onSave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const changes = this.getChanges();

    if (changes.length !== 0) {
      // validate inputs
      const errors = this.onValidate();
      this.setState({ errors });

      // push changes
      if (errors.length === 0) {
        this.props.setSysConfs(changes);
      }
    }
  };

  renderStickerList() {
    const { defaultStickerList } = this.state;

    if (defaultStickerList.length === 0) {
      return null;
    }

    return (
      <div className={styles.stickerImgs}>
        {defaultStickerList.map((s, i) => (
          <ImageChooser
            imageUrl={s.imageUrl}
            onUnSelect={this.onStickerUnSelect}
            onUnSelectInfo={i}
            key={i}
            width={WHITEBOARD_STICKER_SIZE}
            height={WHITEBOARD_STICKER_SIZE}
            placeholderWidth={WHITEBOARD_STICKER_SIZE}
            placeholderHeight={WHITEBOARD_STICKER_SIZE}
            className={styles.stickerImg}
          />
        ))}
      </div>
    );
  }

  render() {
    const { getAllSysConfsRequest, setSysConfsRequest } = this.props;
    const r =
      isRequesting(getAllSysConfsRequest) || isRequesting(setSysConfsRequest);
    const {
      errors,
      customTemplateMaxNumber,
      forgotPasswordEmailList,
      activityRecordsDuration,
    } = this.state;

    if (r) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        <div className={styles.headerRow}>
          <p className={styles.headerTitle}>
            <Text translationKey="admin.sys_conf.title" />
          </p>
        </div>
        <div className={styles.confInputs}>
          <p className={styles.confTitle}>
            <Text translationKey="admin.sys_conf.max_custom_templates" />
          </p>
          <div className={styles.confInputContainer}>
            <InputWithErrorMessage
              placeholderId="admin.sys_conf.max_custom_templates_placeholder"
              labelId="admin.sys_conf.max_custom_templates_placeholder"
              type="number"
              value={customTemplateMaxNumber}
              className={styles.confInput}
              onChange={this.onInputChange}
              onChangeInfo="customTemplateMaxNumber"
              errorTextKey={
                errors.includes(ErrorInvalidMaxCustomTemplateNumber)
                  ? "error.input_a_number"
                  : null
              }
            />
          </div>
          <p className={styles.confTitle}>
            <Text translationKey="admin.sys_conf.forgot_password_email_list" />
          </p>
          <div className={styles.confInputContainer}>
            <InputWithErrorMessage
              placeholderId="admin.sys_conf.forgot_password_email_list_placeholder"
              labelId="admin.sys_conf.forgot_password_email_list_placeholder"
              type="text"
              value={forgotPasswordEmailList}
              className={styles.confInput}
              onChange={this.onInputChange}
              onChangeInfo="forgotPasswordEmailList"
              errorTextKey={
                errors.includes(ErrorInvalidForgotPasswordEmailList)
                  ? "error.input_a_comma_separate_string"
                  : null
              }
            />
          </div>
          <p className={styles.confTitle}>
            <Text translationKey="admin.sys_conf.activity_records_visitable_duration" />
          </p>
          <div className={styles.confInputContainer}>
            <InputWithErrorMessage
              placeholderId="admin.sys_conf.activity_records_visitable_duration_placeholder"
              labelId="admin.sys_conf.activity_records_visitable_duration_placeholder"
              type="number"
              value={activityRecordsDuration}
              className={styles.confInput}
              onChange={this.onInputChange}
              onChangeInfo="activityRecordsDuration"
              errorTextKey={
                errors.includes(ErrorInvalidVisitableActivityRecordDuration)
                  ? "error.input_a_number"
                  : null
              }
            />
          </div>
          <p className={styles.confTitle}>
            <Text translationKey="admin.sys_conf.default_sticker_list" />
          </p>
          <div className={styles.confInputContainer}>
            {this.renderStickerList()}
            <ImageUploadButton
              type="resizeCrop"
              accept="image/png"
              onUploaded={this.onStickerImageUploaded}
              width={STICKER_FIXED_SIZE}
              height={STICKER_FIXED_SIZE}
            />
          </div>
          <Button
            color="green"
            colorApplication="background"
            className={styles.saveButton}
            onClick={this.onSave}
          >
            <Text translationKey="phrase.save_changes" />
          </Button>
        </div>
        <ErrorModal error={extractError(getAllSysConfsRequest)} />
        <ErrorModal error={extractError(setSysConfsRequest)} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  return {
    sysConfById: state.sysConfs.sysConfById,
    getAllSysConfsRequest: state.sysConfs.getAllSysConfsRequest,
    setSysConfsRequest: state.sysConfs.setSysConfsRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getAllSysConfs: bindActionCreators(getAllSysConfs, dispatch),
    setSysConfs: bindActionCreators(setSysConfs, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SysConfManagement);
