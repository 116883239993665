// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeLocale } from "../../redux/actions/app";
import { rememberLocale } from "../../utils/localStorage";
import type { RootState, Dispatch } from "../../types/store";
import type { AppLocale } from "../../types/app";
import styles from "./LocaleSwitch.module.scss";

type OwnProps = {|
  +variant: "header" | "footer",
  +className?: string,
|};

type ConnectedProps = {
  +locale: AppLocale,
  +changeLocale: AppLocale => void,
};

type Props = OwnProps & ConnectedProps;

const LANGS = [
  {
    locale: "en",
    displayName: "EN",
  },
  {
    locale: "zh-Hans-CN",
    displayName: "简",
  },
  {
    locale: "zh-Hant-HK",
    displayName: "繁",
  },
];

class LocaleSwitch extends PureComponent<Props> {
  onClick = (nextLocale: AppLocale) => {
    rememberLocale(nextLocale);
    this.props.changeLocale(nextLocale);
  };

  render() {
    const { locale, className, variant } = this.props;

    return (
      <ul className={classnames(styles.localeOptionsContainer, className)}>
        {LANGS.map(l => (
          <li
            className={classnames(styles.localeOption, {
              [styles.header]: variant === "header",
              [styles.footer]: variant === "footer",
              [styles.active]: locale === l.locale,
            })}
            onClick={this.onClick.bind(this, l.locale)}
            key={l.locale}
          >
            {l.displayName}
          </li>
        ))}
      </ul>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.app.locale,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    changeLocale: bindActionCreators(changeLocale, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  LocaleSwitch
): any): Class<React$Component<OwnProps>>);
