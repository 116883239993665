// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import RichContent from "../RichEditor/RichContent";
import Image from "../Image/Image";
import type { PollingOption } from "../../types/activity";
import { indexToAlpha } from "../../utils/session";
import styles from "./TeacherSessionActivity.module.scss";

type Props = {
  parentHeight: number,
  question: string,
  imageUrl: ?string,
  pollingOptions: $ReadOnlyArray<PollingOption>,
};

export default class TeacherSessionActivityPolling extends PureComponent<
  Props
> {
  renderQuestion() {
    const { imageUrl, question } = this.props;
    if (imageUrl != null) {
      return (
        <div className={styles.pollingQuestionContainer}>
          <Image
            className={styles.questionImage}
            imageUrl={imageUrl}
            width={272}
            placeholderWidth={272}
            placeholderHeight={272 * 0.75}
          />
          <div className={styles.pollingQuestionWithImageText}>
            <RichContent rawContentString={question} />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.pollingQuestionWithoutImageText}>
        <RichContent rawContentString={question} />
      </div>
    );
  }

  renderOption = (option: PollingOption, index: number) => {
    const { imageUrl } = option;
    return (
      <div key={index} className={styles.pollingOptionContainer}>
        <div className={styles.pollingOptionAlpha}>{indexToAlpha(index)}</div>
        {imageUrl == null ? null : (
          <Image
            imageUrl={imageUrl}
            width={210}
            placeholderWidth={210}
            placeholderHeight={210 * 0.75}
            className={styles.pollingOptionImage}
          />
        )}
        <div
          className={classnames(styles.pollingOptionContentContainer, {
            [styles.pollingOptionContentContainerWidthImage]: imageUrl != null,
          })}
        >
          <div className={styles.pollingOptionText}>{option.text}</div>
        </div>
      </div>
    );
  };

  renderOptions() {
    const { pollingOptions } = this.props;
    return (
      <div className={styles.pollingOptionsContainer}>
        {pollingOptions.map(this.renderOption)}
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderQuestion()}
        {this.renderOptions()}
      </Fragment>
    );
  }
}
