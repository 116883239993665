// @flow
import React, { PureComponent, Fragment } from "react";
import qs from "qs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import type { RouterHistory } from "react-router-dom";
import type { RootState, Dispatch } from "../../types/store";
import type { RequestState } from "../../types/request";
import type { Session } from "../../types/session";
import { querySingleSession } from "../../redux/actions/sessions";
import { extractError, isRequesting } from "../../utils/request";
import Text from "../../components/Text/Text";
import WindowSize from "../../components/WindowSize";
import ActivitiesPanel from "../../components/ActivitiesPanel/ActivitiesPanel";
import ActivityRecordPanel from "../../components/ActivityRecordPanel/ActivityRecordPanel";
import AdminViewActivityRecordHeader from "../../components/AdminViewActivityRecordHeader/AdminViewActivityRecordHeader";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ErrorModal from "../../components/ErrorModal";
import { simpleFormat } from "../../utils/dateTime";
import styles from "./ViewActivityRecord.module.scss";

type OwnProps = {
  initialSessionID: string,
  history: RouterHistory,
};

type ConnectedProps = {
  sessionById: { [id: string]: ?Session },
  querySingleSessionRequest: ?RequestState<void>,
  querySingleSession: string => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  sessionID: string,
  activeActivityIdx: number,
};

const MAGIC_HEIGHT_OFFSET = 138;

class ViewActivityRecord extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sessionID: props.initialSessionID,
      activeActivityIdx: 0,
    };
  }

  componentDidMount() {
    this._fetchSession();
  }

  componentDidUpdate(prevProps: Props, prevState: LocalState) {
    if (prevState.sessionID !== this.state.sessionID) {
      this.props.history.replace({
        search: qs.stringify({
          sessionID: this.state.sessionID,
        }),
      });
    }
  }

  onClickActivityPanelItem = idx => {
    const session = this._getSession(this.props, this.state.sessionID);
    if (session != null && idx >= 0 && idx < session.activities.length) {
      this.setState({
        activeActivityIdx: idx,
      });
    }
  };

  renderUnitTemplateLabel() {
    const session = this._getSession(this.props, this.state.sessionID);
    if (session == null) {
      return null;
    }
    return (
      <div className={styles.labelContainer}>
        <p className={styles.labelText}>
          {session.templateDisplayCode + " " + session.templateName}
        </p>
      </div>
    );
  }

  renderSchoolLabel() {
    const session = this._getSession(this.props, this.state.sessionID);
    if (session == null) {
      return null;
    }
    return (
      <div className={styles.labelContainer}>
        <p className={styles.labelText}>{session.schoolEngName}</p>
      </div>
    );
  }

  renderActivityRecordLabel() {
    const session = this._getSession(this.props, this.state.sessionID);
    if (session == null) {
      return null;
    }
    return (
      <div className={styles.labelContainer}>
        <p className={styles.labelText}>
          {session.teacherName + " - " + simpleFormat(session.createdAt)}
        </p>
      </div>
    );
  }

  renderRightPanel(session: Session) {
    const { activeActivityIdx } = this.state;
    return (
      <div className={styles.rightPanelContainer}>
        <ActivityRecordPanel
          activityIndex={activeActivityIdx}
          session={session}
          activity={session.activities[activeActivityIdx]}
          activityState={session.activityStates[activeActivityIdx]}
        />
      </div>
    );
  }

  renderContent() {
    const session = this._getSession(this.props, this.state.sessionID);
    const requesting = isRequesting(this.props.querySingleSessionRequest);

    if (requesting) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.mainContentContainer}>
        <div className={styles.leftPanelContainer}>
          {this.renderUnitTemplateLabel()}
          {this.renderSchoolLabel()}
          {this.renderActivityRecordLabel()}
          {session == null ? null : (
            <p className={styles.activitiesTitleText}>
              <Text
                translationKey="teacher.number_of_activities2"
                values={{ numberOfActivities: session.activities.length }}
              />
            </p>
          )}
          {session == null ? null : (
            <ActivitiesPanel
              variant="adminPortal"
              activities={session.activities}
              activeActivityIdx={this.state.activeActivityIdx}
              onClick={this.onClickActivityPanelItem}
            />
          )}
        </div>
        {session == null ? null : this.renderRightPanel(session)}
      </div>
    );
  }

  render() {
    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.rootContainer}
            >
              <AdminViewActivityRecordHeader />
              {this.renderContent()}
              <Fragment>
                <ErrorModal
                  error={extractError(this.props.querySingleSessionRequest)}
                />
              </Fragment>
            </div>
          );
        }}
      </WindowSize>
    );
  }

  _getSession(props: Props, sessionID: string): ?Session {
    return props.sessionById[sessionID];
  }

  _fetchSession = () => {
    if (this.state.sessionID === "") {
      return;
    }
    this.props.querySingleSession(this.state.sessionID);
  };
}

function mapStateToProps(state: RootState, props: OwnProps) {
  return {
    sessionById: state.sessions.sessionById,
    querySingleSessionRequest: state.sessions.singleSessionRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    querySingleSession: bindActionCreators(querySingleSession, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  ViewActivityRecord
): any): Class<React$Component<OwnProps>>);
