// @flow
import React, { PureComponent } from "react";
import ModalContainer from "./ModalContainer";
import AlertModal from "./AlertModal";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Text from "../Text/Text";
import styles from "./CreateSessionModal.module.scss";
import type { SessionMode } from "../../types/session";

type Props = {
  templateName: string,
  mode: SessionMode,
  onConfirm: ({ name: string, mode: SessionMode }) => void,
  onClose: Event => void,
};

type LocalState = {
  value: string,
  showErrorLabel: boolean,
};

export default class CreateSessionModal extends PureComponent<
  Props,
  LocalState
> {
  state = {
    value: "",
    showErrorLabel: false,
  };

  onButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.value.trim().length > 0) {
      this.props.onConfirm({
        name: this.state.value,
        mode: this.props.mode,
      });
    } else {
      this.setState({ showErrorLabel: true });
    }
  };

  onChange = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({
        value: target.value,
        showErrorLabel: false,
      });
    }
  };

  render() {
    return (
      <ModalContainer onClose={this.props.onClose}>
        <AlertModal
          colorVariant={this.props.mode === "live" ? "pink" : "orange"}
          titleKey={
            this.props.mode === "live"
              ? "teacher.create_session.modal.title"
              : "teacher.preview_session.modal.title"
          }
          titleValues={{
            templateName: this.props.templateName,
          }}
          messageKey={this.resolveMessageKey()}
          buttonKey={
            this.props.mode === "live"
              ? "teacher.create_session.modal.button"
              : "teacher.preview_session.modal.button"
          }
          onButtonClick={this.onButtonClick}
          onClose={this.props.onClose}
          isForm
        >
          <p className={styles.inputLabel}>
            <Text translationKey="teacher.create_session.modal.input.label" />
          </p>
          <div className={styles.textInputWrapper}>
            <InputWithErrorMessage
              className={styles.textInput}
              placeholderId="teacher.create_session.modal.input.placeholder"
              labelId="teacher.create_session.modal.input.placeholder"
              type="text"
              value={this.state.value}
              onChange={this.onChange}
              errorTextKey={
                this.state.showErrorLabel ? "error.teacher_name_empty" : null
              }
            />
          </div>
        </AlertModal>
      </ModalContainer>
    );
  }

  resolveMessageKey() {
    if (this.props.mode === "live") {
      return "teacher.create_session.modal.message.live";
    }
    return "teacher.create_session.modal.message.test";
  }
}
