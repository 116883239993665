// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qs from "qs";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import type { Session } from "../../types/session";
import type { RequestState, PageInfo } from "../../types/request";
import type { UnitTemplateOption, SchoolOption } from "../../types/session";
import type { RootState, Dispatch } from "../../types/store";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import Select from "../../components/Select/Select";
import Option from "../../components/Option/Option";
import StyledDatePicker from "../../components/DatePicker/StyledDatePicker";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ModalContainer from "../../components/Modal/ModalContainer";
import AlertModal from "../../components/Modal/AlertModal";
import ErrorModal from "../../components/ErrorModal";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminViewActivityRecordLink from "../../components/Link/AdminViewActivityRecordLink";
import AdminSessionItem from "../../components/AdminSessionItem/AdminSessionItem";
import WindowSize from "../../components/WindowSize";
import Pagination from "../../components/Pagination/Pagination";
import { toQueryString, parseFromQueryString } from "../../utils/date";
import { ADMIN_DASHBOARD_PAGE_SIZE } from "../../utils/constants";
import {
  exportActivityRecords,
  exportActivityRecordsAsync,
  fetchSelectOptions,
  searchActivityRecords,
  deleteActivityRecord,
} from "../../redux/actions/sessions";
import {
  isRequesting,
  extractError,
  makeRequesting,
  makeSuccess,
  makeFailure,
} from "../../utils/request";
import { isLessThanOrEqualWithoutTime } from "../../utils/date";
import styles from "./RecordsManagement.module.scss";

type OwnProps = {
  +history: RouterHistory,
  +location: Location,
};

type ConnectedProps = {
  sessionByID: { [id: string]: Session },
  templateOptions: ?$ReadOnlyArray<UnitTemplateOption>,
  schoolOptions: ?$ReadOnlyArray<SchoolOption>,
  fetchSelectOptionsRequest: ?RequestState<void>,
  fetchSelectOptions: () => Promise<{
    templateOptions: $ReadOnlyArray<UnitTemplateOption>,
    schoolOptions: $ReadOnlyArray<SchoolOption>,
  }>,
  searchActivityRecordsRequest: ?RequestState<void>,
  searchActivityRecords: (data: {
    pageNumber: number,
    pageSize: number,
    templateID: ?string,
    schoolID: ?string,
    fromDate: Date,
    toDate: Date,
  }) => Promise<{
    pageInfo: PageInfo,
    sessions: $ReadOnlyArray<Session>,
  }>,

  // From query string
  pageNumber: number,
  fromDateString: string,
  toDateString: string,
  selectedTemplateID: string,
  selectedSchoolID: string,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  maxDate: Date,
  fromDate: Date,
  toDate: Date,
  pageInfo: PageInfo,
  sessionIDs: $ReadOnlyArray<string>,
  downloadRequest: ?RequestState<void>,
  deleteRecordRequest: ?RequestState<void>,
  alertModalTitleKey: string,
};

const MAGIC_HEIGHT_OFFSET = 435;

const HEADER_TITLES = [
  {
    text: "admin.activity_record.header.datetime",
    width: "12.5%",
  },
  {
    text: "admin.activity_record.header.id",
    width: "7%",
  },
  {
    text: "admin.activity_record.header.unit_name",
    width: "21%",
  },
  {
    text: "admin.activity_record.header.school",
    width: "27%",
  },
  {
    text: "admin.activity_record.header.participants",
    width: "8%",
  },
];

class RecordsManagement extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    const d = new Date();
    this.state = {
      maxDate: d,
      fromDate: d,
      toDate: d,
      pageInfo: {
        totalCount: 0,
        totalPage: 0,
      },
      sessionIDs: [],
      downloadRequest: null,
      deleteRecordRequest: null,
      alertModalTitleKey: "",
    };
  }

  componentDidMount() {
    if (this.props.templateOptions == null) {
      this.props.fetchSelectOptions();
    }
    this.searchActivityRecords();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.fromDateString !== this.props.fromDateString) {
      const d = parseFromQueryString(nextProps.fromDateString);
      if (d != null) {
        this.setState({
          fromDate: d,
        });
      }
    }
    if (nextProps.toDateString !== this.props.toDateString) {
      const d = parseFromQueryString(nextProps.toDateString);
      if (d != null) {
        this.setState({
          toDate: d,
        });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: LocalState) {
    if (
      prevProps.pageNumber !== this.props.pageNumber ||
      prevProps.selectedSchoolID !== this.props.selectedSchoolID ||
      prevProps.selectedTemplateID !== this.props.selectedTemplateID ||
      prevState.fromDate.getTime() !== this.state.fromDate.getTime() ||
      prevState.toDate.getTime() !== this.state.toDate.getTime()
    ) {
      this.searchActivityRecords();
    }
  }

  onDownloadRecordButtonClick = (sessionID: string) => {
    this.exportActivityRecord(sessionID);
  };

  onDeleteRecordButtonClick = (sessionID: string) => {
    if (!isRequesting(this.state.deleteRecordRequest)) {
      this.setState({
        deleteRecordRequest: makeRequesting(),
      });
      deleteActivityRecord(sessionID).then(
        () => {
          this.setState({
            deleteRecordRequest: makeSuccess(),
          });
          this.searchActivityRecords();
        },
        error => {
          this.setState({
            deleteRecordRequest: makeFailure(error),
          });
        }
      );
    }
  };

  onChangeUnitTemplate = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    this.onReplaceHistory({
      pageNumber: this.props.pageNumber,
      selectedSchoolID: this.props.selectedSchoolID,
      selectedTemplateID: e.target.value,
      fromDateString: toQueryString(this.state.fromDate),
      toDateString: toQueryString(this.state.toDate),
    });
  };

  onChangeSchool = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    this.onReplaceHistory({
      pageNumber: this.props.pageNumber,
      selectedSchoolID: e.target.value,
      selectedTemplateID: this.props.selectedTemplateID,
      fromDateString: toQueryString(this.state.fromDate),
      toDateString: toQueryString(this.state.toDate),
    });
  };

  onChangeFromDate = (date: Date) => {
    if (isLessThanOrEqualWithoutTime(date, this.state.toDate)) {
      this.onReplaceHistory({
        pageNumber: this.props.pageNumber,
        selectedSchoolID: this.props.selectedSchoolID,
        selectedTemplateID: this.props.selectedTemplateID,
        fromDateString: toQueryString(date),
        toDateString: toQueryString(this.state.toDate),
      });
      this.setState({
        fromDate: date,
      });
    }
  };

  onChangeToDate = (date: Date) => {
    if (isLessThanOrEqualWithoutTime(this.state.fromDate, date)) {
      this.onReplaceHistory({
        pageNumber: this.props.pageNumber,
        selectedSchoolID: this.props.selectedSchoolID,
        selectedTemplateID: this.props.selectedTemplateID,
        fromDateString: toQueryString(this.state.fromDate),
        toDateString: toQueryString(date),
      });
      this.setState({
        toDate: date,
      });
    }
  };

  onPageChange = (pageIdx: number) => {
    this.onReplaceHistory({
      pageNumber: pageIdx,
      selectedSchoolID: this.props.selectedSchoolID,
      selectedTemplateID: this.props.selectedTemplateID,
      fromDateString: toQueryString(this.state.fromDate),
      toDateString: toQueryString(this.state.toDate),
    });
  };

  onClickDownloadAllButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.exportActivityRecordsAsync();
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      alertModalTitleKey: "",
    });
  };

  onReplaceHistory(data: {
    pageNumber: number,
    selectedSchoolID: string,
    selectedTemplateID: string,
    fromDateString: string,
    toDateString: string,
  }) {
    const {
      pageNumber,
      selectedSchoolID,
      selectedTemplateID,
      fromDateString,
      toDateString,
    } = data;
    this.props.history.replace({
      search: qs.stringify({
        pageNumber,
        schoolID: selectedSchoolID,
        templateID: selectedTemplateID,
        fromDate: fromDateString,
        toDate: toDateString,
      }),
    });
  }

  renderTemplateText = (o: UnitTemplateOption) => {
    return o.displayCode + " " + o.name;
  };

  renderTemplateOption = (o: UnitTemplateOption) => {
    return (
      <option key={o.id} value={o.id}>
        {this.renderTemplateText(o)}
      </option>
    );
  };

  renderSchoolOption = (o: SchoolOption) => {
    return (
      <option key={o.id} value={o.id}>
        {o.engName}
      </option>
    );
  };

  renderUnitTemplateSelect() {
    const templateOptions = this.props.templateOptions || [];
    const { selectedTemplateID } = this.props;
    const selected = this._findUnitTemplateOption(selectedTemplateID);
    return (
      <Select
        className={styles.unitTemplateSelect}
        value={selectedTemplateID}
        onChange={this.onChangeUnitTemplate}
      >
        <p className={styles.templateOptionText}>
          {selectedTemplateID === "" ? (
            <Text translationKey="admin.activity_record.option.all_units" />
          ) : selected == null ? null : (
            this.renderTemplateText(selected)
          )}
        </p>
        <Option
          value=""
          translationKey="admin.activity_record.option.all_units"
        />
        {templateOptions.map(this.renderTemplateOption)}
      </Select>
    );
  }

  renderSchoolSelect() {
    const schoolOptions = this.props.schoolOptions || [];
    const { selectedSchoolID } = this.props;
    const selected = this._findSchoolOption(selectedSchoolID);
    return (
      <Select
        className={styles.schoolSelect}
        value={selectedSchoolID}
        onChange={this.onChangeSchool}
      >
        <p className={styles.schoolOptionText}>
          {selectedSchoolID === "" ? (
            <Text translationKey="admin.activity_record.option.all_schools" />
          ) : selected == null ? null : (
            selected.engName
          )}
        </p>
        <Option
          value=""
          translationKey="admin.activity_record.option.all_schools"
        />
        {schoolOptions.map(this.renderSchoolOption)}
      </Select>
    );
  }

  renderHeader() {
    return (
      <Fragment>
        <div className={styles.headerRow1}>
          <p className={styles.headerTitle}>
            <span className={styles.headerTitleText}>
              <Text translationKey="admin.activity_record.title" />
            </span>
            <span className={styles.headerCountText}>
              {this.state.pageInfo.totalCount}
            </span>
          </p>
          <Button
            className={styles.downloadAllButton}
            color="orange"
            onClick={this.onClickDownloadAllButton}
          >
            <span className={styles.downloadAllButtonText}>
              <Text translationKey="admin.activity_record.button.download_all_records" />
            </span>
          </Button>
        </div>
        <div className={styles.headerRow2}>
          {this.renderUnitTemplateSelect()}
          {this.renderSchoolSelect()}
          <StyledDatePicker
            variant="to"
            className={styles.toDatePicker}
            value={this.state.toDate}
            maxDate={this.state.maxDate}
            onChange={this.onChangeToDate}
          />
          <StyledDatePicker
            variant="from"
            className={styles.fromDatePicker}
            value={this.state.fromDate}
            maxDate={this.state.maxDate}
            onChange={this.onChangeFromDate}
          />
        </div>
      </Fragment>
    );
  }

  renderAlertModal() {
    if (this.state.alertModalTitleKey === "") {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="green"
          titleKey={this.state.alertModalTitleKey}
          buttonKey="phrase.confirm"
          onButtonClick={this.onCloseAlertModal}
          onClose={this.onCloseAlertModal}
        />
      </ModalContainer>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderHeader()}
        <AdminHeader titles={HEADER_TITLES} />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            const r = isRequesting(this.props.searchActivityRecordsRequest);
            const sessions = this.getSessions();
            return (
              <div style={{ height: h + "px", overflow: "scroll" }}>
                {r && <LoadingIndicator />}
                {!r &&
                  sessions.map(session => {
                    return (
                      <AdminViewActivityRecordLink
                        sessionID={session.id}
                        key={session.id}
                      >
                        <AdminSessionItem
                          key={session.id}
                          sessionID={session.id}
                          date={session.createdAt}
                          templateDisplayCode={session.templateDisplayCode}
                          templateName={session.templateName}
                          schoolName={session.schoolEngName}
                          participantCount={session.participants.length}
                          onDownloadRecordButtonClick={
                            this.onDownloadRecordButtonClick
                          }
                          onDeleteRecordButtonClick={
                            this.onDeleteRecordButtonClick
                          }
                        />
                      </AdminViewActivityRecordLink>
                    );
                  })}
                {!r &&
                  sessions.length === 0 && (
                    <p className={styles.noRelevantRecordsText}>
                      <Text translationKey="admin.no_relevant_records" />
                    </p>
                  )}
              </div>
            );
          }}
        </WindowSize>
        <Pagination
          currentPage={this.props.pageNumber}
          totalPage={this.state.pageInfo.totalPage}
          onPageChange={this.onPageChange}
        />
        <ErrorModal
          error={extractError(this.props.fetchSelectOptionsRequest)}
        />
        <ErrorModal
          error={extractError(this.props.searchActivityRecordsRequest)}
        />
        <ErrorModal error={extractError(this.state.downloadRequest)} />
        <ErrorModal error={extractError(this.state.deleteRecordRequest)} />
        {this.renderAlertModal()}
      </Fragment>
    );
  }

  _findUnitTemplateOption(id: string): ?UnitTemplateOption {
    const { templateOptions } = this.props;
    if (templateOptions == null) {
      return null;
    }
    return templateOptions.find(a => a.id === id);
  }

  _findSchoolOption(id: string): ?SchoolOption {
    const { schoolOptions } = this.props;
    if (schoolOptions == null) {
      return null;
    }
    return schoolOptions.find(a => a.id === id);
  }

  getSessions(): $ReadOnlyArray<Session> {
    const output = [];
    for (const id of this.state.sessionIDs) {
      const s = this.props.sessionByID[id];
      if (s != null) {
        output.push(s);
      }
    }
    return output;
  }

  searchActivityRecords = () => {
    this.props
      .searchActivityRecords({
        pageSize: ADMIN_DASHBOARD_PAGE_SIZE,
        pageNumber: this.props.pageNumber,
        templateID:
          this.props.selectedTemplateID === ""
            ? null
            : this.props.selectedTemplateID,
        schoolID:
          this.props.selectedSchoolID === ""
            ? null
            : this.props.selectedSchoolID,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
      .then(result => {
        this.setState({
          sessionIDs: result.sessions.map(s => s.id),
          pageInfo: result.pageInfo,
        });
      });
  };

  exportActivityRecordsAsync() {
    if (!isRequesting(this.state.downloadRequest)) {
      this.setState({
        downloadRequest: makeRequesting(),
      });
      exportActivityRecordsAsync({
        templateID:
          this.props.selectedTemplateID === ""
            ? null
            : this.props.selectedTemplateID,
        schoolID:
          this.props.selectedSchoolID === ""
            ? null
            : this.props.selectedSchoolID,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      }).then(
        sessionIDs => {
          this.setState({
            downloadRequest: makeSuccess(),
            alertModalTitleKey:
              sessionIDs.length <= 0
                ? "admin.activity_record.no_records_were_exported"
                : "admin.activity_record.export_success_message",
          });
        },
        error => {
          this.setState({
            downloadRequest: makeFailure(error),
          });
        }
      );
    }
  }

  exportActivityRecord(id: string) {
    if (!isRequesting(this.state.downloadRequest)) {
      this.setState({
        downloadRequest: makeRequesting(),
      });
      exportActivityRecords([id]).then(
        () => {
          this.setState({
            downloadRequest: makeSuccess(),
          });
        },
        error => {
          this.setState({
            downloadRequest: makeFailure(error),
          });
        }
      );
    }
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const { search } = ownProps.location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    sessionByID: state.sessions.sessionById,
    schoolOptions: state.sessions.schoolOptions,
    templateOptions: state.sessions.templateOptions,
    fetchSelectOptionsRequest: state.sessions.fetchSelectOptionsRequest,
    searchActivityRecordsRequest: state.sessions.searchActivityRecordsRequest,
    pageNumber: parsed.pageNumber != null ? parseInt(parsed.pageNumber, 10) : 1,
    fromDateString: parsed.fromDate || "",
    toDateString: parsed.toDate || "",
    selectedSchoolID: parsed.schoolID || "",
    selectedTemplateID: parsed.templateID || "",
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchSelectOptions: bindActionCreators(fetchSelectOptions, dispatch),
    searchActivityRecords: bindActionCreators(searchActivityRecords, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordsManagement);
