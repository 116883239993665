// @flow
import React from "react";
import type { Node } from "react";
import type { Template } from "../../types/template";
import Link from "./Link";

export default function EditTemplateLink(props: {
  template: Template,
  className?: string,
  children?: Node,
}) {
  const { template, className, children } = props;
  return (
    <Link
      className={className}
      to={`/template/${template.id}/edit`}
      altId="teacher.edit_template"
    >
      {children}
    </Link>
  );
}
