// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import styles from "./AdminHeader.module.scss";

export type HeaderTitle = {
  text: string,
  width: string,
};

type Props = {
  titles: $ReadOnlyArray<HeaderTitle>,
};

class AdminHeader extends PureComponent<Props> {
  render() {
    return (
      <div className={styles.container}>
        {this.props.titles.map(title => (
          <div
            key={title.text}
            className={styles.title}
            style={{ width: title.width }}
          >
            <Text translationKey={title.text} />
          </div>
        ))}
      </div>
    );
  }
}

export default AdminHeader;
