// @flow
import React, { PureComponent } from "react";
import type { Node } from "react";
import Link from "./Link";
import { openLinkInNewWindow } from "../../utils/window";
import { logResumeSession } from "../../utils/auditTrail";

type Props = {
  className: string,
  sessionID: string,
  sessionCode: string,
  templateId: string,
  templateDisplayCode: string,
  templateName: string,
  teacherName: string,
  children: Node,
};

function toPath(sessionID: string): string {
  return `/session/${sessionID}`;
}

export default class TeacherSessionLink extends PureComponent<Props> {
  onClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { sessionID } = this.props;
    const path = toPath(sessionID);
    openLinkInNewWindow({
      path,
    });
    this.logResumeSession();
  };

  logResumeSession() {
    const {
      sessionID,
      sessionCode,
      templateId,
      templateDisplayCode,
      templateName,
      teacherName,
    } = this.props;
    logResumeSession({
      sessionId: sessionID,
      sessionCode,
      templateId,
      templateDisplayCode,
      templateName,
      teacherName,
    });
  }

  render() {
    const { className, sessionID, children } = this.props;
    const path = toPath(sessionID);
    return (
      <Link
        className={className}
        to={path}
        altId="phrase.confirm"
        onClick={this.onClick}
      >
        {children}
      </Link>
    );
  }
}
