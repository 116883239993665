// @flow
import skygear from "skygear";

export function logResumeSession(data: {
  sessionId: string,
  sessionCode: string,
  templateId: string,
  templateDisplayCode: string,
  templateName: string,
  teacherName: string,
}): Promise<void> {
  const {
    sessionId,
    sessionCode,
    templateId,
    templateDisplayCode,
    templateName,
    teacherName,
  } = data;
  const args = {
    category: "in_progress_session",
    action: "resume",
    content: {
      session: {
        id: sessionId,
        code: sessionCode,
        template_id: templateId,
        template_display_code: templateDisplayCode,
        template_name: templateName,
        teacher_name: teacherName,
      },
    },
  };
  return skygear
    .lambda("polyupaths:audit_trail:add", [args])
    .then(result => {
      return Promise.resolve();
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
