// @flow
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { signupAsSchool, login } from "../../redux/actions/auth";
import { getSchool } from "../../redux/actions/app";
import Text from "../../components/Text/Text";
import ContactUsLink from "../../components/Link/ContactUsLink";
import InputWithErrorMessage from "../../components/Input/InputWithErrorMessage";
import ForgotPasswordLink from "../../components/Link/ForgotPasswordLink";
import Button from "../../components/Button/Button";
import {
  validateLogin,
  ErrorEmptyEmail,
  ErrorIncorrectEmail,
  ErrorEmptyPassword,
} from "../../utils/validate";
import LocaleLayout from "../../components/LocaleLayout";
import ErrorModal from "../../components/ErrorModal";
import type { AuthUser } from "../../types/auth";
import type { School } from "../../types/school";
import type { RootState, Dispatch } from "../../types/store";
import type { RouterHistory } from "react-router-dom";
import type { ValidateLoginError } from "../../utils/validate";
import { ErrCaptchaInvalid } from "../../types/error";
import {
  staticIncorrectCredentialsError,
  interpretError,
} from "../../utils/error";
import { isRequesting, extractError } from "../../utils/request";
import { performCaptcha } from "../../utils/tencentCaptcha";
import config from "../../config";
import styles from "./styles/Login.module.scss";

type Props = {
  +user?: AuthUser,
  +isRequesting: boolean,
  +error: mixed,
  +signupError: mixed,
  +history: RouterHistory,
  +login: (data: {
    password: string,
    email: string,
    captchaData?: {
      ticket: string,
      randstr: string,
    },
    role: string,
  }) => Promise<boolean>,
  +signup: (string, string) => Promise<boolean>,
  +getSchool: () => Promise<School>,
};

type LocalState = {|
  overrideCaptchaError: boolean,
  needCaptcha: boolean,
  email: string,
  password: string,
  validationErrors: $ReadOnlyArray<ValidateLoginError>,
|};

class Login extends PureComponent<Props, LocalState> {
  state: LocalState = {
    overrideCaptchaError: false,
    needCaptcha: false,
    email: "",
    password: "",
    validationErrors: [],
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user) {
      this.props.history.replace("/");
    }
  }

  onSignUp = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    let { email, password } = this.state;
    const validationErrors = validateLogin(email, password);
    this.setState({ validationErrors: validationErrors });
    if (validationErrors.length !== 0) {
      return;
    }
    if (this.props.isRequesting) {
      return;
    }
    email = email.trim();
    password = password.trim();
    this.props.signup(email, password).then(loggedIn => {
      if (loggedIn) {
        this.props.getSchool();
      }
    });
  };

  onLogin = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password } = this.state;
    const validationErrors = validateLogin(email, password);
    this.setState({ validationErrors: validationErrors });
    if (validationErrors.length !== 0) {
      return;
    }
    if (this.props.isRequesting) {
      return;
    }
    let p = Promise.resolve();
    if (this.state.needCaptcha) {
      p = performCaptcha(config.tencentCaptchaAppID);
    }
    p.then(captchaData => {
      this.props
        .login({
          captchaData,
          email: email.trim(),
          password: password.trim(),
          role: "school",
        })
        .then(loggedIn => {
          if (loggedIn) {
            this.props.getSchool();
          }
        })
        .catch(error => {
          const e = interpretError(error);
          if (e === ErrCaptchaInvalid) {
            if (!this.state.needCaptcha) {
              this.setState({
                needCaptcha: true,
                overrideCaptchaError: true,
              });
            } else {
              this.setState({
                overrideCaptchaError: false,
              });
            }
          } else {
            this.setState({
              overrideCaptchaError: false,
            });
          }
        });
    });
  };

  onEmailChange = e => {
    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.setState({ email: target.value });
    }
  };

  onPasswordChange = e => {
    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.setState({ password: target.value });
    }
  };

  renderTitle() {
    return (
      <div className={styles.loginTitleContainer}>
        <LocaleLayout>
          {props => {
            return (
              <p
                className={props.isEn ? styles.loginEnTitle : styles.loginTitle}
              >
                <Text translationKey="teacher.admin_end" />
              </p>
            );
          }}
        </LocaleLayout>
      </div>
    );
  }

  renderLoginForm() {
    const { email, password, validationErrors } = this.state;
    return (
      <div className={styles.loginFormContainer}>
        <div className={styles.row}>
          <p className={styles.title}>
            <Text translationKey="teacher.login" />
          </p>
        </div>
        <form onSubmit={this.onLogin} className={styles.row}>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="teacher.email"
              labelId="teacher.email"
              value={email}
              type="email"
              onChange={this.onEmailChange}
              className={styles.input}
              errorTextKey={
                validationErrors.includes(ErrorEmptyEmail)
                  ? "error.empty_email"
                  : validationErrors.includes(ErrorIncorrectEmail)
                    ? "error.incorrect_email"
                    : null
              }
            />
          </div>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="teacher.password"
              labelId="teacher.password"
              value={password}
              type="password"
              onChange={this.onPasswordChange}
              className={styles.input}
              errorTextKey={
                validationErrors.includes(ErrorEmptyPassword)
                  ? "error.empty_password"
                  : null
              }
            />
          </div>
          <Button color="pink" type="submit" className={styles.actionButton}>
            <Text translationKey="teacher.login" />
          </Button>
        </form>
        <div className={styles.row}>
          <ForgotPasswordLink type="teacher" className={styles.link} />
          <div className={styles.linkContainer}>
            <span className={styles.text}>
              <Text translationKey="teacher.need_help" />
            </span>
            <ContactUsLink className={styles.link} />
          </div>
        </div>
      </div>
    );
  }

  renderLoginError() {
    if (this.state.overrideCaptchaError) {
      return <ErrorModal error={staticIncorrectCredentialsError} />;
    }
    return <ErrorModal error={this.props.error} />;
  }

  render() {
    return (
      <div className={styles.formRow}>
        {this.renderTitle()}
        {this.renderLoginForm()}
        <ErrorModal error={this.props.signupError} />
        {this.renderLoginError()}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
    isRequesting:
      state.auth.isRequesting ||
      isRequesting(state.schools.createSchoolRequest),
    error: state.auth.error,
    signupError: extractError(state.schools.createSchoolRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    login: bindActionCreators(login, dispatch),
    signup: bindActionCreators(signupAsSchool, dispatch),
    getSchool: bindActionCreators(getSchool, dispatch),
  };
}

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);

export default ConnectedLogin;
