// @flow
import { PureComponent } from "react";
import type { Node } from "react";
import onClickOutside from "react-onclickoutside";

type Props = {
  +children?: Node,
  +onClickOutside: Event => void,
};

class PopupWrapper extends PureComponent<Props> {
  handleClickOutside = (e: Event) => {
    this.props.onClickOutside(e);
  };

  render() {
    return this.props.children;
  }
}

export default onClickOutside(PopupWrapper);
