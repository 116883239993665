// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import type { PollingOption } from "../../types/activity";
import type {
  ParticipantAnswer,
  PollingOptionResultItem,
} from "../../utils/session";
import Text from "../Text/Text";
import ModalContainer from "../Modal/ModalContainer";
import PagerModal from "../Modal/PagerModal";
import { getPollingOptionResultItems } from "../../utils/session";
import { indexToAlpha } from "../../utils/session";
import Image from "../Image/Image";
import styles from "./PollingChart.module.scss";

class ImageModal extends PagerModal<PollingOptionResultItem> {}

type Props = {
  className?: string,
  showParticipantName: boolean,
  imageDisplayVariant: "modal" | "inline",
  participantCount: number,
  pollingOptions: $ReadOnlyArray<PollingOption>,
  participantAnswers: $ReadOnlyArray<ParticipantAnswer>,
};

type LocalState = {
  items: $ReadOnlyArray<PollingOptionResultItem>,
  isModalShown: boolean,
  isParticipantNameExpanded: boolean,
  modalInitialIndex: number,
};

const barMaxPercentageWidth = 0.8;

export default class PollingChart extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalShown: false,
      // Expand it by default
      isParticipantNameExpanded: props.showParticipantName,
      modalInitialIndex: 0,
      items: getPollingOptionResultItems(
        props.pollingOptions,
        props.participantAnswers
      ),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      items: getPollingOptionResultItems(
        nextProps.pollingOptions,
        nextProps.participantAnswers
      ),
    });
  }

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: false,
    });
  };

  renderParticipantNames(item: PollingOptionResultItem) {
    const { isParticipantNameExpanded } = this.state;
    const { participants } = item;
    if (!isParticipantNameExpanded || participants.length <= 0) {
      return null;
    }
    const concat = participants.map(a => a.name).join("、");
    return (
      <div className={styles.participantNameContainer}>
        <p className={styles.participantNameText}>{concat}</p>
      </div>
    );
  }

  renderItem = (item: PollingOptionResultItem, index: number) => {
    const alpha = indexToAlpha(index);
    const {
      showParticipantName,
      imageDisplayVariant,
      participantAnswers,
    } = this.props;
    const answeredParticipantCount = participantAnswers.length;
    let percentage = 0;
    // Avoid division by zero
    if (answeredParticipantCount !== 0) {
      percentage = item.participants.length / answeredParticipantCount;
    }
    const percentageText = Math.round(percentage * 100) + "%";
    const inlineImage =
      imageDisplayVariant === "inline" && item.imageUrl != null;

    const barPercentageWidth = barMaxPercentageWidth * percentage;

    // a != b is logical XOR where both a and b are of type boolean
    const contentClickable =
      showParticipantName && item.participants.length > 0;
    const alphaClickable = inlineImage;
    const wholeRowClickable = contentClickable !== alphaClickable;
    return (
      <div
        key={index}
        className={classnames(styles.optionRow, {
          [styles.clickable]: wholeRowClickable,
        })}
        onClick={e => {
          if (wholeRowClickable) {
            e.preventDefault();
            e.stopPropagation();
            if (alphaClickable) {
              this.setState({
                isModalShown: true,
                modalInitialIndex: index,
              });
            } else if (contentClickable) {
              this.setState(prevState => {
                return {
                  isParticipantNameExpanded: !prevState.isParticipantNameExpanded,
                };
              });
            }
          }
        }}
      >
        <div
          className={classnames(styles.optionAlphaContainer, {
            [styles.clickable]: alphaClickable,
          })}
          onClick={e => {
            if (alphaClickable) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({
                isModalShown: true,
                modalInitialIndex: index,
              });
            }
          }}
        >
          <div
            className={classnames(styles.optionAlpha, {
              [styles.clickable]: alphaClickable,
            })}
          >
            {alpha}
          </div>
        </div>
        <div
          className={classnames(styles.optionContent, {
            [styles.clickable]: contentClickable,
          })}
          onClick={e => {
            if (contentClickable) {
              e.preventDefault();
              e.stopPropagation();
              this.setState(prevState => {
                return {
                  isParticipantNameExpanded: !prevState.isParticipantNameExpanded,
                };
              });
            }
          }}
        >
          <p className={styles.text}>{item.text}</p>
          <div className={styles.barAndStats}>
            <div
              style={{ width: 100 * barPercentageWidth + "%" }}
              className={styles.bar}
            />
            <div className={styles.statsContainer}>
              <p className={styles.percentageText}>{percentageText}</p>
              <p
                className={classnames(styles.countText, {
                  [styles.clickable]: contentClickable,
                  [styles.expanded]:
                    showParticipantName && this.state.isParticipantNameExpanded,
                  [styles.collapsed]:
                    showParticipantName &&
                    !this.state.isParticipantNameExpanded,
                })}
              >
                <Text
                  translationKey="student.number_of_answers"
                  values={{
                    n: item.participants.length,
                  }}
                />
              </p>
            </div>
          </div>
          {this.renderParticipantNames(item)}
        </div>
      </div>
    );
  };

  renderModal() {
    if (!this.state.isModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <ImageModal
          hideArrows
          className={styles.imageModal}
          initialIndex={this.state.modalInitialIndex}
          data={this.state.items}
        >
          {(data: PollingOptionResultItem, index: number) => {
            const alpha = indexToAlpha(index);

            return (
              <div className={styles.modalContent}>
                <div className={styles.modalUpperContainer}>
                  <p className={styles.modalAlphaText}>{alpha}</p>
                  <p className={styles.modalText}>{data.text}</p>
                  <div
                    onClick={this.onModalClose}
                    className={styles.modalCloseButton}
                  />
                </div>
                <div className={styles.modalImageContainer}>
                  {data.imageUrl != null && (
                    <Image
                      imageUrl={data.imageUrl}
                      width="100%"
                      height="100%"
                      placeholderWidth="100%"
                      placeholderHeight="75%"
                    />
                  )}
                </div>
              </div>
            );
          }}
        </ImageModal>
      </ModalContainer>
    );
  }

  render() {
    const { items } = this.state;
    return (
      <div className={this.props.className}>
        {items.map(this.renderItem)}

        {this.renderModal()}
      </div>
    );
  }
}
