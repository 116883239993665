// @flow
import skygear from "skygear";
import type { EventHandle } from "skygear";
import { PureComponent } from "react";

type Props = {
  channel: string,
  onOpen: () => void,
  onMessage: mixed => void,
};

// Wrap skygear.pubsub.on to make it return EventHandle
function subscribe(channel: string, handler: mixed => void): EventHandle {
  const fn = data => {
    handler(data);
  };
  const cancel = () => {
    skygear.pubsub.off(channel, fn);
  };
  skygear.pubsub.on(channel, fn);
  return {
    cancel,
  };
}

// Bind React Component lifecycle to skygear pubsub
export default class SkygearPubsub extends PureComponent<Props> {
  _onOpenHandle: ?EventHandle;
  _onCloseHandle: ?EventHandle;
  _onMessageHandle: ?EventHandle;

  componentDidMount() {
    this._onOpenHandle = skygear.pubsub.onOpen(this.onOpen);
    this._onCloseHandle = skygear.pubsub.onClose(this.onClose);
    this._onMessageHandle = subscribe(this.props.channel, this.onMessage);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.channel !== this.props.channel) {
      if (this._onMessageHandle) {
        this._onMessageHandle.cancel();
      }
      this._onMessageHandle = subscribe(this.props.channel, this.onMessage);
    }
  }

  componentWillUnmount() {
    if (this._onOpenHandle) {
      this._onOpenHandle.cancel();
    }
    if (this._onCloseHandle) {
      this._onCloseHandle.cancel();
    }
    if (this._onMessageHandle) {
      this._onMessageHandle.cancel();
    }
  }

  onClose = () => {
    // nothing we need to do
  };

  onOpen = () => {
    this.props.onOpen();
  };

  onMessage = (data: mixed) => {
    this.props.onMessage(data);
  };

  render() {
    return null;
  }
}
