// @flow
import {
  SCHOOL_ACCOUNT_MANAGEMENT_PATH,
  TEMPLATE_MANAGEMENT_PATH,
  SCHOOL_TEMPLATES,
} from "../types/paths";
import { UserTypeSuperAdmin, UserTypeEditor } from "../types/user";
import type { UserType } from "../types/user";

export function defaultPathByUserType(userType: UserType) {
  return userType === UserTypeSuperAdmin
    ? SCHOOL_ACCOUNT_MANAGEMENT_PATH
    : userType === UserTypeEditor ? TEMPLATE_MANAGEMENT_PATH : SCHOOL_TEMPLATES;
}

export const ADMIN_PATH_PATTERNS = [
  "^/admin/school_account_management",
  "^/admin/template_management",
  "^/admin/records_management",
  "^/admin/admin_user_management",
  "^/admin/audit_trail",
  "^/admin/system_configuration",
  "^/preview_session",
];

export const EDITOR_PATH_PATTERNS = [
  "^/admin/template_management",
  "^/admin/records_management",
  "^/preview_session",
];

export const SCHOOL_PATH_PATTERNS = [
  "^/session",
  "^/template",
  "^/preview_session",
];
