// @flow
import en from "./en.json";
import chs from "./zh-Hans.json";
import cht from "./zh-Hant.json";

export default {
  en,
  "zh-Hans-CN": chs,
  "zh-Hant-HK": cht,
};
