// @flow
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchOne, editImported } from "../../redux/actions/templates";
import ImportedTemplate from "../../components/ImportedTemplate/ImportedTemplate";
import type { ContextRouter } from "react-router-dom";
import type { Template } from "../../types/template";
import type { RootState, Dispatch } from "../../types/store";
import type { RouterHistory } from "react-router-dom";

type OwnProps = {
  +id: string,
  +history: RouterHistory,
};

type ConnectedProps = {
  +isRequesting: boolean,
  +originalTemplate: ?Template,
  +fetchOne: string => void,
  +editImported: Template => Promise<Template>,
  +error: mixed,
};

type Props = OwnProps & ConnectedProps;

class EditTemplate extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchOne(this.props.id);
  }

  render() {
    const {
      isRequesting,
      history,
      originalTemplate,
      editImported,
      error,
    } = this.props;

    return (
      <ImportedTemplate
        history={history}
        isRequesting={isRequesting}
        originalTemplate={originalTemplate}
        error={error}
        save={editImported}
      />
    );
  }
}

function mapStateToProps(state: RootState, ownProps: ContextRouter) {
  const id = ((ownProps.match.params.id: any): string);
  const { templatesById, isRequesting, error } = state.templates;
  const originalTemplate = templatesById[id];
  return {
    id,
    originalTemplate,
    isRequesting,
    error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchOne: bindActionCreators(fetchOne, dispatch),
    editImported: bindActionCreators(editImported, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);
