// @flow
import type { RequestState } from "./request";

export type SchoolResponse = {|
  +_id: string,
  +name: string,
  +eng_name: string,
  +email: string,
  +created_at: string,
  +disabled: boolean,
|};

export type School = {
  +id: string,
  +name: string,
  +engName: string,
  +email: string,
  +createdAt: Date,
  +disabled: boolean,
};

export type SchoolsState = {
  +schoolByID: { [id: string]: School },
  +searchSchoolRequest: ?RequestState<void>,
  +createSchoolRequest: ?RequestState<void>,
  +editSchoolRequest: ?RequestState<void>,
  +enableDisableSchoolRequest: ?RequestState<void>,
};

export const SEARCH_SCHOOL_REQUESTING: "SEARCH_SCHOOL_REQUESTING" =
  "SEARCH_SCHOOL_REQUESTING";
export const SEARCH_SCHOOL_SUCCESS: "SEARCH_SCHOOL_SUCCESS" =
  "SEARCH_SCHOOL_SUCCESS";
export const SEARCH_SCHOOL_FAILURE: "SEARCH_SCHOOL_FAILURE" =
  "SEARCH_SCHOOL_FAILURE";

export type SearchSchoolRequesting = {|
  +type: typeof SEARCH_SCHOOL_REQUESTING,
|};
export type SearchSchoolSuccess = {|
  +type: typeof SEARCH_SCHOOL_SUCCESS,
  +payload: $ReadOnlyArray<School>,
|};
export type SearchSchoolFailure = {|
  +type: typeof SEARCH_SCHOOL_FAILURE,
  +payload: mixed,
|};

export const CREATE_SCHOOL_REQUESTING: "SCHOOLS.CREATE_SCHOOL.REQUESTING" =
  "SCHOOLS.CREATE_SCHOOL.REQUESTING";
export const CREATE_SCHOOL_SUCCESS: "SCHOOLS.CREATE_SCHOOL.SUCCESS" =
  "SCHOOLS.CREATE_SCHOOL.SUCCESS";
export const CREATE_SCHOOL_FAILURE: "SCHOOLS.CREATE_SCHOOL.FAILURE" =
  "SCHOOLS.CREATE_SCHOOL.FAILURE";

export type CreateSchoolRequesting = {|
  +type: typeof CREATE_SCHOOL_REQUESTING,
|};
export type CreateSchoolSuccess = {|
  +type: typeof CREATE_SCHOOL_SUCCESS,
  +payload: School,
|};
export type CreateSchoolFailure = {|
  +type: typeof CREATE_SCHOOL_FAILURE,
  +payload: mixed,
|};

export const EDIT_SCHOOL_REQUESTING: "SCHOOLS.EDIT_SCHOOL.REQUESTING" =
  "SCHOOLS.EDIT_SCHOOL.REQUESTING";
export const EDIT_SCHOOL_SUCCESS: "SCHOOLS.EDIT_SCHOOL.SUCCESS" =
  "SCHOOLS.EDIT_SCHOOL.SUCCESS";
export const EDIT_SCHOOL_FAILURE: "SCHOOLS.EDIT_SCHOOL.FAILURE" =
  "SCHOOLS.EDIT_SCHOOL.FAILURE";

export type EditSchoolRequesting = {|
  +type: typeof EDIT_SCHOOL_REQUESTING,
|};
export type EditSchoolSuccess = {|
  +type: typeof EDIT_SCHOOL_SUCCESS,
  +payload: School,
|};
export type EditSchoolFailure = {|
  +type: typeof EDIT_SCHOOL_FAILURE,
  +payload: mixed,
|};

export const ENABLE_DISABLE_SCHOOL_REQUESTING: "SCHOOLS.ENABLE_DISABLE.REQUESTING" =
  "SCHOOLS.ENABLE_DISABLE.REQUESTING";
export const ENABLE_DISABLE_SCHOOL_SUCCESS: "SCHOOLS.ENABLE_DISABLE.SUCCESS" =
  "SCHOOLS.ENABLE_DISABLE.SUCCESS";
export const ENABLE_DISABLE_SCHOOL_FAILURE: "SCHOOLS.ENABLE_DISABLE.FAILURE" =
  "SCHOOLS.ENABLE_DISABLE.FAILURE";

export type EnableDisableSchoolRequesting = {|
  +type: typeof ENABLE_DISABLE_SCHOOL_REQUESTING,
|};
export type EnableDisableSchoolSuccess = {|
  +type: typeof ENABLE_DISABLE_SCHOOL_SUCCESS,
  +payload: School,
|};
export type EnableDisableSchoolFailure = {|
  +type: typeof ENABLE_DISABLE_SCHOOL_FAILURE,
  +payload: mixed,
|};
