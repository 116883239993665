// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import type { IntlShape } from "react-intl";
import type { RootState } from "../../types/store";
import styles from "./Input.module.scss";

export type InputProps = {
  +labelId: string,
  +placeholderId: string,
  +value: string,
  +className?: string,
  +type: string,
  +onChange: (Event, mixed) => void,
  +onChangeInfo?: mixed,
};

type OwnProps = {
  +intl: IntlShape,
};

type Props = InputProps & OwnProps;

class Input extends PureComponent<Props> {
  static childContextTypes = {
    intl: PropTypes.any,
  };

  getChildContext() {
    return {
      intl: this.props.intl,
    };
  }

  onChange = (e: Event) => {
    this.props.onChange(e, this.props.onChangeInfo);
  };

  render() {
    const { intl, labelId, placeholderId, value, className, type } = this.props;

    return (
      <input
        label={intl.formatMessage({ id: labelId })}
        placeholder={intl.formatMessage({ id: placeholderId })}
        onChange={this.onChange}
        value={value}
        className={classnames(styles.input, className)}
        type={type}
      />
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
