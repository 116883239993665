// @flow
import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import type { Node } from "react";
import styles from "./ModalContainer.module.scss";

type Props = {
  applyBackgroundColor: boolean,
  children: Node,
  onClose?: Event => void,
};

const staticStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};

const modalRoot = document.getElementById("modal-root");

export default class ModalContainer extends PureComponent<Props> {
  static defaultProps = {
    applyBackgroundColor: true,
  };

  el: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  render() {
    const { applyBackgroundColor } = this.props;
    const element = (
      <div
        style={applyBackgroundColor ? staticStyle : undefined}
        className={styles.modalContainer}
        onClick={this.props.onClose}
      >
        {this.props.children}
      </div>
    );
    return createPortal(element, this.el);
  }
}
