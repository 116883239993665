// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import IconButton from "../IconButton/IconButton";
import CreateTemplateModal from "../Modal/CreateTemplateModal";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import customIcon from "../../images/template_edit.svg";
import { MAX_CUSTOM_TEMPLATES_NUM } from "../../types/template";
import type { Template } from "../../types/template";
import styles from "./CreateTemplateButton.module.scss";

type Props = {
  +className: string,
  +template: Template,
  +onCreateTemplate: (templateId: string, newName: string) => void,
};

type LocalState = {|
  createCustomTemplateModalShown: boolean,
  alertModalShown: boolean,
|};

class CreateTemplateButton extends PureComponent<Props, LocalState> {
  state = {
    createCustomTemplateModalShown: false,
    alertModalShown: false,
  };

  onCreateCustomTemplateButtonClicked = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.template.canCreateCustomTemplate) {
      this.setState({
        createCustomTemplateModalShown: true,
      });
    } else {
      this.setState({
        alertModalShown: true,
      });
    }
  };

  onCreateCustomTemplate = (inputName: string) => {
    this.props.onCreateTemplate(this.props.template.id, inputName);
    this.setState({ createCustomTemplateModalShown: false });
  };

  onCloseCreateTemplateModal = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ createCustomTemplateModalShown: false });
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
  };

  renderAlertModal() {
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.create_custom_template.error_modal.title"
          buttonKey="phrase.confirm"
          onButtonClick={this.onCloseAlertModal}
          onClose={this.onCloseAlertModal}
        />
      </ModalContainer>
    );
  }

  renderCreateCustomTemplateModal() {
    const { template } = this.props;
    const { createCustomTemplateModalShown } = this.state;

    if (!createCustomTemplateModalShown) {
      return null;
    }

    return (
      <CreateTemplateModal
        templateDisplayCode={`${
          template.displayCode
        }-${template.nextSchoolLocalCode + 1}`}
        customTemplateMaxNumber={
          template.customTemplateMaxNumber != null
            ? template.customTemplateMaxNumber
            : MAX_CUSTOM_TEMPLATES_NUM
        }
        defaultValue={`${template.name}${template.nextSchoolLocalCode + 1}`}
        onClose={this.onCloseCreateTemplateModal}
        onCreate={this.onCreateCustomTemplate}
      />
    );
  }

  render() {
    return (
      <div
        className={classnames(
          styles.createTemplateButtonContainer,
          this.props.className
        )}
      >
        <IconButton
          className={styles.customTemplateIconButton}
          icon={customIcon}
          labelTranslationKey="teacher.custom_template"
          onClick={this.onCreateCustomTemplateButtonClicked}
        />
        {this.renderCreateCustomTemplateModal()}
        {this.renderAlertModal()}
      </div>
    );
  }
}

export default CreateTemplateButton;
