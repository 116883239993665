// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import Select from "../Select/Select";
import Option from "../Option/Option";
import Input from "../Input/Input";
import searchIcon from "../../images/template_search.svg";
import {
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_YEAR_FORM_2,
  TEMPLATE_YEAR_FORM_3,
  TEMPLATE_LANG_ALL,
  TEMPLATE_LANG_EN,
  TEMPLATE_LANG_ZH_HANT,
  TEMPLATE_LANG_ZH_HANS,
} from "../../types/template";
import TemplatesLink from "../Link/TemplatesLink";
import type { TEMPLATE_YEAR, TEMPLATE_LANG } from "../../types/template";
import styles from "./TemplatesHeader.module.scss";

type Props = {
  year: TEMPLATE_YEAR,
  lang: TEMPLATE_LANG,
  keywords: string,
  onChange: (TEMPLATE_LANG, string) => void,
};

class TemplatesHeader extends PureComponent<Props> {
  onLangChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const langUnsafe = event.target.value;
    if (
      langUnsafe === "all" ||
      langUnsafe === "en" ||
      langUnsafe === "zh-Hant" ||
      langUnsafe === "zh-Hans"
    ) {
      const value: TEMPLATE_LANG = (langUnsafe: TEMPLATE_LANG);
      this.props.onChange(value, this.props.keywords);
    }
  };

  onKeywordsChange = (event: Event) => {
    const { target } = event;
    if (target instanceof HTMLInputElement) {
      this.props.onChange(this.props.lang, target.value);
    }
  };

  renderFormFilter(
    year: TEMPLATE_YEAR,
    activeYear: TEMPLATE_YEAR,
    translationKey: string
  ) {
    return (
      <TemplatesLink
        year={year}
        key={year}
        className={classnames(styles.formFilter, {
          [styles.activeFormFilter]: year === activeYear,
        })}
      >
        <Text translationKey={translationKey} />
      </TemplatesLink>
    );
  }

  renderFormFilters() {
    const { year } = this.props;
    const formFilterButtons = [
      TEMPLATE_YEAR_FORM_1,
      TEMPLATE_YEAR_FORM_2,
      TEMPLATE_YEAR_FORM_3,
    ].map((y, idx) =>
      this.renderFormFilter(y, year, `teacher.filter_form_${idx + 1}`)
    );
    return <div className={styles.formFilters}>{formFilterButtons}</div>;
  }

  renderLanguageSelector() {
    const { lang } = this.props;
    const langOptions = [
      TEMPLATE_LANG_ALL,
      TEMPLATE_LANG_ZH_HANT,
      TEMPLATE_LANG_ZH_HANS,
      TEMPLATE_LANG_EN,
    ].map(l => (
      <Option
        translationKey={`teacher.template_language_${l}`}
        value={l}
        key={l}
      />
    ));
    return (
      <div className={styles.selectorContainer}>
        <p className={styles.selectorLabel}>
          <Text translationKey="teacher.filter_template_language" />
        </p>
        <Select
          name="template-language-selector"
          className={styles.selector}
          value={lang}
          onChange={this.onLangChange}
        >
          <p className={styles.selectOptionText}>
            <Text translationKey={`teacher.template_language_${lang}`} />
          </p>
          {langOptions}
        </Select>
      </div>
    );
  }

  renderSearchInput() {
    const { keywords } = this.props;
    return (
      <div className={styles.searchInputContainer}>
        <img className={styles.searchIcon} src={searchIcon} alt="search icon" />
        <Input
          placeholderId="teacher.input_placeholder_search_unit_template"
          labelId="teacher.input_placeholder_search_unit_template"
          type="text"
          value={keywords}
          className={styles.searchInput}
          onChange={this.onKeywordsChange}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.headerContainer}>
        {this.renderFormFilters()}
        <div className={styles.selectorInputContainer}>
          {this.renderLanguageSelector()}
          {this.renderSearchInput()}
        </div>
      </div>
    );
  }
}

export default TemplatesHeader;
