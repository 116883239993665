// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { Answer, Activity } from "../../types/activity";
import type { Session, ParticipationInfo } from "../../types/session";
import type { RequestState } from "../../types/request";
import { submitAnswer } from "../../redux/actions/sessions";
import { isRequesting } from "../../utils/request";
import { getBackgroundImageURL } from "../../utils/activity";
import {
  getSubmittedAnswer,
  wasActivityResultCleared,
  wasActivitySeeked,
} from "../../utils/session";
import ensureSkygearImageBase64Format from "../../utils/ensureSkygearImageBase64Format";
import uploadImageData from "../../utils/uploadImageData";
import QuestionView from "./QuestionView";
import Text from "../Text/Text";
import Button from "../Button/Button";
import SubmitButton from "./SubmitButton";
import DrawingBoard from "../DrawingBoard/DrawingBoard";
import styles from "./StudentSessionActivity.module.scss";

type OwnProps = {
  className: string,
  session: Session,
  participationInfo: ParticipationInfo,
  activity: Activity,
};

type ConnectedProps = {
  submitAnswerRequest: ?RequestState<void>,
  submitAnswer: (
    sessionId: string,
    activityIndex: number,
    participationInfo: ParticipationInfo,
    answer: Answer
  ) => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  isUploadingImage: boolean,
  drawingBoardKeySuffix: number,
  drawingBoardObjectsCount: number,
};

function generateDrawingBoardKey(
  session: Session,
  participationInfo: ParticipationInfo,
  suffix: number
) {
  return `${session.id}_${session.currentActivityIndex}_${
    participationInfo.participantId
  }_${suffix}`;
}

function generateFilename(
  session: Session,
  participationInfo: ParticipationInfo,
  suffix: number
) {
  const key = generateDrawingBoardKey(session, participationInfo, suffix);
  return key + ".png";
}

class StudentSessionActivityWhiteboard extends PureComponent<
  Props,
  LocalState
> {
  drawingBoard: ?DrawingBoard;

  state = {
    isUploadingImage: false,
    drawingBoardKeySuffix: 0,
    drawingBoardObjectsCount: 0,
  };

  componentWillReceiveProps(nextProps: Props) {
    const seeked = wasActivitySeeked(this.props.session, nextProps.session);
    const cleared = wasActivityResultCleared(
      this.props.session,
      nextProps.session
    );
    if (seeked || cleared) {
      this.setState(prevState => {
        return {
          drawingBoardKeySuffix: prevState.drawingBoardKeySuffix + 1,
        };
      });
    }
  }

  onSubmit = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { drawingBoard } = this;
    if (drawingBoard == null) {
      return;
    }

    const { session, participationInfo } = this.props;
    this.setState({
      isUploadingImage: true,
    });
    drawingBoard.generateDataURL().then(dataURL => {
      const base64 = ensureSkygearImageBase64Format(dataURL);
      const filename = generateFilename(
        session,
        participationInfo,
        this.state.drawingBoardKeySuffix
      );
      uploadImageData(filename, base64, "image/png").then(
        asset => {
          this.setState({
            isUploadingImage: false,
          });
          this.props.submitAnswer(
            session.id,
            session.currentActivityIndex,
            participationInfo,
            {
              qaText: null,
              whiteboardImageId: asset.name,
              whiteboardImageUrl: asset.url,
              pollingOptionIndice: null,
            }
          );
        },
        error => {
          this.setState({
            isUploadingImage: false,
          });
        }
      );
    });
  };

  onExportButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { drawingBoard } = this;
    if (drawingBoard != null) {
      drawingBoard.downloadImage();
    }
  };

  onDrawingBoardRef = (r: DrawingBoard | null) => {
    this.drawingBoard = r;
  };

  onObjectsCountChange = (count: number) => {
    this.setState({ drawingBoardObjectsCount: count });
  };

  render() {
    const {
      className,
      activity,
      session,
      participationInfo,
      submitAnswerRequest,
    } = this.props;
    const { drawingBoardKeySuffix, isUploadingImage } = this.state;
    const answer = getSubmittedAnswer(session, participationInfo.participantId);
    const submitted = answer != null;
    const backgroundImageUrl =
      (answer && answer.whiteboardImageUrl) || getBackgroundImageURL(activity);
    const orientation = activity.whiteboardOrientation;
    const stickerImageURLs = activity.whiteboardStickers.map(a => a.imageUrl);

    for (const sticker of session.defaultStickerList) {
      stickerImageURLs.push(sticker.imageUrl);
    }

    return (
      <div className={className}>
        <h1 className={styles.activityNameText}>{activity.name}</h1>
        <QuestionView activity={activity} suppressImage={true} />
        <div className={styles.whiteboardDrawingBoardContainer}>
          <DrawingBoard
            key={generateDrawingBoardKey(
              session,
              participationInfo,
              drawingBoardKeySuffix
            )}
            ref={this.onDrawingBoardRef}
            backgroundImageUrl={backgroundImageUrl}
            stickerImageURLs={stickerImageURLs}
            orientation={orientation}
            readOnly={submitted}
            onObjectsCountChange={this.onObjectsCountChange}
          />
        </div>
        <div className={styles.whiteboardButtonContainer}>
          <Button
            color="green"
            onClick={this.onExportButtonClick}
            className={styles.whiteboardExportButton}
            disabled={this.state.drawingBoardObjectsCount <= 0}
          >
            <Text translationKey="student.export_image" />
          </Button>
          <SubmitButton
            className={styles.whiteboardSubmitButton}
            submitted={submitted}
            loading={isUploadingImage || isRequesting(submitAnswerRequest)}
            onSubmit={this.onSubmit}
            disabled={this.state.drawingBoardObjectsCount <= 0}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    submitAnswerRequest: state.sessions.submitAnswerRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    submitAnswer: bindActionCreators(submitAnswer, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  StudentSessionActivityWhiteboard
): any): Class<React$Component<OwnProps>>);
