// @flow
import React, { PureComponent } from "react";
import ActivityPanelBasicInfo from "./ActivityPanelBasicInfo";
import ActivityPanelQuestionContent from "./ActivityPanelQuestionContent";
import type { Node } from "react";
import type { CommonProps } from "./types/ActivityPanelProps";
import styles from "./ActivityPanelRenderer.module.scss";

type LocalProps = {
  +children?: Node,
} & CommonProps;

class ActivityPanelRenderer extends PureComponent<LocalProps> {
  render() {
    const { children } = this.props;

    return (
      <div className={styles.activityContainer}>
        <ActivityPanelBasicInfo {...this.props} />
        <ActivityPanelQuestionContent {...this.props} />
        {children}
      </div>
    );
  }
}

export default ActivityPanelRenderer;
