// @flow
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";
import TemplatesLink from "../Link/TemplatesLink";
import SchoolAccountManagementLink from "../Link/SchoolAccountManagementLink";
import Text from "../Text/Text";
import Button from "../Button/Button";
import LocaleLayout from "../LocaleLayout";
import { TEMPLATE_YEAR_FORM_1 } from "../../types/template";
import type { RootState, Dispatch } from "../../types/store";
import type { School } from "../../types/school";
import type { AuthUser } from "../../types/auth";
import styles from "./DashboardHeader.module.scss";

type Props = {
  +user: ?AuthUser,
  +school: ?School,
  +isRequesting: boolean,
  +logout: void => void,
  +type: "teacher" | "admin",
};

class DashboardHeader extends PureComponent<Props> {
  onLogout = () => {
    if (this.props.isRequesting) {
      return;
    }
    this.props.logout();
  };

  renderLogo() {
    return <figure className={styles.logo} />;
  }

  renderTitle() {
    return (
      <div className={styles.titleContainer}>
        <LocaleLayout>
          {props => {
            const { isEn } = props;
            const className = isEn ? styles.enTitle : styles.title;
            return (
              <p className={className}>
                <Text translationKey="teacher.interactive_learning_platform" />
              </p>
            );
          }}
        </LocaleLayout>
        <p className={styles.subTitle}>
          <Text
            translationKey={
              this.props.type === "teacher"
                ? "teacher.admin_end"
                : "admin.admin_portal"
            }
          />
        </p>
      </div>
    );
  }

  renderName() {
    const { type, school, user } = this.props;

    if (type === "teacher") {
      return <p className={styles.email}>{school ? school.email : ""}</p>;
    }

    return <p className={styles.email}>{user ? user.email : ""}</p>;
  }

  renderLogoutButton() {
    return (
      <Button color="white" onClick={this.onLogout} className={styles.button}>
        <Text translationKey="teacher.logout" />
      </Button>
    );
  }

  render() {
    return (
      <div className={styles.headerRow}>
        {this.props.type === "teacher" && (
          <TemplatesLink year={TEMPLATE_YEAR_FORM_1}>
            {this.renderLogo()}
            {this.renderTitle()}
          </TemplatesLink>
        )}
        {this.props.type === "admin" && (
          <SchoolAccountManagementLink>
            {this.renderLogo()}
            {this.renderTitle()}
          </SchoolAccountManagementLink>
        )}
        {this.renderName()}
        {this.renderLogoutButton()}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    school: state.app.school,
    user: state.auth.user,
    isRequesting: state.auth.isRequesting,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
