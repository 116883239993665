// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { Activity, Answer } from "../../types/activity";
import type { Session, ParticipationInfo } from "../../types/session";
import type { RequestState } from "../../types/request";
import { submitAnswer } from "../../redux/actions/sessions";
import { isRequesting } from "../../utils/request";
import {
  getSubmittedAnswer,
  wasActivityResultCleared,
  wasActivitySeeked,
} from "../../utils/session";
import QuestionView from "./QuestionView";
import TextArea from "../TextArea/TextArea";
import SubmitButton from "./SubmitButton";
import styles from "./StudentSessionActivity.module.scss";

type OwnProps = {
  className: string,
  session: Session,
  activity: Activity,
  participationInfo: ParticipationInfo,
};

type ConnectedProps = {
  submitAnswerRequest: ?RequestState<void>,
  submitAnswer: (
    sessionId: string,
    activityIndex: number,
    participationInfo: ParticipationInfo,
    answer: Answer
  ) => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  answer: string,
};

function validateAnswer(answer: string): ?string {
  const trimmed = answer.trim();
  if (trimmed === "") {
    return null;
  }
  return trimmed;
}

class StudentSessionActivityQA extends PureComponent<Props, LocalState> {
  state = {
    answer: "",
  };

  componentWillReceiveProps(nextProps: Props) {
    const seeked = wasActivitySeeked(this.props.session, nextProps.session);
    const cleared = wasActivityResultCleared(
      this.props.session,
      nextProps.session
    );
    if (seeked || cleared) {
      this.setState({
        answer: "",
      });
    }
  }

  onSubmit = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { answer } = this.state;
    const validAnswer = validateAnswer(answer);
    if (validAnswer != null) {
      const { session, participationInfo } = this.props;
      this.props.submitAnswer(
        session.id,
        session.currentActivityIndex,
        participationInfo,
        {
          qaText: answer,
          whiteboardImageId: null,
          whiteboardImageUrl: null,
          pollingOptionIndice: null,
        }
      );
    }
  };

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      answer: e.currentTarget.value,
    });
  };

  render() {
    const {
      className,
      activity,
      session,
      participationInfo,
      submitAnswerRequest,
    } = this.props;
    const answer = getSubmittedAnswer(session, participationInfo.participantId);
    const submitted = answer != null;
    const submittedAnswer = (answer && answer.qaText) || "";

    return (
      <div className={className}>
        <h1 className={styles.activityNameText}>{activity.name}</h1>
        <QuestionView activity={activity} />
        <div className={styles.qaTextAreaContainer}>
          <TextArea
            className={styles.qaTextArea}
            placeholderId="student.qa_answer_placeholder"
            onChange={this.onChange}
            disabled={submitted}
            value={submitted ? submittedAnswer : this.state.answer}
          />
        </div>
        <div className={styles.submitButtonContainer}>
          <SubmitButton
            className={styles.submitButton}
            submitted={submitted}
            loading={isRequesting(submitAnswerRequest)}
            onSubmit={this.onSubmit}
            disabled={this.state.answer.length <= 0}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    submitAnswerRequest: state.sessions.submitAnswerRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    submitAnswer: bindActionCreators(submitAnswer, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  StudentSessionActivityQA
): any): Class<React$Component<OwnProps>>);
