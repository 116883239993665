// @flow
import React, { PureComponent } from "react";
import type { Node } from "react";
import classnames from "classnames";
import isImageURLEqual from "../../utils/isImageURLEqual";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./Image.module.scss";

type Props = {
  +imageUrl: string,
  +width?: number | string,
  +height?: number | string,
  +placeholderWidth: number | string,
  +placeholderHeight: number | string,
  +className?: string,
  +children?: Node,
};

type Style = {
  backgroundImage?: string,
  width?: any,
  height?: any,
};

type LocalState = {
  loading: boolean,
  failed: boolean,
  imageIntrinsicWidth: number,
  imageIntrinsicHeight: number,
};

class ImageLoader extends PureComponent<Props, LocalState> {
  state = {
    loading: true,
    failed: false,
    imageIntrinsicWidth: 0,
    imageIntrinsicHeight: 0,
  };

  componentDidMount() {
    this._loadImage(this.props.imageUrl);
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    // need re-calculate image size if width or height is percentage
    this.forceUpdate();
  };

  onClick = (e: Event) => {
    if (this.state.failed) {
      e.preventDefault();
      e.stopPropagation();

      // reload image
      this._loadImage(this.props.imageUrl);
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    const eq = isImageURLEqual(this.props.imageUrl, nextProps.imageUrl);
    if (!eq) {
      this._loadImage(nextProps.imageUrl);
    }
  }

  _loadImage(imageUrl: string) {
    this.setState({
      loading: true,
      failed: false,
      imageIntrinsicWidth: 0,
      imageIntrinsicHeight: 0,
    });
    // use web API Image to load the image
    const image = new Image();
    image.addEventListener("load", () => {
      if (!isImageURLEqual(imageUrl, this.props.imageUrl)) {
        return;
      }

      this.setState({
        loading: false,
        imageIntrinsicWidth: image.width,
        imageIntrinsicHeight: image.height,
      });
    });

    image.addEventListener("error", () => {
      this.setState({ loading: false, failed: true });
    });

    image.src = imageUrl;
  }

  r: ?HTMLElement;
  onRef = (r: HTMLElement | null) => {
    this.r = r;
  };

  render() {
    const {
      imageUrl,
      width,
      height,
      className,
      children,
      placeholderWidth,
      placeholderHeight,
    } = this.props;
    const {
      loading,
      failed,
      imageIntrinsicWidth,
      imageIntrinsicHeight,
    } = this.state;

    const style: Style = {};

    if (!loading && !failed) {
      style.backgroundImage = `url("${imageUrl}")`;
      if (width != null) {
        if (typeof width === "number") {
          style.width = width + "px";
        } else {
          style.width = width;
        }
      }

      if (height != null) {
        if (typeof height === "number") {
          style.height = height + "px";
        } else {
          style.height = height;
        }
      }

      if (this.r != null) {
        if (style.width == null) {
          style.width = `${imageIntrinsicWidth *
            (this.r.clientHeight / imageIntrinsicHeight)}px`;
        }
        if (style.height == null) {
          style.height = `${imageIntrinsicHeight *
            (this.r.clientWidth / imageIntrinsicWidth)}px`;
        }
      }
    } else {
      style.width = placeholderWidth;
      style.height = placeholderHeight;
    }

    return (
      <figure
        ref={this.onRef}
        style={style}
        className={classnames(styles.image, className, {
          [styles.placeholder]: loading,
          [styles.failed]: failed,
        })}
        onClick={this.onClick}
      >
        {!loading && children != null ? children : null}
        {loading && <LoadingIndicator />}
      </figure>
    );
  }
}

export default ImageLoader;
