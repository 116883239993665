// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qs from "qs";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import type { AuditTrail, SEARCH_ACTOR } from "../../types/auditTrail";
import type { RequestState, PageInfo } from "../../types/request";
import type { UnitTemplateOption, SchoolOption } from "../../types/session";
import type { RootState, Dispatch } from "../../types/store";
import Text from "../../components/Text/Text";
import Select from "../../components/Select/Select";
import Option from "../../components/Option/Option";
import StyledDatePicker from "../../components/DatePicker/StyledDatePicker";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ErrorModal from "../../components/ErrorModal";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import WindowSize from "../../components/WindowSize";
import Pagination from "../../components/Pagination/Pagination";
import AuditTrailItem from "../../components/AuditTrailItem/AuditTrailItem";
import { toQueryString, parseFromQueryString } from "../../utils/date";
import { ADMIN_DASHBOARD_PAGE_SIZE } from "../../utils/constants";
import { fetchSelectOptions } from "../../redux/actions/sessions";
import { searchAuditTrails } from "../../redux/actions/auditTrails";
import { isRequesting, extractError } from "../../utils/request";
import { isLessThanOrEqualWithoutTime } from "../../utils/date";
import {
  SEARCH_ACTOR_SCHOOL,
  SEARCH_ACTOR_ADMIN,
} from "../../types/auditTrail";
import styles from "./AuditTrailManagement.module.scss";

type OwnProps = {
  +history: RouterHistory,
  +location: Location,
};

type ConnectedProps = {
  auditTrailById: { [id: string]: AuditTrail },
  schoolOptions: ?$ReadOnlyArray<SchoolOption>,
  fetchSelectOptionsRequest: ?RequestState<void>,
  fetchSelectOptions: () => Promise<{
    templateOptions: $ReadOnlyArray<UnitTemplateOption>,
    schoolOptions: $ReadOnlyArray<SchoolOption>,
  }>,
  searchAuditTrailsRequest: ?RequestState<void>,
  searchAuditTrails: (data: {
    actor: string,
    schoolId: ?string,
    fromDate: Date,
    toDate: Date,
    pageNumber: number,
    pageSize: number,
  }) => Promise<{
    pageInfo: PageInfo,
    auditTrails: $ReadOnlyArray<AuditTrail>,
  }>,

  // From query string
  pageNumber: number,
  fromDateString: string,
  toDateString: string,
  selectedActor: SEARCH_ACTOR,
  selectedSchoolId: string,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  maxDate: Date,
  fromDate: Date,
  toDate: Date,
  pageInfo: PageInfo,
  auditTrailIds: $ReadOnlyArray<string>,
};

const MAGIC_HEIGHT_OFFSET = 435;

const HEADER_TITLES_SCHOOL = [
  {
    text: "admin.audit_trail.header.datetime",
    width: "12.5%",
  },
  {
    text: "admin.audit_trail.header.school",
    width: "37.5%",
  },
  {
    text: "admin.audit_trail.header.action",
    width: "50%",
  },
];

const HEADER_TITLES_ADMIN = [
  {
    text: "admin.audit_trail.header.datetime",
    width: "12.5%",
  },
  {
    text: "admin.audit_trail.header.email",
    width: "37.5%",
  },
  {
    text: "admin.audit_trail.header.action",
    width: "50%",
  },
];

class AuditTrailManagement extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    const d = new Date();
    this.state = {
      maxDate: d,
      fromDate: d,
      toDate: d,
      pageInfo: {
        totalCount: 0,
        totalPage: 0,
      },
      auditTrailIds: [],
    };
  }

  componentDidMount() {
    if (this.props.schoolOptions == null) {
      this.props.fetchSelectOptions();
    }
    this.searchAuditTrails();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.fromDateString !== this.props.fromDateString) {
      const d = parseFromQueryString(nextProps.fromDateString);
      if (d != null) {
        this.setState({
          fromDate: d,
        });
      }
    }
    if (nextProps.toDateString !== this.props.toDateString) {
      const d = parseFromQueryString(nextProps.toDateString);
      if (d != null) {
        this.setState({
          toDate: d,
        });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: LocalState) {
    if (
      prevProps.pageNumber !== this.props.pageNumber ||
      prevProps.selectedSchoolId !== this.props.selectedSchoolId ||
      prevProps.selectedActor !== this.props.selectedActor ||
      prevState.fromDate.getTime() !== this.state.fromDate.getTime() ||
      prevState.toDate.getTime() !== this.state.toDate.getTime()
    ) {
      this.searchAuditTrails();
    }
  }

  onChangeActor = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    if (e.target.value === "school" || e.target.value === "admin") {
      this.onReplaceHistory({
        pageNumber: this.props.pageNumber,
        selectedSchoolId: this.props.selectedSchoolId,
        selectedActor: e.target.value,
        fromDateString: toQueryString(this.state.fromDate),
        toDateString: toQueryString(this.state.toDate),
      });
    }
  };

  onChangeSchool = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    this.onReplaceHistory({
      pageNumber: this.props.pageNumber,
      selectedSchoolId: e.target.value,
      selectedActor: this.props.selectedActor,
      fromDateString: toQueryString(this.state.fromDate),
      toDateString: toQueryString(this.state.toDate),
    });
  };

  onChangeFromDate = (date: Date) => {
    if (isLessThanOrEqualWithoutTime(date, this.state.toDate)) {
      this.onReplaceHistory({
        pageNumber: this.props.pageNumber,
        selectedSchoolId: this.props.selectedSchoolId,
        selectedActor: this.props.selectedActor,
        fromDateString: toQueryString(date),
        toDateString: toQueryString(this.state.toDate),
      });
      this.setState({
        fromDate: date,
      });
    }
  };

  onChangeToDate = (date: Date) => {
    if (isLessThanOrEqualWithoutTime(this.state.fromDate, date)) {
      this.onReplaceHistory({
        pageNumber: this.props.pageNumber,
        selectedSchoolId: this.props.selectedSchoolId,
        selectedActor: this.props.selectedActor,
        fromDateString: toQueryString(this.state.fromDate),
        toDateString: toQueryString(date),
      });
      this.setState({
        toDate: date,
      });
    }
  };

  onPageChange = (pageIdx: number) => {
    this.onReplaceHistory({
      pageNumber: pageIdx,
      selectedSchoolId: this.props.selectedSchoolId,
      selectedActor: this.props.selectedActor,
      fromDateString: toQueryString(this.state.fromDate),
      toDateString: toQueryString(this.state.toDate),
    });
  };

  onReplaceHistory(data: {
    pageNumber: number,
    selectedSchoolId: string,
    selectedActor: SEARCH_ACTOR,
    fromDateString: string,
    toDateString: string,
  }) {
    const {
      pageNumber,
      selectedSchoolId,
      selectedActor,
      fromDateString,
      toDateString,
    } = data;
    this.props.history.replace({
      search: qs.stringify({
        pageNumber,
        schoolId: selectedSchoolId,
        selectedActor: selectedActor,
        fromDate: fromDateString,
        toDate: toDateString,
      }),
    });
  }

  renderActorSelect() {
    const { selectedActor } = this.props;
    return (
      <Select
        className={styles.actorSelect}
        value={selectedActor}
        onChange={this.onChangeActor}
      >
        <p className={styles.actorOptionText}>
          {selectedActor === SEARCH_ACTOR_SCHOOL ? (
            <Text translationKey="admin.audit_trail.option.school" />
          ) : (
            <Text translationKey="admin.audit_trail.option.admin_or_editor" />
          )}
        </p>
        <Option
          value="school"
          translationKey="admin.audit_trail.option.school"
        />
        <Option
          value="admin"
          translationKey="admin.audit_trail.option.admin_or_editor"
        />
      </Select>
    );
  }

  renderSchoolOption = (o: SchoolOption) => {
    return (
      <option key={o.id} value={o.id}>
        {o.engName}
      </option>
    );
  };

  renderSchoolSelect() {
    const schoolOptions = this.props.schoolOptions || [];
    const { selectedSchoolId } = this.props;
    const selected = this._findSchoolOption(selectedSchoolId);
    const { selectedActor } = this.props;

    if (selectedActor === SEARCH_ACTOR_ADMIN) {
      return null;
    }

    return (
      <Select
        className={styles.schoolSelect}
        value={selectedSchoolId}
        onChange={this.onChangeSchool}
      >
        <p className={styles.schoolOptionText}>
          {selectedSchoolId === "" ? (
            <Text translationKey="admin.audit_trail.option.all_schools" />
          ) : selected == null ? null : (
            selected.engName
          )}
        </p>
        <Option
          value=""
          translationKey="admin.audit_trail.option.all_schools"
        />
        {schoolOptions.map(this.renderSchoolOption)}
      </Select>
    );
  }

  renderHeader() {
    return (
      <Fragment>
        <div className={styles.headerRow1}>
          <p className={styles.headerTitle}>
            <span className={styles.headerTitleText}>
              <Text translationKey="admin.audit_trail.title" />
            </span>
            <span className={styles.headerCountText}>
              {this.state.pageInfo.totalCount}
            </span>
          </p>
        </div>
        <div className={styles.headerRow2}>
          {this.renderActorSelect()}
          {this.renderSchoolSelect()}
          <StyledDatePicker
            variant="to"
            className={styles.toDatePicker}
            value={this.state.toDate}
            maxDate={this.state.maxDate}
            onChange={this.onChangeToDate}
          />
          <StyledDatePicker
            variant="from"
            className={styles.fromDatePicker}
            value={this.state.fromDate}
            maxDate={this.state.maxDate}
            onChange={this.onChangeFromDate}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.props.selectedActor === SEARCH_ACTOR_ADMIN && (
          <AdminHeader titles={HEADER_TITLES_ADMIN} />
        )}
        {this.props.selectedActor === SEARCH_ACTOR_SCHOOL && (
          <AdminHeader titles={HEADER_TITLES_SCHOOL} />
        )}
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            const r = isRequesting(this.props.searchAuditTrailsRequest);
            const auditTrails = this.getAuditTrails();
            return (
              <div style={{ height: h + "px", overflow: "scroll" }}>
                {r && <LoadingIndicator />}
                {!r &&
                  auditTrails.map(auditTrail => {
                    return (
                      <AuditTrailItem
                        auditTrail={auditTrail}
                        key={auditTrail.id}
                      />
                    );
                  })}
                {!r &&
                  auditTrails.length === 0 && (
                    <p className={styles.noRelevantRecordsText}>
                      <Text translationKey="admin.no_relevant_records" />
                    </p>
                  )}
              </div>
            );
          }}
        </WindowSize>
        <Pagination
          currentPage={this.props.pageNumber}
          totalPage={this.state.pageInfo.totalPage}
          onPageChange={this.onPageChange}
        />
        <ErrorModal
          error={extractError(this.props.fetchSelectOptionsRequest)}
        />
        <ErrorModal error={extractError(this.props.searchAuditTrailsRequest)} />
      </Fragment>
    );
  }

  _findSchoolOption(id: string): ?SchoolOption {
    const { schoolOptions } = this.props;
    if (schoolOptions == null) {
      return null;
    }
    return schoolOptions.find(a => a.id === id);
  }

  getAuditTrails(): $ReadOnlyArray<AuditTrail> {
    const output = [];
    for (const id of this.state.auditTrailIds) {
      const s = this.props.auditTrailById[id];
      if (s != null) {
        output.push(s);
      }
    }
    return output;
  }

  searchAuditTrails = () => {
    this.props
      .searchAuditTrails({
        pageSize: ADMIN_DASHBOARD_PAGE_SIZE,
        pageNumber: this.props.pageNumber,
        actor: this.props.selectedActor,
        schoolId:
          this.props.selectedSchoolId === ""
            ? null
            : this.props.selectedSchoolId,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
      .then(result => {
        this.setState({
          auditTrailIds: result.auditTrails.map(s => s.id),
          pageInfo: result.pageInfo,
        });
      });
  };
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const { search } = ownProps.location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  let selectedActor = SEARCH_ACTOR_SCHOOL;
  if (parsed.selectedActor === "school" || parsed.selectedActor === "admin") {
    selectedActor = parsed.selectedActor;
  }
  return {
    auditTrailById: state.auditTrails.auditTrailById,
    schoolOptions: state.sessions.schoolOptions,
    fetchSelectOptionsRequest: state.sessions.fetchSelectOptionsRequest,
    searchAuditTrailsRequest: state.auditTrails.searchAuditTrailsRequest,
    pageNumber: parsed.pageNumber != null ? parseInt(parsed.pageNumber, 10) : 1,
    fromDateString: parsed.fromDate || "",
    toDateString: parsed.toDate || "",
    selectedSchoolId: parsed.schoolId || "",
    selectedActor,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchSelectOptions: bindActionCreators(fetchSelectOptions, dispatch),
    searchAuditTrails: bindActionCreators(searchAuditTrails, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AuditTrailManagement
);
