// @flow
import React, { PureComponent } from "react";
import styles from "./ProgressBar.module.scss";
import cssVariables from "../../variables.module.scss";

type Props = {
  +completedPercentage: number,
  +className: string,
  +color: string,
};

class ProgressBar extends PureComponent<Props> {
  static defaultProps = {
    color: cssVariables.orange,
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progress}
            style={{
              width: `${this.props.completedPercentage * 100}%`,
              backgroundColor: this.props.color,
            }}
          />
        </div>
      </div>
    );
  }
}

export default ProgressBar;
