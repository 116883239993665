// @flow
import skygear from "skygear";
import URL from "url-parse";

export function postJSON(path: string, body: mixed): Promise<Response> {
  const apiKey = ((skygear.apiKey: any): string);
  const endPoint = skygear.endPoint;
  const accessToken = skygear.auth.accessToken;
  const url = new URL(path, endPoint);
  const headers: { [key: string]: string } = {
    "content-Type": "application/json",
    "x-skygear-api-key": apiKey,
  };
  if (accessToken != null) {
    headers["x-skygear-access-token"] = accessToken;
  }
  return fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify(body),
  });
}
