//@flow
import { TEMPLATE_TYPE_IMPORTED } from "../types/template";
import type { SchoolResponse, School } from "../types/school";
import type { Template, TemplateResponse } from "../types/template";
import type { ActivityResponse, Activity } from "../types/activity";
import type { PageInfo, PageInfoResponse } from "../types/request";
import type {
  ActivityState,
  ActivityStateResponse,
  Answer,
  AnswerResponse,
} from "../types/activity";
import type {
  Session,
  SessionResponse,
  Participant,
  ParticipantResponse,
  SchoolOptionResponse,
  SchoolOption,
  UnitTemplateOptionResponse,
  UnitTemplateOption,
  ActivityRecordOptionResponse,
  ActivityRecordOption,
} from "../types/session";
import type { UserResponse, User } from "../types/user";
import type { AuthUserResponse, AuthUser } from "../types/auth";
import type { AuditTrailResponse, AuditTrail } from "../types/auditTrail";
import type { SysConfResponse, SysConf } from "../types/sysConf";

export function deserializePageInfo(r: PageInfoResponse): PageInfo {
  return {
    totalCount: r.total_count,
    totalPage: r.total_page,
  };
}

export function deserializeActivityRecordOption(
  r: ActivityRecordOptionResponse
): ActivityRecordOption {
  return {
    id: r._id,
    teacherName: r.teacher_name,
    createdAt: new Date(r.created_at),
  };
}

export function deserializeSchoolOption(r: SchoolOptionResponse): SchoolOption {
  return {
    id: r._id,
    engName: r.eng_name,
  };
}

export function deserializeUnitTemplateOption(
  r: UnitTemplateOptionResponse
): UnitTemplateOption {
  return {
    id: r._id,
    displayCode: r.display_code,
    name: r.name,
  };
}

export function parseOptionalDate(s: ?string): ?Date {
  if (s === null || s === undefined) {
    return null;
  }
  return new Date(s);
}

export function activityDeserializer(activity: ActivityResponse): Activity {
  const deserializedActivity: Activity = {
    type: activity.type,
    name: activity.name,
    question: activity.question,
    imageId: activity.image_id,
    imageUrl: activity.image_url,
    whiteboardBackground: activity.whiteboard_background,
    whiteboardOrientation: activity.whiteboard_orientation,
    whiteboardImageId: activity.whiteboard_image_id,
    whiteboardImageUrl: activity.whiteboard_image_url,
    whiteboardStickers: [],
    pollingNamed: activity.polling_named,
    pollingMultipleChoice: activity.polling_multiple_choice,
    pollingOptions: undefined,
  };

  switch (activity.type) {
    case "polling":
      return {
        ...deserializedActivity,
        pollingOptions: activity.polling_options.map(o => ({
          text: o.text,
          imageId: o.image_id,
          imageUrl: o.image_url,
        })),
      };
    case "whiteboard":
      const whiteboardStickers = activity.whiteboard_stickers.map(s => ({
        imageId: s.image_id,
        imageUrl: s.image_url,
      }));
      return {
        ...deserializedActivity,
        whiteboardStickers,
      };
    default:
      return deserializedActivity;
  }
}

export function templateDeserializer(template: TemplateResponse): Template {
  return {
    id: template._id,
    baseId: template.base_id,
    displayCode: template.display_code,
    lang: template.lang,
    type: template.type,
    year: template.year,
    name: template.name,
    activities: template.activities.map(a => activityDeserializer(a)),
    createdAt: new Date(template.created_at),
    createdBy: template.created_by,
    nextSchoolLocalCode: template.next_school_local_code,
    customTemplateCount: template.custom_template_count,
    canCreateCustomTemplate: template.can_create_custom_template,
    customTemplateMaxNumber: template.custom_template_max_number,
    isImported: template.type === TEMPLATE_TYPE_IMPORTED,
    activityRecordsCount: template.activity_records_count,
  };
}

export function deserializeParticipant(p: ParticipantResponse): Participant {
  return {
    id: p._id,
    name: p.name,
  };
}

export function deserializeAnswer(a: AnswerResponse): Answer {
  return {
    qaText: a.qa_text,
    whiteboardImageId: a.whiteboard_image_id,
    whiteboardImageUrl: a.whiteboard_image_url,
    pollingOptionIndice: a.polling_option_indice,
  };
}

export function deserializeActivityState(
  a: ActivityStateResponse
): ActivityState {
  const answerByParticipantId = {};
  for (const k of Object.keys(a.answer_by_participant_id)) {
    const v = a.answer_by_participant_id[k];
    answerByParticipantId[k] = deserializeAnswer(v);
  }
  return {
    state: a.state,
    resetAt: parseOptionalDate(a.reset_at),
    answerByParticipantId,
  };
}

export function deserializeSession(s: SessionResponse): Session {
  return {
    id: s._id,
    code: s.code,
    templateId: s.template_id,
    templateBaseId: s.template_base_id,
    templateDisplayCode: s.template_display_code,
    templateYear: s.template_year,
    templateName: s.template_name,
    templateLang: s.template_lang,
    teacherName: s.teacher_name,
    schoolID: s.school_id,
    schoolEngName: s.school_eng_name,
    activities: s.activities.map(a => activityDeserializer(a)),
    defaultStickerList: s.default_sticker_list.map(s => ({
      imageId: s.image_id,
      imageUrl: s.image_url,
    })),
    state: s.state,
    isPaused: s.is_paused,
    mode: s.mode,
    createdAt: new Date(s.created_at),
    startedAt: parseOptionalDate(s.started_at),
    pausedAt: parseOptionalDate(s.paused_at),
    endedAt: parseOptionalDate(s.ended_at),
    participants: s.participants.map(deserializeParticipant),
    currentActivityIndex: s.current_activity_index,
    activityStates: s.activity_states.map(deserializeActivityState),
  };
}

export function deserializeSchool(s: SchoolResponse): School {
  return {
    id: s._id,
    name: s.name,
    engName: s.eng_name,
    email: s.email,
    createdAt: new Date(s.created_at),
    disabled: s.disabled,
  };
}

export function deserializeUser(s: UserResponse): User {
  return {
    id: s._id,
    email: s.email,
    name: s.name,
    userType: s.user_type,
    disabled: s.disabled,
  };
}

export function deserializeAuditTrail(a: AuditTrailResponse): AuditTrail {
  return {
    id: a._id,
    createdAt: new Date(a.created_at),
    category: a.category,
    actor: a.actor,
    action: a.action,
    content: a.content,
  };
}

export function deserializeSysConf(s: SysConfResponse): SysConf {
  return {
    id: s._id,
    content: s.content,
  };
}

export function deserializeAuthUser(s: ?AuthUserResponse): ?AuthUser {
  if (s == null) {
    return null;
  }
  return {
    id: s._id,
    email: s.email,
    userType: s.user_type,
  };
}
