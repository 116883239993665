// @flow

export opaque type OngoingRequestError = {};

export const ongoingRequestError: OngoingRequestError = {};

export const ErrNetworkDown: -1 = -1;
export const ErrUserDuplicated: 109 = 109; // skygear.ErrorCodes.Duplicated
export const ErrUserNotFound: 110 = 110; // skygear.ErrorCodes.ResourceNotFound
export const ErrIncorrectCredentials: 105 = 105; // skygear.ErrorCodes.InvalidCredentials
export const ErrUserDisabled: 127 = 127; // skygear.ErrorCodes.UserDisabled
export const ErrCaptchaInvalid: 128 = 128;
export const ErrCustomTemplate: 20001 = 20001;
export const ErrTeachingSessionError: 20002 = 20002;
export const ErrIllegalSessionStateError: 20003 = 20003;
export const ErrSessionNotFoundError: 20004 = 20004;
export const ErrNamelessParticipantError: 20005 = 20005;
export const ErrNotAParticipantError: 20006 = 20006;
export const ErrIllegalAnswerError: 20007 = 20007;
export const ErrAnswerAlreadySubmittedError: 20008 = 20008;
export const ErrPermissionDeniedError: 20009 = 20009;
export const ErrSchoolAccountError: 20010 = 20010;
export const ErrAdminUserError: 20011 = 20011;
export const ErrEmailInUseError: 20012 = 20012;
export const ErrExportError: 20013 = 20013;
export const ErrAuditTrailError: 20014 = 20014;
export const ErrSysConfError: 20015 = 20015;
export const ErrDeleteTemplateError: 20016 = 20016;

export type ErrorCode =
  | typeof ErrNetworkDown
  | typeof ErrUserNotFound
  | typeof ErrUserDuplicated
  | typeof ErrIncorrectCredentials
  | typeof ErrUserDisabled
  | typeof ErrCaptchaInvalid
  | typeof ErrCustomTemplate
  | typeof ErrTeachingSessionError
  | typeof ErrIllegalSessionStateError
  | typeof ErrSessionNotFoundError
  | typeof ErrNamelessParticipantError
  | typeof ErrNotAParticipantError
  | typeof ErrIllegalAnswerError
  | typeof ErrAnswerAlreadySubmittedError
  | typeof ErrPermissionDeniedError
  | typeof ErrSchoolAccountError
  | typeof ErrAdminUserError
  | typeof ErrEmailInUseError
  | typeof ErrExportError
  | typeof ErrAuditTrailError
  | typeof ErrSysConfError
  | typeof ErrDeleteTemplateError;
