// @flow
import React, { PureComponent, Fragment } from "react";
import ActivityRecordPanelRenderer from "./ActivityRecordPanelRenderer";
import type { RecordProps } from "./ActivityRecordType";
import { toParticipantAnswerArray } from "../TeacherSessionActivityResult/utils";
import Text from "../Text/Text";
import Button from "../Button/Button";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import styles from "./ActivityRecordQAPanel.module.scss";

type LocalState = {
  isDeleteAnswerModalShown: boolean,
  answerItemModalInitialIndex: number,
};

class ActivityRecordQAPanel extends PureComponent<RecordProps, LocalState> {
  state = {
    isDeleteAnswerModalShown: false,
    answerItemModalInitialIndex: 0,
  };

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDeleteAnswerModalShown: false,
    });
  };

  onDeleteAnswer = (e: Event, idx: mixed) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof idx === "number") {
      this.setState({
        isDeleteAnswerModalShown: true,
        answerItemModalInitialIndex: idx,
      });
    }
  };

  onConfirmDelete = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDeleteAnswerModalShown: false,
    });
    const { onDeleteAnswer } = this.props;
    if (onDeleteAnswer != null) {
      const { session, activityState } = this.props;
      const { answerItemModalInitialIndex } = this.state;
      const participantAnswers = toParticipantAnswerArray(
        session.participants,
        activityState
      );
      const participantAnswer = participantAnswers[answerItemModalInitialIndex];
      onDeleteAnswer(participantAnswer.participant.id);
    }
  };

  renderDeleteAnswerModal() {
    if (!this.state.isDeleteAnswerModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.sure_to_delete_answer"
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmDelete}
          onClose={this.onModalClose}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderExtra() {
    const { session, activityState } = this.props;
    const answers = toParticipantAnswerArray(
      session.participants,
      activityState
    );
    return (
      <Fragment>
        <p className={styles.titleText}>
          <Text
            translationKey="teacher.student_answers"
            values={{
              numberOfAnswers: toParticipantAnswerArray(
                session.participants,
                activityState
              ).length,
            }}
          />
        </p>
        {answers.map((ans, index) => (
          <div className={styles.answerContainer} key={ans.participant.id}>
            <p>{ans.answer.qaText}</p>

            {this.props.onDeleteAnswer && (
              <div className={styles.deleteButtonContainer}>
                <Button
                  className={styles.qaAnswerItemDeleteButton}
                  color="gray3"
                  onClickInfo={index}
                  onClick={this.onDeleteAnswer}
                />
              </div>
            )}
          </div>
        ))}
      </Fragment>
    );
  }

  render() {
    return (
      <ActivityRecordPanelRenderer {...this.props}>
        {this.renderExtra()}
        {this.renderDeleteAnswerModal()}
      </ActivityRecordPanelRenderer>
    );
  }
}

export default ActivityRecordQAPanel;
