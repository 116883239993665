// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import ModalContainer from "./ModalContainer";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { deepClone } from "../../utils/deepClone";
import closeIcon from "../../images/modal_close.svg";
import {
  validateSchoolAccount,
  ErrorEmptyChineseSchoolName,
  ErrorEmptyEnglishSchoolName,
  ErrorEmptyEmail,
  ErrorIncorrectEmail,
  ErrorEmptyPassword,
  ErrorPasswordViolatesRules,
} from "../../utils/validate";
import type { School } from "../../types/school";
import type { ValidateSchoolAccountError } from "../../utils/validate";
import styles from "./AdminModal.module.scss";

type Props = {
  type: "add" | "edit",
  school?: School,
  onSave: (school: School, password: string) => void,
  onClose: Event => void,
};

type LocalState = {
  editedSchool: School,
  password: string,
  showPassword: boolean,
  errorLabels: $ReadOnlyArray<ValidateSchoolAccountError>,
};

export default class SchoolAccountModal extends PureComponent<
  Props,
  LocalState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editedSchool:
        props.school != null
          ? deepClone(props.school)
          : {
              id: "",
              name: "",
              engName: "",
              email: "",
              createdAt: new Date(),
              disabled: false,
            },
      password: "",
      showPassword: false,
      errorLabels: [],
    };
  }

  onSave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { editedSchool, password } = this.state;
    const errorLabels = validateSchoolAccount({
      school: editedSchool,
      password,
      allowEmptyPassword: this.isEditModal(),
    });

    if (errorLabels.length === 0) {
      this.props.onSave(editedSchool, password);
    } else {
      this.setState({ errorLabels: errorLabels });
    }
  };

  onChange = (e: Event, attr: mixed) => {
    const { target } = e;
    if (target instanceof HTMLInputElement && typeof attr === "string") {
      this.setState({
        editedSchool: {
          ...this.state.editedSchool,
          [attr]: target.value,
        },
      });
    }
  };

  onChangePassword = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({ password: target.value });
    }
  };

  onChangeShowPassword = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({ showPassword: target.checked });
    }
  };

  onClickModal = (e: Event) => {
    e.stopPropagation();
  };

  isAddModal() {
    return this.props.type === "add";
  }

  isEditModal() {
    return this.props.type === "edit";
  }

  chineseNameErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorEmptyChineseSchoolName)
      ? "error.admin.school_account.modal.school_name_empty"
      : null;
  }

  englishNameErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorEmptyEnglishSchoolName)
      ? "error.admin.school_account.modal.school_name_empty"
      : null;
  }

  emailErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorEmptyEmail)
      ? "error.empty_email"
      : errorLabels.includes(ErrorIncorrectEmail)
        ? "error.incorrect_email"
        : null;
  }

  passwordErrorTextKey() {
    const { errorLabels } = this.state;

    return errorLabels.includes(ErrorPasswordViolatesRules)
      ? "error.admin.modal.password_rules_violation"
      : errorLabels.includes(ErrorEmptyPassword)
        ? "error.empty_password"
        : null;
  }

  render() {
    const { onClose } = this.props;
    const { editedSchool, password, showPassword } = this.state;

    return (
      <ModalContainer onClose={onClose}>
        <form
          className={classnames(
            styles.modalContainer,
            { [styles.addContainer]: this.isAddModal() },
            { [styles.editContainer]: this.isEditModal() }
          )}
          onClick={this.onClickModal}
          onSubmit={this.onSave}
        >
          <p className={styles.title}>
            <Text
              translationKey={
                this.isAddModal()
                  ? "admin.school_account.modal.add_school_account"
                  : "admin.school_account.modal.edit_school_account"
              }
            />
          </p>
          <p className={styles.labelRow}>
            <Text translationKey="admin.school_account.modal.chinese_school_name" />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.school_account.modal.input_chinese_school_name"
              labelId="admin.school_account.modal.input_chinese_school_name"
              type="text"
              value={editedSchool.name}
              className={styles.input}
              onChange={this.onChange}
              onChangeInfo="name"
              errorTextKey={this.chineseNameErrorTextKey()}
            />
          </div>
          <p className={styles.labelRow}>
            <Text translationKey="admin.school_account.modal.english_school_name" />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.school_account.modal.input_english_school_name"
              labelId="admin.school_account.modal.input_english_school_name"
              type="text"
              value={editedSchool.engName}
              className={styles.input}
              onChange={this.onChange}
              onChangeInfo="engName"
              errorTextKey={this.englishNameErrorTextKey()}
            />
          </div>
          <p className={styles.labelRow}>
            <Text translationKey="admin.modal.email" />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.modal.input_email"
              labelId="admin.modal.input_email"
              type="email"
              value={editedSchool.email}
              className={styles.input}
              onChange={this.onChange}
              onChangeInfo="email"
              errorTextKey={this.emailErrorTextKey()}
            />
          </div>
          <p className={styles.labelRow}>
            <Text
              translationKey={
                this.isAddModal()
                  ? "admin.modal.create_password"
                  : "admin.modal.reset_password"
              }
            />
          </p>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="admin.modal.password_rules"
              labelId="admin.modal.password_rules"
              type={showPassword ? "text" : "password"}
              value={password}
              className={styles.input}
              onChange={this.onChangePassword}
              errorTextKey={this.passwordErrorTextKey()}
            />
          </div>
          <div className={styles.showPasswordRow}>
            <input
              name="showPassword"
              type="checkbox"
              checked={showPassword}
              onChange={this.onChangeShowPassword}
            />
            <Text translationKey="admin.modal.show_password" />
          </div>
          <div className={styles.buttonRow}>
            <Button
              color={this.isAddModal() ? "blue" : "green"}
              type="submit"
              className={styles.saveButton}
              onClick={this.onSave}
            >
              <Text
                translationKey={
                  this.isAddModal()
                    ? "admin.modal.add_account"
                    : "admin.modal.save_account"
                }
              />
            </Button>
          </div>
          <button className={styles.closeButton} onClick={this.props.onClose}>
            <img className={styles.closeIcon} src={closeIcon} alt="close" />
          </button>
        </form>
      </ModalContainer>
    );
  }
}
