// @flow
import React, { PureComponent } from "react";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Text from "../Text/Text";
import checkIcon from "../../images/edit_template_check.svg";
import styles from "./EditTemplateHeader.module.scss";

type Props = {
  templateDisplayCode: string,
  templateName: string,
  onSave: () => void,
  onCancel: () => void,
  onTemplateNameChange: string => void,
  templateNameEmptyError: boolean,
};

class EditTemplateHeader extends PureComponent<Props> {
  onSave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onSave();
  };

  onCancel = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onCancel();
  };

  onTemplateNameChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.props.onTemplateNameChange(target.value);
    }
  };

  renderNameInput() {
    const {
      templateDisplayCode,
      templateName,
      templateNameEmptyError,
    } = this.props;

    return (
      <div className={styles.nameInputContainer}>
        <span className={styles.nameInputText}>{templateDisplayCode}</span>
        <div className={styles.inputContainer}>
          <InputWithErrorMessage
            placeholderId="teacher.create_custom_template_modal_input_placeholder"
            labelId="teacher.create_custom_template_modal_input_placeholder"
            value={templateName}
            type="text"
            onChange={this.onTemplateNameChange}
            errorTextKey={
              templateNameEmptyError ? "error.template_name_empty" : null
            }
          />
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className={styles.buttonsContainer}>
        <IconButton
          className={styles.finishEditTemplateButton}
          icon={checkIcon}
          labelTranslationKey="teacher.finish_edit"
          onClick={this.onSave}
        />
        <Button
          color="gray"
          colorApplication="border"
          className={styles.cancelButton}
          onClick={this.onCancel}
        >
          <Text translationKey="phrase.cancel" />
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.headerContainer}>
        {this.renderNameInput()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default EditTemplateHeader;
