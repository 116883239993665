// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import fabric from "fabric";
import stableSort from "stable";
import type { WhiteboardOrientation } from "../../types/activity";
import type { DrawingState, Shape } from "./DrawingState";
import {
  getPencilColor,
  getPencilWidth,
  getStrokeColor,
  getFillColor,
  ERASER_BRUSH_COLOR,
} from "./DrawingState";
import { downloadDataURL } from "../../utils/downloadImage";
import isImageURLEqual from "../../utils/isImageURLEqual";
import ResponsiveLayout from "../ResponsiveLayout";
import WindowSize from "../WindowSize";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import AutoExpandTextArea from "../TextArea/AutoExpandTextArea";
import Div from "../Div";
import styles from "./ResponsiveCanvas.module.scss";
import cssVariables from "../../variables.module.scss";

type FabricObjectTag = "pencil" | "eraser";

type Props = {
  lastTransitionEndMilli: ?number,
  drawingState: DrawingState,
  // eslint-disable-next-line flowtype/no-weak-types
  onSelectObject: (obj: Object) => void,
  onDeselectObject: () => void,
  onSelectedTextObjectContentChange: (text: string) => void,
  onBackgroundImageStartLoading: () => void,
  onBackgroundImageEndLoading: () => void,
  onCanvasChange: () => void,
};

type LocalState = {
  backgroundImage: any,
  canvasWidth: number,
  canvasHeight: number,
  canvasBackgroundWidth: number,
  canvasBackgroundHeight: number,
};

const DEFAULT_CANVAS_WIDTH = 1600;
const DEFAULT_CANVAS_HEIGHT = 1200;
// In mobile landscape, we reserve some padding
// so that scrolling is possible
const SCROLL_PADDING = 44;
// We need to use this because the drawing library we are going to
// use is not react library and it will modify the DOM directly.
// So it is safer to create a DOM element for that library.
const canvasId = "fabricCanvas";
const dangerousHTML = {
  __html: `<canvas id="${canvasId}" width="${DEFAULT_CANVAS_WIDTH}" height="${DEFAULT_CANVAS_HEIGHT}" />`,
};

function compareFabricObjectTag(
  a: ?FabricObjectTag,
  b: ?FabricObjectTag
): number {
  // object with a lower index is drawn first
  // We want to achieve this drawing order
  // pencil | eraser, anything else
  if (a == null) {
    if (b == null) {
      // (anything else, anything else)
      return 0;
    }
    // (anything else, pencil | eraser)
    return 1;
  } else {
    if (b == null) {
      // (pencil | eraser, anything else)
      return -1;
    }
    // (pencil | eraser, pencil | eraser)
    // We do not need to sort them
    // because if we do sort them
    // then newly drawn pencil will be erased by
    // previously drawn eraser
    return 0;
  }
}

function compareFabricObject(a: any, b: any): number {
  return compareFabricObjectTag(a.__polyupaths__tag, b.__polyupaths__tag);
}

function sortFabricObjects(arr: any): any {
  // Array.prototype.sort is not stable
  // But we need the sort to be stable
  // We rely on the stable property so that
  // newly drawn pencil is always sorted after previously drawn eraser
  return stableSort(arr, compareFabricObject);
}

// Due to the design of fabric, we need to apply our patch
// so that interactive scaling of fabric objects does not
// scale their strokeWidth.
//
// See https://github.com/kangax/fabric.js/issues/66
//
// The hack we are using here is fully inspired here
// https://stackoverflow.com/questions/39548747/fabricjs-how-to-scale-object-but-keep-the-border-stroke-width-fixed/48343346#48343346
//
// Basically we patch two methods. First we patch _renderStroke
// so that it does not take the scale of the object into account
// when drawing the stroke.
//
// Then we patch _getTransformedDimensions so that the bounding
// rect matches what is rendered.
//
// There is one caveat though. The stroke is not rendered correctly
// when the object is being scaled interactively.
function patchedGetTransformedDimensions(
  skewX: ?number,
  skewY: ?number
): { x: number, y: number } {
  // The original implementation supports skew
  // https://github.com/kangax/fabric.js/blob/v2.2.0/src/mixins/object_geometry.mixin.js#L553
  // But we do not use skew, so ignore it.
  const { strokeWidth, width, height, scaleX, scaleY } = this;
  return {
    x: width * scaleX + strokeWidth,
    y: height * scaleY + strokeWidth,
  };
}

function patchedRenderStroke(ctx: any) {
  // Literally copied from here
  // https://github.com/kangax/fabric.js/blob/v2.2.0/src/shapes/object.class.js#L1310
  if (!this.stroke || this.strokeWidth === 0) {
    return;
  }

  if (this.shadow && !this.shadow.affectStroke) {
    this._removeShadow(ctx);
  }

  ctx.save();
  // This following line does the trick
  ctx.scale(1 / this.scaleX, 1 / this.scaleY);
  // The above line does the trick
  this._setLineDash(ctx, this.strokeDashArray, this._renderDashedStroke);
  this._applyPatternGradientTransform(ctx, this.stroke);
  ctx.stroke();
  ctx.restore();
}

function patchShapeObject(obj: any) {
  obj._getTransformedDimensions = patchedGetTransformedDimensions;
  obj._renderStroke = patchedRenderStroke;
}

function getControlProps(
  canvasWidth: number,
  canvasHeight: number
): {
  cornerSize: number,
  cornerColor: string,
  cornerStrokeColor: string,
  borderColor: string,
  rotatingPointOffset: number,
  transparentCorners: boolean,
  cornerStyle: string,
  borderScaleFactor: number,
} {
  const smaller = Math.min(canvasWidth, canvasHeight);
  return {
    cornerSize: smaller / 20,
    rotatingPointOffset: smaller / 8,
    cornerColor: "white",
    cornerStrokeColor: "black",
    borderColor: "black",
    transparentCorners: false,
    cornerStyle: "circle",
    borderScaleFactor: 4,
  };
}

function getInitialCircleProps(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): {|
  radius: number,
|} {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  return {
    radius: smaller / 6,
  };
}

function getInitialRectProps(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): {|
  width: number,
  height: number,
|} {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  return {
    width: smaller / 3,
    height: smaller / 3,
  };
}

function getInitialTriangleProps(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): {|
  width: number,
  height: number,
|} {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  // Equilateral triangle
  const width = smaller / 3;
  const height = width / 2 * Math.tan(Math.PI / 3);
  return {
    width,
    height,
  };
}

function calcalutePentagonWidth(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): number {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  const width = smaller / 3;
  return width;
}

function calcalutePentagonSide(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): number {
  const { canvasWidth, canvasHeight } = data;
  const width = calcalutePentagonWidth({
    canvasWidth,
    canvasHeight,
  });
  // Cosine law
  // width**2 = side**2 + side**2 - 2(side)(side)cos(108deg)
  // After simplification and rearrangement, we have
  const side = Math.sqrt(
    width * width / (2 * (1 - Math.cos(108 * Math.PI / 180)))
  );
  return side;
}

function getInitialPentagonPoints(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): $ReadOnlyArray<{
  x: number,
  y: number,
}> {
  const { canvasWidth, canvasHeight } = data;
  const width = calcalutePentagonWidth({
    canvasWidth,
    canvasHeight,
  });
  const side = calcalutePentagonSide({
    canvasWidth,
    canvasHeight,
  });
  const theta = 108;
  // Let's name the points we are going to return as A, B, C, D and E.
  //      A
  //  E       B
  //
  //   D     C
  // Trivially, A is (width / 2, 0)
  const A = { x: width / 2, y: 0 };
  // B can be derived from A
  const B = {
    x: A.x + side * Math.cos((90 - theta / 2) * Math.PI / 180),
    y: A.y + side * Math.sin((90 - theta / 2) * Math.PI / 180),
  };
  // C can be derived from B
  const C = {
    x: B.x - side * Math.cos(72 * Math.PI / 180),
    y: B.y + side * Math.sin(72 * Math.PI / 180),
  };
  // D can be derived from C
  const D = {
    x: C.x - side,
    y: C.y,
  };
  // E can be derived from A, effectively it is a mirror of B
  const E = {
    x: A.x - side * Math.cos((90 - theta / 2) * Math.PI / 180),
    y: A.y + side * Math.sin((90 - theta / 2) * Math.PI / 180),
  };
  return [A, B, C, D, E];
}

function getInitialStarPoints(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): $ReadOnlyArray<{
  x: number,
  y: number,
}> {
  // The star polygon we are going to draw looks like this
  // https://en.wikipedia.org/wiki/File:Wide_pentagram.png
  //
  // This star polygon has two parametric angle
  // with alpha = 72deg and beta = 144deg
  // https://en.wikipedia.org/wiki/Star_polygon#Simple_isotoxal_star_polygons
  //
  // If we connect the vertice forming acute angles with straight lines,
  // then we get a pentagon
  //
  // Therefore we can calculate the side of the star polygon by
  // cosine law
  const { canvasWidth, canvasHeight } = data;
  const width = calcalutePentagonWidth({
    canvasWidth,
    canvasHeight,
  });
  const pentagonSide = calcalutePentagonSide({
    canvasWidth,
    canvasHeight,
  });
  const alpha = 72;
  const beta = 144;
  const theta = 108;
  // Cosine law
  // pentagonSide**2 = side**2 + side**2 - 2(side)(side)cos(144deg)
  // After simplification and rearrangement, we have
  const side = Math.sqrt(
    pentagonSide * pentagonSide / (2 * (1 - Math.cos(beta * Math.PI / 180)))
  );
  // Let's name the points we are going to return as A, B, C, D, E
  // F, G, H, I and J
  //            A
  //
  //
  //        J       B
  //
  // I                     C
  //
  //
  //     H             D
  //
  //
  //            F
  //     G             E
  //
  // Trivially, A is (width / 2, 0)
  const A = { x: width / 2, y: 0 };
  // First, derive the points forming the imaginary pentagon
  const C = {
    x: A.x + pentagonSide * Math.cos((90 - theta / 2) * Math.PI / 180),
    y: A.y + pentagonSide * Math.sin((90 - theta / 2) * Math.PI / 180),
  };
  const E = {
    x: C.x - pentagonSide * Math.cos(72 * Math.PI / 180),
    y: C.y + pentagonSide * Math.sin(72 * Math.PI / 180),
  };
  const G = {
    x: E.x - pentagonSide,
    y: E.y,
  };
  const I = {
    x: A.x - pentagonSide * Math.cos((90 - theta / 2) * Math.PI / 180),
    y: A.y + pentagonSide * Math.sin((90 - theta / 2) * Math.PI / 180),
  };

  // Derive the maining points
  // B is trivial
  const B = {
    x: A.x + side * Math.cos((90 - alpha / 2) * Math.PI / 180),
    y: A.y + side * Math.sin((90 - alpha / 2) * Math.PI / 180),
  };
  // J is a mirror of B
  const J = {
    x: A.x - side * Math.cos((90 - alpha / 2) * Math.PI / 180),
    y: A.y + side * Math.sin((90 - alpha / 2) * Math.PI / 180),
  };
  // D and H are vertically above E and G respectively
  const D = {
    x: E.x,
    y: E.y - side,
  };
  const H = {
    x: G.x,
    y: G.y - side,
  };
  // Finally, to calculate F, we need to know the offset
  const F = {
    x: A.x,
    y: E.y - side * Math.cos(beta / 2 * Math.PI / 180),
  };
  return [A, B, C, D, E, F, G, H, I, J];
}

function getInitialHeartPath(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): string {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  const width = smaller / 3;
  // The heart we are going to draw looks like the first heart on this webpage
  // http://www.mathematische-basteleien.de/heart.htm
  // This is a hand-drawn diagram
  // https://imgur.com/l7v3cHw
  // We have
  // a = sqrt(r**2 + r**2) ---- (1)
  // w = a + 2r --------------- (2)
  // (a/2)**2 + b**2 = r**2 --- (3)
  //
  // By (1) and (2), we can resolve r and a
  const r = width / (2 + Math.sqrt(2));
  // const a = Math.sqrt(2) * r;
  // Then we can solve b
  const b = r / Math.sqrt(2);

  // The start point
  const x0 = width / 2;
  const y0 = r - b;

  // The diagonal of the square
  const diagonal = Math.sqrt(4 * r * r + 4 * r * r);

  // The bottom point
  const x2 = width / 2;
  const y2 = y0 + diagonal;

  // The point where the sqaure and the circle meets on the left
  const x1 = x2 - 2 * r * Math.cos(45 * Math.PI / 180);
  const y1 = y2 - 2 * r * Math.sin(45 * Math.PI / 180);

  // The point where the square and the circle meets on the right
  const x3 = x2 + 2 * r * Math.cos(45 * Math.PI / 180);
  const y3 = y2 - 2 * r * Math.sin(45 * Math.PI / 180);

  // Now we derive the path
  const path = `
    M ${x0} ${y0}
    A ${r} ${r} 0 1 0 ${x1} ${y1}
    L ${x2} ${y2}
    L ${x3} ${y3}
    A ${r} ${r} 0 1 0 ${x0} ${y0}
    Z
  `;
  return path;
}

function getInitialColorProps(data: {|
  strokeColor: string,
  fillColor: string,
|}): {|
  fill: string,
  stroke: string,
|} {
  const { strokeColor, fillColor } = data;
  return {
    fill: fillColor,
    stroke: strokeColor,
  };
}

function getInitialPositionProps(): {
  left: number,
  top: number,
} {
  return {
    left: 0,
    top: 0,
  };
}

function getInitialStrokeWidth(data: {|
  canvasWidth: number,
  canvasHeight: number,
|}): {|
  strokeWidth: number,
|} {
  const { canvasWidth, canvasHeight } = data;
  const smaller = Math.min(canvasWidth, canvasHeight);
  return {
    strokeWidth: smaller / 40,
  };
}

// This function implements CSS `background-size: contain`
// Basically it calculates the transform to be applied on child such that
// child is `contained` in parent
function getTransform(childWidth, childHeight, parentWidth, parentHeight) {
  if (parentHeight === 0) {
    return "";
  }

  const childAspectRatio = childWidth / childHeight;
  const parentAspectRatio = parentWidth / parentHeight;

  if (parentAspectRatio >= childAspectRatio) {
    // The parent is wider
    // Make child height equal to parent height
    const scaledHeight = parentHeight;
    const scaleY = parentHeight / childHeight;
    const scaledWidth = scaledHeight * childAspectRatio;
    const translateX = Math.abs(parentWidth - scaledWidth) / 2;
    return `translateX(${translateX}px) scale(${scaleY}, ${scaleY})`;
  }

  if (parentAspectRatio <= childAspectRatio) {
    // The parent is taller
    // Make child width equal to parent width
    const scaledWidth = parentWidth;
    const scaleX = parentWidth / childWidth;
    const scaledHeight = scaledWidth / childAspectRatio;
    const translateY = Math.abs(parentHeight - scaledHeight) / 2;
    return `translateY(${translateY}px) scale(${scaleX}, ${scaleX})`;
  }

  return "";
}

function getCanvasDimen(
  orientation: WhiteboardOrientation
): { canvasWidth: number, canvasHeight: number } {
  return {
    canvasWidth:
      orientation === "landscape"
        ? DEFAULT_CANVAS_WIDTH
        : DEFAULT_CANVAS_HEIGHT,
    canvasHeight:
      orientation === "landscape"
        ? DEFAULT_CANVAS_HEIGHT
        : DEFAULT_CANVAS_WIDTH,
  };
}

function isShape(obj: any): boolean {
  return (
    obj instanceof fabric.Circle ||
    obj instanceof fabric.Rect ||
    obj instanceof fabric.Triangle ||
    obj instanceof fabric.Polygon
  );
}

function isPath(obj: any): boolean {
  return obj instanceof fabric.Path;
}

function canApplyPencilColor(obj: any): boolean {
  return isPath(obj);
}

function canApplyStrokeColorAndFillColor(obj: any): boolean {
  return obj instanceof fabric.Text || isShape(obj);
}

function loadFabricImage(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      url,
      img => {
        if (img == null) {
          reject(null);
        } else {
          resolve(img);
        }
      },
      // If we do not load the image with CORS request,
      // the canvas will be tainted and cannot be exported.
      // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
      { crossOrigin: "Anonymous" }
    );
  });
}

function calculateFitScale(data: {
  w: number,
  h: number,
  W: number,
  H: number,
}): number {
  const { w, h, W, H } = data;
  const ar = w / h;
  const AR = W / H;
  if (AR >= ar) {
    return H / h;
  } else {
    return W / w;
  }
}

function scaleFabricImageForInsertion(data: {
  canvasWidth: number,
  canvasHeight: number,
  fabricImg: any,
}): void {
  const { canvasWidth, canvasHeight, fabricImg } = data;
  const maxWidth = canvasWidth / 2;
  const maxHeight = canvasHeight / 2;
  const { width, height } = fabricImg;

  if (width <= maxWidth && height <= maxHeight) {
    // No need to scale it if the intrinsic size of the image is small enough
    return;
  }

  const scale = calculateFitScale({
    w: width,
    h: height,
    W: maxWidth,
    H: maxHeight,
  });

  fabricImg.set({
    scaleX: scale,
    scaleY: scale,
  });
}

function addFabricObjectAndSelect(fabricCanvas: any, obj: any): void {
  fabricCanvas.add(obj);
  // NOTE(louis): If we do not call setActiveObject within
  // setTimeout, something weird will happen.
  setTimeout(() => {
    fabricCanvas.setActiveObject(obj);
    // If we do not call renderAll,
    // the object will be selected correctly.
    // However, the selection cursor is not drawn.
    // Calling renderAll seems fix this problem
    fabricCanvas.renderAll();
  });
}

export default class ResponsiveCanvas extends PureComponent<Props, LocalState> {
  fabricCanvas: any;
  div: ?HTMLElement;
  textArea: ?HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...getCanvasDimen(props.drawingState.orientation),
      backgroundImage: null,
      canvasBackgroundWidth: 0,
      canvasBackgroundHeight: 0,
    };
  }

  componentDidMount() {
    this._performEffectsWithDrawingState(null, this.props.drawingState);
    this._performEffectsWithLocalState(null, this.state);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.lastTransitionEndMilli !== nextProps.lastTransitionEndMilli
    ) {
      if (this.div != null) {
        this.setState({
          canvasBackgroundWidth: this.div.clientWidth,
          canvasBackgroundHeight: this.div.clientHeight,
        });
      }
    }

    if (this.props.drawingState !== nextProps.drawingState) {
      this._performEffectsWithDrawingState(
        this.props.drawingState,
        nextProps.drawingState
      );
    }
  }

  componentWillUpdate(nextProps: Props, nextState: LocalState) {
    this._performEffectsWithLocalState(this.state, nextState);
  }

  onCanvasContainerRef = (r: HTMLElement | null) => {
    if (r != null) {
      const canvasElement = document.getElementById(canvasId);
      if (canvasElement != null) {
        this.fabricCanvas = new fabric.Canvas(canvasElement, {
          // Disable group selection
          selection: false,
          // This must be set to false
          // Otherwise the canvas will be scaled out of our control
          enableRetinaScaling: false,
        });
        this.fabricCanvas.on("object:added", this.onObjectAdded);
        this.fabricCanvas.on("object:removed", this.onObjectRemoved);
        this.fabricCanvas.on("path:created", this.onPathCreated);
        this.fabricCanvas.on("selection:created", this.onSelectionCreated);
        this.fabricCanvas.on("selection:cleared", this.onSelectionCleared);
        this.fabricCanvas.on("selection:updated", this.onSelectionUpdated);
        this._performEffectsWithDrawingState(null, this.props.drawingState);
        this._performEffectsWithLocalState(null, this.state);
        // This is necessary because I do not know why
        // backgroundColor is not drawn until the first
        // interaction with the canvas.
        this.fabricCanvas.renderAll();
      }
    } else if (this.fabricCanvas != null) {
      this.fabricCanvas.off("object:added", this.onObjectAdded);
      this.fabricCanvas.off("object:removed", this.onObjectRemoved);
      this.fabricCanvas.off("path:created", this.onPathCreated);
      this.fabricCanvas.off("selection:created", this.onSelectionCreated);
      this.fabricCanvas.off("selection:cleared", this.onSelectionCleared);
      this.fabricCanvas.off("selection:updated", this.onSelectionUpdated);
      this.fabricCanvas = null;
    }
  };

  onDivRef = (r: HTMLElement | null) => {
    this.div = r;
  };

  onResize = (width: number, height: number) => {
    this.setState({
      canvasBackgroundWidth: width,
      canvasBackgroundHeight: height,
    });
  };

  onObjectAdded = (options: any) => {
    // this._sortFabricCanvasObjects();
    this.props.onCanvasChange();
  };

  onObjectRemoved = (options: any) => {
    this.props.onCanvasChange();
  };

  onPathCreated = (options: any) => {
    // const path = options.path;
    // const { drawingState } = this.props;
    // const tag = drawingState.selectedTool === "eraser" ? "eraser" : "pencil";
    // const isEraser = tag === "eraser";
    // path.__polyupaths__tag = tag;
    // path.set({
    //   // Make pencil or eraser not interactive at all
    //   evented: false,
    //   // globalCompositeOperation is very powerful
    //   // See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
    //   // "source-over" is the default composition mode
    //   // "destination-out" effectively erases the existing content
    //   // Together with sorted fabric objects, we can have
    //   // pencil being erased by eraser
    //   globalCompositeOperation: isEraser ? "destination-out" : "source-over",
    //   // We must use a opaque color so that the composition
    //   // is not affected by globalAlpha.
    //   // Therefore if we know the path is eraser, use a opaque color
    //   // "black" is just a random choice.
    //   stroke: isEraser ? "black" : path.stroke,
    // });
    // this._sortFabricCanvasObjects();
  };

  onSelectionCreated = (options: any) => {
    const obj = options.target;
    this.props.onSelectObject(obj);
    obj.set({
      ...getControlProps(this.state.canvasWidth, this.state.canvasHeight),
    });
    const { fabricCanvas } = this;
    if (fabricCanvas != null) {
      fabricCanvas.renderAll();
    }
  };

  onSelectionUpdated = (options: any) => {
    const obj = options.target;
    this.props.onSelectObject(obj);
    obj.set({
      ...getControlProps(this.state.canvasWidth, this.state.canvasHeight),
    });
    const { fabricCanvas } = this;
    if (fabricCanvas != null) {
      fabricCanvas.renderAll();
    }
  };

  onSelectionCleared = (options: any) => {
    this.props.onDeselectObject();
    this.props.onCanvasChange();
  };

  onChange = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    this.props.onSelectedTextObjectContentChange(value);
  };

  onTextAreaClick = (e: Event) => {
    // I cannot figure out why we need to
    // focus manually by listening onClick
    e.preventDefault();
    e.stopPropagation();
    const { textArea } = this;
    if (textArea != null) {
      textArea.focus();
    }
  };

  onTextAreaRef = (r: HTMLTextAreaElement | null) => {
    this.textArea = r;
  };

  onClickOutsideTextArea = (e: Event) => {
    // Do not prevent default nor stop propagation
    // we just want to blur the textarea
    const { textArea } = this;
    if (textArea != null) {
      textArea.blur();
    }
  };

  renderTextArea() {
    const { drawingState: { selectedTextObjectContent } } = this.props;
    return (
      <div
        className={classnames(styles.textAreaContainer, {
          [styles.inactive]: selectedTextObjectContent == null,
        })}
      >
        <PopupWrapper onClickOutside={this.onClickOutsideTextArea}>
          <AutoExpandTextArea
            onRef={this.onTextAreaRef}
            placeholderId="drawing.add_text"
            className={styles.textArea}
            onClick={this.onTextAreaClick}
            onChange={this.onChange}
            value={selectedTextObjectContent || ""}
            lineHeight={26}
            paddingTop={10}
            paddingBottom={10}
            maxNumberOfLines={1}
          />
        </PopupWrapper>
      </div>
    );
  }

  renderContent(
    width: number,
    deviceType: "desktop" | "mobileLandscape" | "mobilePortrait"
  ) {
    const {
      canvasWidth,
      canvasHeight,
      canvasBackgroundWidth,
      canvasBackgroundHeight,
    } = this.state;
    const transform = getTransform(
      canvasWidth,
      canvasHeight,
      deviceType === "mobileLandscape"
        ? canvasBackgroundWidth - 2 * SCROLL_PADDING
        : canvasBackgroundWidth,
      canvasBackgroundHeight
    );
    const imageLoading =
      this.props.drawingState.backgroundImageURL != null &&
      this.state.backgroundImage == null;
    return (
      <Div
        onRef={this.onDivRef}
        className={classnames(styles.canvasBackground, {
          [styles.imageLoading]: imageLoading,
        })}
        onResize={this.onResize}
        style={{
          height: deviceType === "desktop" ? undefined : width,
          paddingLeft:
            deviceType === "mobileLandscape" ? SCROLL_PADDING : undefined,
          paddingRight:
            deviceType === "mobileLandscape" ? SCROLL_PADDING : undefined,
        }}
      >
        <div
          ref={this.onCanvasContainerRef}
          style={{
            transform,
            width: canvasWidth,
            height: canvasHeight,
          }}
          className={styles.canvasContainer}
          dangerouslySetInnerHTML={dangerousHTML}
        />
        {this.renderTextArea()}
      </Div>
    );
  }

  render() {
    return (
      <WindowSize>
        {props => {
          const { width } = props;
          return (
            <ResponsiveLayout>
              {deviceType => this.renderContent(width, deviceType)}
            </ResponsiveLayout>
          );
        }}
      </WindowSize>
    );
  }

  _sortFabricCanvasObjects() {
    const { fabricCanvas } = this;
    if (fabricCanvas == null) {
      return;
    }
    const objects = fabricCanvas.getObjects();
    const sortedObjects = sortFabricObjects(objects);
    fabricCanvas._objects = sortedObjects;
    fabricCanvas.renderAll();
  }

  _performEffectsWithLocalState(curr: ?LocalState, next: LocalState) {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }

    if (curr == null || curr.canvasWidth !== next.canvasWidth) {
      fabricCanvas.setWidth(next.canvasWidth);
    }

    if (curr == null || curr.canvasHeight !== next.canvasHeight) {
      fabricCanvas.setHeight(next.canvasHeight);
    }

    if (
      (curr == null && next.backgroundImage != null) ||
      (curr != null && curr.backgroundImage !== next.backgroundImage)
    ) {
      fabricCanvas.clear();
      fabricCanvas.setBackgroundImage(next.backgroundImage);
    }
  }

  // By inspecting the difference between curr and next,
  // emit the imperative commands on fabricCanvas such
  // that fabricCanvas's state matches DrawingState
  _performEffectsWithDrawingState(curr: ?DrawingState, next: DrawingState) {
    // Download background image
    if (
      (curr == null && next.backgroundImageURL != null) ||
      (curr != null &&
        next.backgroundImageURL != null &&
        !isImageURLEqual(
          curr.backgroundImageURL || "",
          next.backgroundImageURL
        ))
    ) {
      const imageURL = next.backgroundImageURL;
      this.props.onBackgroundImageStartLoading();
      loadFabricImage(imageURL).then(img => {
        if (
          this.props.drawingState.backgroundImageURL != null &&
          !isImageURLEqual(this.props.drawingState.backgroundImageURL, imageURL)
        ) {
          return;
        }
        this.props.onBackgroundImageEndLoading();
        this.setState({
          backgroundImage: img,
          canvasWidth: img.width,
          canvasHeight: img.height,
        });
      });
    }
    // Remove background image
    if (
      (curr == null && next.backgroundImageURL == null) ||
      (curr != null &&
        curr.backgroundImageURL != null &&
        next.backgroundImageURL == null)
    ) {
      this.setState({
        backgroundImage: null,
        canvasWidth: DEFAULT_CANVAS_WIDTH,
        canvasHeight: DEFAULT_CANVAS_HEIGHT,
      });
    }

    // Handle orientation
    if (
      (curr == null && next.backgroundImageURL == null) ||
      (curr != null &&
        next.backgroundImageURL == null &&
        curr.orientation !== next.orientation)
    ) {
      this.setState({
        ...getCanvasDimen(next.orientation),
      });
    }

    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }

    if (next.backgroundImageURL == null) {
      fabricCanvas.backgroundColor = next.backgroundColor;
    }

    // Free drawing
    fabricCanvas.isDrawingMode =
      !next.isBackgroundImageLoading &&
      !next.readOnly &&
      next.selectedTool !== null;
    if (next.selectedTool === "pencil") {
      fabricCanvas.freeDrawingBrush.color = getPencilColor(next);
    } else if (next.selectedTool === "eraser") {
      fabricCanvas.freeDrawingBrush.color = ERASER_BRUSH_COLOR;
    }
    fabricCanvas.freeDrawingBrush.width = getPencilWidth(next);

    // Sync text area value
    const { selectedTextObjectContent, selectedObject } = next;
    if (
      selectedObject != null &&
      selectedTextObjectContent != null &&
      selectedObject instanceof fabric.Text &&
      selectedObject.text !== selectedTextObjectContent
    ) {
      // NOTE(louis): The following 4 lines are very tricky.
      // They must be in this order, otherwise the text
      // either not update or the actual dimension of the
      // text object does not match its visual demension.
      //
      // At first, I suppose calling the setter should
      // get my job done. But the text does not update.
      // I tried to add renderAll() and it worked for me.
      //
      // However, the actual dimension does not match
      // the visual dimension. The text is updated but
      // the actual dimension is out of sync with the
      // text content.
      //
      // Then I read the source code of fabric to see
      // how they handle this with their native
      // textarea implementation and I found this
      // https://github.com/kangax/fabric.js/blob/fb13ed0bdf2b2fd887f0059182087214631fed20/src/mixins/itext_key_behavior.mixin.js#L640
      // My initial attempt is
      //     selectedObject.text = selectedTextObjectContent;
      //     selectedObject.initDimensions();
      //     selectedObject.setCoords();
      //     fabricCanvas.renderAll();
      // But it still does not update the actual dimension properly.
      //
      // Finally I switch the order and it seems working very well.
      selectedObject.text = selectedTextObjectContent;
      fabricCanvas.renderAll();
      selectedObject.initDimensions();
      selectedObject.setCoords();
    }
  }

  // Public API
  deselectSelectedObject() {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }

    fabricCanvas.discardActiveObject();
    fabricCanvas.renderAll();
  }

  deleteSelectedObject() {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }
    const { drawingState } = this.props;
    if (drawingState.selectedObject != null) {
      fabricCanvas.remove(drawingState.selectedObject);
    }
  }

  clear() {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }
    const { drawingState } = this.props;
    if (drawingState.selectedObject == null) {
      fabricCanvas.clear();
      if (this.state.backgroundImage != null) {
        fabricCanvas.setBackgroundImage(this.state.backgroundImage);
      } else {
        // Clear() will clear all contexts (background, main, top) of an instance
        // set back default background color
        fabricCanvas.backgroundColor = "#ffffff";
      }
      this.props.onCanvasChange();
    }
  }

  getObjectsCount(): number {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return 0;
    }

    return fabricCanvas.getObjects().length;
  }

  addTextObject() {
    const { fabricCanvas, textArea } = this;
    if (!fabricCanvas) {
      return;
    }
    const { canvasWidth, canvasHeight } = this.state;
    const fillColor = getFillColor(this.props.drawingState);
    const strokeColor = getStrokeColor(this.props.drawingState);
    const textObject = new fabric.Text("", {
      charSpacing: 150,
      fontFamily: cssVariables.fontlist,
      fontSize: 100,
      fontWeight: 500,
      textAlign: "center",
      ...getInitialPositionProps(),
      ...getInitialStrokeWidth({
        canvasWidth,
        canvasHeight,
      }),
      ...getInitialColorProps({
        fillColor,
        strokeColor,
      }),
      // Override the strokeWidth
      strokeWidth: 4,
    });
    addFabricObjectAndSelect(fabricCanvas, textObject);
    if (textArea != null) {
      textArea.focus();
    }
  }

  addImageObjectByURL(url: string) {
    loadFabricImage(url).then(img => {
      const { fabricCanvas } = this;
      if (!fabricCanvas) {
        return;
      }
      const { canvasWidth, canvasHeight } = this.state;
      scaleFabricImageForInsertion({
        canvasWidth,
        canvasHeight,
        fabricImg: img,
      });
      addFabricObjectAndSelect(fabricCanvas, img);
    });
  }

  addImageObjectByBlob(blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    this.addImageObjectByURL(url);
  }

  addShape(shape: Shape) {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }

    const { canvasWidth, canvasHeight } = this.state;
    const fillColor = getFillColor(this.props.drawingState);
    const strokeColor = getStrokeColor(this.props.drawingState);
    switch (shape) {
      case "circle": {
        const circle = new fabric.Circle({
          ...getInitialPositionProps(),
          ...getInitialStrokeWidth({
            canvasWidth,
            canvasHeight,
          }),
          ...getInitialColorProps({
            fillColor,
            strokeColor,
          }),
          ...getInitialCircleProps({
            canvasWidth,
            canvasHeight,
          }),
        });
        patchShapeObject(circle);
        addFabricObjectAndSelect(fabricCanvas, circle);
        break;
      }
      case "rectangle": {
        const rect = new fabric.Rect({
          ...getInitialPositionProps(),
          ...getInitialStrokeWidth({
            canvasWidth,
            canvasHeight,
          }),
          ...getInitialColorProps({
            fillColor,
            strokeColor,
          }),
          ...getInitialRectProps({
            canvasWidth,
            canvasHeight,
          }),
        });
        patchShapeObject(rect);
        addFabricObjectAndSelect(fabricCanvas, rect);
        break;
      }
      case "triangle": {
        const triangle = new fabric.Triangle({
          ...getInitialPositionProps(),
          ...getInitialStrokeWidth({
            canvasWidth,
            canvasHeight,
          }),
          ...getInitialColorProps({
            fillColor,
            strokeColor,
          }),
          ...getInitialTriangleProps({
            canvasWidth,
            canvasHeight,
          }),
        });
        patchShapeObject(triangle);
        addFabricObjectAndSelect(fabricCanvas, triangle);
        break;
      }
      case "pentagon": {
        const pentagon = new fabric.Polygon(
          getInitialPentagonPoints({
            canvasWidth,
            canvasHeight,
          }),
          {
            ...getInitialPositionProps(),
            ...getInitialStrokeWidth({
              canvasWidth,
              canvasHeight,
            }),
            ...getInitialColorProps({
              fillColor,
              strokeColor,
            }),
          }
        );
        patchShapeObject(pentagon);
        addFabricObjectAndSelect(fabricCanvas, pentagon);
        break;
      }
      case "star": {
        const star = new fabric.Polygon(
          getInitialStarPoints({
            canvasWidth,
            canvasHeight,
          }),
          {
            ...getInitialPositionProps(),
            ...getInitialStrokeWidth({
              canvasWidth,
              canvasHeight,
            }),
            ...getInitialColorProps({
              fillColor,
              strokeColor,
            }),
          }
        );
        patchShapeObject(star);
        addFabricObjectAndSelect(fabricCanvas, star);
        break;
      }
      case "heart": {
        const heart = new fabric.Path(
          getInitialHeartPath({
            canvasWidth,
            canvasHeight,
          }),
          {
            ...getInitialPositionProps(),
            ...getInitialStrokeWidth({
              canvasWidth,
              canvasHeight,
            }),
            ...getInitialColorProps({
              fillColor,
              strokeColor,
            }),
          }
        );
        patchShapeObject(heart);
        addFabricObjectAndSelect(fabricCanvas, heart);
        break;
      }
      default:
        break;
    }
  }

  changeSelectedObjectPencilColor(color: string) {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }
    const { drawingState: { selectedObject } } = this.props;
    if (selectedObject != null && canApplyPencilColor(selectedObject)) {
      selectedObject.set("stroke", color);
      fabricCanvas.renderAll();
    }
  }

  changeSelectedObjectStrokeColor(color: string) {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }
    const { drawingState: { selectedObject } } = this.props;
    if (
      selectedObject != null &&
      canApplyStrokeColorAndFillColor(selectedObject)
    ) {
      selectedObject.set("stroke", color);
      fabricCanvas.renderAll();
    }
  }

  changeSelectedObjectFillColor(color: string) {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      return;
    }
    const { drawingState: { selectedObject } } = this.props;
    if (
      selectedObject != null &&
      canApplyStrokeColorAndFillColor(selectedObject)
    ) {
      selectedObject.set("fill", color);
      fabricCanvas.renderAll();
    }
  }

  // This function is useful for exporting the drawn image
  // e.g. upload to skygear and download locally
  generateDataURL(): string {
    const { fabricCanvas } = this;
    if (!fabricCanvas) {
      throw new Error("");
    }
    const foregroundURL = fabricCanvas.toDataURL({
      format: "png",
      enableRetinaScaling: false,
    });
    return foregroundURL;
  }

  // This function may throw error
  downloadImage(): void {
    downloadDataURL(this.generateDataURL());
  }
}
