// @flow
import type { IntlShape } from "react-intl";
import type { RequestState } from "./request";
import type { School } from "./school";

export type AppLocale = "en" | "zh-Hans-CN" | "zh-Hant-HK";

export const APP_CHANGE_LOCALE: "APP_CHANGE_LOCALE" = "APP_CHANGE_LOCALE";
export const APP_SYNC_INTL: "APP_SYNC_INTL" = "APP_SYNC_INTL";

export const SKYGEAR_INIT_REQUESTING: "SKYGEAR.INIT.REQUESTING" =
  "SKYGEAR.INIT.REQUESTING";
export const SKYGEAR_INIT_SUCCESS: "SKYGEAR.INIT.SUCCESS" =
  "SKYGEAR.INIT.SUCCESS";
export const SKYGEAR_INIT_FAILURE: "SKYGEAR.INIT.FAILURE" =
  "SKYGEAR.INIT.FAILURE";

export type AppState = {
  +locale: AppLocale,
  +intl: ?IntlShape,
  +skygearInitRequest: ?RequestState<void>,
  +getSchoolRequest: ?RequestState<void>,
  +school: ?School,
};

// Localization
export type AppChangeLocale = {|
  +type: typeof APP_CHANGE_LOCALE,
  +payload: AppLocale,
|};
export type AppSyncIntl = {|
  +type: typeof APP_SYNC_INTL,
  +payload: IntlShape,
|};

// Skygear init
export type SkygearInitRequesting = {|
  +type: typeof SKYGEAR_INIT_REQUESTING,
|};
export type SkygearInitSuccess = {|
  +type: typeof SKYGEAR_INIT_SUCCESS,
|};
export type SkygearInitFailure = {|
  +type: typeof SKYGEAR_INIT_FAILURE,
  +payload: mixed,
|};

export const GET_SCHOOL_REQUESTING: "GET_SCHOOL_REQUESTING" =
  "GET_SCHOOL_REQUESTING";
export const GET_SCHOOL_SUCCESS: "GET_SCHOOL_SUCCESS" = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_FAILURE: "GET_SCHOOL_FAILURE" = "GET_SCHOOL_FAILURE";
export const RESET_SCHOOL_INFO: "RESET_SCHOOL_INFO" = "RESET_SCHOOL_INFO";

export type GetSchoolRequesting = {|
  +type: typeof GET_SCHOOL_REQUESTING,
|};
export type GetSchoolSuccess = {|
  +type: typeof GET_SCHOOL_SUCCESS,
  +payload: School,
|};
export type GetSchoolFailure = {|
  +type: typeof GET_SCHOOL_FAILURE,
  +payload: mixed,
|};
export type ResetSchoolInfo = {|
  +type: typeof RESET_SCHOOL_INFO,
|};
