// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import DashboardFooter from "../../components/DashboradFooter/DashboardFooter";
import type { Node } from "react";
import type { RootState, Dispatch } from "../../types/store";
import type { AuthUser } from "../../types/auth";
import type { RouterHistory } from "react-router-dom";
import styles from "./styles/Layout.module.scss";

type Props = {
  +user?: AuthUser,
  +children?: Node,
  +history: RouterHistory,
};

class Layout extends PureComponent<Props> {
  componentWillReceiveProps(nextProps) {
    if (this.props.user && !nextProps.user) {
      this.props.history.replace("/login");
    }
  }

  render() {
    const { user, children } = this.props;
    return (
      <div className={styles.main}>
        <div className={styles.container}>
          {user && <DashboardHeader type="teacher" />}
          {!user && <Header />}
          {children}
        </div>
        {user && <DashboardFooter />}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return { user: state.auth.user };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
