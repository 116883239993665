// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import DatePicker from "./DatePicker";
import Text from "../Text/Text";
import FormattedDate from "../FormattedDate/FormattedDate";
import styles from "./StyledDatePicker.module.scss";

type Props = {
  variant: "from" | "to",
  className: string,
  value: Date,
  maxDate?: Date,
  onChange: (date: Date) => void,
};

export default class StyledDatePicker extends PureComponent<Props> {
  render() {
    return (
      <DatePicker
        className={classnames(styles.styledDatePicker, this.props.className)}
        value={this.props.value}
        onChange={this.props.onChange}
        maxDate={this.props.maxDate}
      >
        <span className={styles.labelText}>
          <Text
            translationKey={
              this.props.variant === "from"
                ? "datepicker.label.from"
                : "datepicker.label.to"
            }
          />
        </span>
        <span className={styles.dateText}>
          <FormattedDate
            value={this.props.value}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        </span>
      </DatePicker>
    );
  }
}
