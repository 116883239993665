// @flow

// ref from: https://github.com/DamonOehlman/detect-browser

function getBrowserRules() {
  return buildRules([
    ["aol", /AOLShield\/([0-9._]+)/],
    ["edge", /Edge\/([0-9._]+)/],
    ["yandexbrowser", /YaBrowser\/([0-9._]+)/],
    ["vivaldi", /Vivaldi\/([0-9.]+)/],
    ["kakaotalk", /KAKAOTALK\s([0-9.]+)/],
    ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
    ["phantomjs", /PhantomJS\/([0-9.]+)(:?\s|$)/],
    ["crios", /CriOS\/([0-9.]+)(:?\s|$)/],
    ["firefox", /Firefox\/([0-9.]+)(?:\s|$)/],
    ["fxios", /FxiOS\/([0-9.]+)/],
    ["opera", /Opera\/([0-9.]+)(?:\s|$)/],
    ["opera", /OPR\/([0-9.]+)(:?\s|$)$/],
    ["ie", /Trident\/7.0.*rv:([0-9.]+).*\).*Gecko$/],
    ["ie", /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
    ["ie", /MSIE\s(7.0)/],
    ["bb10", /BB10;\sTouch.*Version\/([0-9.]+)/],
    ["android", /Android\s([0-9.]+)/],
    ["ios", /Version\/([0-9._]+).*Mobile.*Safari.*/],
    ["safari", /Version\/([0-9._]+).*Safari/],
  ]);
}

function buildRules(ruleTuples) {
  return ruleTuples.map(function(tuple) {
    return {
      name: tuple[0],
      rule: tuple[1],
    };
  });
}

export function checkBrowserSupport(): boolean {
  var browsers = getBrowserRules();
  const userAgentString = navigator.userAgent;

  if (!userAgentString) {
    return false;
  }

  const detected =
    browsers
      .map(function(browser) {
        var match = browser.rule.exec(userAgentString);
        var version = match && match[1].split(/[._]/).slice(0, 3);

        if (version && version.length < 3) {
          version = version.concat(version.length === 1 ? [0, 0] : [0]);
        }

        return (
          match && {
            name: browser.name,
            version: version.join("."),
          }
        );
      })
      .filter(Boolean)[0] || null;

  switch (detected.name) {
    case "chrome":
    case "edge":
    case "firefox":
    case "safari":
    case "ios":
    case "android":
    case "ios-webview":
    case "crios": // Chrome for iOS
      return true;
    case "ie":
      const version = parseFloat(detected.version);
      return version >= 10.0;
    default:
      break;
  }

  return false;
}
