// @flow
import React from "react";
import { Link, Redirect } from "react-router-dom";

type Props = {
  className?: string,
  sessionCode: string,
  redirect?: boolean,
  replace?: boolean,
};

const JoinSessionLink = (props: Props) => {
  const { redirect, replace, className, sessionCode } = props;
  const to = `/student?code=${sessionCode}`;
  if (redirect === true) {
    return <Redirect to={to} replace={replace == null ? true : replace} />;
  }
  return (
    <Link className={className} to={to} target="_blank">
      e.paths.hk/student
    </Link>
  );
};

export default JoinSessionLink;
