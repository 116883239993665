// @flow
import React, { PureComponent } from "react";
import type { Node } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedDate as IntlFormattedDate } from "react-intl";
import type {
  $npm$ReactIntl$DateTimeFormatOptions,
  $npm$ReactIntl$DateParseable,
} from "react-intl";
import type { RootState } from "../../types/store";

type Props = $npm$ReactIntl$DateTimeFormatOptions & {
  value: $npm$ReactIntl$DateParseable,
  format?: string,
  children?: (formattedDate: string) => Node,
};

class FormattedDate extends PureComponent<Props> {
  static childContextTypes = {
    intl: PropTypes.any,
  };

  getChildContext() {
    return {
      intl: this.props.intl,
    };
  }

  render() {
    const { intl, ...rest } = this.props;
    return <IntlFormattedDate {...rest} />;
  }
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FormattedDate);
