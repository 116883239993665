// @flow
import { PureComponent } from "react";

type Props = {
  onActive: () => void,
};

export default class OnActive extends PureComponent<Props> {
  componentDidMount() {
    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events
    window.addEventListener("online", this.onOnline);
    // Plain old event supported by many browsers
    window.addEventListener("focus", this.onFocus);
    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    window.addEventListener("visibilitychange", this.onVisibilityChange);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.onOnline);
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("visibilitychange", this.onVisibilityChange);
  }

  onFocus = () => {
    this.props.onActive();
  };

  onOnline = () => {
    this.props.onActive();
  };

  onVisibilityChange = () => {
    if (!document.hidden) {
      this.props.onActive();
    }
  };

  render() {
    return null;
  }
}
