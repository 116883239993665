// @flow
import skygear from "skygear";
import type {
  AuthUser,
  AuthRequesting,
  AuthSuccess,
  AuthFailure,
} from "../../types/auth";
import { resetSchoolInfo } from "./app";
import { deserializeAuthUser } from "../../utils/deserializer";

export const REQUESTING: "AUTH.REQUESTING" = "AUTH.REQUESTING";
export const SUCCESS: "AUTH.SUCCESS" = "AUTH.SUCCESS";
export const FAILURE: "AUTH.FAILURE" = "AUTH.FAILURE";

export function authRequesting(): AuthRequesting {
  return {
    type: REQUESTING,
  };
}

export function authSuccess(user: ?AuthUser): AuthSuccess {
  return {
    type: SUCCESS,
    payload: user,
  };
}

export function authFailure(error: mixed): AuthFailure {
  return {
    type: FAILURE,
    payload: error,
  };
}

export function login(data: {
  email: string,
  password: string,
  captchaData?: {
    ticket: string,
    randstr: string,
  },
  role: string,
}) {
  const { email, password, role, captchaData } = data;
  return (dispatch: Dispatch): Promise<boolean> => {
    dispatch(authRequesting());
    const args = {
      email: email,
      password: password,
      captcha_data: captchaData,
    };
    return skygear
      .lambda(`polyupaths:${role}:login`, [args])
      .then(skygear.auth._authResolve.bind(skygear.auth))
      .then(user => {
        dispatch(authSuccess(deserializeAuthUser(user)));
        return !!user;
      })
      .catch(error => {
        dispatch(authFailure(error));
        return Promise.reject(error);
      });
  };
}

export function signupAsSchool(email: string, password: string) {
  return (dispatch: Dispatch): Promise<boolean> => {
    dispatch(authRequesting());
    const args = {
      email,
      password,
      name: email,
      eng_name: email,
    };
    return skygear
      .lambda("polyupaths:school:create", [args])
      .then(result => {
        return skygear.auth._authResolve(result.auth_response).then(user => {
          dispatch(authSuccess(deserializeAuthUser(user)));
          return !!user;
        });
      })
      .catch(error => {
        dispatch(authFailure(error));
        return Promise.reject(error);
      });
  };
}

export function logout() {
  return (dispatch: Dispatch) => {
    dispatch(authRequesting());
    skygear.auth
      .logout()
      .then(() => {
        dispatch(authSuccess(null));
        dispatch(resetSchoolInfo());
      })
      .catch(error => dispatch(authFailure(error)));
  };
}

export function forgotPassword(email: string): Promise<string> {
  const args = {
    email,
  };
  return skygear
    .lambda("polyupaths:admin:forgot_password", [args])
    .then(result => {
      return (result.email: string);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
