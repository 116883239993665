// @flow
import type { Action } from "../../types/store";
import type { SessionsState } from "../../types/session";
import {
  CREATE_SESSION_REQUESTING,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  PAUSE_SESSION_REQUESTING,
  PAUSE_SESSION_SUCCESS,
  PAUSE_SESSION_FAILURE,
  UNPAUSE_SESSION_REQUESTING,
  UNPAUSE_SESSION_SUCCESS,
  UNPAUSE_SESSION_FAILURE,
  START_SESSION_REQUESTING,
  START_SESSION_SUCCESS,
  START_SESSION_FAILURE,
  END_SESSION_REQUESTING,
  END_SESSION_SUCCESS,
  END_SESSION_FAILURE,
  JOIN_SESSION_REQUESTING,
  JOIN_SESSION_SUCCESS,
  JOIN_SESSION_FAILURE,
  SEEK_ACTIVITY_REQUESTING,
  SEEK_ACTIVITY_SUCCESS,
  SEEK_ACTIVITY_FAILURE,
  RESET_ACTIVITY_REQUESTING,
  RESET_ACTIVITY_SUCCESS,
  RESET_ACTIVITY_FAILURE,
  END_ACTIVITY_REQUESTING,
  END_ACTIVITY_SUCCESS,
  END_ACTIVITY_FAILURE,
  SUBMIT_ANSWER_REQUESTING,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAILURE,
  DELETE_ANSWER_REQUESTING,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAILURE,
  QUERY_ACTIVE_SESSIONS_REQUESTING,
  QUERY_ACTIVE_SESSIONS_SUCCESS,
  QUERY_ACTIVE_SESSIONS_FAILURE,
  QUERY_SINGLE_SESSION_REQUESTING,
  QUERY_SINGLE_SESSION_SUCCESS,
  QUERY_SINGLE_SESSION_FAILURE,
  SYNC_SESSION_FROM_PUBSUB,
  FETCH_ACTIVITY_RECORDS_REQUESTING,
  FETCH_ACTIVITY_RECORDS_SUCCESS,
  FETCH_ACTIVITY_RECORDS_FAILURE,
  FETCH_SELECT_OPTIONS_REQUESTING,
  FETCH_SELECT_OPTIONS_SUCCESS,
  FETCH_SELECT_OPTIONS_FAILURE,
  SEARCH_ACTIVITY_RECORDS_REQUESTING,
  SEARCH_ACTIVITY_RECORDS_SUCCESS,
  SEARCH_ACTIVITY_RECORDS_FAILURE,
} from "../../types/session";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";
import { rebuildActiveSessionList } from "../../utils/session";

const INITIAL_STATE: SessionsState = {
  sessionById: {},
  templateOptions: null,
  schoolOptions: null,
  activeSessionIds: [],
  recordsByTemplateId: {},
  activeSessionsRequest: null,
  createSessionRequest: null,
  pauseOrUnpauseSessionRequest: null,
  joinSessionRequest: null,
  startSessionRequest: null,
  endSessionRequest: null,
  seekActivityRequest: null,
  resetActivityRequest: null,
  endActivityRequest: null,
  submitAnswerRequest: null,
  deleteAnswerRequest: null,
  singleSessionRequest: null,
  fetchActivityRecordsRequest: null,
  fetchSelectOptionsRequest: null,
  searchActivityRecordsRequest: null,
};

export function reducer(state: SessionsState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case CREATE_SESSION_REQUESTING: {
      return {
        ...state,
        createSessionRequest: makeRequesting(),
      };
    }
    case QUERY_ACTIVE_SESSIONS_REQUESTING: {
      return {
        ...state,
        activeSessionsRequest: makeRequesting(),
      };
    }
    case START_SESSION_REQUESTING: {
      return {
        ...state,
        startSessionRequest: makeRequesting(),
      };
    }
    case END_SESSION_REQUESTING: {
      return {
        ...state,
        endSessionRequest: makeRequesting(),
      };
    }
    case JOIN_SESSION_REQUESTING: {
      return {
        ...state,
        joinSessionRequest: makeRequesting(),
      };
    }
    case PAUSE_SESSION_REQUESTING: {
      return {
        ...state,
        pauseOrUnpauseSessionRequest: makeRequesting(),
      };
    }
    case UNPAUSE_SESSION_REQUESTING: {
      return {
        ...state,
        pauseOrUnpauseSessionRequest: makeRequesting(),
      };
    }
    case SEEK_ACTIVITY_REQUESTING: {
      return {
        ...state,
        seekActivityRequest: makeRequesting(),
      };
    }
    case RESET_ACTIVITY_REQUESTING: {
      return {
        ...state,
        resetActivityRequest: makeRequesting(),
      };
    }
    case END_ACTIVITY_REQUESTING: {
      return {
        ...state,
        endActivityRequest: makeRequesting(),
      };
    }
    case SUBMIT_ANSWER_REQUESTING: {
      return {
        ...state,
        submitAnswerRequest: makeRequesting(),
      };
    }
    case DELETE_ANSWER_REQUESTING: {
      return {
        ...state,
        deleteAnswerRequest: makeRequesting(),
      };
    }
    case CREATE_SESSION_SUCCESS: {
      const session = action.payload;
      const sessionId = session.id;
      const activeSessionIds = rebuildActiveSessionList(
        state.activeSessionIds,
        session
      );
      const sessionById = {
        ...state.sessionById,
        [sessionId]: session,
      };
      return {
        ...state,
        activeSessionIds,
        sessionById,
        createSessionRequest: makeSuccess(),
      };
    }
    case START_SESSION_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        startSessionRequest: makeSuccess(),
      };
    }
    case END_SESSION_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        endSessionRequest: makeSuccess(),
      };
    }
    case JOIN_SESSION_SUCCESS: {
      const { session } = action.payload;
      const sessionId = session.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: session,
      };
      return {
        ...state,
        sessionById,
        joinSessionRequest: makeSuccess(),
      };
    }
    case PAUSE_SESSION_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        pauseOrUnpauseSessionRequest: makeSuccess(),
      };
    }
    case UNPAUSE_SESSION_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        pauseOrUnpauseSessionRequest: makeSuccess(),
      };
    }
    case SEEK_ACTIVITY_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        seekActivityRequest: makeSuccess(),
      };
    }
    case RESET_ACTIVITY_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        resetActivityRequest: makeSuccess(),
      };
    }
    case END_ACTIVITY_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        endActivityRequest: makeSuccess(),
      };
    }
    case SUBMIT_ANSWER_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        submitAnswerRequest: makeSuccess(),
      };
    }
    case DELETE_ANSWER_SUCCESS: {
      const sessionId = action.payload.id;
      const sessionById = {
        ...state.sessionById,
        [sessionId]: action.payload,
      };
      return {
        ...state,
        sessionById,
        deleteAnswerRequest: makeSuccess(),
      };
    }
    case QUERY_ACTIVE_SESSIONS_SUCCESS: {
      const sessionById = { ...state.sessionById };
      const activeSessionIds = action.payload.map(s => s.id);
      for (const s of action.payload) {
        sessionById[s.id] = s;
      }
      return {
        ...state,
        activeSessionIds,
        sessionById,
        activeSessionsRequest: makeSuccess(),
      };
    }
    case CREATE_SESSION_FAILURE: {
      return {
        ...state,
        createSessionRequest: makeFailure(action.payload),
      };
    }
    case PAUSE_SESSION_FAILURE: {
      return {
        ...state,
        pauseOrUnpauseSessionRequest: makeFailure(action.payload),
      };
    }
    case UNPAUSE_SESSION_FAILURE: {
      return {
        ...state,
        pauseOrUnpauseSessionRequest: makeFailure(action.payload),
      };
    }
    case START_SESSION_FAILURE: {
      return {
        ...state,
        startSessionRequest: makeFailure(action.payload),
      };
    }
    case END_SESSION_FAILURE: {
      return {
        ...state,
        endSessionRequest: makeFailure(action.payload),
      };
    }
    case JOIN_SESSION_FAILURE: {
      return {
        ...state,
        joinSessionRequest: makeFailure(action.payload),
      };
    }
    case SEEK_ACTIVITY_FAILURE: {
      return {
        ...state,
        seekActivityRequest: makeFailure(action.payload),
      };
    }
    case RESET_ACTIVITY_FAILURE: {
      return {
        ...state,
        resetActivityRequest: makeFailure(action.payload),
      };
    }
    case END_ACTIVITY_FAILURE: {
      return {
        ...state,
        endActivityRequest: makeFailure(action.payload),
      };
    }
    case SUBMIT_ANSWER_FAILURE: {
      return {
        ...state,
        submitAnswerRequest: makeFailure(action.payload),
      };
    }
    case DELETE_ANSWER_FAILURE: {
      return {
        ...state,
        deleteAnswerRequest: makeFailure(action.payload),
      };
    }
    case QUERY_ACTIVE_SESSIONS_FAILURE: {
      return {
        ...state,
        activeSessionsRequest: makeFailure(action.payload),
      };
    }
    case QUERY_SINGLE_SESSION_REQUESTING: {
      return {
        ...state,
        singleSessionRequest: makeRequesting(),
      };
    }
    case QUERY_SINGLE_SESSION_SUCCESS: {
      const s = action.payload;
      const sessionById = { ...state.sessionById };
      sessionById[s.id] = s;
      return {
        ...state,
        sessionById,
        singleSessionRequest: makeSuccess(),
      };
    }
    case QUERY_SINGLE_SESSION_FAILURE: {
      return {
        ...state,
        singleSessionRequest: makeFailure(action.payload),
      };
    }
    case SYNC_SESSION_FROM_PUBSUB: {
      const newSession = action.payload;
      const id = newSession.id;
      const activeSessionIds = rebuildActiveSessionList(
        state.activeSessionIds,
        newSession
      );
      const sessionById = { ...state.sessionById };
      sessionById[id] = newSession;
      return {
        ...state,
        activeSessionIds,
        sessionById,
      };
    }
    case FETCH_ACTIVITY_RECORDS_REQUESTING: {
      return {
        ...state,
        fetchActivityRecordsRequest: makeRequesting(),
      };
    }
    case FETCH_ACTIVITY_RECORDS_SUCCESS: {
      const { templateId, records } = action.payload;
      return {
        ...state,
        fetchActivityRecordsRequest: makeSuccess(),
        recordsByTemplateId: {
          ...state.recordsByTemplateId,
          [templateId]: records,
        },
      };
    }
    case FETCH_ACTIVITY_RECORDS_FAILURE: {
      return {
        ...state,
        fetchActivityRecordsRequest: makeFailure(action.payload),
      };
    }
    case FETCH_SELECT_OPTIONS_REQUESTING: {
      return {
        ...state,
        fetchSelectOptionsRequest: makeRequesting(),
      };
    }
    case FETCH_SELECT_OPTIONS_SUCCESS: {
      return {
        ...state,
        fetchSelectOptionsRequest: makeSuccess(),
        templateOptions: action.payload.templateOptions,
        schoolOptions: action.payload.schoolOptions,
      };
    }
    case FETCH_SELECT_OPTIONS_FAILURE: {
      return {
        ...state,
        fetchSelectOptionsRequest: makeFailure(action.payload),
      };
    }
    case SEARCH_ACTIVITY_RECORDS_REQUESTING: {
      return {
        ...state,
        searchActivityRecordsRequest: makeRequesting(),
      };
    }
    case SEARCH_ACTIVITY_RECORDS_SUCCESS: {
      const sessions = action.payload;
      const cloned = { ...state.sessionById };
      for (const s of sessions) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        sessionById: cloned,
        searchActivityRecordsRequest: makeSuccess(),
      };
    }
    case SEARCH_ACTIVITY_RECORDS_FAILURE: {
      return {
        ...state,
        searchActivityRecordsRequest: makeFailure(action.payload),
      };
    }
    default:
      return state;
  }
}
