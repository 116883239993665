// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { Session } from "../../types/session";
import type { RequestState } from "../../types/request";
import type {
  TeacherSessionNonNullProps,
  TeacherSessionNullableProps,
} from "./TeacherSessionProps";
import { toNonNull } from "./TeacherSessionProps";
import TeacherLayout from "./TeacherLayout";
import Synchronization from "../../components/Synchronization/Synchronization";
import TeacherSessionHeader from "../../components/TeacherSessionHeader/TeacherSessionHeader";
import TeacherSessionFooter from "../../components/TeacherSessionFooter/TeacherSessionFooter";
import TeacherSessionActivity from "../../components/TeacherSessionActivity/TeacherSessionActivity";
import TeacherSessionActivityResult from "../../components/TeacherSessionActivityResult/TeacherSessionActivityResult";
import SessionsLink from "../../components/Link/SessionsLink";
import JoinSessionLink from "../../components/Link/JoinSessionLink";
import WindowSize from "../../components/WindowSize";
import Div from "../../components/Div";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import CloseWindowOrRedirect from "../../components/CloseWindowOrRedirect";
import { startSession } from "../../redux/actions/sessions";
import testModeSrc from "../../images/session_preview.svg";
import participantsSrc from "../../images/participants.svg";
import type { AppLocale } from "../../types/app";
import { changeLocale } from "../../redux/actions/app";
import { coverTemplateLangToAppLocale } from "../../utils/locales";
import { extractError } from "../../utils/request";
import ErrorModal from "../../components/ErrorModal";
import styles from "./TeacherSession.module.scss";

type ConnectedProps = {
  querySingleSessionRequest: ?RequestState<void>,
  startSessionRequest: ?RequestState<void>,
  startSession: string => Promise<Session>,
  changeLocale: AppLocale => void,
};

type Props = TeacherSessionNullableProps & ConnectedProps;

type LocalState = {
  stateCreatedContentHeight: number,
  renderClose: boolean,
  previewActivityIndex: number,
};

// 344px = 120px + 127px + 30px + 35px + 32px
//       = headerHeight
//       + footerHeight
//       + layoutMarginTop
//       + layoutPaddingTop
//       + layoutPaddingBottom
const MAGIC_HEIGHT_OFFSET = 344;
const MAGIC_HEIGHT_OFFSET_NO_FOOTER = MAGIC_HEIGHT_OFFSET - 127;

class TeacherSession extends PureComponent<Props, LocalState> {
  state = {
    stateCreatedContentHeight: 0,
    renderClose: false,
    previewActivityIndex: -1,
  };

  componentDidMount() {
    const { templateLang } = this.props;
    if (templateLang != null) {
      this.props.changeLocale(coverTemplateLangToAppLocale(templateLang));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { templateLang } = nextProps;
    if (this.props.templateLang !== templateLang && templateLang != null) {
      this.props.changeLocale(coverTemplateLangToAppLocale(templateLang));
    }
  }

  onCloseSessionButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      renderClose: true,
    });
  };

  onStateCreatedContentResize = (width: number, height: number) => {
    this.setState({
      stateCreatedContentHeight: height,
    });
  };

  onClickStartButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { sessionID } = this.props;
    if (sessionID != null) {
      this.props.startSession(sessionID);
    }
  };

  onSeekPreviewActivity = (idx: number) => {
    const { sessionID } = this.props;
    const nonNullProps = toNonNull(this.props);
    // only support seek preview activity in preview mode (sessionID == null)
    if (sessionID == null && nonNullProps != null) {
      if (idx >= 0 && idx < nonNullProps.activities.length) {
        this.setState({ previewActivityIndex: idx });
      }
    }
  };

  renderTestModeNotice(nonNullProps: TeacherSessionNonNullProps) {
    const { sessionIsTestMode } = nonNullProps;
    if (!sessionIsTestMode) {
      return null;
    }
    return (
      <div className={styles.testModeContainer}>
        <p className={styles.testModeNotice}>
          <img
            className={styles.testModeIcon}
            src={testModeSrc}
            alt="test mode"
          />
          <Text translationKey="teacher.session.created.test_mode_notice" />
        </p>
      </div>
    );
  }

  renderStartButton() {
    return (
      <div className={styles.startButton} onClick={this.onClickStartButton}>
        <Text translationKey="teacher.session.created.button.start" />
      </div>
    );
  }

  renderNumberOfParticipants(nonNullProps: TeacherSessionNonNullProps) {
    const { participants } = nonNullProps;
    const participantCount = participants.length;
    return (
      <div className={styles.numberOfParticipantsContainer}>
        <div className={styles.numberOfParticipantsLabel}>
          <Text translationKey="teacher.session.created.number_of_participants.label" />
        </div>
        <div className={styles.numberOfParticipantsBottomContainer}>
          <img
            className={styles.numberOfParticipantsIcon}
            src={participantsSrc}
            alt="number of participants"
          />
          <span className={styles.numberOfParticipantsCount}>
            {participantCount}
          </span>
        </div>
      </div>
    );
  }

  renderStateCreated(nonNullProps: TeacherSessionNonNullProps) {
    const { sessionCode } = nonNullProps;
    return (
      <WindowSize>
        {props => {
          const h1 = props.height - MAGIC_HEIGHT_OFFSET;
          const h2 = this.state.stateCreatedContentHeight;
          const height = Math.max(h1, h2);
          return (
            <div
              style={{ minHeight: height }}
              className={styles.stateCreatedContainer}
            >
              <Div
                onResize={this.onStateCreatedContentResize}
                className={styles.stateCreatedContentContainer}
              >
                <p className={styles.participation}>
                  <Text
                    translationKey="teacher.session.created.participation"
                    values={{
                      url: (
                        <JoinSessionLink
                          className={styles.participationLink}
                          sessionCode={sessionCode}
                        />
                      ),
                      br: <br />,
                    }}
                  />
                </p>
                <div className={styles.sessionCodeContainer}>
                  <p className={styles.sessionCode}>{sessionCode}</p>
                </div>
                {this.renderTestModeNotice(nonNullProps)}
              </Div>
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderStateEnded() {
    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET_NO_FOOTER;
          return (
            <div style={{ height: h }} className={styles.endedContainer}>
              <div className={styles.endedContentContainer}>
                <p className={styles.endedMessageText}>
                  <Text translationKey="session.end" />
                </p>
                <Button
                  color="pink"
                  className={styles.closeSessionButton}
                  onClick={this.onCloseSessionButtonClick}
                >
                  <span className={styles.closeSessionButtonText}>
                    <Text translationKey="phrase.close" />
                  </span>
                </Button>
              </div>
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderFooter(nonNullProps: TeacherSessionNonNullProps) {
    const { sessionID } = this.props;
    const {
      sessionState,
      participants,
      answerCount,
      activityAction,
      activities,
    } = nonNullProps;
    const [
      activity,
      currentActivityIndex,
    ] = this.getCurrentActivityIdxAndActivity(nonNullProps);
    if (sessionState === "created") {
      return (
        <div className={styles.footerWrapper}>
          <div className={styles.createdBottomContainer}>
            {this.renderNumberOfParticipants(nonNullProps)}
            {this.renderStartButton()}
          </div>
        </div>
      );
    } else if (sessionState === "started") {
      return (
        <div className={styles.footerWrapper}>
          <TeacherSessionFooter
            sessionID={sessionID}
            currentActivityIndex={currentActivityIndex}
            participantCount={participants.length}
            answerCount={answerCount}
            activityAction={activityAction}
            activityName={activity.name}
            activities={activities}
            seekPreviewActivity={this.onSeekPreviewActivity}
          />
        </div>
      );
    }
    return null;
  }

  renderContent(nonNullProps: TeacherSessionNonNullProps) {
    const { sessionID } = this.props;
    const { activityState, sessionState, participants } = nonNullProps;
    const [
      activity,
      currentActivityIndex,
    ] = this.getCurrentActivityIdxAndActivity(nonNullProps);
    const renderElement = height => {
      if (sessionState === "created") {
        return this.renderStateCreated(nonNullProps);
      } else if (sessionState === "ended") {
        return this.renderStateEnded();
      } else if (activityState.state === "accepting_answer") {
        return (
          <TeacherSessionActivity activity={activity} parentHeight={height} />
        );
      } else if (activityState.state === "viewing_result") {
        return (
          <TeacherSessionActivityResult
            parentHeight={height}
            sessionID={sessionID}
            participants={participants}
            currentActivityIndex={currentActivityIndex}
            activity={activity}
            activityState={activityState}
          />
        );
      }
      return null;
    };
    return (
      <WindowSize>
        {props => {
          const height = props.height - MAGIC_HEIGHT_OFFSET;
          return renderElement(height);
        }}
      </WindowSize>
    );
  }

  renderCloseOrRedirect() {
    const { renderClose } = this.state;
    if (!renderClose) {
      return null;
    }
    return (
      <CloseWindowOrRedirect>
        <SessionsLink redirect replace />
      </CloseWindowOrRedirect>
    );
  }

  render() {
    const { sessionID } = this.props;
    const nonNullProps = toNonNull(this.props);
    if (nonNullProps == null) {
      return null;
    }
    const {
      sessionIsPaused,
      sessionState,
      sessionCode,
      templateName,
      templateDisplayCode,
    } = nonNullProps;
    return (
      <TeacherLayout className="">
        {sessionID != null && <Synchronization sessionId={sessionID} />}
        {this.renderCloseOrRedirect()}
        <TeacherSessionHeader
          className=""
          sessionID={sessionID}
          sessionIsPaused={sessionIsPaused}
          sessionState={sessionState}
          sessionCode={sessionCode}
          templateName={templateName}
          templateDisplayCode={templateDisplayCode}
        />
        {this.renderContent(nonNullProps)}
        {this.renderFooter(nonNullProps)}
        <Fragment>
          <ErrorModal
            error={extractError(this.props.querySingleSessionRequest)}
          />
          <ErrorModal error={extractError(this.props.startSessionRequest)} />
        </Fragment>
      </TeacherLayout>
    );
  }

  getCurrentActivityIdxAndActivity(nonNullProps: TeacherSessionNonNullProps) {
    const { activity, activities, currentActivityIndex } = nonNullProps;
    const { previewActivityIndex } = this.state;

    // If previewActivityIndex is not -1, which means we are in preview mode
    if (previewActivityIndex !== -1) {
      return [activities[previewActivityIndex], previewActivityIndex];
    } else {
      return [activity, currentActivityIndex];
    }
  }
}

function mapStateToProps(state: RootState) {
  return {
    querySingleSessionRequest: state.sessions.singleSessionRequest,
    startSessionRequest: state.sessions.startSessionRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    startSession: bindActionCreators(startSession, dispatch),
    changeLocale: bindActionCreators(changeLocale, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  TeacherSession
): any): Class<React$Component<TeacherSessionNullableProps>>);
