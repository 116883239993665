// @flow
import React from "react";
import RecordsManagementLink from "../Link/RecordsManagementLink";
import Arrow from "../ART/Arrow";
import Text from "../Text/Text";
import styles from "./AdminViewActivityRecordHeader.module.scss";

export default function AdminViewActivityRecordHeader(props: {}) {
  return (
    <div className={styles.headerContainer}>
      <Arrow
        className={styles.arrowIcon}
        width={9}
        height={19}
        strokeWidth={1}
        strokeColor="#979797"
        direction="left"
      />
      <RecordsManagementLink>
        <div className={styles.recordsManagementLinkText}>
          <Text translationKey="admin.records_management" />
        </div>
      </RecordsManagementLink>
      <Arrow
        className={styles.arrowIcon}
        width={9}
        height={19}
        strokeWidth={1}
        strokeColor="#979797"
        direction="left"
      />
      <div className={styles.currentLinkText}>
        <Text translationKey="admin.activity_record.title" />
      </div>
    </div>
  );
}
