// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import ActivityPanelRenderer from "./ActivityPanelRenderer";
import addOptionIcon from "../../images/add.svg";
import ImageUploadButton from "../ImageUploadButton/ImageUploadButton";
import ImageChooser from "../ImageChooser/ImageChooser";
import IconButton from "../IconButton/IconButton";
import Image from "../Image/Image";
import closeIcon from "../../images/close2.svg";
import { MIN_OPTIONS_COUNT, MAX_OPTIONS_COUNT } from "../../utils/validate";
import { indexToAlpha } from "../../utils/session";
import { POLLING_OPTION_DIAGRAM_HEIGHT } from "../../utils/constants";
import type { PollingOption } from "../../types/activity";
import type { CommonProps } from "./types/ActivityPanelProps";
import { ErrorActivityPollingOptionQuestionEmpty } from "../../utils/validate";
import styles from "./ActivityPollingPanel.module.scss";

class ActivityPollingPanel extends PureComponent<CommonProps> {
  onOptionChange = (e: Event, idx: mixed) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement && typeof idx === "number") {
      const editedPollingOptions = this.genPollingOptionsCopy();
      editedPollingOptions[idx] = {
        ...editedPollingOptions[idx],
        text: target.value,
      };

      this.onChangeActivity(editedPollingOptions);
    }
  };

  onImageUploaded = (id: string, url: string, idx: mixed) => {
    if (typeof idx === "number") {
      const editedPollingOptions = this.genPollingOptionsCopy();
      editedPollingOptions[idx] = {
        ...editedPollingOptions[idx],
        imageId: id,
        imageUrl: url,
      };

      this.onChangeActivity(editedPollingOptions);
    }
  };

  onImageUnSelect = (idx: mixed) => {
    if (typeof idx === "number") {
      const editedPollingOptions = this.genPollingOptionsCopy();
      editedPollingOptions[idx] = {
        ...editedPollingOptions[idx],
        imageId: null,
        imageUrl: null,
      };

      this.onChangeActivity(editedPollingOptions);
    }
  };

  onAddOption = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const editedPollingOptions = this.genPollingOptionsCopy();
    this.onChangeActivity([
      ...editedPollingOptions,
      ({
        text: "",
        imageUrl: null,
        imageId: null,
      }: PollingOption),
    ]);
  };

  onRemoveOption = (e: Event, idx: mixed) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof idx === "number") {
      const editedPollingOptions = this.genPollingOptionsCopy();
      editedPollingOptions.splice(idx, 1);
      this.onChangeActivity(editedPollingOptions);
    }
  };

  onChangeMultipleChoice = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { activity, onActivityChange } = this.props;
    if (onActivityChange != null) {
      onActivityChange({
        ...activity,
        pollingMultipleChoice: e.target.checked,
      });
    }
  };

  onChangeNamed = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { activity, onActivityChange } = this.props;
    if (onActivityChange != null) {
      onActivityChange({
        ...activity,
        pollingNamed: e.target.checked,
      });
    }
  };

  allowToRemoveOption() {
    return this.genPollingOptionsCopy().length > MIN_OPTIONS_COUNT;
  }

  // eslint-disable-next-line flowtype/no-mutable-array
  genPollingOptionsCopy(): Array<PollingOption> {
    if (this.props.activity.pollingOptions) {
      return Array.from(this.props.activity.pollingOptions);
    } else {
      return [];
    }
  }

  onChangeActivity(pollingOptions: $ReadOnlyArray<PollingOption>) {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        pollingOptions: pollingOptions,
      });
    }
  }

  renderOption(option: PollingOption, idx: number) {
    return (
      <div key={idx} className={styles.pollingOptionContainer}>
        <p className={styles.optionInputIdx}>{indexToAlpha(idx)}</p>
        <div className={styles.optionInputValue}>
          <p className={styles.pollingOptionText}>{option.text}</p>
          {option.imageUrl != null && (
            <Image
              imageUrl={option.imageUrl}
              height={POLLING_OPTION_DIAGRAM_HEIGHT}
              placeholderWidth={POLLING_OPTION_DIAGRAM_HEIGHT * 1.33}
              placeholderHeight={POLLING_OPTION_DIAGRAM_HEIGHT}
              className={styles.optionDiagram}
            />
          )}
        </div>
      </div>
    );
  }

  renderEditableOption(option: PollingOption, idx: number) {
    const { validationErrors } = this.props;

    return (
      <div className={styles.optionInputWrapper} key={idx}>
        <p className={styles.optionInputIdx}>{indexToAlpha(idx)}</p>
        <div className={styles.optionInputValue}>
          <InputWithErrorMessage
            placeholderId="teacher.polling_option_placeholder"
            labelId="teacher.polling_option"
            className={styles.optionInput}
            value={option.text}
            type="text"
            onChange={this.onOptionChange}
            onChangeInfo={idx}
            errorTextKey={
              validationErrors != null &&
              validationErrors.includes(
                ErrorActivityPollingOptionQuestionEmpty
              ) &&
              option.text.trim().length === 0
                ? "error.activity_polling_option_question_empty"
                : null
            }
          />
          <div className={styles.editorSupplements}>
            {option.imageUrl == null && (
              <ImageUploadButton
                className={styles.imageUploadButton}
                onUploaded={this.onImageUploaded}
                onUploadedInfo={idx}
              />
            )}
            {option.imageUrl != null && (
              <ImageChooser
                className={styles.imageChooser}
                imageUrl={option.imageUrl}
                onUnSelect={this.onImageUnSelect}
                onUnSelectInfo={idx}
                height={POLLING_OPTION_DIAGRAM_HEIGHT}
                placeholderWidth={POLLING_OPTION_DIAGRAM_HEIGHT * 1.33}
                placeholderHeight={POLLING_OPTION_DIAGRAM_HEIGHT}
              />
            )}
            <IconButton
              className={styles.removeOption}
              icon={closeIcon}
              labelTranslationKey="teacher.remove_polling_option"
              onClick={this.onRemoveOption}
              onClickInfo={idx}
              disabled={!this.allowToRemoveOption()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderOptions() {
    const {
      pollingOptions,
      pollingNamed,
      pollingMultipleChoice,
    } = this.props.activity;

    if (!pollingOptions) {
      return <div />;
    }

    return (
      <div className={styles.pollingContainer}>
        <div className={styles.pollingHeader}>
          <p className={styles.pollingOptionsTitleText}>
            <Text translationKey="teacher.polling_options" />
          </p>
          <p className={styles.pollingOptionsCheckboxDescriptionText}>
            <Text
              translationKey="teacher.polling.checkboxes_description"
              values={{
                NAMED: !!pollingNamed,
                MULTIPLE: !!pollingMultipleChoice,
              }}
            />
          </p>
        </div>
        {pollingOptions.map((p, idx) => this.renderOption(p, idx))}
      </div>
    );
  }

  renderEditableOptions() {
    const { pollingMultipleChoice, pollingNamed } = this.props.activity;
    const pollingOptions = this.props.activity.pollingOptions || [];

    return (
      <div className={styles.pollingContainer}>
        <div className={styles.editPollingOptionsHeaderWrapper}>
          <div className={styles.editPollingOptionsTitle}>
            <p className={styles.editPollingOptions}>
              <Text translationKey="teacher.polling_options" />
            </p>
            <p className={styles.editPollingOptionsHint}>
              <Text
                translationKey="teacher.add_polling_option_limit"
                values={{ min: MIN_OPTIONS_COUNT, max: MAX_OPTIONS_COUNT }}
              />
            </p>
          </div>
          <div className={styles.editPollingCheckboxGroup}>
            <p className={styles.editPollingCheckbox}>
              <input
                name="pollingNamed"
                type="checkbox"
                checked={pollingNamed}
                onChange={this.onChangeNamed}
              />
              <Text translationKey="teacher.polling.named" />
            </p>
            <p className={styles.editPollingCheckbox}>
              <input
                name="pollingMultipleChoice"
                type="checkbox"
                checked={pollingMultipleChoice}
                onChange={this.onChangeMultipleChoice}
              />
              <Text translationKey="teacher.polling.multiple_option" />
            </p>
          </div>
        </div>
        {pollingOptions.map((p, idx) => this.renderEditableOption(p, idx))}
        {pollingOptions.length < MAX_OPTIONS_COUNT && (
          <IconButton
            className={styles.addOption}
            icon={addOptionIcon}
            labelTranslationKey="teacher.add_polling_option"
            onClick={this.onAddOption}
          />
        )}
      </div>
    );
  }

  renderExtra() {
    const { editable } = this.props;

    if (!editable) {
      return this.renderOptions();
    }

    return this.renderEditableOptions();
  }

  render() {
    return (
      <ActivityPanelRenderer {...this.props}>
        {this.renderExtra()}
      </ActivityPanelRenderer>
    );
  }
}

export default ActivityPollingPanel;
