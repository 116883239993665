//@flow
import skygear from "skygear";
import { templateDeserializer } from "../../utils/deserializer";
import type {
  Template,
  CreateTemplateRequesting,
  CreateTemplateSuccess,
  CreateTemplateFailure,
  CreateTemplateParameter,
} from "../../types/template";

export const CREATE_TEMPLATE_REQUESTING: "TEMPLATES.CREATE_TEMPLATE.REQUESTING" =
  "TEMPLATES.CREATE_TEMPLATE.REQUESTING";
export const CREATE_TEMPLATE_SUCCESS: "TEMPLATES.CREATE_TEMPLATE.SUCCESS" =
  "TEMPLATES.CREATE_TEMPLATE.SUCCESS";
export const CREATE_TEMPLATE_FAILURE: "TEMPLATES.CREATE_TEMPLATE.FAILURE" =
  "TEMPLATES.CREATE_TEMPLATE.FAILURE";

export function createTemplateRequesting(): CreateTemplateRequesting {
  return {
    type: CREATE_TEMPLATE_REQUESTING,
  };
}

export function createTemplateSuccess(
  baseTemplate: Template,
  createdTemplate: Template
): CreateTemplateSuccess {
  return {
    type: CREATE_TEMPLATE_SUCCESS,
    payload: {
      baseTemplate,
      createdTemplate,
    },
  };
}

export function createTemplateFailure(error: ?Error): CreateTemplateFailure {
  return {
    type: CREATE_TEMPLATE_FAILURE,
    payload: error,
  };
}

export function createTemplate({ baseId, newName }: CreateTemplateParameter) {
  return (dispatch: Dispatch): Promise<Template> => {
    dispatch(createTemplateRequesting());
    const args = {
      base_id: baseId,
      new_name: newName,
    };

    return skygear
      .lambda("polyupaths:unit_template:create_custom", [args])
      .then(result => {
        const baseTemplate: Template = templateDeserializer(
          result.base_template
        );
        const createdTemplate: Template = templateDeserializer(
          result.unit_template
        );
        dispatch(createTemplateSuccess(baseTemplate, createdTemplate));
        return createdTemplate;
      })
      .catch(error => {
        dispatch(createTemplateFailure(error));
        return Promise.reject(error);
      });
  };
}
