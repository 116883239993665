// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import type { Node } from "react";
import styles from "./StyleButton.module.scss";

type Props = {
  style: string,
  children: Node,
  active?: boolean,
  onToggle: string => void,
};

export default class StyleButton extends PureComponent<Props> {
  static defaultProps = {
    active: false,
  };

  onToggle = (e: Event) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    return (
      <div
        className={classnames(styles.button, {
          [styles.active]: this.props.active,
        })}
        onMouseDown={this.onToggle}
      >
        {this.props.children}
      </div>
    );
  }
}
