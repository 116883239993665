// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { Activity, Answer, PollingOption } from "../../types/activity";
import type { Session, ParticipationInfo } from "../../types/session";
import type { RequestState } from "../../types/request";
import { submitAnswer } from "../../redux/actions/sessions";
import { isRequesting } from "../../utils/request";
import {
  getSubmittedAnswer,
  wasActivityResultCleared,
  wasActivitySeeked,
} from "../../utils/session";
import SubmitButton from "./SubmitButton";
import Button from "../Button/Button";
import { indexToAlpha } from "../../utils/session";
import Image from "../Image/Image";
import QuestionView from "./QuestionView";
import ResponsiveLayout from "../ResponsiveLayout";
import styles from "./StudentSessionActivity.module.scss";

type OwnProps = {
  className: string,
  session: Session,
  activity: Activity,
  participationInfo: ParticipationInfo,
  pollingMultipleChoice: boolean,
  pollingOptions: $ReadOnlyArray<PollingOption>,
};

type ConnectedProps = {
  submitAnswerRequest: ?RequestState<void>,
  submitAnswer: (
    sessionId: string,
    activityIndex: number,
    participationInfo: ParticipationInfo,
    answer: Answer
  ) => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  selectedOptionIndice: $ReadOnlyArray<number>,
};

class StudentSessionActivityPolling extends PureComponent<Props, LocalState> {
  state = {
    selectedOptionIndice: [],
  };

  componentWillReceiveProps(nextProps: Props) {
    const seeked = wasActivitySeeked(this.props.session, nextProps.session);
    const cleared = wasActivityResultCleared(
      this.props.session,
      nextProps.session
    );
    if (seeked || cleared) {
      this.setState({
        selectedOptionIndice: [],
      });
    }
  }

  onSubmit = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedOptionIndice } = this.state;
    if (selectedOptionIndice.length > 0) {
      const { session, participationInfo } = this.props;
      this.props.submitAnswer(
        session.id,
        session.currentActivityIndex,
        participationInfo,
        {
          qaText: null,
          whiteboardImageId: null,
          whiteboardImageUrl: null,
          pollingOptionIndice: selectedOptionIndice,
        }
      );
    }
  };

  onClick = (e: Event, info: mixed) => {
    e.preventDefault();
    e.stopPropagation();

    const { selectedOptionIndice } = this.state;
    const { session, participationInfo, pollingMultipleChoice } = this.props;

    // If there is submitted answer, do not allow selection
    const answer = getSubmittedAnswer(session, participationInfo.participantId);
    if (answer != null && answer.pollingMultipleChoice != null) {
      return;
    }

    if (typeof info === "number") {
      const index = info;
      const i = selectedOptionIndice.indexOf(index);

      // This option is not selected now
      if (i < 0) {
        // we allow selection if nothing was selected or if it is MC
        if (selectedOptionIndice.length === 0 || pollingMultipleChoice) {
          const newArray = [...selectedOptionIndice];
          newArray.push(index);
          this.setState({
            selectedOptionIndice: newArray,
          });
        } else {
          // Otherwise make it the only selection
          this.setState({
            selectedOptionIndice: [index],
          });
        }
      } else {
        // Otherwise we remove it from selection
        const newArray = [...selectedOptionIndice];
        newArray.splice(i, 1);
        this.setState({
          selectedOptionIndice: newArray,
        });
      }
    }
  };

  renderOptionContent(option: PollingOption, index: number) {
    const { session, participationInfo } = this.props;
    const answer = getSubmittedAnswer(session, participationInfo.participantId);
    const pollingOptionIndice = answer && answer.pollingOptionIndice;

    // If there is an answer, use it.
    // Otherwise use local state
    const c = {
      [styles.selected]: false,
      [styles.notSelected]: false,
    };
    let selected = false;
    if (pollingOptionIndice != null) {
      const i = pollingOptionIndice.indexOf(index);
      c[styles.selected] = i >= 0;
      selected = i >= 0;
      c[styles.notSelected] = i < 0;
    } else {
      const i = this.state.selectedOptionIndice.indexOf(index);
      c[styles.selected] = i >= 0;
      selected = i >= 0;
      c[styles.notSelected] = i < 0;
    }

    const { imageUrl } = option;
    return (
      <Button
        color={selected ? "green" : "gray"}
        colorApplication={selected ? "background" : "border"}
        applyBorderRadius={false}
        className={classnames(styles.pollingOptionItemButton, c)}
        onClick={this.onClick}
        onClickInfo={index}
      >
        <div className={classnames(styles.pollingOptionAlpha, c)}>
          {indexToAlpha(index)}
        </div>
        <div className={styles.pollingOptionContentContainer}>
          {imageUrl == null ? null : (
            <ResponsiveLayout>
              {deviceType => (
                <Image
                  imageUrl={imageUrl}
                  width={deviceType === "desktop" ? 180 : "30%"}
                  placeholderWidth={deviceType === "desktop" ? 180 : "30%"}
                  placeholderHeight={
                    deviceType === "desktop" ? 180 * 0.75 : "22.5%"
                  }
                  className={styles.pollingOptionImage}
                />
              )}
            </ResponsiveLayout>
          )}
          <div className={classnames(styles.pollingOptionText, c)}>
            {option.text}
          </div>
        </div>
      </Button>
    );
  }

  renderOption = (option: PollingOption, index: number) => {
    return (
      <li key={index} className={styles.pollingOptionItem}>
        {this.renderOptionContent(option, index)}
      </li>
    );
  };

  render() {
    const {
      className,
      activity,
      session,
      participationInfo,
      submitAnswerRequest,
      pollingOptions,
    } = this.props;
    const answer = getSubmittedAnswer(session, participationInfo.participantId);
    const submitted = answer != null;
    return (
      <div className={className}>
        <h1 className={styles.activityNameText}>{activity.name}</h1>
        <QuestionView activity={activity} />
        <ol className={styles.pollingOptionList}>
          {pollingOptions.map(this.renderOption)}
        </ol>
        <div className={styles.submitButtonContainer}>
          <SubmitButton
            className={styles.submitButton}
            submitted={submitted}
            loading={isRequesting(submitAnswerRequest)}
            onSubmit={this.onSubmit}
            disabled={this.state.selectedOptionIndice.length <= 0}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    submitAnswerRequest: state.sessions.submitAnswerRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    submitAnswer: bindActionCreators(submitAnswer, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  StudentSessionActivityPolling
): any): Class<React$Component<OwnProps>>);
