// @flow
import React, { PureComponent } from "react";
import ModalContainer from "./ModalContainer";
import AlertModal from "./AlertModal";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import { MAX_CUSTOM_TEMPLATE_NAME_LEN } from "../../types/template";
import styles from "./CreateTemplateModal.module.scss";

type Props = {
  templateDisplayCode: string,
  customTemplateMaxNumber: number,
  defaultValue: string,
  onCreate: (value: string) => void,
  onClose: Event => void,
};

type LocalState = {
  value: string,
  showErrorLabel: boolean,
};

export default class CreateTemplateModal extends PureComponent<
  Props,
  LocalState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.defaultValue,
      showErrorLabel: false,
    };
  }

  onCreate = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();

    const { value } = this.state;
    const { onCreate } = this.props;

    if (value.trim().length > 0) {
      onCreate(value);
    } else {
      this.setState({ showErrorLabel: true });
    }
  };

  onChange = (e: Event) => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      this.setState({
        value: target.value,
        showErrorLabel: false,
      });
    }
  };

  render() {
    const {
      templateDisplayCode,
      onClose,
      customTemplateMaxNumber,
    } = this.props;
    const { value } = this.state;

    return (
      <ModalContainer onClose={onClose}>
        <AlertModal
          colorVariant="blue"
          titleKey="teacher.create_custom_template_modal_title"
          messageKey="teacher.create_custom_template_modal_message"
          messageValues={{
            num: customTemplateMaxNumber,
          }}
          buttonKey="teacher.create_custom_template_modal_button_title"
          buttonClassName={styles.customTemplateModalButton}
          onButtonClick={this.onCreate}
          onClose={onClose}
          isForm
        >
          <div className={styles.createCustomTemplateModalRow}>
            <p className={styles.modalRowDisplayCode}>{templateDisplayCode}</p>
            <div className={styles.textInputWrapper}>
              <InputWithErrorMessage
                placeholderId="teacher.create_custom_template_modal_input_placeholder"
                labelId="teacher.create_custom_template_modal_input_placeholder"
                type="text"
                value={value}
                className={styles.modalRowInput}
                onChange={this.onChange}
                maxLength={MAX_CUSTOM_TEMPLATE_NAME_LEN}
                errorTextKey={
                  this.state.showErrorLabel ? "error.template_name_empty" : null
                }
              />
            </div>
          </div>
        </AlertModal>
      </ModalContainer>
    );
  }
}
