// @flow
import { downloadBlob } from "./blob";

function dataURLToBlob(dataURL: string): Blob {
  // assume dataURL look likes "data:image/png;base64,"
  const encodedBase64 = dataURL.slice("data:image/png;base64,".length);
  const decodedBase64 = atob(encodedBase64);
  const arr = [];
  for (let i = 0; i < decodedBase64.length; ++i) {
    arr.push(decodedBase64.charCodeAt(i));
  }
  const byteArray = new Uint8Array(arr);
  return new Blob([byteArray], { type: "image/png" });
}

export function downloadDataURL(dataURL: string): void {
  const filename = "image.png";
  const blob = dataURLToBlob(dataURL);
  downloadBlob(blob, filename);
}
