// @flow
import React, { PureComponent } from "react";
import Link from "./Link";
import type { Node } from "react";

type Props = {
  +className?: string,
  +templateId: string,
  +children?: Node,
  +disabled: boolean,
};

class ActivityRecordsLink extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  onClick = (e: Event) => {
    if (this.props.disabled) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    return (
      <Link
        to={`/template/${this.props.templateId}/records`}
        altId="teacher.activity_records"
        className={this.props.className}
        onClick={this.onClick}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default ActivityRecordsLink;
