// @flow
import skygear from "skygear";
import {
  SEARCH_AUDIT_TRAILS_REQUESTING,
  SEARCH_AUDIT_TRAILS_SUCCESS,
  SEARCH_AUDIT_TRAILS_FAILURE,
} from "../../types/auditTrail";
import type {
  SearchAuditTrailsRequesting,
  SearchAuditTrailsSuccess,
  SearchAuditTrailsFailure,
} from "../../types/auditTrail";
import type { Dispatch, GetState } from "../../types/store";
import type { PageInfo } from "../../types/request";
import type { AuditTrail, SEARCH_ACTOR } from "../../types/auditTrail";
import {
  deserializeAuditTrail,
  deserializePageInfo,
} from "../../utils/deserializer";
import { SEARCH_ACTOR_SCHOOL } from "../../types/auditTrail";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";
import { jsTimezoneOffsetToISO8601 } from "../../utils/dateTime";

function searchAuditTrailsRequesting(): SearchAuditTrailsRequesting {
  return {
    type: SEARCH_AUDIT_TRAILS_REQUESTING,
  };
}

function searchAuditTrailsSuccess(
  auditTrails: $ReadOnlyArray<AuditTrail>
): SearchAuditTrailsSuccess {
  return {
    type: SEARCH_AUDIT_TRAILS_SUCCESS,
    payload: auditTrails,
  };
}

function searchAuditTrailsFailure(error: mixed): SearchAuditTrailsFailure {
  return {
    type: SEARCH_AUDIT_TRAILS_FAILURE,
    payload: error,
  };
}

export function searchAuditTrails(data: {
  actor: SEARCH_ACTOR,
  schoolId: ?string,
  fromDate: Date,
  toDate: Date,
  pageSize: number,
  pageNumber: number,
}) {
  const { actor, schoolId, fromDate, toDate, pageSize, pageNumber } = data;
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{
    auditTrails: $ReadOnlyArray<AuditTrail>,
    pageInfo: PageInfo,
  }> => {
    const currentState = getState();
    if (isRequesting(currentState.auditTrails.searchAuditTrailsRequest)) {
      return Promise.reject(ongoingRequestError);
    }
    dispatch(searchAuditTrailsRequesting());
    const iso8601TimezoneOffset = jsTimezoneOffsetToISO8601(
      fromDate.getTimezoneOffset()
    );
    const args = {
      actor,
      school_id: actor === SEARCH_ACTOR_SCHOOL ? schoolId : null,
      from_date: fromDate.toISOString(),
      to_date: toDate.toISOString(),
      timezone_offset: iso8601TimezoneOffset,
      page_args: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    };

    return skygear
      .lambda("polyupaths:audit_trail:search", [args])
      .then(result => {
        const auditTrails = result.audit_trails.map(deserializeAuditTrail);
        const pageInfo = deserializePageInfo(result.page_info);
        dispatch(searchAuditTrailsSuccess(auditTrails));
        return { auditTrails, pageInfo };
      })
      .catch(error => {
        dispatch(searchAuditTrailsFailure(error));
        return Promise.reject(error);
      });
  };
}
