// @flow
import React, { PureComponent } from "react";
import { Editor, EditorState } from "draft-js";
import { convertFromHTML } from "draft-convert";
import styles from "./RichContent.module.scss";

type Props = {
  rawContentString: string,
};

type LocalState = {
  editorState: EditorState,
};

class RichContent extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = { editorState: this.genEditorState(props.rawContentString) };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.rawContentString !== nextProps.rawContentString) {
      this.setState({
        editorState: this.genEditorState(nextProps.rawContentString),
      });
    }
  }

  genEditorState(rawContentString: string): EditorState {
    const contentState = convertFromHTML(rawContentString);
    return EditorState.createWithContent(contentState);
  }

  render() {
    return (
      <div className={styles.richContentContainer}>
        <Editor editorState={this.state.editorState} readOnly />
      </div>
    );
  }
}

export default RichContent;
