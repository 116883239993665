// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import Page from "./Page";
import styles from "./Pagination.module.scss";

// <= 10 pages: render all pages
// > 10 pages:
//   1. current page <= 5: [1], 2, 3, 4, 5, ..., last
//   2. current page > 5 and not not last 5 pages: 1, ..., 4, 5, [6], 7, 8, ..., last
//      keeps current page at the center
//   3. current page is one of last 5 pages: 1, ..., last-4, last-3, last-2, last-1, [last]

const MAX_PAGES = 10;
const PRIMARY_PAGE_SIZE = 5;

type Props = {
  +currentPage: number,
  +totalPage: number,
  +onPageChange: (pageIdx: number) => void,
};

function range(start, end) {
  const n = end - start;
  if (n <= 0) {
    return [];
  }
  return [...Array(n)].map((_, i) => start + i);
}

class Pagination extends PureComponent<Props> {
  onClickPrev = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onPageChange(this.props.currentPage - 1);
  };

  onClickNext = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onPageChange(this.props.currentPage + 1);
  };

  onClickPage = (e: Event, pageIdx: number) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onPageChange(pageIdx);
  };

  renderEllipsis() {
    return <span className={styles.ellipsis}>…</span>;
  }

  renderAllPages() {
    const { currentPage, totalPage } = this.props;
    const pages = range(1, totalPage + 1);
    return (
      <Fragment>
        {pages.map(page => (
          <Page
            key={page}
            pageIdx={page}
            isCurrentPage={page === currentPage}
            onClickPage={this.onClickPage}
          />
        ))}
      </Fragment>
    );
  }

  renderPrimaryPagesAtHead() {
    const { currentPage, totalPage } = this.props;
    const pages = range(1, PRIMARY_PAGE_SIZE + 1);
    return (
      <Fragment>
        {pages.map(page => (
          <Page
            key={page}
            pageIdx={page}
            isCurrentPage={page === currentPage}
            onClickPage={this.onClickPage}
          />
        ))}
        {this.renderEllipsis()}
        <Page
          key={totalPage}
          pageIdx={totalPage}
          isCurrentPage={false}
          onClickPage={this.onClickPage}
        />
      </Fragment>
    );
  }

  renderPrimaryPagesAtTail() {
    const { currentPage, totalPage } = this.props;
    const pages = range(totalPage - PRIMARY_PAGE_SIZE + 1, totalPage + 1);
    return (
      <Fragment>
        <Page
          key={1}
          pageIdx={1}
          isCurrentPage={false}
          onClickPage={this.onClickPage}
        />
        {this.renderEllipsis()}
        {pages.map(page => (
          <Page
            key={page}
            pageIdx={page}
            isCurrentPage={page === currentPage}
            onClickPage={this.onClickPage}
          />
        ))}
      </Fragment>
    );
  }

  renderPrimaryPagesAtMiddle() {
    const { currentPage, totalPage } = this.props;
    const pages = range(currentPage - 2, currentPage + 3);
    return (
      <Fragment>
        <Page
          key={1}
          pageIdx={1}
          isCurrentPage={false}
          onClickPage={this.onClickPage}
        />
        {this.renderEllipsis()}
        {pages.map(page => (
          <Page
            key={page}
            pageIdx={page}
            isCurrentPage={page === currentPage}
            onClickPage={this.onClickPage}
          />
        ))}
        {this.renderEllipsis()}
        <Page
          key={totalPage}
          pageIdx={totalPage}
          isCurrentPage={false}
          onClickPage={this.onClickPage}
        />
      </Fragment>
    );
  }

  renderSomePages() {
    const { currentPage, totalPage } = this.props;

    if (currentPage <= PRIMARY_PAGE_SIZE) {
      return this.renderPrimaryPagesAtHead();
    }

    if (currentPage >= totalPage - PRIMARY_PAGE_SIZE + 1) {
      return this.renderPrimaryPagesAtTail();
    }

    return this.renderPrimaryPagesAtMiddle();
  }

  render() {
    const { currentPage, totalPage } = this.props;
    const prevDisabled = currentPage === 1 || totalPage === 0;
    const nextDisabled = currentPage === totalPage || totalPage === 0;
    return (
      <div className={styles.container}>
        <div className={styles.pagesContainer}>
          <button
            className={classnames(styles.pageNav, {
              [styles.disabled]: prevDisabled,
            })}
            disabled={prevDisabled}
            onClick={currentPage === 1 ? null : this.onClickPrev}
          >
            <Text translationKey="admin.pagination.prev_page" />
          </button>
          {totalPage <= MAX_PAGES && this.renderAllPages()}
          {totalPage > MAX_PAGES && this.renderSomePages()}
          <button
            className={classnames(styles.pageNav, {
              [styles.disabled]: nextDisabled,
            })}
            disabled={nextDisabled}
            onClick={currentPage === totalPage ? null : this.onClickNext}
          >
            <Text translationKey="admin.pagination.next_page" />
          </button>
        </div>
      </div>
    );
  }
}

export default Pagination;
