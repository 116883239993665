// @flow
import type {
  Activity,
  ActivityResponse,
  ActivityState,
  ActivityStateResponse,
  Sticker,
  StickerResponse,
} from "./activity";
import type { TEMPLATE_YEAR, TEMPLATE_LOCALE } from "./template";
import type { RequestState } from "./request";

export type SessionState = "created" | "started" | "ended";

export type SessionMode = "live" | "test";

export type Participant = {|
  +id: string,
  +name: string,
|};

export type Session = {|
  +id: string,
  +code: string,
  +templateId: string,
  +templateBaseId: string,
  +templateDisplayCode: string,
  +templateYear: TEMPLATE_YEAR,
  +templateName: string,
  +templateLang: TEMPLATE_LOCALE,
  +teacherName: string,
  +schoolID: string,
  +schoolEngName: string,
  +activities: $ReadOnlyArray<Activity>,
  +defaultStickerList: $ReadOnlyArray<Sticker>,
  +state: SessionState,
  +isPaused: boolean,
  +mode: SessionMode,
  +createdAt: Date,
  +startedAt: ?Date,
  +pausedAt: ?Date,
  +endedAt: ?Date,
  +participants: $ReadOnlyArray<Participant>,
  +currentActivityIndex: number,
  +activityStates: $ReadOnlyArray<ActivityState>,
|};

export type SessionAndParticipant = {|
  +session: Session,
  +participant: Participant,
|};

export type ParticipationInfo = {
  sessionId: string,
  participationCode: string,
  participantId: string,
  participantName: string,
};

export type PreviewSessionInfo = {
  templateName: string,
  templateDisplayCode: string,
  templateLang: TEMPLATE_LOCALE,
  activities: $ReadOnlyArray<Activity>,
};

export type ParticipantResponse = {|
  +_id: string,
  +name: string,
|};

export type SessionResponse = {|
  +_id: string,
  +code: string,
  +template_id: string,
  +template_base_id: string,
  +template_name: string,
  +template_display_code: string,
  +template_year: TEMPLATE_YEAR,
  +template_lang: TEMPLATE_LOCALE,
  +school_id: string,
  +school_eng_name: string,
  +teacher_name: string,
  +activities: $ReadOnlyArray<ActivityResponse>,
  +default_sticker_list: $ReadOnlyArray<StickerResponse>,
  +state: SessionState,
  +is_paused: boolean,
  +mode: SessionMode,
  +created_at: string,
  +started_at: ?string,
  +paused_at: ?string,
  +ended_at: ?string,
  +participants: $ReadOnlyArray<ParticipantResponse>,
  +current_activity_index: number,
  +activity_states: $ReadOnlyArray<ActivityStateResponse>,
|};

export type CreateSessionPayload = {|
  +mode: SessionMode,
  +templateId: string,
  +teacherName: string,
|};

export type UnitTemplateOptionResponse = {|
  +_id: string,
  +display_code: string,
  +name: string,
|};

export type UnitTemplateOption = {|
  +id: string,
  +displayCode: string,
  +name: string,
|};

export type SchoolOptionResponse = {|
  +_id: string,
  +eng_name: string,
|};

export type SchoolOption = {|
  +id: string,
  +engName: string,
|};

export type ActivityRecordOptionResponse = {|
  +_id: string,
  +teacher_name: string,
  +created_at: string,
|};

export type ActivityRecordOption = {|
  +id: string,
  +teacherName: string,
  +createdAt: Date,
|};

export type SessionsState = {
  +sessionById: { [id: string]: Session },
  +templateOptions: ?$ReadOnlyArray<UnitTemplateOption>,
  +schoolOptions: ?$ReadOnlyArray<SchoolOption>,
  +activeSessionIds: $ReadOnlyArray<string>,
  +recordsByTemplateId: { [id: string]: $ReadOnlyArray<Session> },
  +activeSessionsRequest: ?RequestState<void>,
  +createSessionRequest: ?RequestState<void>,
  +pauseOrUnpauseSessionRequest: ?RequestState<void>,
  +startSessionRequest: ?RequestState<void>,
  +endSessionRequest: ?RequestState<void>,
  +joinSessionRequest: ?RequestState<void>,
  +seekActivityRequest: ?RequestState<void>,
  +submitAnswerRequest: ?RequestState<void>,
  +deleteAnswerRequest: ?RequestState<void>,
  +resetActivityRequest: ?RequestState<void>,
  +endActivityRequest: ?RequestState<void>,
  +singleSessionRequest: ?RequestState<void>,
  +fetchActivityRecordsRequest: ?RequestState<void>,
  +fetchSelectOptionsRequest: ?RequestState<void>,
  +searchActivityRecordsRequest: ?RequestState<void>,
};

export const CREATE_SESSION_REQUESTING: "SESSION.CREATE.REQUESTING" =
  "SESSION.CREATE.REQUESTING";
export const CREATE_SESSION_SUCCESS: "SESSION.CREATE.SUCCESS" =
  "SESSION.CREATE.SUCCESS";
export const CREATE_SESSION_FAILURE: "SESSION.CREATE.FAILURE" =
  "SESSION.CREATE.FAILURE";

export type CreateSessionRequesting = {|
  +type: typeof CREATE_SESSION_REQUESTING,
|};
export type CreateSessionSuccess = {|
  +type: typeof CREATE_SESSION_SUCCESS,
  +payload: Session,
|};
export type CreateSessionFailure = {|
  +type: typeof CREATE_SESSION_FAILURE,
  +payload: mixed,
|};

export const PAUSE_SESSION_REQUESTING: "SESSION.PAUSE.REQUESTING" =
  "SESSION.PAUSE.REQUESTING";
export const PAUSE_SESSION_SUCCESS: "SESSION.PAUSE.SUCCESS" =
  "SESSION.PAUSE.SUCCESS";
export const PAUSE_SESSION_FAILURE: "SESSION.PAUSE.FAILURE" =
  "SESSION.PAUSE.FAILURE";

export type PauseSessionRequesting = {|
  +type: typeof PAUSE_SESSION_REQUESTING,
|};
export type PauseSessionSuccess = {|
  +type: typeof PAUSE_SESSION_SUCCESS,
  +payload: Session,
|};
export type PauseSessionFailure = {|
  +type: typeof PAUSE_SESSION_FAILURE,
  +payload: mixed,
|};

export const UNPAUSE_SESSION_REQUESTING: "SESSION.UNPAUSE.REQUESTING" =
  "SESSION.UNPAUSE.REQUESTING";
export const UNPAUSE_SESSION_SUCCESS: "SESSION.UNPAUSE.SUCCESS" =
  "SESSION.UNPAUSE.SUCCESS";
export const UNPAUSE_SESSION_FAILURE: "SESSION.UNPAUSE.FAILURE" =
  "SESSION.UNPAUSE.FAILURE";

export type UnpauseSessionRequesting = {|
  +type: typeof UNPAUSE_SESSION_REQUESTING,
|};
export type UnpauseSessionSuccess = {|
  +type: typeof UNPAUSE_SESSION_SUCCESS,
  +payload: Session,
|};
export type UnpauseSessionFailure = {|
  +type: typeof UNPAUSE_SESSION_FAILURE,
  +payload: mixed,
|};

export const START_SESSION_REQUESTING: "SESSION.START.REQUESTING" =
  "SESSION.START.REQUESTING";
export const START_SESSION_SUCCESS: "SESSION.START.SUCCESS" =
  "SESSION.START.SUCCESS";
export const START_SESSION_FAILURE: "SESSION.START.FAILURE" =
  "SESSION.START.FAILURE";

export type StartSessionRequesting = {|
  +type: typeof START_SESSION_REQUESTING,
|};
export type StartSessionSuccess = {|
  +type: typeof START_SESSION_SUCCESS,
  +payload: Session,
|};
export type StartSessionFailure = {|
  +type: typeof START_SESSION_FAILURE,
  +payload: mixed,
|};

export const END_SESSION_REQUESTING: "END_SESSION_REQUESTING" =
  "END_SESSION_REQUESTING";
export const END_SESSION_SUCCESS: "END_SESSION_SUCCESS" = "END_SESSION_SUCCESS";
export const END_SESSION_FAILURE: "END_SESSION_FAILURE" = "END_SESSION_FAILURE";

export type EndSessionRequesting = {|
  +type: typeof END_SESSION_REQUESTING,
|};
export type EndSessionSuccess = {|
  +type: typeof END_SESSION_SUCCESS,
  +payload: Session,
|};
export type EndSessionFailure = {|
  +type: typeof END_SESSION_FAILURE,
  +payload: mixed,
|};

export const JOIN_SESSION_REQUESTING: "SESSION.JOIN.REQUESTING" =
  "SESSION.JOIN.REQUESTING";
export const JOIN_SESSION_SUCCESS: "SESSION.JOIN.SUCCESS" =
  "SESSION.JOIN.SUCCESS";
export const JOIN_SESSION_FAILURE: "SESSION.JOIN.FAILURE" =
  "SESSION.JOIN.FAILURE";

export type JoinSessionRequesting = {|
  +type: typeof JOIN_SESSION_REQUESTING,
|};
export type JoinSessionSuccess = {|
  +type: typeof JOIN_SESSION_SUCCESS,
  +payload: SessionAndParticipant,
|};
export type JoinSessionFailure = {|
  +type: typeof JOIN_SESSION_FAILURE,
  +payload: mixed,
|};

export const SEEK_ACTIVITY_REQUESTING: "SESSION.SEEK_ACTIVITY.REQUESTING" =
  "SESSION.SEEK_ACTIVITY.REQUESTING";
export const SEEK_ACTIVITY_SUCCESS: "SESSION.SEEK_ACTIVITY.SUCCESS" =
  "SESSION.SEEK_ACTIVITY.SUCCESS";
export const SEEK_ACTIVITY_FAILURE: "SESSION.SEEK_ACTIVITY.FAILURE" =
  "SESSION.SEEK_ACTIVITY.FAILURE";

export type SeekActivityRequesting = {|
  +type: typeof SEEK_ACTIVITY_REQUESTING,
|};
export type SeekActivitySuccess = {|
  +type: typeof SEEK_ACTIVITY_SUCCESS,
  +payload: Session,
|};
export type SeekActivityFailure = {|
  +type: typeof SEEK_ACTIVITY_FAILURE,
  +payload: mixed,
|};

export const RESET_ACTIVITY_REQUESTING: "SESSION.RESET_ACTIVITY.REQUESTING" =
  "SESSION.RESET_ACTIVITY.REQUESTING";
export const RESET_ACTIVITY_SUCCESS: "SESSION.RESET_ACTIVITY.SUCCESS" =
  "SESSION.RESET_ACTIVITY.SUCCESS";
export const RESET_ACTIVITY_FAILURE: "SESSION.RESET_ACTIVITY.FAILURE" =
  "SESSION.RESET_ACTIVITY.FAILURE";

export type ResetActivityRequesting = {|
  +type: typeof RESET_ACTIVITY_REQUESTING,
|};
export type ResetActivitySuccess = {|
  +type: typeof RESET_ACTIVITY_SUCCESS,
  +payload: Session,
|};
export type ResetActivityFailure = {|
  +type: typeof RESET_ACTIVITY_FAILURE,
  +payload: mixed,
|};

export const END_ACTIVITY_REQUESTING: "END_ACTIVITY_REQUESTING" =
  "END_ACTIVITY_REQUESTING";
export const END_ACTIVITY_SUCCESS: "END_ACTIVITY_SUCCESS" =
  "END_ACTIVITY_SUCCESS";
export const END_ACTIVITY_FAILURE: "END_ACTIVITY_FAILURE" =
  "END_ACTIVITY_FAILURE";

export type EndActivityRequesting = {|
  +type: typeof END_ACTIVITY_REQUESTING,
|};
export type EndActivitySuccess = {|
  +type: typeof END_ACTIVITY_SUCCESS,
  +payload: Session,
|};
export type EndActivityFailure = {|
  +type: typeof END_ACTIVITY_FAILURE,
  +payload: mixed,
|};

export const QUERY_ACTIVE_SESSIONS_REQUESTING: "SESSION.QUERY_ACTIVE.REQUESTING" =
  "SESSION.QUERY_ACTIVE.REQUESTING";
export const QUERY_ACTIVE_SESSIONS_SUCCESS: "SESSION.QUERY_ACTIVE.SUCCESS" =
  "SESSION.QUERY_ACTIVE.SUCCESS";
export const QUERY_ACTIVE_SESSIONS_FAILURE: "SESSION.QUERY_ACTIVE.FAILURE" =
  "SESSION.QUERY_ACTIVE.FAILURE";

export type QueryActiveSessionsRequesting = {|
  +type: typeof QUERY_ACTIVE_SESSIONS_REQUESTING,
|};
export type QueryActiveSessionsSuccess = {|
  +type: typeof QUERY_ACTIVE_SESSIONS_SUCCESS,
  +payload: $ReadOnlyArray<Session>,
|};
export type QueryActiveSessionsFailure = {|
  +type: typeof QUERY_ACTIVE_SESSIONS_FAILURE,
  +payload: mixed,
|};

export const SUBMIT_ANSWER_REQUESTING: "SUBMIT_ANSWER_REQUESTING" =
  "SUBMIT_ANSWER_REQUESTING";
export const SUBMIT_ANSWER_SUCCESS: "SUBMIT_ANSWER_SUCCESS" =
  "SUBMIT_ANSWER_SUCCESS";
export const SUBMIT_ANSWER_FAILURE: "SUBMIT_ANSWER_FAILURE" =
  "SUBMIT_ANSWER_FAILURE";

export type SubmitAnswerRequesting = {|
  +type: typeof SUBMIT_ANSWER_REQUESTING,
|};
export type SubmitAnswerSuccess = {|
  +type: typeof SUBMIT_ANSWER_SUCCESS,
  +payload: Session,
|};
export type SubmitAnswerFailure = {|
  +type: typeof SUBMIT_ANSWER_FAILURE,
  +payload: mixed,
|};

export const DELETE_ANSWER_REQUESTING: "DELETE_ANSWER_REQUESTING" =
  "DELETE_ANSWER_REQUESTING";
export const DELETE_ANSWER_SUCCESS: "DELETE_ANSWER_SUCCESS" =
  "DELETE_ANSWER_SUCCESS";
export const DELETE_ANSWER_FAILURE: "DELETE_ANSWER_FAILURE" =
  "DELETE_ANSWER_FAILURE";

export type DeleteAnswerRequesting = {|
  +type: typeof DELETE_ANSWER_REQUESTING,
|};
export type DeleteAnswerSuccess = {|
  +type: typeof DELETE_ANSWER_SUCCESS,
  +payload: Session,
|};
export type DeleteAnswerFailure = {|
  +type: typeof DELETE_ANSWER_FAILURE,
  +payload: mixed,
|};

export const QUERY_SINGLE_SESSION_REQUESTING: "SESSION.QUERY_SINGLE.REQUESTING" =
  "SESSION.QUERY_SINGLE.REQUESTING";
export const QUERY_SINGLE_SESSION_SUCCESS: "SESSION.QUERY_SINGLE.SUCCESS" =
  "SESSION.QUERY_SINGLE.SUCCESS";
export const QUERY_SINGLE_SESSION_FAILURE: "SESSION.QUERY_SINGLE.FAILURE" =
  "SESSION.QUERY_SINGLE.FAILURE";

export type QuerySingleSessionRequesting = {|
  +type: typeof QUERY_SINGLE_SESSION_REQUESTING,
|};
export type QuerySingleSessionSuccess = {|
  +type: typeof QUERY_SINGLE_SESSION_SUCCESS,
  +payload: Session,
|};
export type QuerySingleSessionFailure = {|
  +type: typeof QUERY_SINGLE_SESSION_FAILURE,
  +payload: mixed,
|};

export const SYNC_SESSION_FROM_PUBSUB: "SESSION.SYNC_FROM_PUBSUB" =
  "SESSION.SYNC_FROM_PUBSUB";

export type SyncSessionFromPubsub = {|
  +type: typeof SYNC_SESSION_FROM_PUBSUB,
  +payload: Session,
|};

export const FETCH_ACTIVITY_RECORDS_REQUESTING: "SESSION.FETCH_ACTIVITY_RECORDS.REQUESTING" =
  "SESSION.FETCH_ACTIVITY_RECORDS.REQUESTING";
export const FETCH_ACTIVITY_RECORDS_SUCCESS: "SESSION.FETCH_ACTIVITY_RECORDS.SUCCESS" =
  "SESSION.FETCH_ACTIVITY_RECORDS.SUCCESS";
export const FETCH_ACTIVITY_RECORDS_FAILURE: "SESSION.FETCH_ACTIVITY_RECORDS.FAILURE" =
  "SESSION.FETCH_ACTIVITY_RECORDS.FAILURE";

export type FetchActivityRecordsRequesting = {|
  +type: typeof FETCH_ACTIVITY_RECORDS_REQUESTING,
|};
export type FetchActivityRecordsSuccess = {|
  +type: typeof FETCH_ACTIVITY_RECORDS_SUCCESS,
  +payload: {
    templateId: string,
    records: $ReadOnlyArray<Session>,
  },
|};
export type FetchActivityRecordsFailure = {|
  +type: typeof FETCH_ACTIVITY_RECORDS_FAILURE,
  +payload: mixed,
|};

export const FETCH_SELECT_OPTIONS_REQUESTING: "FETCH_SELECT_OPTIONS_REQUESTING" =
  "FETCH_SELECT_OPTIONS_REQUESTING";
export const FETCH_SELECT_OPTIONS_SUCCESS: "FETCH_SELECT_OPTIONS_SUCCESS" =
  "FETCH_SELECT_OPTIONS_SUCCESS";
export const FETCH_SELECT_OPTIONS_FAILURE: "FETCH_SELECT_OPTIONS_FAILURE" =
  "FETCH_SELECT_OPTIONS_FAILURE";

export type FetchSelectOptionsRequesting = {|
  +type: typeof FETCH_SELECT_OPTIONS_REQUESTING,
|};
export type FetchSelectOptionsSuccess = {|
  +type: typeof FETCH_SELECT_OPTIONS_SUCCESS,
  +payload: {
    templateOptions: $ReadOnlyArray<UnitTemplateOption>,
    schoolOptions: $ReadOnlyArray<SchoolOption>,
  },
|};
export type FetchSelectOptionsFailure = {|
  +type: typeof FETCH_SELECT_OPTIONS_FAILURE,
  +payload: mixed,
|};

export const SEARCH_ACTIVITY_RECORDS_REQUESTING: "SEARCH_ACTIVITY_RECORDS_REQUESTING" =
  "SEARCH_ACTIVITY_RECORDS_REQUESTING";
export const SEARCH_ACTIVITY_RECORDS_SUCCESS: "SEARCH_ACTIVITY_RECORDS_SUCCESS" =
  "SEARCH_ACTIVITY_RECORDS_SUCCESS";
export const SEARCH_ACTIVITY_RECORDS_FAILURE: "SEARCH_ACTIVITY_RECORDS_FAILURE" =
  "SEARCH_ACTIVITY_RECORDS_FAILURE";

export type SearchActivityRecordsRequesting = {|
  +type: typeof SEARCH_ACTIVITY_RECORDS_REQUESTING,
|};
export type SearchActivityRecordsSuccess = {|
  +type: typeof SEARCH_ACTIVITY_RECORDS_SUCCESS,
  +payload: $ReadOnlyArray<Session>,
|};
export type SearchActivityRecordsFailure = {|
  +type: typeof SEARCH_ACTIVITY_RECORDS_FAILURE,
  +payload: mixed,
|};
