// @flow
import React, { PureComponent } from "react";
import FormattedDate from "../FormattedDate/FormattedDate";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import IconButton from "../IconButton/IconButton";
import Button from "../Button/Button";
import Text from "../Text/Text";
import downloadIcon from "../../images/download_orange.svg";
import moreIcon from "../../images/template_item_more.svg";
import trashCanIcon from "../../images/trash_can.svg";
import styles from "./AdminSessionItem.module.scss";

type Props = {
  sessionID: string,
  date: Date,
  templateDisplayCode: string,
  templateName: string,
  schoolName: string,
  participantCount: number,
  onDownloadRecordButtonClick: (sessionID: string) => void,
  onDeleteRecordButtonClick: (sessionID: string) => void,
};

type LocalState = {
  isPopupMenuOpen: boolean,
};

export default class AdminSessionItem extends PureComponent<Props, LocalState> {
  state = {
    isPopupMenuOpen: false,
  };

  onClickMoreActionsButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      isPopupMenuOpen: !prevState.isPopupMenuOpen,
    }));
  };

  onClickMoreActionsOutside = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isPopupMenuOpen: false });
  };

  onDownloadRecordButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isPopupMenuOpen: false });
    this.props.onDownloadRecordButtonClick(this.props.sessionID);
  };

  onDeleteRecordButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isPopupMenuOpen: false });
    this.props.onDeleteRecordButtonClick(this.props.sessionID);
  };

  renderViewButton() {
    return (
      <Button
        className={styles.viewButton}
        colorApplication="border"
        color="gray"
      >
        <span className={styles.viewButtonText}>
          <Text translationKey="admin.activity_record.button.view_record" />
        </span>
      </Button>
    );
  }

  renderMoreActionsButton() {
    const { isPopupMenuOpen } = this.state;
    return (
      <Button
        styled={false}
        color={null}
        className={styles.moreButton}
        onClick={this.onClickMoreActionsButton}
        disabled={isPopupMenuOpen}
      >
        <img
          src={moreIcon}
          className={styles.moreButtonIcon}
          alt="button form more"
        />
      </Button>
    );
  }

  renderPopupMenu() {
    const { isPopupMenuOpen } = this.state;
    if (!isPopupMenuOpen) {
      return null;
    }
    return (
      <PopupWrapper onClickOutside={this.onClickMoreActionsOutside}>
        <div className={styles.moreActionsPopupMenu}>
          <IconButton
            className={styles.downloadRecordButton}
            icon={downloadIcon}
            labelTranslationKey="admin.activity_record.button.download_record"
            onClick={this.onDownloadRecordButtonClick}
          />
          <IconButton
            className={styles.deleteRecordButton}
            icon={trashCanIcon}
            labelTranslationKey="admin.activity_record.button.delete_record"
            onClick={this.onDeleteRecordButtonClick}
          />
        </div>
      </PopupWrapper>
    );
  }

  render() {
    return (
      <div className={styles.rowContainer}>
        <p className={styles.dateTimeText}>
          <FormattedDate
            value={this.props.date}
            year="numeric"
            month="numeric"
            day="numeric"
            hour12={false}
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
          />
        </p>
        <p className={styles.templateCodeText}>
          {this.props.templateDisplayCode}
        </p>
        <p className={styles.templateNameText}>{this.props.templateName}</p>
        <p className={styles.schoolNameText}>{this.props.schoolName}</p>
        <p className={styles.participantCountText}>
          {String(this.props.participantCount)}
        </p>
        {this.renderViewButton()}
        {this.renderMoreActionsButton()}
        {this.renderPopupMenu()}
      </div>
    );
  }
}
