// @flow
import type { ErrorCode, OngoingRequestError } from "../types/error";
import {
  ongoingRequestError,
  ErrNetworkDown,
  ErrUserDuplicated,
  ErrUserNotFound,
  ErrIncorrectCredentials,
  ErrUserDisabled,
  ErrCaptchaInvalid,
  ErrCustomTemplate,
  ErrTeachingSessionError,
  ErrIllegalSessionStateError,
  ErrSessionNotFoundError,
  ErrNamelessParticipantError,
  ErrNotAParticipantError,
  ErrIllegalAnswerError,
  ErrAnswerAlreadySubmittedError,
  ErrPermissionDeniedError,
  ErrSchoolAccountError,
  ErrAdminUserError,
  ErrEmailInUseError,
  ErrExportError,
  ErrAuditTrailError,
  ErrSysConfError,
  ErrDeleteTemplateError,
} from "../types/error";

export const staticIncorrectCredentialsError = {
  error: {
    code: ErrIncorrectCredentials,
  },
};

export function interpretError(
  error: mixed
): OngoingRequestError | ErrorCode | null {
  if (error === ongoingRequestError) {
    return ongoingRequestError;
  }
  if (
    error != null &&
    error.error != null &&
    typeof error.error.code === "number"
  ) {
    switch (error.error.code) {
      case ErrUserDuplicated:
        return ErrUserDuplicated;
      case ErrUserNotFound:
        return ErrUserNotFound;
      case ErrIncorrectCredentials:
        return ErrIncorrectCredentials;
      case ErrUserDisabled:
        return ErrUserDisabled;
      case ErrCaptchaInvalid:
        return ErrCaptchaInvalid;
      case ErrCustomTemplate:
        return ErrCustomTemplate;
      case ErrTeachingSessionError:
        return ErrTeachingSessionError;
      case ErrIllegalSessionStateError:
        return ErrIllegalSessionStateError;
      case ErrSessionNotFoundError:
        return ErrSessionNotFoundError;
      case ErrNamelessParticipantError:
        return ErrNamelessParticipantError;
      case ErrNotAParticipantError:
        return ErrNotAParticipantError;
      case ErrIllegalAnswerError:
        return ErrIllegalAnswerError;
      case ErrAnswerAlreadySubmittedError:
        return ErrAnswerAlreadySubmittedError;
      case ErrPermissionDeniedError:
        return ErrPermissionDeniedError;
      case ErrSchoolAccountError:
        return ErrSchoolAccountError;
      case ErrAdminUserError:
        return ErrAdminUserError;
      case ErrEmailInUseError:
        return ErrEmailInUseError;
      case ErrExportError:
        return ErrExportError;
      case ErrAuditTrailError:
        return ErrAuditTrailError;
      case ErrSysConfError:
        return ErrSysConfError;
      case ErrDeleteTemplateError:
        return ErrDeleteTemplateError;
      default:
        return null;
    }
  }
  return ErrNetworkDown;
}
