// @flow
import React, { PureComponent } from "react";
import ModalContainer from "./ModalContainer";
import Text from "../Text/Text";
import Button from "../Button/Button";
import closeIcon from "../../images/modal_close.svg";
import ResponsiveLayout from "../ResponsiveLayout";
import styles from "./BrowserNotSupportModal.module.scss";

type Props = {
  onClose: Event => void,
};

type Browser = {|
  +name: string,
  +version: string,
|};

const browsers: $ReadOnlyArray<Browser> = [
  { name: "Microsoft Edge", version: "Updated version" },
  { name: "Internet Explorer", version: "Updated 10-11" },
  { name: "Chrome", version: "Updated version" },
  { name: "Firefox", version: "Updated version" },
  { name: "Safari in Mac OS", version: "Updated version" },
];

export default class BrowserNotSupportModal extends PureComponent<Props> {
  onClickModal = (e: Event) => {
    e.stopPropagation();
  };

  render() {
    return (
      <ModalContainer onClose={this.props.onClose}>
        <div className={styles.alertContainer} onClick={this.onClickModal}>
          <div className={styles.leftContainer} />
          <div className={styles.rightContainer}>
            <p className={styles.title}>
              <Text translationKey="error.browser_not_support" />
            </p>
            <p className={styles.message}>
              <ResponsiveLayout>
                {deviceType => (
                  <Text
                    translationKey={
                      deviceType === "desktop"
                        ? "error.please_update_browser"
                        : "error.please_update_browser_2"
                    }
                  />
                )}
              </ResponsiveLayout>
            </p>
            <ul className={styles.browserList}>
              {browsers.map(b => (
                <li key={b.name}>
                  {b.name}{" "}
                  <span className={styles.browserVersion}>({b.version})</span>
                </li>
              ))}
            </ul>
            <Button
              color="red"
              className={styles.button}
              onClick={this.props.onClose}
            >
              <Text translationKey="phrase.i_see" />
            </Button>
            <button className={styles.closeButton} onClick={this.props.onClose}>
              <img className={styles.closeIcon} src={closeIcon} alt="close" />
            </button>
          </div>
        </div>
      </ModalContainer>
    );
  }
}
