// @flow

export function performCaptcha(
  appID: string
): Promise<{ ticket: string, randstr: string }> {
  return new Promise((resolve, reject) => {
    const captcha = new window.TencentCaptcha(appID, res => {
      if (res.ret !== 0) {
        reject();
      } else {
        resolve({
          ticket: res.ticket,
          randstr: res.randstr,
        });
      }
    });
    captcha.show();
  });
}
