// @flow

export function isDateEqual(a: ?Date, b: ?Date): boolean {
  return a == null
    ? b == null ? true : false
    : b == null ? false : a.getTime() === b.getTime();
}

export function isLessThanOrEqualToToday(date: Date): boolean {
  const now = new Date();
  return date.getTime() <= now.getTime();
}

export function isLessThanOrEqualWithoutTime(a: Date, b: Date): boolean {
  const ta = Math.floor(a.getTime() / (86400 * 1000));
  const tb = Math.floor(b.getTime() / (86400 * 1000));
  return ta <= tb;
}

export function toQueryString(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function parseFromQueryString(s: string): ?Date {
  const parts = s.split("-");
  if (parts.length !== 3) {
    return null;
  }
  const y = parseInt(parts[0], 10);
  const m = parseInt(parts[1], 10);
  const d = parseInt(parts[2], 10);
  if (isNaN(y) || isNaN(m) || isNaN(d)) {
    return null;
  }
  return new Date(y, m - 1, d);
}
