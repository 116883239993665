// @flow
import React, { PureComponent } from "react";
import ActivityPanelRenderer from "./ActivityPanelRenderer";
import type { CommonProps } from "./types/ActivityPanelProps";

class ActivityQAPanel extends PureComponent<CommonProps> {
  render() {
    return <ActivityPanelRenderer {...this.props} />;
  }
}

export default ActivityQAPanel;
