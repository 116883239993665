// @flow
import React, { PureComponent } from "react";
import WindowSize from "./WindowSize";
import type { WindowSizeProps } from "./WindowSize";

type Props = {
  children: React$Node,
};

export default class HtmlBodyRootMinHeight extends PureComponent<Props> {
  syncHeight = (props: WindowSizeProps) => {
    const { height } = props;
    const html = document.documentElement;
    const body = document.body;
    const root = document.getElementById("root");
    const elements = [html, body, root];
    for (const elt of elements) {
      if (elt) {
        elt.style.minHeight = height + "px";
      }
    }
    return this.props.children;
  };

  render() {
    return <WindowSize children={this.syncHeight} />;
  }
}
