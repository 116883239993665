// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import styles from "./LoadingIndicator.module.scss";

type Props = {|
  size: "small" | "normal",
|};

export default class LoadingIndicator extends PureComponent<Props> {
  static defaultProps = {
    size: "normal",
  };

  render() {
    return (
      <div
        className={classnames(styles.indicatorContainer, {
          [styles.small]: this.props.size === "small",
        })}
      >
        <span className={styles.spinner} />
      </div>
    );
  }
}
