// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import RichEditor from "../RichEditor/RichEditor";
import type { Node } from "react";
import type { RecordProps } from "./ActivityRecordType";
import styles from "./ActivityRecordPanelRenderer.module.scss";
import Image from "../Image/Image";
import { QUESTION_DIAGRAM_HEIGHT } from "../../utils/constants";

type LocalProps = {
  +children?: Node,
} & RecordProps;

class ActivityRecordPanelRenderer extends PureComponent<LocalProps> {
  renderBasicInfo() {
    const { activity } = this.props;

    return (
      <div className={styles.activityBasicInfo}>
        <p className={styles.activityName}>{activity.name}</p>
        <p className={styles.activityType}>
          <Text translationKey="teacher.activity_type_label" />
          <Text translationKey={`teacher.activity_type_${activity.type}`} />
        </p>
      </div>
    );
  }

  renderQuestionContent() {
    const { activity, activityIndex } = this.props;

    return (
      <div className={styles.questionContainer}>
        <p className={styles.teacherAsk}>
          <Text translationKey="teacher.teacher_ask" />
        </p>
        <RichEditor
          key={activityIndex}
          initialContentString={activity.question}
          readOnly
        />
        {activity.imageUrl != null && (
          <Image
            imageUrl={activity.imageUrl}
            className={styles.questionDiagram}
            height={QUESTION_DIAGRAM_HEIGHT}
            placeholderWidth={QUESTION_DIAGRAM_HEIGHT * 1.33}
            placeholderHeight={QUESTION_DIAGRAM_HEIGHT}
          />
        )}
      </div>
    );
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.activityContainer}>
        {this.renderBasicInfo()}
        {this.renderQuestionContent()}
        {children}
      </div>
    );
  }
}

export default ActivityRecordPanelRenderer;
