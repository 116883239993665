// @flow
import { EditorState } from "draft-js";
import { convertFromHTML } from "draft-convert";
import { countNumberOfCodePoints } from "./string";

export function calcRichContentLength(content: string) {
  const contentState = convertFromHTML(content);
  const editorState = EditorState.createWithContent(contentState);
  return countNumberOfCodePoints(
    editorState
      .getCurrentContent()
      .getPlainText()
      .trim()
  );
}
