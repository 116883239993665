// @flow
import React, { PureComponent } from "react";
import Color from "color";
import type { Node } from "react";
import classnames from "classnames";
import cssVariables from "../../variables.module.scss";
import styles from "./Button.module.scss";

type ButtonColor =
  | "gray"
  | "gray3"
  | "red"
  | "white"
  | "blue"
  | "pink"
  | "orange"
  | "green";

type Props = {|
  +children?: Node,
  +className: string,
  +type: "button" | "submit",
  +onClick?: (Event, mixed) => void,
  +onClickInfo?: mixed,
  +disabled?: boolean,
  +loading?: boolean,
  +styled: boolean,
  +color: ButtonColor | null,
  +colorApplication: "background" | "border" | "borderAndText",
  +applyBorderRadius: boolean,
  +applyHoverColor: boolean,
  +autoFocus: boolean,
|};

type LocalState = {
  isHovering: boolean,
  observedWidth: number,
  observedHeight: number,
};

const COLOR = {
  gray: cssVariables.gray2,
  gray3: cssVariables.gray3,
  red: cssVariables.red,
  white: "white",
  blue: cssVariables.blue,
  pink: cssVariables.red2,
  orange: cssVariables.orange,
  green: cssVariables.green,
};

function getLoadingStyle(buttonColor: ButtonColor): "png" | "css" {
  if (
    buttonColor === "gray" ||
    buttonColor === "gray3" ||
    buttonColor === "white"
  ) {
    return "css";
  }
  return "png";
}

class Button extends PureComponent<Props, LocalState> {
  div: ?HTMLElement;

  static defaultProps = {
    type: "button",
    styled: true,
    colorApplication: "background",
    loading: false,
    applyBorderRadius: true,
    applyHoverColor: true,
    autoFocus: false,
  };

  state = {
    isHovering: false,
    observedWidth: 0,
    observedHeight: 0,
  };

  componentDidMount() {
    // REF: https://github.com/reactjs/react-modal/issues/51
    // This is a workaround to auto-focus button
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.div != null) {
          this.div.focus();
        }
      }, 0);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { div } = this;
    if (div == null) {
      return;
    }
    if (
      nextProps.loading === true &&
      (this.props.loading == null || this.props.loading === false)
    ) {
      // When we render loading state, we will ignore our children
      // thus we must remember the current dimensions
      // otherwise the layout will flicker.
      const { width, height } = div.getBoundingClientRect();
      this.setState({
        observedWidth: width,
        observedHeight: height,
      });
    }
  }

  onRef = (r: HTMLElement | null) => {
    this.div = r;
  };

  onClick = (e: Event) => {
    if (this.props.onClick != null) {
      this.props.onClick(e, this.props.onClickInfo);
    }
  };

  onMouseEnter = (e: Event) => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = (e: Event) => {
    this.setState({ isHovering: false });
  };

  render() {
    const {
      type,
      children,
      className,
      disabled,
      loading,
      styled,
      color,
      colorApplication,
      applyBorderRadius,
      applyHoverColor,
    } = this.props;
    const isHovering = applyHoverColor && this.state.isHovering;
    const isLoading = loading === true;
    return (
      <button
        type={type}
        ref={this.onRef}
        style={{
          borderRadius: styled && applyBorderRadius ? "9999px" : undefined,
          backgroundColor:
            styled && colorApplication === "background" && color != null
              ? isHovering
                ? Color(COLOR[color])
                    .darken(0.05)
                    .toString()
                : COLOR[color]
              : // Workaround Safari weird style
                "transparent",
          color:
            styled && colorApplication === "borderAndText" && color != null
              ? COLOR[color]
              : styled &&
                colorApplication === "background" &&
                (color != null && color !== "white")
                ? "white"
                : undefined,
          border:
            styled &&
            (colorApplication === "borderAndText" ||
              colorApplication === "border") &&
            color != null
              ? `${color === "gray" ? 1 : 2}px solid ${COLOR[color]}`
              : undefined,
          width: loading === true ? this.state.observedWidth : undefined,
          height: loading === true ? this.state.observedHeight : undefined,
          opacity: disabled === true ? "0.7" : undefined,
        }}
        className={classnames(
          styles.button,
          {
            [styles.loadingPNGGradient]:
              loading === true &&
              color != null &&
              getLoadingStyle(color) === "png",
            [styles.loadingPureCSS]:
              loading === true &&
              color != null &&
              getLoadingStyle(color) === "css",
          },
          className
        )}
        onClick={this.onClick}
        disabled={isLoading || disabled}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {isLoading ? null : children}
      </button>
    );
  }
}

export default Button;
