// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import TruncatedText from "../TruncatedText/TruncatedText";
import TeacherSessionLink from "../Link/TeacherSessionLink";
import FormattedDate from "../FormattedDate/FormattedDate";
import ProgressBar from "../ProgressBar/ProgressBar";
import type { Session } from "../../types/session";
import { getDerivedState } from "../../utils/session";
import styles from "./SessionItem.module.scss";

type Props = {
  +session: Session,
};

class SessionItem extends PureComponent<Props> {
  renderSessionInfo(session: Session) {
    return (
      <div className={styles.basicInfoRow}>
        <div className={styles.nameContainer}>
          <p className={styles.baseId}>{session.templateDisplayCode}</p>
          <TruncatedText
            lines={2}
            lineHeight={
              26 /* the style has a height value that depends on line height*/
            }
            fontSize={22}
            fontWeight={500}
            className={styles.name}
          >
            {session.templateName}
          </TruncatedText>
        </div>
      </div>
    );
  }

  renderActivityInfo(session: Session) {
    const currentActivity = session.activities[session.currentActivityIndex];
    return (
      <div className={styles.activityRow}>
        <div className={styles.activityRowRight}>
          <p className={styles.resumeText}>
            <Text translationKey="phrase.resume" />
          </p>
        </div>
        <div className={styles.activityRowLeft}>
          <TruncatedText
            lines={1}
            lineHeight={26}
            fontSize={14}
            fontWeight={500}
            className={styles.activityName}
          >
            {currentActivity.name}
          </TruncatedText>
          <ProgressBar
            completedPercentage={
              (session.currentActivityIndex + 1) / session.activities.length
            }
            className={styles.progressBar}
          />
        </div>
      </div>
    );
  }

  renderTeacherName(session: Session) {
    return (
      <TruncatedText
        lines={1}
        lineHeight={17}
        fontSize={12}
        fontWeight={400}
        className={styles.teacherName}
      >
        {session.teacherName}
      </TruncatedText>
    );
  }

  renderDatetime(session: Session) {
    return (
      <p>
        <span className={styles.datetimeLabel}>
          <Text translationKey="teacher.in_progress_session_started_at" />
        </span>
        <span className={styles.datetime}>
          <FormattedDate
            value={session.createdAt}
            hour12={false}
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
            year="numeric"
            month="long"
            day="numeric"
          />
        </span>
      </p>
    );
  }

  renderTeacherNameDateTimeParticipantCount(session: Session) {
    const { participantCount } = getDerivedState(session);
    return (
      <div className={styles.lowerRowContainer}>
        <div className={styles.participantCountContainer}>
          <p className={styles.participantCountText}>{participantCount}</p>
        </div>
        <div className={styles.teacherNameAndDatetimeContainer}>
          {this.renderTeacherName(session)}
          {this.renderDatetime(session)}
        </div>
      </div>
    );
  }

  render() {
    const { session } = this.props;
    return (
      <TeacherSessionLink
        sessionID={session.id}
        sessionCode={session.code}
        templateId={session.templateId}
        templateDisplayCode={session.templateDisplayCode}
        templateName={session.templateName}
        teacherName={session.teacherName}
        className={styles.sessionContainer}
      >
        <div className={styles.session}>
          {this.renderSessionInfo(session)}
          {this.renderActivityInfo(session)}
          {this.renderTeacherNameDateTimeParticipantCount(session)}
        </div>
      </TeacherSessionLink>
    );
  }
}

export default SessionItem;
