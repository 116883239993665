// @flow
import jsesc from "jsesc";
import type {
  Answer,
  Activity,
  ActivityState,
  ActivityStateVariant,
  PollingOption,
} from "../types/activity";
import type {
  Session,
  Participant,
  PreviewSessionInfo,
} from "../types/session";
import { isDateEqual } from "./date";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

// ActivityAction represents the action applicable to the session
export type ActivityAction = "view_result" | "next_activity" | "finish_session";

export type ParticipantAnswer = {|
  participant: Participant,
  answer: Answer,
|};

type DerviedState = {|
  participantCount: number,
  answerCount: number,
  activityCount: number,
  activity: Activity,
  activityState: ActivityState,
  activityAction: ActivityAction,
|};

function inferActivityAction(
  state: ActivityStateVariant,
  currentActivityIndex: number,
  activityCount: number
): ActivityAction {
  switch (state) {
    case "accepting_answer":
      return "view_result";
    case "viewing_result":
      if (currentActivityIndex === activityCount - 1) {
        return "finish_session";
      }
      return "next_activity";
    default:
      throw new Error("unreachable");
  }
}

export function getDerivedState(session: Session): DerviedState {
  const { currentActivityIndex } = session;
  const participantCount = session.participants.length;
  const activity = session.activities[currentActivityIndex];
  const activityState = session.activityStates[currentActivityIndex];
  const activityCount = session.activities.length;
  const answerCount = Object.keys(activityState.answerByParticipantId).length;
  const activityAction = inferActivityAction(
    activityState.state,
    currentActivityIndex,
    activityCount
  );
  return {
    participantCount,
    answerCount,
    activityCount,
    activity,
    activityState,
    activityAction,
  };
}

export function indexToAlpha(index: number): string {
  return String(alphabet[index]);
}

export function getSubmittedAnswer(
  session: Session,
  participantId: string
): ?Answer {
  const { activityState } = getDerivedState(session);
  const { answerByParticipantId } = activityState;
  const answer = answerByParticipantId[participantId];
  return answer;
}

export function wasActivitySeeked(
  oldSession: Session,
  newSession: Session
): boolean {
  if (oldSession.currentActivityIndex !== newSession.currentActivityIndex) {
    return true;
  }
  return false;
}

export function wasActivityResultCleared(
  oldSession: Session,
  newSession: Session
): boolean {
  if (oldSession.currentActivityIndex !== newSession.currentActivityIndex) {
    return false;
  }
  const { activityState: { resetAt: a } } = getDerivedState(oldSession);
  const { activityState: { resetAt: b } } = getDerivedState(newSession);
  return !isDateEqual(a, b);
}

export type PollingOptionResultItem = {|
  text: string,
  imageUrl: ?string,
  participants: $ReadOnlyArray<Participant>,
|};

export function getPollingOptionResultItems(
  pollingOptions: $ReadOnlyArray<PollingOption>,
  participantAnswers: $ReadOnlyArray<ParticipantAnswer>
): $ReadOnlyArray<PollingOptionResultItem> {
  const output = [];

  // First we initialize one item for each option
  for (let i = 0; i < pollingOptions.length; ++i) {
    const option = pollingOptions[i];
    output.push({
      participants: [],
      text: option.text,
      imageUrl: option.imageUrl,
    });
  }

  // Group the participants
  for (const pa of participantAnswers) {
    const { answer: { pollingOptionIndice } } = pa;
    if (pollingOptionIndice != null) {
      for (const index of pollingOptionIndice) {
        // The array is read-only to outside world
        // but it is safe to write to it when we are constructing it
        (output[index].participants: any).push(pa.participant);
      }
    }
  }

  // Sort the participants by their name
  for (const item of output) {
    item.participants.sort();
  }

  return output;
}

export function rebuildActiveSessionList(
  ids: $ReadOnlyArray<string>,
  newSession: Session
): $ReadOnlyArray<string> {
  // Ignore test sessions
  if (newSession.mode !== "live") {
    return ids;
  }
  const id = newSession.id;
  const i = ids.indexOf(id);
  // Originally not in array and it is not yet ended
  if (i < 0 && newSession.state !== "ended") {
    return [id, ...ids];
  }
  // Originally in the array and it is ended
  if (i >= 0 && newSession.state === "ended") {
    const newIDs = [...ids];
    newIDs.splice(i, 1);
    return newIDs;
  }
  return ids;
}

export function encodePreviewSessionInfo(info: PreviewSessionInfo): string {
  const jsonString = jsesc(info, { json: true });
  const base64encoded = btoa(jsonString);
  const queryStringEscaped = encodeURIComponent(base64encoded);
  return queryStringEscaped;
}

export function decodePreviewSessionInfo(
  queryStringEscaped: string
): PreviewSessionInfo {
  const base64encoded = decodeURIComponent(queryStringEscaped);
  const jsonString = atob(base64encoded);
  return JSON.parse(jsonString);
}
