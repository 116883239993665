// @flow
import React, { PureComponent } from "react";
import type { Session, ParticipationInfo } from "../../types/session";
import type { Activity } from "../../types/activity";
import StudentSessionActivityQA from "./StudentSessionActivityQA";
import StudentSessionActivityPolling from "./StudentSessionActivityPolling";
import StudentSessionActivityWhiteboard from "./StudentSessionActivityWhiteboard";

type Props = {
  className: string,
  session: Session,
  activity: Activity,
  participationInfo: ParticipationInfo,
};

export default class StudentSessionActivity extends PureComponent<Props> {
  render() {
    const { className, session, activity, participationInfo } = this.props;
    if (activity.type === "qa") {
      return (
        <StudentSessionActivityQA
          className={className}
          session={session}
          activity={activity}
          participationInfo={participationInfo}
        />
      );
    }
    if (activity.type === "polling") {
      return (
        <StudentSessionActivityPolling
          className={className}
          session={session}
          activity={activity}
          participationInfo={participationInfo}
          pollingOptions={(activity.pollingOptions: any)}
          pollingMultipleChoice={(activity.pollingMultipleChoice: any)}
        />
      );
    }
    if (activity.type === "whiteboard") {
      return (
        <StudentSessionActivityWhiteboard
          className={className}
          session={session}
          activity={activity}
          participationInfo={participationInfo}
        />
      );
    }
    return null;
  }
}
