// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { TeacherSessionNullableProps } from "./TeacherSessionProps";
import type { Session } from "../../types/session";
import { querySingleSession } from "../../redux/actions/sessions";
import { getDerivedState, decodePreviewSessionInfo } from "../../utils/session";
import {
  getSessionEncodedInfo,
  forgetSessionEncodedInfo,
} from "../../utils/localStorage";
import TeacherSession from "./TeacherSession";

type OwnProps = {
  sessionID?: string,
  useEncodedSessionInfo?: boolean,
};

type ConnectedProps = {
  session: ?Session,
  querySingleSession: string => Promise<void>,
};

type Props = OwnProps & ConnectedProps;

function makeProps(props: Props): TeacherSessionNullableProps {
  const allNull = {
    sessionID: null,
    templateName: null,
    templateDisplayCode: null,
    templateLang: null,
    sessionIsTestMode: null,
    sessionIsPaused: null,
    sessionCode: null,
    sessionState: null,
    participants: null,
    answerCount: null,
    currentActivityIndex: null,
    activity: null,
    activityState: null,
    activityAction: null,
    activities: null,
  };
  const { sessionID, useEncodedSessionInfo, session } = props;
  if (sessionID != null) {
    if (session != null) {
      const {
        answerCount,
        activity,
        activityState,
        activityAction,
      } = getDerivedState(session);
      return {
        sessionID,
        activity,
        activityState,
        activityAction,
        answerCount,
        participants: session.participants,
        templateName: session.templateName,
        templateDisplayCode: session.templateDisplayCode,
        templateLang: session.templateLang,
        sessionIsTestMode: session.mode === "test",
        sessionIsPaused: session.isPaused,
        sessionCode: session.code,
        sessionState: session.state,
        currentActivityIndex: session.currentActivityIndex,
        activities: session.activities,
      };
    }
    return allNull;
  }
  if (useEncodedSessionInfo === true) {
    const encodedPreviewSessionInfo = getSessionEncodedInfo();
    if (encodedPreviewSessionInfo != null) {
      const {
        templateName,
        templateDisplayCode,
        templateLang,
        activities,
      } = decodePreviewSessionInfo(encodedPreviewSessionInfo);

      // remove encoded session info after decoding
      forgetSessionEncodedInfo();

      return {
        activity: activities[0],
        templateName,
        templateDisplayCode,
        templateLang,
        sessionID: null,
        activityState: {
          state: "accepting_answer",
          answerByParticipantId: {},
          resetAt: null,
        },
        // if more than one activity to preview, support next activity only
        activityAction:
          activities.length === 1 ? "view_result" : "next_activity",
        answerCount: 0,
        participants: [],
        sessionIsTestMode: false,
        sessionIsPaused: false,
        sessionCode: "10IOio",
        sessionState: "started",
        currentActivityIndex: 0,
        activities,
      };
    }
  }
  return allNull;
}

class TeacherSessionRenderer extends PureComponent<Props> {
  componentDidMount() {
    const { sessionID } = this.props;
    if (sessionID != null) {
      this.props.querySingleSession(sessionID);
    }
  }

  render() {
    const props = makeProps(this.props);
    return <TeacherSession {...props} />;
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const { sessionID } = ownProps;
  const session =
    sessionID == null ? null : state.sessions.sessionById[sessionID];
  return {
    session,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    querySingleSession: bindActionCreators(querySingleSession, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  TeacherSessionRenderer
): any): Class<React$Component<OwnProps>>);
