// @flow
import type { Participant, SessionState } from "../../types/session";
import type { Activity, ActivityState } from "../../types/activity";
import type { TEMPLATE_LOCALE } from "../../types/template";
import type { ActivityAction } from "../../utils/session";
export type TeacherSessionNullableProps = {
  sessionID: ?string,
  templateName: ?string,
  templateDisplayCode: ?string,
  templateLang: ?TEMPLATE_LOCALE,
  sessionIsTestMode: ?boolean,
  sessionIsPaused: ?boolean,
  sessionCode: ?string,
  sessionState: ?SessionState,
  participants: ?$ReadOnlyArray<Participant>,
  answerCount: ?number,
  currentActivityIndex: ?number,
  activity: ?Activity,
  activityState: ?ActivityState,
  activityAction: ?ActivityAction,
  activities: ?$ReadOnlyArray<Activity>,
};

export type TeacherSessionNonNullProps = {
  templateName: string,
  templateDisplayCode: string,
  templateLang: TEMPLATE_LOCALE,
  sessionIsTestMode: boolean,
  sessionIsPaused: boolean,
  sessionCode: string,
  sessionState: SessionState,
  participants: $ReadOnlyArray<Participant>,
  answerCount: number,
  currentActivityIndex: number,
  activity: Activity,
  activityState: ActivityState,
  activityAction: ActivityAction,
  activities: $ReadOnlyArray<Activity>,
};

export function toNonNull(
  props: TeacherSessionNullableProps
): ?TeacherSessionNonNullProps {
  const {
    templateName,
    templateDisplayCode,
    templateLang,
    sessionIsTestMode,
    sessionIsPaused,
    sessionCode,
    sessionState,
    participants,
    answerCount,
    currentActivityIndex,
    activity,
    activityState,
    activityAction,
    activities,
  } = props;
  if (
    templateName != null &&
    templateDisplayCode != null &&
    templateLang != null &&
    sessionIsTestMode != null &&
    sessionIsPaused != null &&
    sessionCode != null &&
    sessionState != null &&
    participants != null &&
    answerCount != null &&
    currentActivityIndex != null &&
    activity != null &&
    activityState != null &&
    activityAction != null &&
    activities != null
  ) {
    return {
      templateName,
      templateDisplayCode,
      templateLang,
      sessionIsTestMode,
      sessionIsPaused,
      sessionCode,
      sessionState,
      participants,
      answerCount,
      currentActivityIndex,
      activity,
      activityState,
      activityAction,
      activities,
    };
  }
  return null;
}
