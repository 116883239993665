// @flow
import React, { PureComponent, Fragment } from "react";
import type { Node } from "react";
import WindowSize from "../../components/WindowSize";
import styles from "./StudentLayout.module.scss";

type Props = {
  className: string,
  children: ?Node,
};

export default class StudentLayout extends PureComponent<Props> {
  render() {
    return (
      <Fragment>
        <div className={styles.background} />
        <WindowSize>
          {props => {
            const h = props.height;
            return (
              <div
                style={{ minHeight: h + "px" }}
                className={styles.contentContainer}
              >
                {this.props.children}
              </div>
            );
          }}
        </WindowSize>
      </Fragment>
    );
  }
}
