// @flow
import React from "react";
import type { Node } from "react";
import Link from "./Link";

export default function SystemConfigurationLink(props: {
  className?: string,
  children?: Node,
}) {
  const { className, children } = props;
  return (
    <Link
      className={className}
      to={{
        pathname: "/admin/system_configuration",
      }}
      altId="admin.system_configuration"
    >
      {children}
    </Link>
  );
}
