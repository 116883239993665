// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import RichEditor from "../RichEditor/RichEditor";
import ImageUploadButton from "../ImageUploadButton/ImageUploadButton";
import ImageChooser from "../ImageChooser/ImageChooser";
import Image from "../Image/Image";
import { QUESTION_DIAGRAM_HEIGHT } from "../../utils/constants";
import type { CommonProps } from "./types/ActivityPanelProps";
import { ErrorActivityQuestionEmpty } from "../../utils/validate";
import styles from "./ActivityPanelRenderer.module.scss";

class ActivityPanelQuestionContent extends PureComponent<CommonProps> {
  onQuestionChange = (question: string) => {
    const { activity, onActivityChange } = this.props;
    if (onActivityChange) {
      onActivityChange({
        ...activity,
        question: question,
      });
    }
  };

  onImageUploaded = (id: string, url: string) => {
    const { activity, onActivityChange } = this.props;
    if (onActivityChange) {
      onActivityChange({
        ...activity,
        imageUrl: url,
        imageId: id,
      });
    }
  };

  onImageUnSelect = () => {
    const { activity, onActivityChange } = this.props;
    if (onActivityChange) {
      onActivityChange({
        ...activity,
        imageUrl: null,
        imageId: null,
      });
    }
  };

  renderQuestionContent() {
    const { activity, activityIndex } = this.props;

    return (
      <div className={styles.questionContainer}>
        <p className={styles.teacherAsk}>
          <Text translationKey="teacher.teacher_ask" />
        </p>
        <RichEditor
          key={activityIndex}
          initialContentString={activity.question}
          readOnly
        />
        {activity.imageUrl != null && (
          <Image
            imageUrl={activity.imageUrl}
            className={styles.questionDiagram}
            height={QUESTION_DIAGRAM_HEIGHT}
            placeholderWidth={QUESTION_DIAGRAM_HEIGHT * 1.33}
            placeholderHeight={QUESTION_DIAGRAM_HEIGHT}
          />
        )}
      </div>
    );
  }

  renderEditableQuestionContent() {
    const { activityIndex, activity, validationErrors } = this.props;

    return (
      <div className={styles.questionContainer}>
        <p className={styles.teacherAsk}>
          <Text translationKey="teacher.teacher_ask" />
        </p>
        <RichEditor
          key={activityIndex}
          initialContentString={this.props.activity.question}
          onChange={this.onQuestionChange}
          showToolbar
          className={
            validationErrors != null &&
            validationErrors.includes(ErrorActivityQuestionEmpty)
              ? styles.questionEditorError
              : styles.questionEditor
          }
        />
        {validationErrors != null &&
          validationErrors.includes(ErrorActivityQuestionEmpty) && (
            <p className={styles.errorText}>
              <Text translationKey="error.activity_question_empty" />
            </p>
          )}
        <div className={styles.editorSupplements}>
          {activity.imageUrl == null && (
            <ImageUploadButton
              className={styles.imageUploadButton}
              onUploaded={this.onImageUploaded}
            />
          )}
          {activity.imageUrl != null && (
            <ImageChooser
              className={styles.imageChooser}
              imageUrl={activity.imageUrl}
              height={QUESTION_DIAGRAM_HEIGHT}
              placeholderWidth={QUESTION_DIAGRAM_HEIGHT * 1.33}
              placeholderHeight={QUESTION_DIAGRAM_HEIGHT}
              onUnSelect={this.onImageUnSelect}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { editable } = this.props;

    if (!editable) {
      return this.renderQuestionContent();
    }

    return this.renderEditableQuestionContent();
  }
}

export default ActivityPanelQuestionContent;
