// @flow
import { calcRichContentLength } from "./richContent";
import type { Template } from "../types/template";
import type { Activity } from "../types/activity";
import type { School } from "../types/school";
import type { User } from "../types/user";

// errors for validate template
export const ErrorTemplateNameEmpty: "ErrorTemplateNameEmpty" =
  "ErrorTemplateNameEmpty";
export const ErrorTemplateDisplayCodeEmpty: "ErrorTemplateDisplayCodeEmpty" =
  "ErrorTemplateDisplayCodeEmpty";
export const ErrorActivityNameEmpty: "ErrorActivityNameEmpty" =
  "ErrorActivityNameEmpty";
export const ErrorActivityQuestionEmpty: "ErrorActivityQuestionEmpty" =
  "ErrorActivityQuestionEmpty";
export const ErrorActivityPollingOptionQuestionEmpty: "ErrorActivityPollingOptionQuestionEmpty" =
  "ErrorActivityPollingOptionQuestionEmpty";
export const ErrorActivityMissingBackgroundImage: "ErrorActivityMissingBackgroundImage" =
  "ErrorActivityMissingBackgroundImage";

// errors for login
export const ErrorEmptyEmail: "ErrorEmptyEmail" = "ErrorEmptyEmail";
export const ErrorIncorrectEmail: "ErrorIncorrectEmail" = "ErrorIncorrectEmail";
export const ErrorEmptyPassword: "ErrorEmptyPassword" = "ErrorEmptyPassword";

export const MIN_OPTIONS_COUNT = 2;
export const MAX_OPTIONS_COUNT = 8;
export const MAX_STICKERS_COUNT = 10;

export type ValidateTemplateError =
  | typeof ErrorTemplateDisplayCodeEmpty
  | typeof ErrorTemplateNameEmpty;

export type ValidateActivityError =
  | typeof ErrorActivityNameEmpty
  | typeof ErrorActivityQuestionEmpty
  | typeof ErrorActivityPollingOptionQuestionEmpty
  | typeof ErrorActivityMissingBackgroundImage;

export type ValidateLoginError =
  | typeof ErrorEmptyEmail
  | typeof ErrorIncorrectEmail
  | typeof ErrorEmptyPassword;

export function validateActivity(
  activity: Activity
): $ReadOnlyArray<ValidateActivityError> {
  const errors = [];

  if (!activity.name.trim()) {
    errors.push(ErrorActivityNameEmpty);
  }

  const questionLength = calcRichContentLength(activity.question);
  if (questionLength === 0) {
    errors.push(ErrorActivityQuestionEmpty);
  }

  if (activity.type === "polling") {
    if (
      activity.pollingOptions != null &&
      activity.pollingOptions.find(pollingOption => {
        return pollingOption.text.trim().length === 0;
      }) !== undefined
    ) {
      errors.push(ErrorActivityPollingOptionQuestionEmpty);
    }
  }

  if (activity.type === "whiteboard") {
    if (
      activity.whiteboardBackground === "image" &&
      activity.whiteboardImageId == null
    ) {
      errors.push(ErrorActivityMissingBackgroundImage);
    }
  }

  return errors;
}

export function validateTemplate(
  template: Template
): $ReadOnlyArray<ValidateTemplateError> {
  const errors = [];
  if (!template.name.trim()) {
    errors.push(ErrorTemplateNameEmpty);
  }

  if (!template.displayCode.trim()) {
    errors.push(ErrorTemplateDisplayCodeEmpty);
  }

  return errors;
}

function isEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateLogin(
  email: string,
  password: string
): $ReadOnlyArray<ValidateLoginError> {
  const errors = [];
  if (!email.trim()) {
    errors.push(ErrorEmptyEmail);
  }
  if (!isEmail(email.trim())) {
    errors.push(ErrorIncorrectEmail);
  }
  if (!password.trim()) {
    errors.push(ErrorEmptyPassword);
  }

  return errors;
}

export const ErrorEmptyChineseSchoolName: "ErrorEmptyChineseSchoolName" =
  "ErrorEmptyChineseSchoolName";
export const ErrorEmptyEnglishSchoolName: "ErrorEmptyEnglishSchoolName" =
  "ErrorEmptyEnglishSchoolName";
export const ErrorPasswordViolatesRules: "ErrorPasswordViolatesRules" =
  "ErrorPasswordViolatesRules";
export const MIN_SCHOOL_ACCOUNT_PASSWORD_LEN = 8;

function withDigits(str) {
  return str.match(/\d/) !== null;
}

export type ValidateSchoolAccountError =
  | typeof ErrorEmptyChineseSchoolName
  | typeof ErrorEmptyEnglishSchoolName
  | typeof ErrorEmptyEmail
  | typeof ErrorIncorrectEmail
  | typeof ErrorEmptyPassword
  | typeof ErrorPasswordViolatesRules;

export function validateSchoolAccount(data: {
  school: School,
  password: string,
  allowEmptyPassword: boolean,
}): $ReadOnlyArray<ValidateSchoolAccountError> {
  const { school, password, allowEmptyPassword } = data;
  const errorLabels = [];

  if (school.name.trim().length === 0) {
    errorLabels.push(ErrorEmptyChineseSchoolName);
  }

  if (school.engName.trim().length === 0) {
    errorLabels.push(ErrorEmptyEnglishSchoolName);
  }

  if (school.email.trim().length === 0) {
    errorLabels.push(ErrorEmptyEmail);
  }

  if (!isEmail(school.email)) {
    errorLabels.push(ErrorIncorrectEmail);
  }

  if (password.length === 0) {
    if (!allowEmptyPassword) {
      errorLabels.push(ErrorEmptyPassword);
    }
  } else {
    if (
      password.length < MIN_SCHOOL_ACCOUNT_PASSWORD_LEN ||
      !withDigits(password)
    ) {
      errorLabels.push(ErrorPasswordViolatesRules);
    }
  }

  return errorLabels;
}

export const ErrorEmptyAdminName: "ErrorEmptyAdminName" = "ErrorEmptyAdminName";

export type ValidateAdminUserError =
  | typeof ErrorEmptyAdminName
  | typeof ErrorEmptyEmail
  | typeof ErrorIncorrectEmail
  | typeof ErrorEmptyPassword
  | typeof ErrorPasswordViolatesRules;

export function validateAdminUser(data: {
  user: User,
  password: string,
  allowEmptyPassword: boolean,
}): $ReadOnlyArray<ValidateAdminUserError> {
  const errorLabels = [];
  const { user, password, allowEmptyPassword } = data;

  if (user.name.trim().length === 0) {
    errorLabels.push(ErrorEmptyAdminName);
  }

  if (user.email.trim().length === 0) {
    errorLabels.push(ErrorEmptyEmail);
  }

  if (!isEmail(user.email)) {
    errorLabels.push(ErrorIncorrectEmail);
  }

  if (password.length === 0) {
    if (!allowEmptyPassword) {
      errorLabels.push(ErrorEmptyPassword);
    }
  } else {
    if (
      password.length < MIN_SCHOOL_ACCOUNT_PASSWORD_LEN ||
      !withDigits(password)
    ) {
      errorLabels.push(ErrorPasswordViolatesRules);
    }
  }

  return errorLabels;
}

export const ErrorInvalidMaxCustomTemplateNumber: "ErrorInvalidMaxCustomTemplateNumber" =
  "ErrorInvalidMaxCustomTemplateNumber";
export const ErrorInvalidForgotPasswordEmailList: "ErrorInvalidForgotPasswordEmailList" =
  "ErrorInvalidForgotPasswordEmailList";
export const ErrorInvalidVisitableActivityRecordDuration: "ErrorInvalidVisitableActivityRecordDuration" =
  "ErrorInvalidVisitableActivityRecordDuration";

export type ValidateSysConfError =
  | typeof ErrorInvalidMaxCustomTemplateNumber
  | typeof ErrorInvalidForgotPasswordEmailList
  | typeof ErrorInvalidVisitableActivityRecordDuration;

export function validateSysConfs(data: {
  customTemplateMaxNumber: string,
  forgotPasswordEmailList: string,
  activityRecordsDuration: string,
}): $ReadOnlyArray<ValidateSysConfError> {
  const {
    customTemplateMaxNumber,
    forgotPasswordEmailList,
    activityRecordsDuration,
  } = data;
  const errors = [];

  let parsed = parseInt(customTemplateMaxNumber, 10);
  if (isNaN(parsed) || parsed <= 0) {
    errors.push(ErrorInvalidMaxCustomTemplateNumber);
  }

  parsed = forgotPasswordEmailList.split(",");
  if (parsed.length === 0) {
    errors.push(ErrorInvalidForgotPasswordEmailList);
  }

  for (let email of parsed) {
    if (!validateEmail(email.trim())) {
      errors.push(ErrorInvalidForgotPasswordEmailList);
      break;
    }
  }

  parsed = parseInt(activityRecordsDuration, 10);
  if (isNaN(parsed) || parsed <= 0) {
    errors.push(ErrorInvalidVisitableActivityRecordDuration);
  }

  return errors;
}

export type ValidateEmailError =
  | typeof ErrorEmptyEmail
  | typeof ErrorIncorrectEmail;

export function validateEmail(
  email: string
): $ReadOnlyArray<ValidateEmailError> {
  const errors = [];

  if (email.trim().length === 0) {
    errors.push(ErrorEmptyEmail);
  }

  if (!isEmail(email)) {
    errors.push(ErrorIncorrectEmail);
  }

  return errors;
}
