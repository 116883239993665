// @flow
import styles from "./TeacherSessionActivity.module.scss";

export const CONTAINER_PADDING = Number(styles.rootcontainerpadding);
const contentcontainerverticalpadding = Number(
  styles.contentcontainerverticalpadding
);

export function getHeightForGrayRectangleContainer(
  parentHeight: number
): number {
  return Math.max(
    parentHeight,
    2 * CONTAINER_PADDING + 2 * contentcontainerverticalpadding
  );
}

export function getHeightForGrayRectangle(parentHeight: number): number {
  return Math.max(
    2 * contentcontainerverticalpadding,
    parentHeight - 2 * CONTAINER_PADDING
  );
}
