// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { default as ReactDatePicker } from "react-datepicker";
import moment from "moment";
import type { RootState, Dispatch } from "../../types/store";
import type { AppLocale } from "../../types/app";
import styles from "./DatePicker.module.scss";

type OwnProps = {
  className: string,
  children: React$Node,
  value: Date,
  maxDate?: Date,
  onChange: (date: Date) => void,
};

type ConnectedProps = {
  locale: AppLocale,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  momentValue: any,
  momentMaxDate: any,
};

function getDerivedStateFromProps(props: Props): LocalState {
  return {
    momentValue: moment(props.value),
    momentMaxDate: props.maxDate != null ? moment(props.maxDate) : undefined,
  };
}

function appLocaleToMomentLocale(locale: AppLocale): "en" | "zh-cn" | "zh-hk" {
  switch (locale) {
    case "en":
      return "en";
    case "zh-Hans-CN":
      return "zh-cn";
    case "zh-Hant-HK":
      return "zh-hk";
    default:
      throw new Error("unreachable");
  }
}

class DatePicker extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = getDerivedStateFromProps(props);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState(getDerivedStateFromProps(nextProps));
  }

  onChange = (date: any) => {
    this.props.onChange(date.toDate());
  };

  onChangeRaw = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    return (
      <div
        className={classnames(styles.datePickerContainer, this.props.className)}
      >
        <ReactDatePicker
          className={styles.reactDatePicker}
          selected={this.state.momentValue}
          maxDate={this.state.momentMaxDate}
          onChange={this.onChange}
          onChangeRaw={this.onChangeRaw}
          locale={appLocaleToMomentLocale(this.props.locale)}
        />
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.app.locale,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
