// @flow
import React from "react";
import type { Node } from "react";
import { Redirect } from "react-router-dom";
import Link from "./Link";

export default function SessionsLink(props: {
  className?: string,
  children?: Node,
  redirect?: boolean,
  replace?: boolean,
}) {
  const to = "/sessions";
  const { className, children, redirect, replace } = props;
  if (redirect === true) {
    return <Redirect to={to} replace={replace == null ? true : replace} />;
  }
  return (
    <Link className={className} to={to} altId="teacher.in_progress">
      {children}
    </Link>
  );
}
