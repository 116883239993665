// @flow
import skygear from "skygear";
import type { Asset } from "skygear";

export default function uploadImageData(
  name: string,
  data: string,
  contentType: string
): Promise<Asset> {
  const asset = new skygear.Asset({
    name: name,
    base64: data,
    contentType: contentType,
  });
  const databaseContainer = new skygear.DatabaseContainer(skygear);
  return databaseContainer.uploadAsset(asset);
}
