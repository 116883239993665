// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import {
  defaultPathByUserType,
  ADMIN_PATH_PATTERNS,
  EDITOR_PATH_PATTERNS,
  SCHOOL_PATH_PATTERNS,
} from "../../utils/paths";
import { Route, Redirect } from "react-router-dom";
import type { Location } from "react-router-dom";
import type { RootState, Dispatch } from "../../types/store";
import type { AuthUser } from "../../types/auth";

type Props = {
  // If this is true, then this route will redirect
  // if there is not user.
  // If this is false, then this route will redirect
  // if there is logged user.
  +authOnly?: boolean,
  +authAdmin: boolean,
  +location?: Location,
  +user: ?AuthUser,
  +path: string,
  +useDefaultPath: boolean,
};

class AuthRoute extends PureComponent<Props> {
  static defaultProps = {
    authOnly: true,
    authAdmin: false,
    useDefaultPath: false,
  };

  getDefaultPath() {
    const { user } = this.props;
    const match = this.props.authAdmin ? "/admin" : "";
    return user == null
      ? match + "/login"
      : defaultPathByUserType(user.userType);
  }

  checkPermission() {
    const { user, path } = this.props;
    const authOnly = this.props.authOnly === true;

    if (user == null) {
      // if authOnly is false, will render the route anyway
      // even when the user is not logged in
      return !authOnly;
    }

    // check the path is allowed to access by the user's userType
    const patternsMap = {
      PolyupathsSuperAdmin: ADMIN_PATH_PATTERNS,
      PolyupathsEditor: EDITOR_PATH_PATTERNS,
      PolyupathsSchool: SCHOOL_PATH_PATTERNS,
    };
    const patterns = patternsMap[user.userType];
    return patterns != null
      ? !!patterns.find(pattern => new RegExp(pattern).test(path))
      : false;
  }

  render() {
    const { useDefaultPath } = this.props;

    // check permission before render the route
    // useDefaultPath means AuthRoute decides logged users' default route
    return (
      <Fragment>
        {this.checkPermission() && !useDefaultPath ? (
          <Route {...this.props} />
        ) : (
          <Route
            {...this.props}
            render={props => (
              <Redirect
                to={{
                  pathname: this.getDefaultPath(),
                  state: { from: this.props.location },
                }}
              />
            )}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

const AuthRouteApp = connect(mapStateToProps, mapDispatchToProps)(AuthRoute);

export default AuthRouteApp;
