// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import styles from "./Select.module.scss";

type Props = {
  className: string,
  disabled?: boolean,
  onChange: (e: SyntheticInputEvent<HTMLSelectElement>) => void,
  value: string,
  // the first child is any React Node to represent the selected option
  // it can be anything
  // the rest children must be <option> or component that renders to <option>
  children: $ReadOnlyArray<any>,
};

export default class Select extends PureComponent<Props> {
  render() {
    const { children, className, disabled, onChange, value } = this.props;
    const [first, ...rest] = children;
    return (
      <div className={classnames(styles.selectContainer, className)}>
        <select
          className={styles.select}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {rest}
        </select>
        {first}
      </div>
    );
  }
}
