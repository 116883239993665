// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TemplatesHeader from "../../components/TemplatesHeader/TemplatesHeader";
import TemplateItem from "../../components/TemplateItem/TemplateItem";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ErrorModal from "../../components/ErrorModal";
import { fetchAll, deleteCustom } from "../../redux/actions/templates";
import { createTemplate } from "../../redux/actions/createTemplate";
import { TEMPLATE_LANG_ALL } from "../../types/template";
import { extractError } from "../../utils/request";
import type { RootState, Dispatch } from "../../types/store";
import type {
  Template,
  TEMPLATE_YEAR,
  TEMPLATE_LANG,
  CreateTemplateParameter,
} from "../../types/template";
import type { RouterHistory } from "react-router-dom";

type Props = {
  +history: RouterHistory,
  +isRequesting: boolean,
  +templatesError: mixed,
  +createSessionError: mixed,
  +year: TEMPLATE_YEAR,
  +templatesById: { [id: string]: Template },
  +form1TemplateIds: $ReadOnlyArray<string>,
  +form2TemplateIds: $ReadOnlyArray<string>,
  +form3TemplateIds: $ReadOnlyArray<string>,
  +fetchAll: void => void,
  +deleteCustom: string => void,
  +createTemplate: CreateTemplateParameter => Promise<Template>,
};

type LocalState = {|
  lang: TEMPLATE_LANG,
  keywords: string,
  displayCustomTemplatesIds: $ReadOnlyArray<string>,
|};

class Templates extends PureComponent<Props, LocalState> {
  constructor(props) {
    super(props);

    this.state = {
      lang: TEMPLATE_LANG_ALL,
      keywords: "",
      displayCustomTemplatesIds: [],
    };
  }

  componentDidMount() {
    this.props.fetchAll();
  }

  onSearchFilterChanged = (lang, keywords) => {
    this.setState({ lang, keywords });
  };

  onDeleteTemplate = templateId => {
    this.props.deleteCustom(templateId);
  };

  onCreateTemplate = (templateId: string, newName: string) => {
    this.props
      .createTemplate({
        baseId: templateId,
        newName,
      })
      .then(createdTemplate => {
        this.props.history.push({
          pathname: `/template/${createdTemplate.id}`,
        });
      });
  };

  onToggleDisplayCustomTemplates = templateId => {
    // Keep custom templates displayed state
    // This is helpful when user deletes a custom template
    // and the imported template should keep in expanded state
    const displayCustomTemplatesIds = Array.from(
      this.state.displayCustomTemplatesIds
    );
    const idx = displayCustomTemplatesIds.indexOf(templateId);
    if (idx === -1) {
      displayCustomTemplatesIds.push(templateId);
    } else {
      displayCustomTemplatesIds.splice(idx, 1);
    }
    this.setState({ displayCustomTemplatesIds: displayCustomTemplatesIds });
  };

  filterByLang() {
    const { year, templatesById } = this.props;
    const { lang } = this.state;
    const templateIds = this.props[`${year}TemplateIds`];
    const templates = templateIds.map(id => templatesById[id]);

    return templates.filter(
      template => lang === TEMPLATE_LANG_ALL || template.lang === lang
    );
  }

  filterByKeywords(templates) {
    const { keywords } = this.state;

    return templates.filter(template => {
      return (
        template.name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 ||
        template.displayCode
          .toLowerCase()
          .indexOf(keywords.toLocaleLowerCase()) !== -1
      );
    });
  }

  genCustomTemplatesByBaseId(templates) {
    // generate base dictionary with empty array
    const dictByBaseId = templates.reduce(
      (accu, curr) => (curr.isImported ? { ...accu, [curr.id]: [] } : accu),
      {}
    );
    // filled the array with custom templates
    return templates.reduce((accu, curr) => {
      if (!curr.isImported && accu[curr.baseId]) {
        return {
          ...accu,
          [curr.baseId]: [...accu[curr.baseId], curr],
        };
      } else {
        return accu;
      }
    }, dictByBaseId);
  }

  render() {
    const {
      year,
      isRequesting,
      templatesError,
      createSessionError,
    } = this.props;
    const { lang, keywords, displayCustomTemplatesIds } = this.state;
    const templates = this.filterByLang();
    const importedTemplates: $ReadOnlyArray<Template> = templates.filter(
      template => template.isImported
    );
    const customTemplatesByBaseId: {
      [id: string]: $ReadOnlyArray<Template>,
    } = this.genCustomTemplatesByBaseId(templates);

    if (isRequesting) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        <TemplatesHeader
          year={year}
          lang={lang}
          keywords={keywords}
          onChange={this.onSearchFilterChanged}
        />
        {!keywords &&
          importedTemplates.map(template => (
            <TemplateItem
              key={template.id}
              template={template}
              customTemplates={customTemplatesByBaseId[template.id]}
              onDeleteTemplate={this.onDeleteTemplate}
              onCreateTemplate={this.onCreateTemplate}
              onToggleDisplayCustomTemplates={
                this.onToggleDisplayCustomTemplates
              }
              displayCustomTemplates={displayCustomTemplatesIds.includes(
                template.id
              )}
            />
          ))}
        {keywords &&
          this.filterByKeywords(templates).map(template => (
            <TemplateItem
              key={template.id}
              template={template}
              customTemplates={[]}
              onDeleteTemplate={this.onDeleteTemplate}
              onCreateTemplate={this.onCreateTemplate}
              onToggleDisplayCustomTemplates={
                this.onToggleDisplayCustomTemplates
              }
            />
          ))}
        <Fragment>
          <ErrorModal error={templatesError} />
          <ErrorModal error={createSessionError} />
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    isRequesting: state.templates.isRequesting,
    templatesError: state.templates.error,
    createSessionError: extractError(state.sessions.createSessionRequest),
    templatesById: state.templates.templatesById,
    form1TemplateIds: state.templates.form1TemplateIds,
    form2TemplateIds: state.templates.form2TemplateIds,
    form3TemplateIds: state.templates.form3TemplateIds,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchAll: bindActionCreators(fetchAll, dispatch),
    deleteCustom: bindActionCreators(deleteCustom, dispatch),
    createTemplate: bindActionCreators(createTemplate, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
