// @flow

// Apply javascript polyfill
import "@babel/polyfill";

// Aply fetch polyfill
import "whatwg-fetch";

// Apply Intl polyfill
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/zh-Hans-CN";
import "intl/locale-data/jsonp/zh-Hant-HK";

// Apply locale data
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";

// Configure React ART
import "art/modes/canvas";

// Configure react-datepicker and moment
import "moment/locale/zh-hk";
import "moment/locale/zh-cn";
import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import HtmlBodyRootMinHeight from "./components/HtmlBodyRootMinHeight";
import { Provider } from "react-redux";
import "./global.scss";
import { createStore } from "./redux/store";

addLocaleData([...en, ...zh]);
const container = document.getElementById("root");
if (container) {
  const store = createStore();
  const el = (
    <Provider store={store}>
      <HtmlBodyRootMinHeight>
        <App />
      </HtmlBodyRootMinHeight>
    </Provider>
  );
  ReactDOM.render(el, container);
}
