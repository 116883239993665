// @flow

const config = {
  endPoint: process.env.REACT_APP_SKYGEAR_ENDPOINT,
  apiKey: process.env.REACT_APP_SKYGEAR_API_KEY,
  tencentCaptchaAppID: ((process.env
    .REACT_APP_TENCENT_CAPTCHA_APP_ID: any): string),
};

export default config;
