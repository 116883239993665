// @flow
import React, { PureComponent } from "react";
import Text from "../../components/Text/Text";
import InputWithErrorMessage from "../../components/Input/InputWithErrorMessage";
import Button from "../../components/Button/Button";
import ContactUsLink from "../../components/Link/ContactUsLink";
import LocaleLayout from "../../components/LocaleLayout";
import ModalContainer from "../../components/Modal/ModalContainer";
import AlertModal from "../../components/Modal/AlertModal";
import ErrorModal from "../../components/ErrorModal";
import {
  validateEmail,
  ErrorEmptyEmail,
  ErrorIncorrectEmail,
} from "../../utils/validate";
import { forgotPassword } from "../../redux/actions/auth";
import type { RouterHistory } from "react-router-dom";
import type { ValidateEmailError } from "../../utils/validate";
import styles from "./styles/Login.module.scss";

type Props = {
  +history: RouterHistory,
};

type LocalState = {|
  email: string,
  validationErrors: $ReadOnlyArray<ValidateEmailError>,
  isModalShown: boolean,
  error: mixed,
|};

class ForgotPassword extends PureComponent<Props, LocalState> {
  state: LocalState = {
    email: "",
    validationErrors: [],
    isModalShown: false,
    error: null,
  };

  onForgotPassword = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const validationErrors = validateEmail(this.state.email);
    this.setState({ validationErrors: validationErrors });
    if (validationErrors.length === 0) {
      forgotPassword(this.state.email)
        .then(email => this.setState({ isModalShown: true }))
        .catch(error => this.setState({ error }));
    }
  };

  onEmailChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.setState({ email: target.value });
    }
  };

  renderTitle() {
    return (
      <div className={styles.loginTitleContainer}>
        <LocaleLayout>
          {props => {
            return (
              <p
                className={props.isEn ? styles.loginEnTitle : styles.loginTitle}
              >
                <Text translationKey="teacher.admin_end" />
              </p>
            );
          }}
        </LocaleLayout>
      </div>
    );
  }

  renderForgotPasswordForm() {
    const { email, validationErrors } = this.state;
    return (
      <div className={styles.loginFormContainer}>
        <div className={styles.row}>
          <p className={styles.title}>
            <Text translationKey="phrase.forgot_password" />
          </p>
        </div>
        <form onSubmit={this.onForgotPassword} className={styles.row}>
          <div className={styles.inputRow}>
            <InputWithErrorMessage
              placeholderId="teacher.email"
              labelId="teacher.email"
              value={email}
              type="email"
              onChange={this.onEmailChange}
              className={styles.input}
              errorTextKey={
                validationErrors.includes(ErrorEmptyEmail)
                  ? "error.empty_email"
                  : validationErrors.includes(ErrorIncorrectEmail)
                    ? "error.incorrect_email"
                    : null
              }
            />
          </div>
          <Button color="pink" type="submit" className={styles.actionButton}>
            <Text translationKey="phrase.submit" />
          </Button>
        </form>
        <div className={styles.row}>
          <div className={styles.linkContainer}>
            <span className={styles.text}>
              <Text translationKey="teacher.need_help" />
            </span>
            <ContactUsLink className={styles.link} />
          </div>
        </div>
      </div>
    );
  }

  onCloseAlertModal = () => {
    this.setState({ isModalShown: false });
  };

  renderSuccessModal() {
    const { isModalShown } = this.state;

    if (!isModalShown) {
      return null;
    }

    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="forgot_password.success"
          buttonKey="phrase.confirm"
          onButtonClick={this.onCloseAlertModal}
          onClose={this.onCloseAlertModal}
        />
      </ModalContainer>
    );
  }

  renderErrorModal() {
    return <ErrorModal error={this.state.error} />;
  }

  render() {
    return (
      <div className={styles.formRow}>
        {this.renderTitle()}
        {this.renderForgotPasswordForm()}
        {this.renderSuccessModal()}
        {this.renderErrorModal()}
      </div>
    );
  }
}

export default ForgotPassword;
