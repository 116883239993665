// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Session, ParticipationInfo } from "../../types/session";
import type { RootState, Dispatch } from "../../types/store";
import type { RequestState } from "../../types/request";
import { findParticipationInfo } from "../../utils/localStorage";
import { getDerivedState } from "../../utils/session";
import { querySingleSession } from "../../redux/actions/sessions";
import Synchronization from "../../components/Synchronization/Synchronization";
import WindowSize from "../../components/WindowSize";
import Button from "../../components/Button/Button";
import Text from "../../components/Text/Text";
import StudentLayout from "./StudentLayout";
import CloseWindowOrRedirect from "../../components/CloseWindowOrRedirect";
import JoinSessionLink from "../../components/Link/JoinSessionLink";
import StudentSessionHeader from "../../components/StudentSessionHeader/StudentSessionHeader";
import StudentLockScreen from "../../components/StudentLockScreen/StudentLockScreen";
import StudentSessionActivity from "../../components/StudentSessionActivity/StudentSessionActivity";
import type { AppLocale } from "../../types/app";
import { changeLocale } from "../../redux/actions/app";
import { coverTemplateLangToAppLocale } from "../../utils/locales";
import { extractError } from "../../utils/request";
import ErrorModal from "../../components/ErrorModal";
import styles from "./StudentSession.module.scss";

type OwnProps = {
  participationCode: string,
};

type ConnectedProps = {
  sessionById: { [id: string]: Session },
  querySingleSessionRequest: ?RequestState<void>,
  querySingleSession: string => Promise<void>,
  changeLocale: AppLocale => void,
  submitAnswerRequest: ?RequestState<void>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  didFindInfo: boolean,
  participationInfo: ?ParticipationInfo,
  renderClose: boolean,
};

class StudentSession extends PureComponent<Props, LocalState> {
  state = {
    didFindInfo: false,
    participationInfo: null,
    renderClose: false,
  };

  componentDidMount() {
    const info = findParticipationInfo(this.props.participationCode);
    this.setState({
      didFindInfo: true,
      participationInfo: info,
    });
    if (info != null) {
      const { sessionId } = info;
      this.props.querySingleSession(sessionId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { participationInfo } = this.state;
    if (participationInfo != null) {
      const nextSession = nextProps.sessionById[participationInfo.sessionId];
      if (nextSession) {
        this.props.changeLocale(
          coverTemplateLangToAppLocale(nextSession.templateLang)
        );
      }
    }
  }

  onCloseSessionButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      renderClose: true,
    });
  };

  renderActivityBySessionState(info: ParticipationInfo, session: Session) {
    const { activity, activityState } = getDerivedState(session);

    if (
      session.state === "created" ||
      (session.state === "started" && activityState.state === "viewing_result")
    ) {
      return (
        <WindowSize>
          {props => {
            return (
              <div
                style={{ height: props.height }}
                className={styles.created}
              />
            );
          }}
        </WindowSize>
      );
    }

    if (session.state === "ended") {
      return (
        <Fragment>
          <p className={styles.endedMessageText}>
            <Text translationKey="session.end" />
          </p>
          <div className={styles.closeSessionButtonContainer}>
            <Button
              color="pink"
              className={styles.closeSessionButton}
              onClick={this.onCloseSessionButtonClick}
            >
              <span className={styles.closeSessionButtonText}>
                <Text translationKey="phrase.close" />
              </span>
            </Button>
          </div>
        </Fragment>
      );
    }

    if (
      session.state === "started" &&
      activityState.state === "accepting_answer"
    ) {
      return (
        <StudentSessionActivity
          className={styles.main}
          session={session}
          activity={activity}
          participationInfo={info}
        />
      );
    }

    return null;
  }

  renderHeader(info: ParticipationInfo, session: Session) {
    const { activityState } = getDerivedState(session);
    if (
      session.state === "created" ||
      (activityState.state === "viewing_result" && session.state !== "ended")
    ) {
      return null;
    }
    return (
      <StudentSessionHeader
        className=""
        participationInfo={info}
        session={session}
      />
    );
  }

  renderCloseOrRedirect() {
    const { renderClose } = this.state;
    if (!renderClose) {
      return null;
    }
    return (
      <CloseWindowOrRedirect>
        <JoinSessionLink sessionCode="" redirect replace />
      </CloseWindowOrRedirect>
    );
  }

  renderLockScreen(session: Session) {
    if (session.isPaused && session.state !== "ended") {
      return <StudentLockScreen />;
    }
    return null;
  }

  renderWithAllRequiredData(info: ParticipationInfo, session: Session) {
    return (
      <StudentLayout className="">
        <Synchronization sessionId={session.id} />
        {this.renderCloseOrRedirect()}
        {this.renderHeader(info, session)}
        {this.renderActivityBySessionState(info, session)}
        {this.renderLockScreen(session)}
      </StudentLayout>
    );
  }

  render() {
    let content = null;
    const { participationInfo } = this.state;
    if (participationInfo != null) {
      const session = this.props.sessionById[participationInfo.sessionId];
      if (session != null) {
        content = this.renderWithAllRequiredData(participationInfo, session);
      }
    }
    return (
      <Fragment>
        {content}
        <ErrorModal
          error={extractError(this.props.querySingleSessionRequest)}
        />
        <ErrorModal error={extractError(this.props.submitAnswerRequest)} />
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    sessionById: state.sessions.sessionById,
    querySingleSessionRequest: state.sessions.singleSessionRequest,
    submitAnswerRequest: state.sessions.submitAnswerRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    querySingleSession: bindActionCreators(querySingleSession, dispatch),
    changeLocale: bindActionCreators(changeLocale, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  StudentSession
): any): Class<React$Component<OwnProps>>);
