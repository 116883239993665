// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import uploadIcon from "../../images/upload.svg";
import { resizeImage, resizeImageError } from "../../utils/resizeImage";
import {
  resizeCropImage,
  resizeCropImageError,
} from "../../utils/resizeCropImage";
import uploadImageData from "../../utils/uploadImageData";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import styles from "./ImageUploadButton.module.scss";

type Props = {
  +type: "resize" | "resizeCrop",
  +className?: string,
  +disabled: boolean,
  +onUploaded?: (string, string, mixed) => void,
  +onUploadedInfo?: mixed,
  +accept: string,
  +width: number,
  +height: number,
};

type LocalState = {
  uploading: boolean,
  uploadFailed: boolean,
  resizeFailed: boolean,
};

const MAX_WIDTH = 1600;
const MAX_HEIGHT = 1600;

class ImageUploadButton extends PureComponent<Props, LocalState> {
  static defaultProps = {
    type: "resize",
    disabled: false,
    accept: "image/*",
    width: MAX_WIDTH,
    height: MAX_HEIGHT,
  };

  state = {
    uploading: false,
    uploadFailed: false,
    resizeFailed: false,
  };

  startUpload() {
    this.setState({
      uploading: true,
      uploadFailed: false,
      resizeFailed: false,
    });
  }

  uploadSuccess() {
    this.setState({
      uploading: false,
      uploadFailed: false,
      resizeFailed: false,
    });
  }

  resizeFailed() {
    this.setState({
      uploading: false,
      uploadFailed: false,
      resizeFailed: true,
    });
  }

  uploadFailed() {
    this.setState({
      uploading: false,
      uploadFailed: true,
      resizeFailed: false,
    });
  }

  handleFiles = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { type, onUploaded, onUploadedInfo, width, height } = this.props;
    const { target } = e;

    if (target instanceof HTMLInputElement && onUploaded) {
      const file = target.files[0];
      const operation = type === "resize" ? resizeImage : resizeCropImage;

      this.startUpload();
      operation(file, width, height)
        .then(data => {
          return uploadImageData(file.name, data, "image/png");
        })
        .then(asset => {
          this.uploadSuccess();
          if (asset.url != null) {
            onUploaded(asset.name, asset.url, onUploadedInfo);
          }
        })
        .catch(error => {
          if (error === resizeImageError || error === resizeCropImageError) {
            this.resizeFailed();
          } else {
            this.uploadFailed();
          }
        });
    }
  };

  render() {
    const { className, disabled, accept } = this.props;
    const { uploading, uploadFailed, resizeFailed } = this.state;

    return (
      <div
        className={classnames(styles.uploadButtonWrapper, {
          [styles.disabled]: disabled,
        })}
      >
        <button
          className={classnames(
            styles.buttonContainer,
            { [styles.uploadingContainer]: uploading },
            className != null ? className : null
          )}
          disabled={uploading}
        >
          <img className={styles.buttonImg} src={uploadIcon} alt="" />
          <span className={styles.buttonText}>
            <Text translationKey="teacher.upload_image" />
          </span>
          {uploading && <LoadingIndicator size="small" />}
        </button>
        {!uploading &&
          !disabled && (
            <input type="file" accept={accept} onChange={this.handleFiles} />
          )}
        {(uploadFailed || resizeFailed) && (
          <p className={styles.error}>
            <Text
              translationKey={
                uploadFailed
                  ? "error.unknown_error"
                  : "error.unable_to_load_image"
              }
            />
          </p>
        )}
      </div>
    );
  }
}

export default ImageUploadButton;
