// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Session } from "../../types/session";
import type { Activity } from "../../types/activity";
import type { RootState, Dispatch } from "../../types/store";
import type { ActivityAction } from "../../utils/session";
import type { RequestState } from "../../types/request";
import TruncatedText from "../TruncatedText/TruncatedText";
import ProgressBar from "../ProgressBar/ProgressBar";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import ActivitiesPanel from "../ActivitiesPanel/ActivitiesPanel";
import Arrow from "../ART/Arrow";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import Text from "../Text/Text";
import Button from "../Button/Button";
import styles from "./TeacherSessionFooter.module.scss";
import {
  seekActivity,
  endActivity,
  endSession,
  resetActivity,
} from "../../redux/actions/sessions";
import { isRequesting } from "../../utils/request";
import { extractError } from "../../utils/request";
import ErrorModal from "../ErrorModal";
import cssVariables from "../../variables.module.scss";

type OwnProps = {
  sessionID: ?string,
  currentActivityIndex: number,
  participantCount: number,
  answerCount: number,
  activityAction: ActivityAction,
  activityName: string,
  activities: $ReadOnlyArray<Activity>,
  seekPreviewActivity: number => void,
};

type ConnectedProps = {
  seekActivityRequest: ?RequestState<void>,
  endActivityRequest: ?RequestState<void>,
  endSessionRequest: ?RequestState<void>,
  resetActivityRequest: ?RequestState<void>,
  seekActivity: (string, number) => Promise<Session>,
  endActivity: (string, number) => Promise<Session>,
  resetActivity: (string, number) => Promise<Session>,
  endSession: string => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  popupListShown: boolean,
  isModalShown: boolean,
};

class TeacherSessionFooter extends PureComponent<Props, LocalState> {
  state = {
    popupListShown: false,
    isModalShown: false,
  };

  onClickActionButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { sessionID, currentActivityIndex, activityAction } = this.props;
    switch (activityAction) {
      case "view_result":
        if (sessionID == null) {
          return;
        }
        this.props.endActivity(sessionID, currentActivityIndex);
        break;
      case "next_activity": {
        const i = this.getSeekRightIndex();
        if (i != null) {
          this.seekActivity(i);
        }
        break;
      }
      case "finish_session": {
        if (sessionID == null) {
          return;
        }
        this.props.endSession(sessionID);
        break;
      }
      default:
        break;
    }
  };

  onClickActivityPopupListItem = (idx: number) => {
    const { currentActivityIndex } = this.props;
    if (idx === currentActivityIndex) {
      return;
    }
    this.seekActivity(idx);
    this.setState({ popupListShown: false });
  };

  onClickLeftArrow = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const i = this.getSeekLeftIndex();
    if (i != null) {
      this.seekActivity(i);
    }
  };

  onClickRightArrow = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const i = this.getSeekRightIndex();
    if (i != null) {
      this.seekActivity(i);
    }
  };

  onClickPopupListButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ popupListShown: !this.state.popupListShown });
  };

  onClickOutside = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ popupListShown: false });
  };

  onClearResultButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isModalShown: true });
  };

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isModalShown: false });
  };

  onConfirmClearResult = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isModalShown: false });
    const { sessionID, currentActivityIndex } = this.props;
    if (sessionID == null) {
      return;
    }
    this.props.resetActivity(sessionID, currentActivityIndex);
  };

  shouldDisableActivityActionButton() {
    const {
      sessionID,
      activities,
      activityAction,
      currentActivityIndex,
    } = this.props;

    if (
      activityAction === "view_result" ||
      activityAction === "finish_session"
    ) {
      return sessionID == null;
    }

    const len = activities.length;
    return len <= 1 || currentActivityIndex === len - 1;
  }

  renderAnswerCount() {
    const { participantCount, answerCount } = this.props;
    return (
      <span className={styles.answerCountText}>
        <Text
          translationKey="teacher.session.footer.answer_count"
          values={{
            n: answerCount,
            d: participantCount,
          }}
        />
      </span>
    );
  }

  renderClearResultButton() {
    const { resetActivityRequest } = this.props;
    const loading = isRequesting(resetActivityRequest);
    return (
      <Button
        color="gray"
        colorApplication="border"
        className={classnames(styles.clearResultButton, {
          [styles.notLoading]: !loading,
          [styles.loading]: loading,
        })}
        onClick={this.onClearResultButtonClick}
        disabled={this.shouldDisableActivityActionButton()}
        loading={loading}
      >
        <span className={styles.clearResultButtonText}>
          <Text translationKey="teacher.session.header.button.clear_result" />
        </span>
      </Button>
    );
  }

  renderRightUpper() {
    return (
      <div className={styles.rightUpperContainer}>
        {this.renderClearResultButton()}
        {this.renderAnswerCount()}
      </div>
    );
  }

  renderActionButton() {
    const { activityAction } = this.props;
    const translationKey =
      "teacher.session.footer.action_button." + activityAction;
    const request = this.getRequestStateByActivityAction(activityAction);
    const loading = isRequesting(request);
    return (
      <Button
        color="pink"
        className={styles.actionButton}
        onClick={this.onClickActionButton}
        loading={loading}
        disabled={this.shouldDisableActivityActionButton()}
      >
        <Text translationKey={translationKey} />
      </Button>
    );
  }

  renderActivityName() {
    const { activityName } = this.props;
    return (
      <TruncatedText
        lines={1}
        fontSize={24}
        lineHeight={33}
        fontWeight={500}
        className={styles.activityName}
      >
        {activityName}
      </TruncatedText>
    );
  }

  renderActivityPopupList() {
    if (!this.state.popupListShown) {
      return null;
    }
    const { activities, currentActivityIndex } = this.props;
    return (
      <PopupWrapper onClickOutside={this.onClickOutside}>
        <div className={styles.popupList}>
          <ActivitiesPanel
            variant="teacherSession"
            activities={activities}
            activeActivityIdx={currentActivityIndex}
            onClick={this.onClickActivityPopupListItem}
          />
        </div>
      </PopupWrapper>
    );
  }

  renderActivityPopupListButton() {
    const { popupListShown } = this.state;
    const activityCount = this.props.activities.length;
    const { currentActivityIndex } = this.props;
    const adjustedIndex = currentActivityIndex + 1;
    return (
      <div
        className={classnames(
          styles.popupButton,
          "ignore-react-onclickoutside"
        )}
        onClick={this.onClickPopupListButton}
      >
        <span
          className={classnames(styles.popupButtonText, {
            [styles.popupListShown]: popupListShown,
          })}
        >
          <Text
            translationKey="teacher.session.footer.activity.progress"
            values={{
              curr: adjustedIndex,
              total: activityCount,
            }}
          />
        </span>
        {this.renderActivityPopupList()}
      </div>
    );
  }

  renderArrows() {
    const leftIndex = this.getSeekLeftIndex();
    const rightIndex = this.getSeekRightIndex();
    return (
      <div className={styles.widgetUpperRightContainer}>
        <div
          className={classnames(styles.arrowContainer, {
            [styles.disabled]: leftIndex == null,
          })}
          onClick={this.onClickLeftArrow}
        >
          <Arrow
            className={styles.arrow}
            width={15}
            height={28}
            strokeColor={
              leftIndex != null ? cssVariables.red2 : cssVariables.gray2
            }
            strokeWidth={2}
            direction="left"
          />
        </div>
        <div
          className={classnames(styles.arrowContainer, {
            [styles.disabled]: rightIndex == null,
          })}
          onClick={this.onClickRightArrow}
        >
          <Arrow
            className={styles.arrow}
            width={15}
            height={28}
            strokeColor={
              rightIndex != null ? cssVariables.red2 : cssVariables.gray2
            }
            strokeWidth={2}
            direction="right"
          />
        </div>
      </div>
    );
  }

  renderWidget() {
    const activityCount = this.props.activities.length;
    const { currentActivityIndex } = this.props;
    const adjustedIndex = currentActivityIndex + 1;
    return (
      <div className={styles.widgetContainer}>
        <div className={styles.widgetUpperContainer}>
          <div className={styles.widgetUpperLeftContainer}>
            {this.renderActivityName()}
            {this.renderActivityPopupListButton()}
          </div>
          {this.renderArrows()}
        </div>
        <ProgressBar
          color={cssVariables.red2}
          className={styles.progressBar}
          completedPercentage={adjustedIndex / activityCount}
        />
      </div>
    );
  }

  renderModal() {
    const { isModalShown } = this.state;
    if (!isModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.session.clear_result_modal_title"
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmClearResult}
          onClose={this.onModalClose}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  render() {
    return (
      <div className={styles.rootContainer}>
        <div className={styles.rightContainer}>
          {this.renderRightUpper()}
          {this.renderActionButton()}
        </div>
        <div className={styles.leftContainer}>{this.renderWidget()}</div>
        {this.renderModal()}
        <Fragment>
          <ErrorModal error={extractError(this.props.seekActivityRequest)} />
          <ErrorModal error={extractError(this.props.endActivityRequest)} />
          <ErrorModal error={extractError(this.props.endSessionRequest)} />
          <ErrorModal error={extractError(this.props.resetActivityRequest)} />
        </Fragment>
      </div>
    );
  }

  getSeekLeftIndex(): ?number {
    const { currentActivityIndex: i } = this.props;
    if (i <= 0) {
      return null;
    }
    return i - 1;
  }

  getSeekRightIndex(): ?number {
    const activityCount = this.props.activities.length;
    const { currentActivityIndex: i } = this.props;
    if (i >= activityCount - 1) {
      return null;
    }
    return i + 1;
  }

  getRequestStateByActivityAction(a: ActivityAction): ?RequestState<void> {
    switch (a) {
      case "view_result":
        return this.props.endActivityRequest;
      case "next_activity":
        return this.props.seekActivityRequest;
      case "finish_session":
        return this.props.endSessionRequest;
      default:
        return null;
    }
  }

  seekActivity(i: number) {
    const { sessionID } = this.props;
    if (sessionID != null) {
      this.props.seekActivity(sessionID, i);
    } else {
      this.props.seekPreviewActivity(i);
    }
  }
}

function mapStateToProps(state: RootState) {
  return {
    seekActivityRequest: state.sessions.seekActivityRequest,
    endActivityRequest: state.sessions.endActivityRequest,
    endSessionRequest: state.sessions.endSessionRequest,
    resetActivityRequest: state.sessions.resetActivityRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    seekActivity: bindActionCreators(seekActivity, dispatch),
    endActivity: bindActionCreators(endActivity, dispatch),
    endSession: bindActionCreators(endSession, dispatch),
    resetActivity: bindActionCreators(resetActivity, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  TeacherSessionFooter
): any): Class<React$Component<OwnProps>>);
