// @flow
import type { Action } from "../../types/store";
import type { SysConfsState } from "../../types/sysConf";
import {
  GET_ALL_SYS_CONFS_REQUESTING,
  GET_ALL_SYS_CONFS_SUCCESS,
  GET_ALL_SYS_CONFS_FAILURE,
  SET_SYS_CONFS_REQUESTING,
  SET_SYS_CONFS_SUCCESS,
  SET_SYS_CONFS_FAILURE,
} from "../../types/sysConf";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";

const INITIAL_STATE: SysConfsState = {
  sysConfById: {},
  getAllSysConfsRequest: null,
  setSysConfsRequest: null,
};

export function reducer(state: SysConfsState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case GET_ALL_SYS_CONFS_REQUESTING: {
      return {
        ...state,
        getAllSysConfsRequest: makeRequesting(),
      };
    }
    case GET_ALL_SYS_CONFS_SUCCESS: {
      const cloned = {};
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        sysConfById: cloned,
        getAllSysConfsRequest: makeSuccess(),
      };
    }
    case GET_ALL_SYS_CONFS_FAILURE: {
      return {
        ...state,
        getAllSysConfsRequest: makeFailure(action.payload),
      };
    }
    case SET_SYS_CONFS_REQUESTING: {
      return {
        ...state,
        setSysConfsRequest: makeRequesting(),
      };
    }
    case SET_SYS_CONFS_SUCCESS: {
      const cloned = {};
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        sysConfById: cloned,
        setSysConfsRequest: makeSuccess(),
      };
    }
    case SET_SYS_CONFS_FAILURE: {
      return {
        ...state,
        setSysConfsRequest: makeFailure(action.payload),
      };
    }
    default:
      return state;
  }
}
