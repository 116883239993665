// @flow
import React, { PureComponent } from "react";
import type { WhiteboardOrientation } from "../../types/activity";
import type { DrawingState, Shape } from "./DrawingState";
import {
  makeInitialState,
  setBackgroundImageURL,
  setStickerImageURLs,
  setOrientation,
  setIsBackgroundImageLoading,
  setReadOnly,
  selectStrokeColorIndex,
  selectFillColorIndex,
  selectPencilColorIndex,
  selectStrokeWidthIndex,
  selectTool,
  deselectTool,
  selectObject,
  deselectObject,
  setSelectedTextObjectContent,
  getPencilColor,
  getStrokeColor,
  getFillColor,
  isSelectedObjectText,
} from "./DrawingState";
import BrowserNotSupportModal from "../Modal/BrowserNotSupportModal";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import Toolbox from "./Toolbox";
import ResponsiveCanvas from "./ResponsiveCanvas";
import { fileType } from "../../utils/fileType";
import styles from "./DrawingBoard.module.scss";

type Props = {
  backgroundImageUrl: ?string,
  orientation: WhiteboardOrientation,
  stickerImageURLs: $ReadOnlyArray<string>,
  readOnly: boolean,
  onObjectsCountChange: number => void,
};

type LocalState = {
  lastTransitionEndMilli: ?number,
  drawingState: DrawingState,
  isModalShown: boolean,
  isFileTypeNotSupportedModalShown: boolean,
};

function isArrayOfStringEqual(
  a: $ReadOnlyArray<string>,
  b: $ReadOnlyArray<string>
): boolean {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; ++i) {
    const aa = a[i];
    const bb = b[i];
    if (aa !== bb) {
      return false;
    }
  }
  return true;
}

export default class DrawingBoard extends PureComponent<Props, LocalState> {
  canvas: ?ResponsiveCanvas;

  constructor(props: Props) {
    super(props);
    let drawingState = makeInitialState(
      props.orientation,
      props.stickerImageURLs
    );
    drawingState = setBackgroundImageURL(
      drawingState,
      props.backgroundImageUrl
    );
    drawingState = setReadOnly(drawingState, props.readOnly);
    this.state = {
      drawingState,
      lastTransitionEndMilli: null,
      isModalShown: false,
      isFileTypeNotSupportedModalShown: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState(prevState => {
      let { drawingState } = prevState;
      if (this.props.backgroundImageUrl !== nextProps.backgroundImageUrl) {
        drawingState = setBackgroundImageURL(
          drawingState,
          nextProps.backgroundImageUrl
        );
      }
      if (this.props.orientation !== nextProps.orientation) {
        drawingState = setOrientation(drawingState, nextProps.orientation);
      }
      if (
        !isArrayOfStringEqual(
          this.props.stickerImageURLs,
          nextProps.stickerImageURLs
        )
      ) {
        drawingState = setStickerImageURLs(
          drawingState,
          nextProps.stickerImageURLs
        );
      }
      if (this.props.readOnly !== nextProps.readOnly) {
        drawingState = setReadOnly(drawingState, nextProps.readOnly);
      }
      return {
        drawingState,
      };
    });
  }

  onTransitionEnd = (e: Event) => {
    this.setState({
      lastTransitionEndMilli: Date.now(),
    });
  };

  onSelectStrokeColorIndex = (i: number) => {
    const { drawingState } = this.state;
    const newState = selectStrokeColorIndex(drawingState, i);
    this.setState({
      drawingState: newState,
    });
    const color = getStrokeColor(newState);
    const { canvas } = this;
    if (canvas != null) {
      canvas.changeSelectedObjectStrokeColor(color);
    }
  };

  onSelectFillColorIndex = (i: number) => {
    const { drawingState } = this.state;
    const newState = selectFillColorIndex(drawingState, i);
    this.setState({
      drawingState: newState,
    });
    const color = getFillColor(newState);
    const { canvas } = this;
    if (canvas != null) {
      canvas.changeSelectedObjectFillColor(color);
    }
  };

  onSelectPencilColorIndex = (i: number) => {
    const { drawingState } = this.state;
    const newState = selectPencilColorIndex(drawingState, i);
    this.setState({
      drawingState: newState,
    });
    const color = getPencilColor(newState);
    const { canvas } = this;
    if (canvas != null) {
      canvas.changeSelectedObjectPencilColor(color);
    }
  };

  onSelectStrokeWidthIndex = (i: number) => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: selectStrokeWidthIndex(drawingState, i),
    });
  };

  onSelectTool = (tool: "pencil" | "eraser" | null) => {
    const { drawingState } = this.state;
    if (tool === drawingState.selectedTool) {
      this.setState({
        drawingState: deselectTool(drawingState),
      });
    } else {
      this.setState({
        drawingState: selectTool(drawingState, tool),
      });
    }
  };

  onClearButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.canvas != null) {
      this.canvas.clear();
    }
  };

  onDeleteButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.canvas != null) {
      this.canvas.deleteSelectedObject();
    }
  };

  onAddTextButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { canvas } = this;
    if (canvas == null) {
      return;
    }

    const { drawingState } = this.state;
    if (isSelectedObjectText(drawingState)) {
      canvas.deselectSelectedObject();
    } else {
      this.setState({
        drawingState: deselectTool(drawingState),
      });
      canvas.addTextObject();
    }
  };

  onAddImageButtonClick = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { canvas } = this;
    const file = e.target.files[0];
    if (file != null && canvas != null) {
      const { drawingState } = this.state;
      this.setState({
        drawingState: deselectTool(drawingState),
      });
      const reader = new FileReader();
      reader.onload = e => {
        const result = fileType(e.target.result);
        if (result != null && result.mime.startsWith("image")) {
          canvas.addImageObjectByBlob(file);
        } else {
          this.setState({ isFileTypeNotSupportedModalShown: true });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  onAddShape = (shape: Shape) => {
    const { canvas } = this;
    if (canvas != null) {
      const { drawingState } = this.state;
      this.setState({
        drawingState: deselectTool(drawingState),
      });
      canvas.addShape(shape);
    }
  };

  onAddSticker = (url: string) => {
    const { canvas } = this;
    if (canvas != null) {
      const { drawingState } = this.state;
      this.setState({
        drawingState: deselectTool(drawingState),
      });
      canvas.addImageObjectByURL(url);
    }
  };

  // eslint-disable-next-line flowtype/no-weak-types
  onSelectObject = (obj: Object) => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: selectObject(drawingState, obj),
    });
  };

  onDeselectObject = () => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: deselectObject(drawingState),
    });
  };

  onSelectedTextObjectContentChange = (text: string) => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: setSelectedTextObjectContent(drawingState, text),
    });
  };

  onBackgroundImageStartLoading = () => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: setIsBackgroundImageLoading(drawingState, true),
    });
  };

  onBackgroundImageEndLoading = () => {
    const { drawingState } = this.state;
    this.setState({
      drawingState: setIsBackgroundImageLoading(drawingState, false),
    });
  };

  onCanvasRef = (r: ResponsiveCanvas | null) => {
    this.canvas = r;
  };

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: false,
      isFileTypeNotSupportedModalShown: false,
    });
  };

  onUserInteraction = () => {
    const { canvas } = this;
    if (canvas != null) {
      canvas.deselectSelectedObject();
    }
  };

  onCanvasChange = () => {
    const { canvas } = this;
    if (canvas != null) {
      this.props.onObjectsCountChange(canvas.getObjectsCount());
    }
  };

  renderModal() {
    const { isModalShown } = this.state;
    if (!isModalShown) {
      return null;
    }
    return <BrowserNotSupportModal onClose={this.onModalClose} />;
  }

  renderFileTypeNotSupportedModal() {
    const { isFileTypeNotSupportedModalShown } = this.state;
    if (!isFileTypeNotSupportedModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <AlertModal
          colorVariant="red"
          titleKey="error.not_image_file_type"
          buttonKey="phrase.confirm"
          onButtonClick={this.onModalClose}
          onClose={this.onModalClose}
        />
      </ModalContainer>
    );
  }

  render() {
    const { drawingState } = this.state;
    return (
      <div className={styles.drawingBoardContainer}>
        <Toolbox
          onTransitionEnd={this.onTransitionEnd}
          drawingState={drawingState}
          onSelectPencilColorIndex={this.onSelectPencilColorIndex}
          onSelectStrokeColorIndex={this.onSelectStrokeColorIndex}
          onSelectFillColorIndex={this.onSelectFillColorIndex}
          onSelectStrokeWidthIndex={this.onSelectStrokeWidthIndex}
          onSelectTool={this.onSelectTool}
          onClearButtonClick={this.onClearButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
          onAddTextButtonClick={this.onAddTextButtonClick}
          onAddImageButtonClick={this.onAddImageButtonClick}
          onAddShape={this.onAddShape}
          onAddSticker={this.onAddSticker}
          onUserInteraction={this.onUserInteraction}
        />
        <ResponsiveCanvas
          ref={this.onCanvasRef}
          drawingState={drawingState}
          lastTransitionEndMilli={this.state.lastTransitionEndMilli}
          onSelectObject={this.onSelectObject}
          onDeselectObject={this.onDeselectObject}
          onSelectedTextObjectContentChange={
            this.onSelectedTextObjectContentChange
          }
          onBackgroundImageStartLoading={this.onBackgroundImageStartLoading}
          onBackgroundImageEndLoading={this.onBackgroundImageEndLoading}
          onCanvasChange={this.onCanvasChange}
        />
        {this.renderModal()}
        {this.renderFileTypeNotSupportedModal()}
      </div>
    );
  }

  // Public API
  downloadImage() {
    const { canvas } = this;
    if (canvas != null) {
      try {
        canvas.downloadImage();
      } catch (e) {
        this.setState({
          isModalShown: true,
        });
      }
    }
  }

  generateDataURL(): Promise<string> {
    const { canvas } = this;
    if (canvas != null) {
      return Promise.resolve(canvas.generateDataURL());
    }
    return Promise.reject();
  }
}
