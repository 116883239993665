// @flow
import {
  TEMPLATE_LANG_EN,
  TEMPLATE_LANG_ZH_HANT,
  TEMPLATE_LANG_ZH_HANS,
} from "../types/template";
import type { AppLocale } from "../types/app";
import type { TEMPLATE_LOCALE } from "../types/template";

export function coverTemplateLangToAppLocale(
  templateLocale: TEMPLATE_LOCALE
): AppLocale {
  switch (templateLocale) {
    case TEMPLATE_LANG_EN:
      return "en";
    case TEMPLATE_LANG_ZH_HANT:
      return "zh-Hant-HK";
    case TEMPLATE_LANG_ZH_HANS:
      return "zh-Hans-CN";
    default:
      return "zh-Hant-HK";
  }
}
