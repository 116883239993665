// @flow
import React, { PureComponent, Fragment } from "react";
import RichContent from "../RichEditor/RichContent";
import Image from "../Image/Image";
import styles from "./TeacherSessionActivity.module.scss";

type Props = {
  parentHeight: number,
  question: string,
  imageUrl: ?string,
};

export default class TeacherSessionActivityQA extends PureComponent<Props> {
  render() {
    const { imageUrl, question } = this.props;
    return (
      <Fragment>
        {imageUrl != null ? (
          <Image
            imageUrl={imageUrl}
            className={styles.questionImage}
            width={412}
            placeholderWidth={412}
            placeholderHeight={412 * 0.75}
          />
        ) : null}
        <div
          className={
            imageUrl != null
              ? styles.qaWithImageText
              : styles.qaWithoutImageText
          }
        >
          <RichContent rawContentString={question} />
        </div>
      </Fragment>
    );
  }
}
