// @flow
import React, { PureComponent } from "react";
import type { Node } from "react";
import type { PreviewSessionInfo } from "../../types/session";
import Link from "./Link";
import { encodePreviewSessionInfo } from "../../utils/session";
import { rememberSessionEncodedInfo } from "../../utils/localStorage";
import { openLinkInNewWindow } from "../../utils/window";

type Props = {
  className: string,
  previewSessionInfo: PreviewSessionInfo,
  children: Node,
};

function toStaticPath(): string {
  return "/preview_session";
}

function saveEncodedSessionInfo(info: PreviewSessionInfo) {
  // the encoded string could too long to be url parameter
  // so saved in local storage instead
  const encoded = encodePreviewSessionInfo(info);
  rememberSessionEncodedInfo(encoded);
}

export default class PreviewSessionLink extends PureComponent<Props> {
  onClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    saveEncodedSessionInfo(this.props.previewSessionInfo);
    openLinkInNewWindow({
      path: toStaticPath(),
    });
  };

  render() {
    const { className, children } = this.props;
    return (
      <Link
        className={className}
        to={toStaticPath()}
        altId="phrase.confirm"
        onClick={this.onClick}
      >
        {children}
      </Link>
    );
  }
}
