// @flow
import ensureSkygearImageBase64Format from "./ensureSkygearImageBase64Format";

export opaque type ResizeImageError = {};
export const resizeImageError: ResizeImageError = {};

export function resizeImage(
  file: File,
  max_width: number,
  max_height: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    const reader = new FileReader();

    reader.onload = function(e) {
      img.src = e.target.result;
    };

    img.addEventListener("load", () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > max_width) {
          height *= max_width / width;
          width = max_width;
        }
      } else {
        if (height > max_height) {
          width *= max_height / height;
          height = max_height;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const dataURL = canvas.toDataURL("image/png");
      resolve(ensureSkygearImageBase64Format(dataURL));
    });

    img.addEventListener("error", (e: Event) => {
      reject(resizeImageError);
    });

    reader.readAsDataURL(file);
  });
}
