// @flow
import {
  APP_CHANGE_LOCALE,
  APP_SYNC_INTL,
  SKYGEAR_INIT_REQUESTING,
  SKYGEAR_INIT_SUCCESS,
  SKYGEAR_INIT_FAILURE,
  GET_SCHOOL_REQUESTING,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAILURE,
  RESET_SCHOOL_INFO,
} from "../../types/app";
import type { AppState } from "../../types/app";
import type { Action } from "../../types/store";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";

const INITIAL_STATE: AppState = {
  locale: "zh-Hant-HK",
  intl: null,
  skygearInitRequest: null,
  getSchoolRequest: null,
  school: null,
};

export function reducer(state: AppState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case APP_CHANGE_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }
    case APP_SYNC_INTL: {
      return {
        ...state,
        intl: action.payload,
      };
    }
    case SKYGEAR_INIT_REQUESTING: {
      return {
        ...state,
        skygearInitRequest: makeRequesting(),
      };
    }
    case SKYGEAR_INIT_SUCCESS: {
      return {
        ...state,
        skygearInitRequest: makeSuccess(),
      };
    }
    case SKYGEAR_INIT_FAILURE: {
      return {
        ...state,
        skygearInitRequest: makeFailure(action.payload),
      };
    }
    case GET_SCHOOL_REQUESTING: {
      return {
        ...state,
        getSchoolRequest: makeRequesting(),
      };
    }
    case GET_SCHOOL_SUCCESS: {
      return {
        ...state,
        getSchoolRequest: makeSuccess(),
        school: action.payload,
      };
    }
    case GET_SCHOOL_FAILURE: {
      return {
        ...state,
        getSchoolRequest: makeFailure(action.payload),
      };
    }
    case RESET_SCHOOL_INFO: {
      return {
        ...state,
        school: null,
      };
    }
    default:
      return state;
  }
}
