// @flow
import React, { PureComponent } from "react";
import FormattedDate from "../FormattedDate/FormattedDate";
import Text from "../Text/Text";
import type { AuditTrail } from "../../types/auditTrail";
import styles from "./AuditTrailItem.module.scss";

type Props = {
  +auditTrail: AuditTrail,
};

const CATEGORY_TO_CONTENT_KEY = {
  unit_template: "template",
  in_progress_session: "session",
  admin_management: "user",
  school_management: "school",
  sys_conf: "sys_conf",
};

class AuditTrailItem extends PureComponent<Props> {
  getTranslationKey() {
    const { category, action } = this.props.auditTrail;
    return `admin.audit_trail.${category}.${action}`;
  }

  getContent() {
    const { category, content } = this.props.auditTrail;

    if (category === "sys_conf") {
      const parsed = content[CATEGORY_TO_CONTENT_KEY[category]];
      switch (parsed.key) {
        case "CUSTOM_TEMPLATE_MAX_NUMBER":
          return { key: "custom template max number", value: parsed.value };
        case "FORGOT_PASSWORD_EMAIL_LIST":
          return {
            key: "forgot password email list",
            value: JSON.stringify(parsed.value),
          };
        case "ACTIVITY_RECORDS_VISITABLE_DURATION_DAYS":
          return {
            key: "duration of activity records by day",
            value: parsed.value,
          };
        case "ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH":
          return {
            key: "duration of activity records by month",
            value: parsed.value,
          };
        case "DEFAULT_STICKER_LIST":
          return { key: "default sticker list", value: "[...]" };
        default:
          break;
      }
    }

    return content[CATEGORY_TO_CONTENT_KEY[category]];
  }

  render() {
    return (
      <div className={styles.rowContainer}>
        <p className={styles.dateTimeText}>
          <FormattedDate
            value={this.props.auditTrail.createdAt}
            year="numeric"
            month="numeric"
            day="numeric"
            hour12={false}
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
          />
        </p>
        <p className={styles.actor}>{this.props.auditTrail.actor}</p>
        <p className={styles.action}>
          <Text
            translationKey={this.getTranslationKey()}
            values={this.getContent()}
          />
        </p>
      </div>
    );
  }
}

export default AuditTrailItem;
