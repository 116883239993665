// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/Header/Header";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import { changeLocale } from "../../redux/actions/app";
import type { Node } from "react";
import type { RootState, Dispatch } from "../../types/store";
import type { AuthUser } from "../../types/auth";
import type { RouterHistory } from "react-router-dom";
import type { AppLocale } from "../../types/app";
import styles from "./Layout.module.scss";

type Props = {
  +user?: AuthUser,
  +children?: Node,
  +history: RouterHistory,
  +changeLocale: AppLocale => void,
};

class Layout extends PureComponent<Props> {
  componentWillReceiveProps(nextProps) {
    if (this.props.user && !nextProps.user) {
      this.props.history.replace("/admin/login");
    }
  }

  componentDidMount() {
    // admin portal always in en locale
    this.props.changeLocale("en");
  }

  render() {
    const { user, children } = this.props;
    return (
      <div className={styles.main}>
        <div className={styles.container}>
          {user && <DashboardHeader type="admin" />}
          {!user && <Header canChangeLocale={false} />}
          {children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return { user: state.auth.user };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    changeLocale: bindActionCreators(changeLocale, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
