// @flow
import type {
  RequestState,
  Requesting,
  Success,
  Failure,
} from "../types/request";

export function isRequesting<T>(request: ?RequestState<T>): boolean {
  if (request === null || request === undefined) {
    return false;
  }
  return request.type === "requesting";
}

export function isSuccess<T>(request: ?RequestState<T>): boolean {
  if (request === null || request === undefined) {
    return false;
  }
  return request.type === "success";
}

export function isFailure<T>(request: ?RequestState<T>): boolean {
  if (request === null || request === undefined) {
    return false;
  }
  return request.type === "failure";
}

export function extractError<T>(request: ?RequestState<T>): mixed {
  if (!isFailure(request)) {
    return null;
  }
  return ((request: any): Failure).error;
}

export function makeRequesting(): Requesting {
  return {
    type: "requesting",
  };
}

export function makeSuccess<T>(t: T): Success<T> {
  return {
    type: "success",
    value: t,
  };
}

export function makeFailure(e: mixed): Failure {
  return {
    type: "failure",
    error: e,
  };
}
