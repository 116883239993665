// @flow
import React from "react";
import type { Node } from "react";
import Link from "./Link";

export default function ViewTemplateLink(props: {
  templateID: string,
  className?: string,
  children: Node,
}) {
  const { templateID, className, children } = props;
  return (
    <Link
      className={className}
      to={`/template/${templateID}`}
      altId="teacher.view_template"
    >
      {children}
    </Link>
  );
}
