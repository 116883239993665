// @flow
import {
  TEMPLATE_LANG_EN,
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_TYPE_IMPORTED,
  MAX_CUSTOM_TEMPLATES_NUM,
} from "../types/template";
import { generateEmptyActivity } from "./activity";
import type { Template } from "../types/template";

export function generateEmptyImportedTemplate(): Template {
  return {
    id: "",
    baseId: null,
    displayCode: "",
    lang: TEMPLATE_LANG_EN,
    type: TEMPLATE_TYPE_IMPORTED,
    year: TEMPLATE_YEAR_FORM_1,
    name: "",
    activities: [generateEmptyActivity()],
    createdAt: new Date(),
    createdBy: "",
    nextSchoolLocalCode: 0,
    customTemplateCount: 0,
    canCreateCustomTemplate: true,
    customTemplateMaxNumber: MAX_CUSTOM_TEMPLATES_NUM,
    isImported: true,
    activityRecordsCount: 0,
  };
}
