// @flow
import React, { PureComponent, Fragment } from "react";
import type { TEMPLATE_LOCALE } from "../../types/template";
import type { PreviewSessionInfo } from "../../types/session";
import PreviewSessionLink from "../Link/PreviewSessionLink";
import ActivityQAPanel from "./ActivityQAPanel";
import ActivityPollingPanel from "./ActivityPollingPanel";
import ActivityWhiteboardPanel from "./ActivityWhiteboardPanel";
import IconButton from "../IconButton/IconButton";
import trashCanIcon from "../../images/trash_can.svg";
import previewIcon from "../../images/preview.svg";
import type { CommonProps } from "./types/ActivityPanelProps";
import styles from "./ActivityPanel.module.scss";

type OwnProps = {
  templateName: string,
  templateDisplayCode: string,
  templateLang: TEMPLATE_LOCALE,
};

type Props = CommonProps & OwnProps;

function toPreviewSessionInfo(props: Props): PreviewSessionInfo {
  return {
    templateName: props.templateName,
    templateDisplayCode: props.templateDisplayCode,
    templateLang: props.templateLang,
    activities: [props.activity],
  };
}

class ActivityPanel extends PureComponent<Props> {
  static defaultProps = {
    editable: false,
  };

  onRemoveActivity = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { onRemoveActivity } = this.props;

    if (onRemoveActivity != null) {
      onRemoveActivity();
    }
  };

  render() {
    const { activity, editable } = this.props;

    return (
      <Fragment>
        {activity &&
          activity.type === "qa" && <ActivityQAPanel {...this.props} />}
        {activity &&
          activity.type === "polling" && (
            <ActivityPollingPanel {...this.props} />
          )}
        {activity &&
          activity.type === "whiteboard" && (
            <ActivityWhiteboardPanel {...this.props} />
          )}
        {editable && (
          <div className={styles.activityActionsContainer}>
            <IconButton
              className={styles.removeActivity}
              icon={trashCanIcon}
              labelTranslationKey="teacher.remove_activity"
              onClick={this.onRemoveActivity}
            />
            <PreviewSessionLink
              className=""
              previewSessionInfo={toPreviewSessionInfo(this.props)}
            >
              <IconButton
                className={styles.previewActivity}
                icon={previewIcon}
                labelTranslationKey="teacher.preview_activity"
              />
            </PreviewSessionLink>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ActivityPanel;
