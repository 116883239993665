// @flow
import { Component } from "react";

export type WindowSizeProps = {
  width: number,
  height: number,
};

type Props = {
  children: (props: WindowSizeProps) => React$Node,
};

type LocalState = {
  width: number,
  height: number,
};

export default class WindowSize extends Component<Props, LocalState> {
  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  render() {
    const { children } = this.props;
    const { width, height } = this.state;
    if (width === 0 && height === 0) {
      return null;
    }
    return children({
      width,
      height,
    });
  }
}
