// @flow
import React from "react";
import type { Node } from "react";
import Link from "./Link";

export default function CreateImportedTemplateLink(props: {
  className?: string,
  children?: Node,
}) {
  const { className, children } = props;
  return (
    <Link
      className={className}
      to={`/admin/template_management/create`}
      altId="admin.template.edit_template"
    >
      {children}
    </Link>
  );
}
