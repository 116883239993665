// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { bindActionCreators } from "redux";
import { joinSession } from "../../redux/actions/sessions";
import type { RootState, Dispatch } from "../../types/store";
import type {
  SessionAndParticipant,
  ParticipationInfo,
} from "../../types/session";
import {
  rememberParticipationInfo,
  findParticipationInfo,
} from "../../utils/localStorage";
import { extractError } from "../../utils/request";
import logoURL from "../../images/app_logo.svg";
import WindowSize from "../../components/WindowSize";
import Text from "../../components/Text/Text";
import InputWithErrorMessage from "../../components/Input/InputWithErrorMessage";
import Button from "../../components/Button/Button";
import LocaleSwitch from "../../components/LocaleSwitch/LocaleSwitch";
import ModalContainer from "../../components/Modal/ModalContainer";
import ResponsiveLayout from "../../components/ResponsiveLayout";
import LocaleLayout from "../../components/LocaleLayout";
import ErrorModal from "../../components/ErrorModal";
import styles from "./JoinSessionScreen.module.scss";

type Props = {
  prefillCode: ?string,
  joinSession: (
    participationCode: string,
    name: string
  ) => Promise<SessionAndParticipant>,
  joinSessionError: mixed,
};

type LocalState = {|
  isHelpModalShown: boolean,
  noParticipantCodeError: boolean,
  noNameError: boolean,
  participationCode: string,
  name: string,
  redirect: boolean,
  existingParticipationInfo: ?ParticipationInfo,
|};

function sanitizeParticipationCode(rawInput: string): string {
  // Since the participation code consists of uppercase ASCII characters
  // and digits,
  // we always uppercase the value
  return rawInput
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "") // remove invalid characters
    .slice(0, 6); // it is safe to use slice because characters are ASCII
}

function validateParticipationCode(participationCode: string): ?string {
  // It is safe to use .length because characters are ASCII
  if (participationCode.length === 6) {
    return participationCode;
  }
  return null;
}

function validateName(name: string): ?string {
  // Just ensure it is not empty
  const trimmed = name.trim();
  if (trimmed !== "") {
    return trimmed;
  }
  return null;
}

class JoinSessionScreen extends PureComponent<Props, LocalState> {
  constructor(props, context) {
    super(props, context);

    // Initialize participationCode and existingParticipationInfo
    const participationCode = sanitizeParticipationCode(
      props.prefillCode || ""
    );
    const existingParticipationInfo = findParticipationInfo(participationCode);

    this.state = {
      participationCode,
      existingParticipationInfo,
      isHelpModalShown: false,
      noParticipantCodeError: false,
      noNameError: false,
      name: "",
      redirect: false,
    };
  }

  onHelpButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isHelpModalShown: true });
  };

  onHelpModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isHelpModalShown: false });
  };

  onParticipationCodeChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const value = target.value;

      // Sanitize
      const code = sanitizeParticipationCode(value);
      // Validate
      const validParticipationCode = validateParticipationCode(code);
      // If the code is valid, then we try to see if we
      // have participated in it before
      if (validParticipationCode != null) {
        const info = findParticipationInfo(validParticipationCode);
        this.setState({
          participationCode: code,
          existingParticipationInfo: info,
        });
      } else {
        this.setState({
          participationCode: code,
          existingParticipationInfo: null,
        });
      }
    }
  };

  onNameChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const value = target.value;
      this.setState({
        name: value,
      });
    }
  };

  onJoinButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.joinSession();
  };

  renderHelpModal() {
    const { isHelpModalShown } = this.state;
    if (!isHelpModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onHelpModalClose}>
        <div className={styles.howToJoinModalContent}>
          <div
            className={styles.howToJoinModalCloseButton}
            onClick={this.onHelpModalClose}
          />
          <p className={styles.howToJoinModalTitleText}>
            <Text translationKey="student.join.howto" />
          </p>
          <figure className={styles.howToJoinModalImage} />
        </div>
      </ModalContainer>
    );
  }

  renderForm() {
    const {
      existingParticipationInfo,
      noParticipantCodeError,
      noNameError,
    } = this.state;
    // We either use the form value or the existing name
    let name = "";
    if (existingParticipationInfo != null) {
      name = existingParticipationInfo.participantName;
    } else {
      name = this.state.name;
    }

    return (
      <form onSubmit={this.onJoinButtonClick} className={styles.formContainer}>
        <p className={styles.formTitle}>
          <Text translationKey="student.join.form_title" />
        </p>
        <div className={styles.participationInputContainer}>
          <InputWithErrorMessage
            className={styles.participationInput}
            placeholderId="student.join.participation_code"
            labelId="student.join.participation_code"
            value={this.state.participationCode}
            onChange={this.onParticipationCodeChange}
            errorTextKey={noParticipantCodeError ? "student.join.howto" : null}
            type="text"
          />
          <div
            className={styles.participationInputHelpButton}
            onClick={this.onHelpButtonClick}
          >
            {"?"}
          </div>
        </div>
        <InputWithErrorMessage
          className={styles.nameInput}
          placeholderId="student.join.name"
          labelId="student.join.name"
          value={name}
          onChange={this.onNameChange}
          disabled={existingParticipationInfo != null}
          errorTextKey={noNameError ? "error.student.join.missing_name" : null}
          type="text"
        />
        <Button type="submit" color="pink" className={styles.joinButton}>
          <Text translationKey="student.join.join" />
        </Button>
      </form>
    );
  }

  renderHowToJoin() {
    return (
      <div className={styles.howToJoinContainer}>
        <p className={styles.howToJoinText}>
          <Text translationKey="student.join.howto" />
        </p>
        <figure className={styles.howToJoinImage} />
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      const { participationCode } = this.state;
      return (
        <Redirect push={false} to={`/student/session/${participationCode}`} />
      );
    }

    return (
      <Fragment>
        <div className={styles.background} />
        <WindowSize>
          {props => {
            const h = props.height;
            return (
              <div
                style={{ minHeight: h + "px" }}
                className={styles.contentContainer}
              >
                <div className={styles.header}>
                  <img className={styles.logoImage} src={logoURL} alt="logo" />
                  <LocaleSwitch
                    variant="header"
                    className={styles.localeSwitch}
                  />
                  <LocaleLayout>
                    {props => {
                      return (
                        <h1
                          className={
                            props.isEn ? styles.enTitleText : styles.titleText
                          }
                        >
                          <Text translationKey="teacher.interactive_learning_platform" />
                        </h1>
                      );
                    }}
                  </LocaleLayout>
                </div>
                <div className={styles.main}>
                  {this.renderHowToJoin()}
                  {this.renderForm()}
                </div>
                <ErrorModal error={this.props.joinSessionError} />
              </div>
            );
          }}
        </WindowSize>
        <ResponsiveLayout>
          {deviceType =>
            deviceType === "desktop" ? null : this.renderHelpModal()
          }
        </ResponsiveLayout>
      </Fragment>
    );
  }

  joinSession() {
    const { existingParticipationInfo } = this.state;
    // 1. We validate the user input
    const participationCode = validateParticipationCode(
      this.state.participationCode
    );
    // If there is an existing name, use it.
    let name = null;
    if (existingParticipationInfo != null) {
      name = existingParticipationInfo.participantName;
    } else {
      name = this.state.name;
    }
    name = validateName(name);

    if (participationCode == null || name == null) {
      this.setState({
        noParticipantCodeError: participationCode == null,
        noNameError: name == null,
      });
      return;
    }
    this.setState({ noParticipantCodeError: false, noNameError: false });

    // 2. Try to find an existing participation
    // If such participation is found, redirect immediately
    // without joining
    const info = findParticipationInfo(participationCode);
    if (info != null) {
      this.setState({
        redirect: true,
      });
      return;
    }

    // 3. Otherwise join the session and persist the participation
    this.props.joinSession(participationCode, name).then(sna => {
      const { session, participant } = sna;
      rememberParticipationInfo({
        participationCode,
        sessionId: session.id,
        participantId: participant.id,
        participantName: participant.name,
      });
      this.setState({
        redirect: true,
      });
    });
  }
}

function mapStateToProps(state: RootState) {
  return {
    joinSessionError: extractError(state.sessions.joinSessionRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    joinSession: bindActionCreators(joinSession, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinSessionScreen);
