// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import Button from "../Button/Button";
import styles from "./SubmitButton.module.scss";

type Props = {
  className: string,
  disabled: boolean,
  loading: boolean,
  submitted: boolean,
  onSubmit: (e: Event) => void,
};

export default class SubmitButton extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    const { submitted, disabled, loading } = this.props;
    const isDisabled = disabled || loading || submitted;
    return (
      <Button
        color="pink"
        colorApplication={submitted ? "borderAndText" : "background"}
        className={this.props.className}
        loading={loading}
        onClick={this.props.onSubmit}
        disabled={isDisabled}
      >
        <span
          className={classnames(styles.buttonText, {
            [styles.submit]: !submitted,
            [styles.submitted]: submitted,
          })}
        >
          <Text
            translationKey={
              submitted ? "student.submitted_answer" : "student.submit_answer"
            }
          />
        </span>
      </Button>
    );
  }
}
