// @flow
export type ActivityType = "qa" | "polling" | "whiteboard";

export const ActivityPanelItemDnDType: "ActivityPanelItem" =
  "ActivityPanelItem";

export type PollingOptionResponse = {
  +text: string,
  +image_url: ?string,
  +image_id: ?string,
};

export type StickerResponse = {
  +image_url: string,
  +image_id: string,
};

export type WhiteboardBackground = "empty" | "image";

export type WhiteboardOrientation = "landscape" | "portrait";

export type ActivityResponse = {
  +type: ActivityType,
  +name: string,
  +question: string,
  +image_url: ?string,
  +image_id: ?string,
  +whiteboard_background: WhiteboardBackground,
  +whiteboard_image_url: ?string,
  +whiteboard_image_id: ?string,
  +whiteboard_orientation: WhiteboardOrientation,
  +whiteboard_stickers: $ReadOnlyArray<StickerResponse>,
  +polling_multiple_choice: ?boolean,
  +polling_named: ?boolean,
  +polling_options: $ReadOnlyArray<PollingOptionResponse>,
};

export type PollingOption = {
  +text: string,
  +imageUrl: ?string,
  +imageId: ?string,
};

export type Sticker = {
  +imageUrl: string,
  +imageId: string,
};

export type Activity = {
  +type: ActivityType,
  +name: string,
  +question: string,
  +imageId: ?string,
  +imageUrl: ?string,
  +whiteboardBackground: WhiteboardBackground,
  +whiteboardImageId: ?string,
  +whiteboardImageUrl: ?string,
  +whiteboardOrientation: WhiteboardOrientation,
  +whiteboardStickers: $ReadOnlyArray<Sticker>,
  +pollingNamed: ?boolean,
  +pollingMultipleChoice: ?boolean,
  +pollingOptions: ?$ReadOnlyArray<PollingOption>,
};

export type ActivityStateVariant = "accepting_answer" | "viewing_result";

export type Answer = {|
  qaText: ?string,
  whiteboardImageId: ?string,
  whiteboardImageUrl: ?string,
  pollingOptionIndice: ?$ReadOnlyArray<number>,
|};

export type ActivityState = {|
  state: ActivityStateVariant,
  answerByParticipantId: { [id: string]: Answer },
  resetAt: ?Date,
|};

export type AnswerResponse = {|
  qa_text: ?string,
  whiteboard_image_id: ?string,
  whiteboard_image_url: ?string,
  polling_option_indice: ?$ReadOnlyArray<number>,
|};

export type ActivityStateResponse = {|
  state: ActivityStateVariant,
  answer_by_participant_id: { [id: string]: AnswerResponse },
  reset_at?: string,
|};
