// @flow
import {
  createStore as createReduxStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import type { Store } from "../types/store";
import thunk from "redux-thunk";
import { reducer as appReducer } from "./reducers/app";
import { reducer as authReducer } from "./reducers/auth";
import { reducer as sessionsReducer } from "./reducers/sessions";
import { reducer as templatesReducer } from "./reducers/templates";
import { reducer as schoolsReducer } from "./reducers/schools";
import { reducer as usersReducer } from "./reducers/users";
import { reducer as auditTrailsReducer } from "./reducers/auditTrails";
import { reducer as sysConfsReducer } from "./reducers/sysConfs";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  sessions: sessionsReducer,
  templates: templatesReducer,
  schools: schoolsReducer,
  users: usersReducer,
  auditTrails: auditTrailsReducer,
  sysConfs: sysConfsReducer,
});

export function createStore(): Store {
  const store = createReduxStore(rootReducer, applyMiddleware(thunk));
  return store;
}
