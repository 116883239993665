// @flow
import type { Action } from "../../types/store";
import type { AuditTrailsState } from "../../types/auditTrail";
import {
  SEARCH_AUDIT_TRAILS_REQUESTING,
  SEARCH_AUDIT_TRAILS_SUCCESS,
  SEARCH_AUDIT_TRAILS_FAILURE,
} from "../../types/auditTrail";
import { makeRequesting, makeSuccess, makeFailure } from "../../utils/request";

const INITIAL_STATE: AuditTrailsState = {
  auditTrailById: {},
  searchAuditTrailsRequest: null,
};

export function reducer(
  state: AuditTrailsState = INITIAL_STATE,
  action: Action
) {
  switch (action.type) {
    case SEARCH_AUDIT_TRAILS_REQUESTING: {
      return {
        ...state,
        searchAuditTrailsRequest: makeRequesting(),
      };
    }
    case SEARCH_AUDIT_TRAILS_SUCCESS: {
      const cloned = { ...state.auditTrailById };
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        auditTrailById: cloned,
        searchAuditTrailsRequest: makeSuccess(),
      };
    }
    case SEARCH_AUDIT_TRAILS_FAILURE: {
      return {
        ...state,
        searchAuditTrailsRequest: makeFailure(action.payload),
      };
    }
    default:
      return state;
  }
}
