// @flow
import skygear from "skygear";
import {
  GET_ALL_SYS_CONFS_REQUESTING,
  GET_ALL_SYS_CONFS_SUCCESS,
  GET_ALL_SYS_CONFS_FAILURE,
  SET_SYS_CONFS_REQUESTING,
  SET_SYS_CONFS_SUCCESS,
  SET_SYS_CONFS_FAILURE,
} from "../../types/sysConf";
import { deserializeSysConf } from "../../utils/deserializer";
import type {
  SysConf,
  GetAllSysConfsRequesting,
  GetAllSysConfsSuccess,
  GetAllSysConfsFailure,
  SetSysConfsRequesting,
  SetSysConfsSuccess,
  SetSysConfsFailure,
} from "../../types/sysConf";
import type { Dispatch, GetState } from "../../types/store";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";

function getAllSysConfsRequesting(): GetAllSysConfsRequesting {
  return {
    type: GET_ALL_SYS_CONFS_REQUESTING,
  };
}

function getAllSysConfsSuccess(
  sysConfs: $ReadOnlyArray<SysConf>
): GetAllSysConfsSuccess {
  return {
    type: GET_ALL_SYS_CONFS_SUCCESS,
    payload: sysConfs,
  };
}

function getAllSysConfsFailure(error: mixed): GetAllSysConfsFailure {
  return {
    type: GET_ALL_SYS_CONFS_FAILURE,
    payload: error,
  };
}

export function getAllSysConfs() {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<$ReadOnlyArray<SysConf>> => {
    const currentState = getState();
    if (isRequesting(currentState.sysConfs.getAllSysConfsRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(getAllSysConfsRequesting());
    const args = {};
    return skygear
      .lambda("polyupaths:sys_conf:get_all", [args])
      .then(result => {
        const sysConfs = result.sys_confs.map(deserializeSysConf);
        dispatch(getAllSysConfsSuccess(sysConfs));
        return sysConfs;
      })
      .catch(error => {
        dispatch(getAllSysConfsFailure(error));
        return Promise.reject(error);
      });
  };
}

function setSysConfsRequesting(): SetSysConfsRequesting {
  return {
    type: SET_SYS_CONFS_REQUESTING,
  };
}

function setSysConfsSuccess(
  sysConfs: $ReadOnlyArray<SysConf>
): SetSysConfsSuccess {
  return {
    type: SET_SYS_CONFS_SUCCESS,
    payload: sysConfs,
  };
}

function setSysConfsFailure(error: mixed): SetSysConfsFailure {
  return {
    type: SET_SYS_CONFS_FAILURE,
    payload: error,
  };
}

export function setSysConfs(sysConfs: $ReadOnlyArray<SysConf>) {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<$ReadOnlyArray<SysConf>> => {
    const currentState = getState();
    if (isRequesting(currentState.sysConfs.getAllSysConfsRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(setSysConfsRequesting());
    const args = {
      sys_confs: sysConfs,
    };
    return skygear
      .lambda("polyupaths:sys_conf:set", [args])
      .then(result => {
        const sysConfs = result.sys_confs.map(deserializeSysConf);
        dispatch(setSysConfsSuccess(sysConfs));
        return sysConfs;
      })
      .catch(error => {
        dispatch(setSysConfsFailure(error));
        return Promise.reject(error);
      });
  };
}
