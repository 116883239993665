// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import TabBar from "../../components/TabBar/TabBar";
import type { TabItem } from "../../components/TabBar/TabBar";
import SchoolAccountManagementLink from "../../components/Link/SchoolAccountManagementLink";
import TemplateManagementLink from "../../components/Link/TemplateManagementLink";
import RecordsManagementLink from "../../components/Link/RecordsManagementLink";
import AdminUserManagementLink from "../../components/Link/AdminUserManagementLink";
import AuditTrailLink from "../../components/Link/AuditTrailLink";
import SystemConfigurationLink from "../../components/Link/SystemConfigurationLink";
import WindowSize from "../../components/WindowSize";
import RecordsManagement from "./RecordsManagement";
import SchoolAccountManagement from "./SchoolAccountManagement";
import AdminUserManagement from "./AdminUserManagement";
import TemplateManagement from "./TemplateManagement";
import AuditTrailManagement from "./AuditTrailManagement";
import SysConfManagement from "./SysConfManagement";
import { UserTypeSuperAdmin, UserTypeEditor } from "../../types/user";
import type { Location } from "react-router-dom";
import type { RouterHistory } from "react-router-dom";
import type { RootState, Dispatch } from "../../types/store";
import type { AuthUser } from "../../types/auth";
import styles from "./Dashboard.module.scss";

export const SCHOOL_ACCOUNT_MANAGEMENT: "schoolAccountManagement" =
  "schoolAccountManagement";
export const TEMPLATE_MANAGEMENT: "templateManagement" = "templateManagement";
export const RECORDS_MANAGEMENT: "recordsManagement" = "recordsManagement";
export const ADMIN_USER_MANAGEMENT: "adminUserManagement" =
  "adminUserManagement";
export const AUDIT_TRAIL: "auditTrail" = "auditTrail";
export const SYSTEM_CONFIGURATION: "systemConfiguration" =
  "systemConfiguration";

type Props = {
  +currentUser: ?AuthUser,
  +history: RouterHistory,
  +location?: Location,
  +tab:
    | typeof SCHOOL_ACCOUNT_MANAGEMENT
    | typeof TEMPLATE_MANAGEMENT
    | typeof RECORDS_MANAGEMENT
    | typeof ADMIN_USER_MANAGEMENT
    | typeof AUDIT_TRAIL
    | typeof SYSTEM_CONFIGURATION,
};

const ADMIN_TAB_BAR_ITEMS: $ReadOnlyArray<TabItem> = [
  {
    id: SCHOOL_ACCOUNT_MANAGEMENT,
    labelId: "admin.school_account_management",
    LinkComponent: SchoolAccountManagementLink,
    className: styles.schoolAccountManagement,
    bottomBarClassName: styles.schoolAccountManagementBottomBar,
  },
  {
    id: TEMPLATE_MANAGEMENT,
    labelId: "admin.template_management",
    LinkComponent: TemplateManagementLink,
    className: styles.templateManagement,
    bottomBarClassName: styles.templateManagementBottomBar,
  },
  {
    id: RECORDS_MANAGEMENT,
    labelId: "admin.records_management",
    LinkComponent: RecordsManagementLink,
    className: styles.recordsManagement,
    bottomBarClassName: styles.recordsManagementBottomBar,
  },
  {
    id: ADMIN_USER_MANAGEMENT,
    labelId: "admin.admin_user_management",
    LinkComponent: AdminUserManagementLink,
    className: styles.adminUserManagement,
    bottomBarClassName: styles.adminUserManagementBottomBar,
  },
  {
    id: AUDIT_TRAIL,
    labelId: "admin.audit_trail",
    LinkComponent: AuditTrailLink,
    className: styles.auditTrail,
    bottomBarClassName: styles.auditTrailBottomBar,
  },
  {
    id: SYSTEM_CONFIGURATION,
    labelId: "admin.system_configuration",
    LinkComponent: SystemConfigurationLink,
    className: styles.systemConfiguration,
    bottomBarClassName: styles.systemConfigurationBottomBar,
  },
];

const EDITOR_TAB_BAR_ITEMS: $ReadOnlyArray<TabItem> = [
  {
    id: TEMPLATE_MANAGEMENT,
    labelId: "admin.template_management",
    LinkComponent: TemplateManagementLink,
    className: styles.templateManagement,
    bottomBarClassName: styles.templateManagementBottomBar,
  },
  {
    id: RECORDS_MANAGEMENT,
    labelId: "admin.records_management",
    LinkComponent: RecordsManagementLink,
    className: styles.recordsManagement,
    bottomBarClassName: styles.recordsManagementBottomBar,
  },
];

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// layout tab bar: 56px;
const MAGIC_HEIGHT_OFFSET = 194;

class Dashboard extends PureComponent<Props> {
  render() {
    const { currentUser, tab, ...rest } = this.props;
    return (
      <Fragment>
        <TabBar
          items={
            currentUser == null
              ? []
              : currentUser.userType === UserTypeSuperAdmin
                ? ADMIN_TAB_BAR_ITEMS
                : currentUser.userType === UserTypeEditor
                  ? EDITOR_TAB_BAR_ITEMS
                  : []
          }
          activeTabId={tab}
          multiLineTabText={true}
        />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            return (
              <div
                style={{ minHeight: h + "px" }}
                className={styles.dashboardContainer}
              >
                {tab === SCHOOL_ACCOUNT_MANAGEMENT && (
                  <SchoolAccountManagement {...rest} />
                )}
                {tab === ADMIN_USER_MANAGEMENT && (
                  <AdminUserManagement {...rest} />
                )}
                {tab === TEMPLATE_MANAGEMENT && (
                  <TemplateManagement {...rest} />
                )}
                {tab === RECORDS_MANAGEMENT && <RecordsManagement {...rest} />}
                {tab === AUDIT_TRAIL && <AuditTrailManagement {...rest} />}
                {tab === SYSTEM_CONFIGURATION && (
                  <SysConfManagement {...rest} />
                )}
              </div>
            );
          }}
        </WindowSize>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
