// @flow
import styles from "./TeacherSessionActivityResult.module.scss";
import type { Participant } from "../../types/session";
import type { ActivityState } from "../../types/activity";
import type { ParticipantAnswer } from "../../utils/session";

// The height handling here is very tricky.
// Basically we want the question part to take at most half of the
// parent height.
// And the content part take remaining height.
// But we cannot use flexbox, so we have to calculate ourselves.

export const questionContainerPadding = Number(styles.questioncontainerpadding);
export const rootContainerPadding = Number(styles.rootcontainerpadding);
const contentPartMargin = Number(styles.contentpartmargin);

export function getHeightForContentContainer(parentHeight: number): number {
  return parentHeight - 2 * rootContainerPadding;
}

export function getMaxHeightForQuestion(parentHeight: number): number {
  const totalContentHeight =
    parentHeight - 2 * rootContainerPadding - contentPartMargin;
  return totalContentHeight / 2;
}

export function getExactHeightForContent(
  parentHeight: number,
  questionHeight: number
): number {
  return (
    parentHeight - 2 * rootContainerPadding - questionHeight - contentPartMargin
  );
}

export function toParticipantAnswerArray(
  participants: $ReadOnlyArray<Participant>,
  activityState: ActivityState
): $ReadOnlyArray<ParticipantAnswer> {
  const output = [];
  const { answerByParticipantId } = activityState;
  for (const p of participants) {
    const answer = answerByParticipantId[p.id];
    if (answer != null) {
      output.push({
        answer,
        participant: p,
      });
    }
  }
  return output;
}
