// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import styles from "./TabBar.module.scss";

export type TabItem = {
  +id: string,
  +labelId: string,
  +LinkComponent: React$ElementType,
  +className?: string,
  +bottomBarClassName?: string,
};

type Props = {
  +items: $ReadOnlyArray<TabItem>,
  +activeTabId: string,
  +multiLineTabText: boolean,
};

class TabBar extends PureComponent<Props> {
  static defaultProps = {
    multiLineTabText: false,
  };

  renderItem(
    item: TabItem,
    idx: number,
    activeTabId: string,
    totalItems: number,
    multiLineTabText: boolean
  ) {
    const { LinkComponent } = item;
    const className = classnames(
      { [styles.tabBarItemEven]: idx % 2 === 0 },
      { [styles.tabBarItemOdd]: idx % 2 === 1 },
      { [styles.tabBarItemActive]: activeTabId === item.id },
      { [styles.tabBarWidth1of4]: totalItems <= 4 },
      { [styles.tabBarWidth1of6]: totalItems <= 6 },
      item.className
    );
    return (
      <LinkComponent key={item.id} className={className}>
        <p
          className={classnames(
            { [styles.tabBarItemText]: !multiLineTabText },
            { [styles.tabBarItemMultipleText]: multiLineTabText }
          )}
        >
          <Text translationKey={item.labelId} />
        </p>
      </LinkComponent>
    );
  }

  renderBottomBar(items: $ReadOnlyArray<TabItem>, activeTabId: string) {
    const activeItem = items.find(item => item.id === activeTabId);
    const activeTabIdx = items.findIndex(item => item.id === activeTabId);

    if (activeItem === undefined || activeTabIdx === -1) {
      return null;
    }

    return (
      <div
        className={classnames(
          { [styles.bottomBarEven]: activeTabIdx % 2 === 0 },
          { [styles.bottomBarOdd]: activeTabIdx % 2 === 1 },
          activeItem.bottomBarClassName
        )}
      />
    );
  }

  render() {
    const { items, activeTabId, multiLineTabText } = this.props;
    return (
      <div className={styles.tabBar}>
        {items.map((item, idx) =>
          this.renderItem(
            item,
            idx,
            activeTabId,
            items.length,
            multiLineTabText
          )
        )}
        {this.renderBottomBar(items, activeTabId)}
      </div>
    );
  }
}

export default TabBar;
