// @flow
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchActivityRecords,
  deleteAnswer,
} from "../../redux/actions/sessions";
import { fetchOne } from "../../redux/actions/templates";
import WindowSize from "../../components/WindowSize";
import ActivityRecordsHeader from "../../components/ActivityRecordsHeader/ActivityRecordsHeader";
import ActivitiesPanel from "../../components/ActivitiesPanel/ActivitiesPanel";
import ActivityRecordPanel from "../../components/ActivityRecordPanel/ActivityRecordPanel";
import Text from "../../components/Text/Text";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Select from "../../components/Select/Select";
import { simpleFormat } from "../../utils/dateTime";
import type { RootState, Dispatch } from "../../types/store";
import type { ContextRouter } from "react-router-dom";
import type { Session } from "../../types/session";
import type { Template } from "../../types/template";
import { isRequesting, extractError } from "../../utils/request";
import ErrorModal from "../../components/ErrorModal";
import styles from "./styles/ActivityRecords.module.scss";

type Props = {
  +id: string,
  +isRequesting: boolean,
  +fetchActivityRecordsError: mixed,
  +fetchTemplateError: mixed,
  +template: ?Template,
  +recordsByTemplateId: { [id: string]: $ReadOnlyArray<Session> },
  +fetchActivityRecords: string => void,
  +fetchOne: string => void,
  +deleteAnswer: (data: {
    +sessionId: string,
    +activityIndex: number,
    +participantId: string,
  }) => Promise<Session>,
};

type LocalState = {|
  selectedRecordIdx: number,
  activeActivityIdx: number,
|};

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
const MAGIC_HEIGHT_OFFSET = 138;

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// templateContainer border-top: 8px
// templateContainer header: 86px
// templateContainer header border-bottom: 1px
// templateContainer paddingTop: 28px;
// layout footer: 44px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 305;

class ActivityRecordsComponent extends PureComponent<Props, LocalState> {
  state = {
    selectedRecordIdx: 0,
    activeActivityIdx: 0,
  };

  componentDidMount() {
    this.props.fetchOne(this.props.id);
    this.props.fetchActivityRecords(this.props.id);
  }

  getRecords(): $ReadOnlyArray<Session> {
    const { id, recordsByTemplateId } = this.props;
    return recordsByTemplateId != null && recordsByTemplateId[id]
      ? recordsByTemplateId[id]
      : [];
  }

  getActiveRecord(): ?Session {
    const records = this.getRecords();
    const { selectedRecordIdx } = this.state;

    if (records.length === 0 || selectedRecordIdx >= records.length) {
      return null;
    }

    return records[selectedRecordIdx];
  }

  onClickActivityPanelItem = idx => {
    const record = this.getActiveRecord();

    if (record != null && idx >= 0 && idx < record.activities.length) {
      this.setState({ activeActivityIdx: idx });
    }
  };

  onRecordChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const records = this.getRecords();
    const recordId = event.target.value;
    const recordIdx = records.findIndex(e => e.id === recordId);

    if (recordIdx !== -1) {
      this.setState({ selectedRecordIdx: recordIdx });
    }
  };

  onDeleteAnswer = participantID => {
    const session = this.getActiveRecord();
    const { activeActivityIdx } = this.state;

    if (session == null) {
      return;
    }

    this.props
      .deleteAnswer({
        sessionId: session.id,
        activityIndex: activeActivityIdx,
        participantId: participantID,
      })
      .then(session => {
        this.props.fetchActivityRecords(this.props.id);
      });
  };

  renderActivityRecordText = (o: Session) => {
    return o.teacherName + " - " + simpleFormat(o.endedAt);
  };

  renderActivityRecordOption = (o: Session) => {
    return (
      <option key={o.id} value={o.id}>
        {this.renderActivityRecordText(o)}
      </option>
    );
  };

  renderRecordSelector() {
    const records = this.getRecords();
    const activeRecord = this.getActiveRecord();

    if (records.length === 0 || activeRecord == null) {
      return null;
    }

    return (
      <div className={styles.selectorContainer}>
        <Select
          value={activeRecord.id}
          onChange={this.onRecordChange}
          className={styles.recordSelector}
        >
          <p className={styles.selectOptionText}>
            {activeRecord == null
              ? null
              : this.renderActivityRecordText(activeRecord)}
          </p>
          {records.map(this.renderActivityRecordOption)}
        </Select>
      </div>
    );
  }

  renderActivitiesPanel() {
    const record = this.getActiveRecord();
    if (record == null) {
      return null;
    }

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.activitiesPanel}
            >
              <p className={styles.activitiesTitle}>
                <Text translationKey="teacher.select_activity_records" />
              </p>
              {this.renderRecordSelector()}
              <p className={styles.activitiesTitle}>
                <Text
                  translationKey="teacher.number_of_activities2"
                  values={{ numberOfActivities: record.activities.length }}
                />
              </p>
              <ActivitiesPanel
                variant="teacherPortal"
                activities={record.activities}
                activeActivityIdx={this.state.activeActivityIdx}
                onClick={this.onClickActivityPanelItem}
              />
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderActivityResultPanel() {
    const record = this.getActiveRecord();
    const { activeActivityIdx } = this.state;

    if (record == null) {
      return null;
    }

    return (
      <div className={styles.activityResultPanel}>
        <ActivityRecordPanel
          activityIndex={activeActivityIdx}
          session={record}
          activity={record.activities[activeActivityIdx]}
          activityState={record.activityStates[activeActivityIdx]}
          onDeleteAnswer={this.onDeleteAnswer}
        />
      </div>
    );
  }

  renderEmpty() {
    const { isRequesting } = this.props;

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{
                minHeight: h + "px",
              }}
              className={styles.recordsContainer}
            >
              {isRequesting && <LoadingIndicator />}
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderNoRecords(template: Template) {
    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.recordsContainer}
            >
              <ActivityRecordsHeader
                id={this.props.id}
                displayCode={template.displayCode}
                year={template.year}
                name={template.name}
              />
              <p className={styles.noActivityRecordsText}>
                <Text translationKey="teacher.no_activity_records" />
              </p>
            </div>
          );
        }}
      </WindowSize>
    );
  }

  render() {
    const {
      isRequesting,
      template,
      fetchActivityRecordsError,
      fetchTemplateError,
    } = this.props;

    if (!template || isRequesting) {
      return this.renderEmpty();
    }

    if (template != null && template.activityRecordsCount === 0) {
      return this.renderNoRecords(template);
    }

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.recordsContainer}
            >
              <ActivityRecordsHeader
                id={this.props.id}
                displayCode={template.displayCode}
                year={template.year}
                name={template.name}
              />
              <div className={styles.activitiesContainer}>
                {this.renderActivitiesPanel()}
                {this.renderActivityResultPanel()}
              </div>
              <Fragment>
                <ErrorModal error={fetchActivityRecordsError} />
                <ErrorModal error={fetchTemplateError} />
              </Fragment>
            </div>
          );
        }}
      </WindowSize>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: ContextRouter) {
  const id = ((ownProps.match.params.id: any): string);
  const { templatesById } = state.templates;
  return {
    id: ownProps.match.params.id,
    isRequesting:
      state.templates.isRequesting ||
      isRequesting(state.sessions.fetchActivityRecordsRequest) ||
      isRequesting(state.sessions.deleteAnswerRequest),
    fetchActivityRecordsError: extractError(
      state.sessions.fetchActivityRecordsRequest
    ),
    fetchTemplateError: state.templates.error,
    recordsByTemplateId: state.sessions.recordsByTemplateId,
    template: templatesById[id],
  };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: Props) {
  return {
    fetchOne: bindActionCreators(fetchOne, dispatch),
    fetchActivityRecords: bindActionCreators(fetchActivityRecords, dispatch),
    deleteAnswer: bindActionCreators(deleteAnswer, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ActivityRecordsComponent
);
