// @flow
import type { ParticipationInfo } from "../types/session";
import type { AppLocale } from "../types/app";
const STUDENT_KEY = "polyupaths.student_state";
const LOCALE_KEY = "locale";
const LOCALE_DEFAULT_VALUE = "zh-Hant-HK";
const SESSION_ENCODED_INFO_KEY = "encoded_session";

function getInfos(): $ReadOnlyArray<ParticipationInfo> {
  try {
    let infos = [];
    const s = window.localStorage.getItem(STUDENT_KEY);
    if (s != null) {
      infos = JSON.parse(s);
    }
    return infos;
  } catch (e) {
    return [];
  }
}

export function findParticipationInfo(
  participationCode: string
): ?ParticipationInfo {
  const infos = getInfos();
  // Since we push latest info, we need to iterate from the back of the array
  for (let i = infos.length - 1; i >= 0; i--) {
    const info = infos[i];
    if (info.participationCode === participationCode) {
      return info;
    }
  }
  return null;
}

export function rememberParticipationInfo(info: ParticipationInfo): void {
  try {
    const infos = [...getInfos()];
    infos.push(info);
    window.localStorage.setItem(STUDENT_KEY, JSON.stringify(infos));
  } catch (e) {
    try {
      window.localStorage.removeItem(STUDENT_KEY);
    } catch (e) {}
  }
}

export function rememberLocale(locale: AppLocale) {
  try {
    window.localStorage.setItem(LOCALE_KEY, locale);
  } catch (error) {}
}

export function getLocale(): AppLocale {
  try {
    return window.localStorage.getItem(LOCALE_KEY) || LOCALE_DEFAULT_VALUE;
  } catch (error) {
    return LOCALE_DEFAULT_VALUE;
  }
}

export function rememberSessionEncodedInfo(data: string) {
  try {
    window.localStorage.setItem(SESSION_ENCODED_INFO_KEY, data);
  } catch (error) {}
}

export function forgetSessionEncodedInfo() {
  try {
    window.localStorage.removeItem(SESSION_ENCODED_INFO_KEY);
  } catch (error) {}
}

export function getSessionEncodedInfo(): ?string {
  try {
    return window.localStorage.getItem(SESSION_ENCODED_INFO_KEY);
  } catch (error) {
    return null;
  }
}
