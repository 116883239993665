// @flow
import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createImported } from "../../redux/actions/templates";
import ImportedTemplate from "../../components/ImportedTemplate/ImportedTemplate";
import { generateEmptyImportedTemplate } from "../../utils/template";
import type { Template } from "../../types/template";
import type { RootState, Dispatch } from "../../types/store";
import type { RouterHistory } from "react-router-dom";

type OwnProps = {
  +history: RouterHistory,
};

type ConnectedProps = {
  +isRequesting: boolean,
  +createImported: Template => Promise<Template>,
  +error: mixed,
};

type Props = OwnProps & ConnectedProps;

class CreateTemplate extends PureComponent<Props> {
  render() {
    const { history, isRequesting, createImported, error } = this.props;

    return (
      <ImportedTemplate
        history={history}
        isRequesting={isRequesting}
        originalTemplate={generateEmptyImportedTemplate()}
        error={error}
        save={createImported}
      />
    );
  }
}

function mapStateToProps(state: RootState) {
  const { isRequesting, error } = state.templates;
  return {
    isRequesting,
    error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createImported: bindActionCreators(createImported, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);
