//@flow
import {
  FETCH_ALL_REQUESTING,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ONE_REQUESTING,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  EDIT_CUSTOM_REQUESTING,
  EDIT_CUSTOM_SUCCESS,
  EDIT_CUSTOM_FAILURE,
  DELETE_CUSTOM_REQUESTING,
  DELETE_CUSTOM_SUCCESS,
  DELETE_CUSTOM_FAILURE,
  SEARCH_IMPORTED_REQUESTING,
  SEARCH_IMPORTED_SUCCESS,
  SEARCH_IMPORTED_FAILURE,
  CREATE_IMPORTED_REQUESTING,
  CREATE_IMPORTED_SUCCESS,
  CREATE_IMPORTED_FAILURE,
  EDIT_IMPORTED_REQUESTING,
  EDIT_IMPORTED_SUCCESS,
  EDIT_IMPORTED_FAILURE,
  DELETE_IMPORTED_REQUESTING,
  DELETE_IMPORTED_SUCCESS,
  DELETE_IMPORTED_FAILURE,
} from "../redux/actions/templates";
import {
  CREATE_TEMPLATE_REQUESTING,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
} from "../redux/actions/createTemplate";
import type { ActivityResponse, Activity } from "./activity";

export const TEMPLATE_YEAR_FORM_ALL: "all" = "all";
export const TEMPLATE_YEAR_FORM_1: "form1" = "form1";
export const TEMPLATE_YEAR_FORM_2: "form2" = "form2";
export const TEMPLATE_YEAR_FORM_3: "form3" = "form3";
export const TEMPLATE_LANG_ALL: "all" = "all";
export const TEMPLATE_LANG_EN: "en" = "en";
export const TEMPLATE_LANG_ZH_HANT: "zh-Hant" = "zh-Hant";
export const TEMPLATE_LANG_ZH_HANS: "zh-Hans" = "zh-Hans";
export const TEMPLATE_TYPE_IMPORTED: "imported" = "imported";
export const TEMPLATE_TYPE_CUSTOM: "custom" = "custom";
export const MAX_CUSTOM_TEMPLATES_NUM: 10 = 10;
export const MAX_CUSTOM_TEMPLATE_NAME_LEN: 30 = 30;

export type TEMPLATE_LANG =
  | typeof TEMPLATE_LANG_ALL
  | typeof TEMPLATE_LANG_EN
  | typeof TEMPLATE_LANG_ZH_HANT
  | typeof TEMPLATE_LANG_ZH_HANS;

export type TEMPLATE_LOCALE =
  | typeof TEMPLATE_LANG_EN
  | typeof TEMPLATE_LANG_ZH_HANT
  | typeof TEMPLATE_LANG_ZH_HANS;

export type TEMPLATE_YEAR_FORM =
  | typeof TEMPLATE_YEAR_FORM_ALL
  | typeof TEMPLATE_YEAR_FORM_1
  | typeof TEMPLATE_YEAR_FORM_2
  | typeof TEMPLATE_YEAR_FORM_3;

export type TEMPLATE_YEAR =
  | typeof TEMPLATE_YEAR_FORM_1
  | typeof TEMPLATE_YEAR_FORM_2
  | typeof TEMPLATE_YEAR_FORM_3;

export type TEMPLATE_TYPE =
  | typeof TEMPLATE_TYPE_IMPORTED
  | typeof TEMPLATE_TYPE_CUSTOM;

export type TemplateResponse = {|
  +_id: string,
  +base_id: ?string,
  +display_code: string,
  +lang: TEMPLATE_LOCALE,
  +type: TEMPLATE_TYPE,
  +year: TEMPLATE_YEAR,
  +name: string,
  +activities: $ReadOnlyArray<ActivityResponse>,
  +created_at: string,
  +created_by: string,
  +next_school_local_code: number,
  +custom_template_count: ?number,
  +can_create_custom_template: boolean,
  +custom_template_max_number: ?number,
  +activity_records_count: number,
|};

export type Template = {
  +id: string,
  +baseId: ?string,
  +displayCode: string,
  +lang: TEMPLATE_LOCALE,
  +type: TEMPLATE_TYPE,
  +year: TEMPLATE_YEAR,
  +name: string,
  +activities: $ReadOnlyArray<Activity>,
  +createdAt: Date,
  +createdBy: string,
  +nextSchoolLocalCode: number,
  +customTemplateCount: ?number,
  +canCreateCustomTemplate: boolean,
  +customTemplateMaxNumber: ?number,
  +isImported: boolean,
  +activityRecordsCount: number,
};

export type TemplatesState = {
  +isRequesting: boolean,
  +templatesById: { [id: string]: Template },
  +form1TemplateIds: $ReadOnlyArray<string>,
  +form2TemplateIds: $ReadOnlyArray<string>,
  +form3TemplateIds: $ReadOnlyArray<string>,
  +error: mixed,
};

export type FetchAllTemplatesRequesting = {|
  +type: typeof FETCH_ALL_REQUESTING,
|};

export type FetchAllTemplatesSuccess = {|
  +type: typeof FETCH_ALL_SUCCESS,
  +payload: {
    templatesById: { [id: string]: Template },
    form1TemplateIds: $ReadOnlyArray<string>,
    form2TemplateIds: $ReadOnlyArray<string>,
    form3TemplateIds: $ReadOnlyArray<string>,
  },
|};

export type FetchAllTemplatesFailure = {|
  +type: typeof FETCH_ALL_FAILURE,
  +payload: mixed,
|};

export type CreateTemplateParameter = {|
  +baseId: string,
  +newName: string,
|};

export type CreateTemplateRequesting = {|
  +type: typeof CREATE_TEMPLATE_REQUESTING,
|};

export type CreateTemplateSuccess = {|
  +type: typeof CREATE_TEMPLATE_SUCCESS,
  +payload: {
    baseTemplate: Template,
    createdTemplate: Template,
  },
|};

export type CreateTemplateFailure = {|
  +type: typeof CREATE_TEMPLATE_FAILURE,
  +payload: mixed,
|};

export type FetchOneTemplateRequesting = {|
  +type: typeof FETCH_ONE_REQUESTING,
|};

export type FetchOneTemplateSuccess = {|
  +type: typeof FETCH_ONE_SUCCESS,
  +payload: Template,
|};

export type FetchOneTemplateFailure = {|
  +type: typeof FETCH_ONE_FAILURE,
  +payload: mixed,
|};

export type EditCustomTemplateRequesting = {|
  +type: typeof EDIT_CUSTOM_REQUESTING,
|};

export type EditCustomTemplateSuccess = {|
  +type: typeof EDIT_CUSTOM_SUCCESS,
  +payload: Template,
|};

export type EditCustomTemplateFailure = {|
  +type: typeof EDIT_CUSTOM_FAILURE,
  +payload: mixed,
|};

export type DeleteCustomTemplateRequesting = {|
  +type: typeof DELETE_CUSTOM_REQUESTING,
|};

export type DeleteCustomTemplateSuccess = {|
  +type: typeof DELETE_CUSTOM_SUCCESS,
  +payload: Template,
|};

export type DeleteCustomTemplateFailure = {|
  +type: typeof DELETE_CUSTOM_FAILURE,
  +payload: mixed,
|};

export type SearchImportedTemplatesRequesting = {|
  +type: typeof SEARCH_IMPORTED_REQUESTING,
|};
export type SearchImportedTemplatesSuccess = {|
  +type: typeof SEARCH_IMPORTED_SUCCESS,
  +payload: $ReadOnlyArray<Template>,
|};
export type SearchImportedTemplatesFailure = {|
  +type: typeof SEARCH_IMPORTED_FAILURE,
  +payload: mixed,
|};

export type CreateImportedTemplateRequesting = {|
  +type: typeof CREATE_IMPORTED_REQUESTING,
|};

export type CreateImportedTemplateSuccess = {|
  +type: typeof CREATE_IMPORTED_SUCCESS,
  +payload: Template,
|};

export type CreateImportedTemplateFailure = {|
  +type: typeof CREATE_IMPORTED_FAILURE,
  +payload: mixed,
|};

export type EditImportedTemplateRequesting = {|
  +type: typeof EDIT_IMPORTED_REQUESTING,
|};

export type EditImportedTemplateSuccess = {|
  +type: typeof EDIT_IMPORTED_SUCCESS,
  +payload: Template,
|};

export type EditImportedTemplateFailure = {|
  +type: typeof EDIT_IMPORTED_FAILURE,
  +payload: mixed,
|};

export type DeleteImportedTemplateRequesting = {|
  +type: typeof DELETE_IMPORTED_REQUESTING,
|};

export type DeleteImportedTemplateSuccess = {|
  +type: typeof DELETE_IMPORTED_SUCCESS,
  +payload: Template,
|};

export type DeleteImportedTemplateFailure = {|
  +type: typeof DELETE_IMPORTED_FAILURE,
  +payload: mixed,
|};
