// @flow
import React, { Component } from "react";
import type { Node } from "react";

type Props = {
  onResize: (width: number, height: number) => void,
  onRef?: (r: HTMLElement | null) => void,
  domElement?: string,
  children?: Node,
};

type LocalState = {
  width: number,
  height: number,
};

export default class Div extends Component<Props, LocalState> {
  static defaultProps = {
    domElement: "div",
  };

  r: ?HTMLElement;

  state = {
    width: 0,
    height: 0,
  };

  componentDidUpdate() {
    this.onResize();
  }

  onResize = () => {
    if (this.r != null) {
      const width = this.r.clientWidth;
      const height = this.r.clientHeight;
      if (width !== this.state.width || height !== this.state.height) {
        this.setState({
          width,
          height,
        });
        this.props.onResize(width, height);
      }
    }
  };

  onRef = (r: HTMLElement | null) => {
    if (r != null) {
      window.addEventListener("resize", this.onResize);
      this.r = r;
      this.onResize();
    } else if (this.r != null) {
      window.removeEventListener("resize", this.onResize);
      this.r = null;
    }
    if (this.props.onRef != null) {
      this.props.onRef(r);
    }
  };

  render() {
    const {
      // onRef and onResize are not used in the following code.
      // However it is necessary to destructure them so that
      // rest does not contain them.
      // Otherwise react will complain that domElement
      // does not have these props
      onRef,
      onResize,
      children,
      domElement,
      ...rest
    } = this.props;
    return React.createElement(
      domElement == null ? "div" : domElement,
      { ref: this.onRef, ...rest },
      children
    );
  }
}
