// @flow
export const SCHOOL_ACCOUNT_MANAGEMENT_PATH =
  "/admin/school_account_management";
export const TEMPLATE_MANAGEMENT_PATH = "/admin/template_management";
export const RECORDS_MANAGEMENT_PATH = "/admin/records_management";
export const ADMIN_USER_MANAGEMENT_PATH = "/admin/admin_user_management";
export const AUDIT_TRAIL_PATH = "/admin/audit_trail";
export const SYSTEM_CONFIGURATION_PATH = "/admin/system_configuration";
export const SCHOOL_TEMPLATES = "/templates";
export const SCHOOL_SESSIONS = "/sessions";
