// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import Input from "./Input";
import Text from "../Text/Text";
import type { InputProps } from "./Input";
import styles from "./InputWithErrorMessage.module.scss";

type OwnProps = {
  +errorTextKey: ?string,
};

type Props = InputProps & OwnProps;

export default class InputWithErrorMessage extends PureComponent<Props> {
  render() {
    const { className, errorTextKey, ...rest } = this.props;

    return (
      <Fragment>
        <Input
          className={classnames(className, {
            [styles.error]: errorTextKey != null,
          })}
          {...rest}
        />
        {errorTextKey == null ? null : (
          <p className={styles.errorText}>
            <Text translationKey={errorTextKey} />
          </p>
        )}
      </Fragment>
    );
  }
}
