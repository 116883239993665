// @flow
import React, { PureComponent } from "react";
import ModalContainer from "./Modal/ModalContainer";
import AlertModal from "./Modal/AlertModal";
import NetworkDownModal from "./Modal/NetworkDownModal";
import {
  ongoingRequestError,
  ErrUserNotFound,
  ErrIncorrectCredentials,
  ErrUserDisabled,
  ErrUserDuplicated,
  ErrNetworkDown,
  ErrSessionNotFoundError,
  ErrIllegalSessionStateError,
  ErrPermissionDeniedError,
  ErrSchoolAccountError,
  ErrAdminUserError,
  ErrEmailInUseError,
  ErrExportError,
  ErrSysConfError,
  ErrDeleteTemplateError,
} from "../types/error";
import { interpretError } from "../utils/error";

type Props = {
  error: mixed,
};

type LocalState = {
  isModalShown: boolean,
  error: mixed,
};

function getDerivedStateFromProps(props: Props) {
  return {
    error: props.error,
    isModalShown: props.error != null,
  };
}

class ErrorModal extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = getDerivedStateFromProps(props);
  }

  componentWillReceiveProps(nextProps: Props) {
    // ignore ongoingRequestError
    if (
      this.props.error !== nextProps.error &&
      nextProps.error !== ongoingRequestError
    ) {
      this.setState(getDerivedStateFromProps(nextProps));
    }
  }

  onCloseAlertModal = () => {
    this.setState({ isModalShown: false });
  };

  renderErrorModal(titleKey: string) {
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey={titleKey}
          buttonKey="phrase.confirm"
          onButtonClick={this.onCloseAlertModal}
          onClose={this.onCloseAlertModal}
        />
      </ModalContainer>
    );
  }

  renderError(error: mixed) {
    const interpreted = interpretError(error);

    switch (interpreted) {
      case ErrUserNotFound:
        return this.renderErrorModal("error.user_not_found");
      case ErrUserDuplicated:
        return this.renderErrorModal("error.user_duplicated");
      case ErrIncorrectCredentials:
        return this.renderErrorModal("error.incorrect_credentials");
      case ErrSessionNotFoundError:
        return this.renderErrorModal("error.session_not_found");
      case ErrUserDisabled:
        return this.renderErrorModal("error.user_disabled");
      case ErrIllegalSessionStateError:
        return this.renderErrorModal("error.unable_to_submit_answer");
      case ErrPermissionDeniedError:
        return this.renderErrorModal("error.permission_denied");
      case ErrSchoolAccountError:
        return this.renderErrorModal("error.some_error_for_school_account");
      case ErrAdminUserError:
        return this.renderErrorModal("error.some_error_for_admin_user");
      case ErrEmailInUseError:
        return this.renderErrorModal("error.email_is_in_use");
      case ErrExportError:
        return this.renderErrorModal("error.export_failed");
      case ErrSysConfError:
        return this.renderErrorModal("error.some_error_for_sys_confs");
      case ErrDeleteTemplateError:
        return this.renderErrorModal("error.delete_template_error");
      case ErrNetworkDown:
        return <NetworkDownModal onClose={this.onCloseAlertModal} />;
      default:
        return this.renderErrorModal("error.unknown_error");
    }
  }

  render() {
    if (!this.state.isModalShown) {
      return null;
    }
    return this.renderError(this.props.error);
  }
}

export default ErrorModal;
