// @flow
import skygear from "skygear";
import {
  SEARCH_SCHOOL_REQUESTING,
  SEARCH_SCHOOL_SUCCESS,
  SEARCH_SCHOOL_FAILURE,
  CREATE_SCHOOL_REQUESTING,
  CREATE_SCHOOL_SUCCESS,
  CREATE_SCHOOL_FAILURE,
  EDIT_SCHOOL_REQUESTING,
  EDIT_SCHOOL_SUCCESS,
  EDIT_SCHOOL_FAILURE,
  ENABLE_DISABLE_SCHOOL_REQUESTING,
  ENABLE_DISABLE_SCHOOL_SUCCESS,
  ENABLE_DISABLE_SCHOOL_FAILURE,
} from "../../types/school";
import type {
  School,
  SearchSchoolRequesting,
  SearchSchoolSuccess,
  SearchSchoolFailure,
  CreateSchoolRequesting,
  CreateSchoolSuccess,
  CreateSchoolFailure,
  EditSchoolRequesting,
  EditSchoolSuccess,
  EditSchoolFailure,
  EnableDisableSchoolRequesting,
  EnableDisableSchoolSuccess,
  EnableDisableSchoolFailure,
} from "../../types/school";
import type { PageInfo } from "../../types/request";
import type { Dispatch, GetState } from "../../types/store";
import {
  deserializeSchool,
  deserializePageInfo,
} from "../../utils/deserializer";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";

function searchSchoolRequesting(): SearchSchoolRequesting {
  return {
    type: SEARCH_SCHOOL_REQUESTING,
  };
}

function searchSchoolSuccess(
  schools: $ReadOnlyArray<School>
): SearchSchoolSuccess {
  return {
    type: SEARCH_SCHOOL_SUCCESS,
    payload: schools,
  };
}

function searchSchoolFailure(error: mixed): SearchSchoolFailure {
  return {
    type: SEARCH_SCHOOL_FAILURE,
    payload: error,
  };
}

export function searchSchool(data: {
  keyword: string,
  pageSize: number,
  pageNumber: number,
}) {
  const { keyword, pageSize, pageNumber } = data;
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{ schools: $ReadOnlyArray<School>, pageInfo: PageInfo }> => {
    const currentState = getState();
    if (isRequesting(currentState.schools.searchSchoolRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(searchSchoolRequesting());
    const args = {
      keyword,
      page_args: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    };

    return skygear
      .lambda("polyupaths:school:query_all", [args])
      .then(result => {
        const schools = result.schools.map(deserializeSchool);
        const pageInfo = deserializePageInfo(result.page_info);
        dispatch(searchSchoolSuccess(schools));
        return {
          schools,
          pageInfo,
        };
      })
      .catch(error => {
        dispatch(searchSchoolFailure(error));
        return Promise.reject(error);
      });
  };
}

function createSchoolRequesting(): CreateSchoolRequesting {
  return {
    type: CREATE_SCHOOL_REQUESTING,
  };
}

function createSchoolSuccess(school: School): CreateSchoolSuccess {
  return {
    type: CREATE_SCHOOL_SUCCESS,
    payload: school,
  };
}

function createSchoolFailure(error: mixed): CreateSchoolFailure {
  return {
    type: CREATE_SCHOOL_FAILURE,
    payload: error,
  };
}

export function createSchool(
  name: string,
  engName: string,
  email: string,
  password: string
) {
  return (dispatch: Dispatch, getState: GetState): Promise<School> => {
    const currentState = getState();
    if (isRequesting(currentState.schools.createSchoolRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(createSchoolRequesting());
    const args = {
      name,
      eng_name: engName,
      email,
      password,
    };
    return skygear
      .lambda("polyupaths:school:create", [args])
      .then(result => {
        const school = deserializeSchool(result.school);
        dispatch(createSchoolSuccess(school));
        return Promise.resolve(school);
      })
      .catch(error => {
        dispatch(createSchoolFailure(error));
        return Promise.reject(error);
      });
  };
}

function editSchoolRequesting(): EditSchoolRequesting {
  return {
    type: EDIT_SCHOOL_REQUESTING,
  };
}

function editSchoolSuccess(school: School): EditSchoolSuccess {
  return {
    type: EDIT_SCHOOL_SUCCESS,
    payload: school,
  };
}

function editSchoolFailure(error: mixed): EditSchoolFailure {
  return {
    type: EDIT_SCHOOL_FAILURE,
    payload: error,
  };
}

export function editSchool(
  id: string,
  name: string,
  engName: string,
  email: string,
  password: string
) {
  return (dispatch: Dispatch, getState: GetState): Promise<School> => {
    const currentState = getState();
    if (isRequesting(currentState.schools.editSchoolRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(editSchoolRequesting());
    const args = {
      school_id: id,
      name,
      eng_name: engName,
      email,
      password: password.length !== 0 ? password : null,
    };
    return skygear
      .lambda("polyupaths:school:edit", [args])
      .then(result => {
        const school = deserializeSchool(result.school);
        dispatch(editSchoolSuccess(school));
        return Promise.resolve(school);
      })
      .catch(error => {
        dispatch(editSchoolFailure(error));
        return Promise.reject(error);
      });
  };
}

function enableDisableSchoolRequesting(): EnableDisableSchoolRequesting {
  return {
    type: ENABLE_DISABLE_SCHOOL_REQUESTING,
  };
}

function enableDisableSchoolSuccess(
  school: School
): EnableDisableSchoolSuccess {
  return {
    type: ENABLE_DISABLE_SCHOOL_SUCCESS,
    payload: school,
  };
}

function enableDisableSchoolFailure(error: mixed): EnableDisableSchoolFailure {
  return {
    type: ENABLE_DISABLE_SCHOOL_FAILURE,
    payload: error,
  };
}

export function enableDisableSchool(id: string, disable: boolean) {
  return (dispatch: Dispatch, getState: GetState): Promise<School> => {
    const currentState = getState();
    if (isRequesting(currentState.schools.enableDisableSchoolRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(enableDisableSchoolRequesting());
    const args = {
      school_id: id,
      disable,
    };
    return skygear
      .lambda("polyupaths:school:enable_disable", [args])
      .then(result => {
        const school = deserializeSchool(result.school);
        dispatch(enableDisableSchoolSuccess(school));
        return Promise.resolve(school);
      })
      .catch(error => {
        dispatch(enableDisableSchoolFailure(error));
        return Promise.reject(error);
      });
  };
}
