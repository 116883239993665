// @flow
import React, { PureComponent } from "react";
import ContactUsLink from "../Link/ContactUsLink";
import UserGuideLink from "../Link/UserGuideLink";
import LocaleSwitch from "../LocaleSwitch/LocaleSwitch";
import styles from "./DashboardFooter.module.scss";

export default class DashboardFooter extends PureComponent<{}> {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.row}>
            <ContactUsLink className={styles.link} />
            <UserGuideLink className={styles.link} />
            <LocaleSwitch
              variant="footer"
              className={styles.localeSwitchContainer}
            />
          </div>
        </div>
      </div>
    );
  }
}
