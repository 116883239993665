// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import TemplatesLink from "../Link/TemplatesLink";
import ViewTemplateLink from "../Link/ViewTemplateLink";
import Arrow from "../ART/Arrow";
import type { TEMPLATE_YEAR } from "../../types/template";
import styles from "./ActivityRecordsHeader.module.scss";

type Props = {
  id: string,
  displayCode: string,
  year: TEMPLATE_YEAR,
  name: string,
};

class ActivityRecordsHeader extends PureComponent<Props> {
  render() {
    const { id, year, displayCode, name } = this.props;

    return (
      <div className={styles.headerContainer}>
        <div className={styles.breadcrumbsContainer}>
          <Arrow
            className={styles.arrowIcon}
            width={9}
            height={19}
            strokeWidth={1}
            strokeColor="#979797"
            direction="left"
          />
          <TemplatesLink
            className={classnames(styles.breadcrumbsText, styles.templateYear)}
            year={year}
          >
            <Text translationKey={`teacher.template_${year}`} />
          </TemplatesLink>
          <Arrow
            className={styles.arrowIcon}
            width={9}
            height={19}
            strokeWidth={1}
            strokeColor="#979797"
            direction="left"
          />
          <ViewTemplateLink
            templateID={id}
            className={classnames(styles.breadcrumbsText, styles.templateName)}
          >
            <span>{displayCode}</span> <span>{name}</span>
          </ViewTemplateLink>
          <Arrow
            className={styles.arrowIcon}
            width={9}
            height={19}
            strokeWidth={1}
            strokeColor="#979797"
            direction="left"
          />
          <p className={styles.breadcrumbsText}>
            <Text translationKey="teacher.activity_records" />
          </p>
        </div>
      </div>
    );
  }
}

export default ActivityRecordsHeader;
