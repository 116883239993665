// @flow
import ensureSkygearImageBase64Format from "./ensureSkygearImageBase64Format";

export opaque type ResizeCropImageError = {};
export const resizeCropImageError: ResizeCropImageError = {};

export function resizeCropImage(
  file: File,
  dw: number,
  dh: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    const reader = new FileReader();

    reader.onload = function(e) {
      img.src = e.target.result;
    };

    img.addEventListener("load", () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // resize image
      let width = img.width;
      let height = img.height;
      let widthScale = dw / width;
      let heightScale = dh / height;
      if (widthScale > heightScale) {
        width = width * widthScale;
        height = height * widthScale;
      } else {
        width = width * heightScale;
        height = height * heightScale;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // crop center image
      let dx = 0;
      let dy = 0;
      if (width > dw) {
        dx = (width - dw) / 2;
      }
      if (height > dh) {
        dy = (height - dh) / 2;
      }
      const imageData = ctx.getImageData(dx, dy, dw, dh);
      canvas.width = dw;
      canvas.height = dh;
      ctx.putImageData(imageData, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(ensureSkygearImageBase64Format(dataURL));
    });

    img.addEventListener("error", (e: Event) => {
      reject(resizeCropImageError);
    });

    reader.readAsDataURL(file);
  });
}
