// @flow
import React, { PureComponent } from "react";
import type { Activity } from "../../types/activity";
import TeacherSessionActivityQA from "./TeacherSessionActivityQA";
import TeacherSessionActivityPolling from "./TeacherSessionActivityPolling";
import TeacherSessionActivityWhiteboard from "./TeacherSessionActivityWhiteboard";
import {
  getHeightForGrayRectangleContainer,
  getHeightForGrayRectangle,
} from "./utils";
import styles from "./TeacherSessionActivity.module.scss";

type Props = {
  activity: Activity,
  parentHeight: number,
};

export default class TeacherSessionActivity extends PureComponent<Props> {
  renderActivityByType() {
    const { activity } = this.props;
    if (activity.type === "qa") {
      return (
        <TeacherSessionActivityQA
          parentHeight={this.props.parentHeight}
          question={activity.question}
          imageUrl={activity.imageUrl}
        />
      );
    }
    if (activity.type === "polling" && activity.pollingOptions != null) {
      return (
        <TeacherSessionActivityPolling
          parentHeight={this.props.parentHeight}
          question={activity.question}
          imageUrl={activity.imageUrl}
          pollingOptions={activity.pollingOptions}
        />
      );
    }
    if (activity.type === "whiteboard") {
      return (
        <TeacherSessionActivityWhiteboard
          parentHeight={this.props.parentHeight}
          question={activity.question}
          imageUrl={activity.imageUrl}
        />
      );
    }
    return null;
  }

  render() {
    const { parentHeight } = this.props;
    return (
      <div
        style={{
          height: getHeightForGrayRectangleContainer(parentHeight),
        }}
        className={styles.grayRectangleContainer}
      >
        <div
          style={{ height: getHeightForGrayRectangle(parentHeight) }}
          className={styles.grayRectangle}
        >
          <div className={styles.grayRectangleContent}>
            {this.renderActivityByType()}
          </div>
        </div>
      </div>
    );
  }
}
