// @flow
import React, { PureComponent, Fragment } from "react";
import debounce from "debounce";
import qs from "qs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import IconButton from "../../components/IconButton/IconButton";
import searchIcon from "../../images/template_search.svg";
import plusIcon from "../../images/plus.svg";
import AdminUserModal from "../../components/Modal/AdminUserModal";
import ErrorModal from "../../components/ErrorModal";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminUserItem from "../../components/AdminUserItem/AdminUserItem";
import WindowSize from "../../components/WindowSize";
import Pagination from "../../components/Pagination/Pagination";
import {
  searchUsers,
  createUser,
  editUser,
  enableDisableUser,
} from "../../redux/actions/users";
import { isRequesting, extractError } from "../../utils/request";
import {
  ADMIN_DASHBOARD_PAGE_SIZE,
  ADMIN_DASHBOARD_DEBOUNCE_TIME_MS,
} from "../../utils/constants";
import { ADMIN_USER_MANAGEMENT_PATH } from "../../types/paths";
import type { RootState, Dispatch } from "../../types/store";
import type { User, UserType } from "../../types/user";
import type { HeaderTitle } from "../../components/AdminHeader/AdminHeader";
import type { AuthUser } from "../../types/auth";
import type { PageInfo } from "../../types/request";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import styles from "./AdminUserManagement.module.scss";

type OwnProps = {
  +history: RouterHistory,
  +location: Location,
};

type ConnectedProps = {
  +currentUser: ?AuthUser,
  +isRequesting: boolean,
  +userById: { [id: string]: ?User },
  +searchUsersError: mixed,
  +createUserError: mixed,
  +enableDisableUserError: mixed,
  +editUserError: mixed,
  +searchUsers: (data: {
    keyword: string,
    pageSize: number,
    pageNumber: number,
  }) => Promise<{
    users: $ReadOnlyArray<User>,
    pageInfo: PageInfo,
  }>,
  +createUser: (
    name: string,
    userType: UserType,
    email: string,
    password: string
  ) => Promise<User>,
  +editUser: (
    userId: string,
    name: string,
    userType: UserType,
    email: string,
    password: string
  ) => Promise<User>,
  +enableDisableUser: (userId: string, disable: boolean) => Promise<User>,
  keywords: string,
  currentPage: number, // 1 based
};

type Props = OwnProps & ConnectedProps;

type LocalState = {|
  pageInfo: PageInfo,
  userIds: $ReadOnlyArray<string>,
  editAdminUserIdx: number,
  editAdminModalShown: boolean,
  addAdminModalShown: boolean,
|};

const headerTitles: $ReadOnlyArray<HeaderTitle> = [
  {
    text: "admin.admin_user.header.name",
    width: "50%",
  },
  {
    text: "admin.admin_user.header.email",
    width: "25%",
  },
  {
    text: "admin.admin_user.header.type",
    width: "10%",
  },
];

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// admin header: 56px
// total count row margin top: 18px;
// total count row: 25px;
// total count row margin bottom: 10;
// actions row: 34px;
// actions row margin-bottom: 33px;
// school accounts header: 31px;
// layout pagination footer: 77px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 422;

class AdminUserManagement extends PureComponent<Props, LocalState> {
  state = {
    userIds: [],
    pageInfo: {
      totalCount: 0,
      totalPage: 0,
    },
    editAdminUserIdx: -1,
    editAdminModalShown: false,
    addAdminModalShown: false,
  };

  searchUsers: () => void;

  constructor(props: Props) {
    super(props);

    this.searchUsers = debounce(
      this.searchUsers,
      ADMIN_DASHBOARD_DEBOUNCE_TIME_MS
    );
  }

  componentDidMount() {
    this.searchUsers();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.keywords !== this.props.keywords ||
      nextProps.currentPage !== this.props.currentPage
    ) {
      this.searchUsers();
    }
  }

  onKeywordsChange = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    if (target instanceof HTMLInputElement) {
      this.onReplaceHistory(1, target.value);
    }
  };

  onAddAdminButtonClicked = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ addAdminModalShown: true });
  };

  onCloseAddAdminModal = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ addAdminModalShown: false });
  };

  onAddAdmin = (user: User, password: string) => {
    this.props
      .createUser(user.name, user.userType, user.email, password)
      .then(user => {
        this.setState({ userIds: [user.id, ...this.state.userIds] });
      });
    this.setState({ addAdminModalShown: false });
  };

  onEditButtonClick = (userIdx: number) => {
    this.setState({ editAdminUserIdx: userIdx, editAdminModalShown: true });
  };

  onEnableDisableClick = (userIdx: number) => {
    const users = this.getUsers();
    const id = this.state.userIds[userIdx];
    const user = users[userIdx];
    this.props.enableDisableUser(id, !user.disabled);
  };

  onCloseEditAccountModal = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ editAdminModalShown: false });
  };

  onEditAdmin = (user: User, password: string) => {
    const { editAdminUserIdx } = this.state;
    const id = this.state.userIds[editAdminUserIdx];
    this.props.editUser(id, user.name, user.userType, user.email, password);
    this.setState({ editAdminModalShown: false });
  };

  onPageChange = (pageIdx: number) => {
    const { keywords } = this.props;
    this.onReplaceHistory(pageIdx, keywords);
  };

  onReplaceHistory = (pageIdx: number, keywords: string) => {
    this.props.history.replace({
      pathname: ADMIN_USER_MANAGEMENT_PATH,
      search: qs.stringify({ currentPage: pageIdx, keywords }),
    });
  };

  renderSearchInput() {
    const { keywords } = this.props;
    return (
      <div className={styles.searchInputContainer}>
        <img className={styles.searchIcon} src={searchIcon} alt="search icon" />
        <Input
          placeholderId="admin.admin_user.header.search_admin"
          labelId="admin.admin_user.header.search_admin"
          type="text"
          value={keywords}
          className={styles.searchInput}
          onChange={this.onKeywordsChange}
        />
      </div>
    );
  }

  renderAddAdminButton() {
    return (
      <div className={styles.addButtonContainer}>
        <IconButton
          className={styles.addButton}
          icon={plusIcon}
          labelTranslationKey="admin.admin_user.header.add_new_admin"
          onClick={this.onAddAdminButtonClicked}
        />
      </div>
    );
  }

  renderAddAdminModal() {
    const { addAdminModalShown } = this.state;

    if (!addAdminModalShown) {
      return null;
    }

    return (
      <AdminUserModal
        type="add"
        onClose={this.onCloseAddAdminModal}
        onSave={this.onAddAdmin}
      />
    );
  }

  renderEditAccountModal() {
    const users = this.getUsers();
    const { currentUser } = this.props;
    const { editAdminModalShown, editAdminUserIdx } = this.state;

    if (!editAdminModalShown) {
      return null;
    }

    return (
      <AdminUserModal
        type="edit"
        user={users[editAdminUserIdx]}
        onClose={this.onCloseEditAccountModal}
        onSave={this.onEditAdmin}
        currentUserId={currentUser != null ? currentUser.id : ""}
      />
    );
  }

  renderHeader() {
    const { totalCount } = this.state.pageInfo;

    return (
      <Fragment>
        <p className={styles.countText}>
          <Text translationKey="admin.admin_user.header.count" />
          <span className={styles.countNumberText}> {totalCount}</span>
        </p>
        <div className={styles.actionsRow}>
          {this.renderSearchInput()}
          {this.renderAddAdminButton()}
        </div>
      </Fragment>
    );
  }

  render() {
    const users = this.getUsers();
    const {
      isRequesting,
      searchUsersError,
      createUserError,
      editUserError,
      enableDisableUserError,
      currentPage,
    } = this.props;
    const { pageInfo } = this.state;

    return (
      <div>
        {this.renderHeader()}
        <AdminHeader titles={headerTitles} />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
            return (
              <div style={{ height: h + "px", overflow: "scroll" }}>
                {isRequesting && <LoadingIndicator />}
                {!isRequesting &&
                  users.map((user, idx) => (
                    <AdminUserItem
                      key={user.id}
                      user={user}
                      onEdit={this.onEditButtonClick}
                      onEnableDisable={this.onEnableDisableClick}
                      userIdx={idx}
                    />
                  ))}
                {!isRequesting &&
                  users.length === 0 && (
                    <p className={styles.noRelevantRecordsText}>
                      <Text translationKey="admin.no_relevant_records" />
                    </p>
                  )}
              </div>
            );
          }}
        </WindowSize>
        <Pagination
          currentPage={currentPage}
          totalPage={pageInfo.totalPage}
          onPageChange={this.onPageChange}
        />
        {this.renderAddAdminModal()}
        {this.renderEditAccountModal()}
        <Fragment>
          <ErrorModal error={searchUsersError} />
          <ErrorModal error={createUserError} />
          <ErrorModal error={editUserError} />
          <ErrorModal error={enableDisableUserError} />
        </Fragment>
      </div>
    );
  }

  getUsers(): $ReadOnlyArray<User> {
    const output = [];
    for (const id of this.state.userIds) {
      const user = this.props.userById[id];
      if (user != null) {
        output.push(user);
      }
    }
    return output;
  }

  searchUsers() {
    this.props
      .searchUsers({
        keyword: this.props.keywords,
        pageSize: ADMIN_DASHBOARD_PAGE_SIZE,
        pageNumber: this.props.currentPage,
      })
      .then(result => {
        this.setState({
          userIds: result.users.map(s => s.id),
          pageInfo: result.pageInfo,
        });
      });
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const usersState = state.users;
  const { search } = ownProps.location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    currentUser: state.auth.user,
    userById: usersState.userById,
    isRequesting:
      isRequesting(usersState.searchUsersRequest) ||
      isRequesting(usersState.createUserRequest) ||
      isRequesting(usersState.editUserRequest) ||
      isRequesting(usersState.enableDisableUserRequest),
    searchUsersError: extractError(usersState.searchUsersRequest),
    createUserError: extractError(usersState.createUserRequest),
    editUserError: extractError(usersState.editUserRequest),
    enableDisableUserError: extractError(usersState.enableDisableUserRequest),
    keywords: parsed.keywords || "",
    currentPage:
      parsed.currentPage != null ? parseInt(parsed.currentPage, 10) : 1,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    searchUsers: bindActionCreators(searchUsers, dispatch),
    createUser: bindActionCreators(createUser, dispatch),
    editUser: bindActionCreators(editUser, dispatch),
    enableDisableUser: bindActionCreators(enableDisableUser, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AdminUserManagement
);
