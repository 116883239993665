// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import styles from "./VerticalIconButton.module.scss";
import endSrc from "../../images/teacher_session_end.svg";
import pauseSrc from "../../images/teacher_session_pause.svg";
import pausedSrc from "../../images/teacher_session_paused.svg";

type Variant = "end" | "pause" | "paused";

type Spec = {
  iconSrc: string,
  iconAlt: string,
  labelKey: string,
};

type Props = {
  className: string,
  disabled: boolean,
  variant: Variant,
  onClick: (e: Event) => void,
};

const specs: { [Variant]: Spec } = {
  end: {
    iconSrc: endSrc,
    iconAlt: "end",
    labelKey: "teacher.session.header.button.end",
  },
  pause: {
    iconSrc: pauseSrc,
    iconAlt: "pause",
    labelKey: "teacher.session.header.button.pause",
  },
  paused: {
    iconSrc: pausedSrc,
    iconAlt: "paused",
    labelKey: "teacher.session.header.button.paused",
  },
};

export default class VerticalIconButton extends PureComponent<Props> {
  render() {
    const spec = specs[this.props.variant];
    const { iconSrc, iconAlt, labelKey } = spec;
    const variantClassName = {
      [styles.end]: this.props.variant === "end",
      [styles.pause]: this.props.variant === "pause",
      [styles.paused]: this.props.variant === "paused",
    };
    const iconWrapperClassName = classnames([
      styles.iconWrapper,
      variantClassName,
    ]);
    const iconClassName = classnames([styles.icon, variantClassName]);
    const labelClassName = classnames([styles.label, variantClassName]);
    return (
      <button
        className={classnames(styles.button, this.props.className)}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        <div className={iconWrapperClassName}>
          <img className={iconClassName} src={iconSrc} alt={iconAlt} />
        </div>
        <span className={labelClassName}>
          <Text translationKey={labelKey} />
        </span>
      </button>
    );
  }
}
