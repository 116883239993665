// @flow
import React, { PureComponent } from "react";
import Text from "../Text/Text";
import LocaleSwitch from "../LocaleSwitch/LocaleSwitch";
import LocaleLayout from "../LocaleLayout";
import styles from "./Header.module.scss";

type Props = {
  +canChangeLocale: boolean,
};

export default class Header extends PureComponent<Props> {
  static defaultProps = {
    canChangeLocale: true,
  };

  render() {
    return (
      <div className={styles.headerRow}>
        <figure className={styles.logo} />
        <LocaleLayout>
          {props => {
            const className = props.isEn
              ? styles.enTitleText
              : styles.titleText;
            return (
              <p className={className}>
                <Text translationKey="teacher.interactive_learning_platform" />
              </p>
            );
          }}
        </LocaleLayout>
        {this.props.canChangeLocale && (
          <LocaleSwitch variant="header" className={styles.localeSwitch} />
        )}
      </div>
    );
  }
}
