// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import closeIcon from "../../images/close.svg";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import Image from "../Image/Image";
import styles from "./ImageChooser.module.scss";

type Props = {
  +className?: string,
  +onUnSelect?: mixed => void,
  +onUnSelectInfo?: mixed,
  +width?: number,
  +height?: number,
  +placeholderWidth: number | string,
  +placeholderHeight: number | string,
  +imageUrl: string,
};

type LocalState = {
  alertModalShown: boolean,
};

class ImageChooser extends PureComponent<Props, LocalState> {
  state = {
    alertModalShown: false,
  };

  onClickClose = () => {
    this.setState({ alertModalShown: true });
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
  };

  onUnSelect = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
    const { onUnSelect } = this.props;

    if (onUnSelect != null) {
      onUnSelect(this.props.onUnSelectInfo);
    }
  };

  renderAlertModal() {
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.sure_to_delete_image"
          buttonKey="phrase.confirm"
          onButtonClick={this.onUnSelect}
          onClose={this.onCloseAlertModal}
        />
      </ModalContainer>
    );
  }

  render() {
    const { className, ...rest } = this.props;

    return (
      <Image className={classnames(className, styles.imageContainer)} {...rest}>
        <img
          className={styles.closeButton}
          src={closeIcon}
          alt="unselect"
          onClick={this.onClickClose}
        />
        {this.renderAlertModal()}
      </Image>
    );
  }
}

export default ImageChooser;
