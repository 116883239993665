// @flow
import React, { PureComponent, Fragment } from "react";
import debounce from "debounce";
import qs from "qs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import WindowSize from "../../components/WindowSize";
import Pagination from "../../components/Pagination/Pagination";
import IconButton from "../../components/IconButton/IconButton";
import Select from "../../components/Select/Select";
import Option from "../../components/Option/Option";
import ImportedTemplateItem from "../../components/ImportedTemplateItem/ImportedTemplateItem";
import ErrorModal from "../../components/ErrorModal";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import CreateImportedTemplateLink from "../../components/Link/CreateImportedTemplateLink";
import searchIcon from "../../images/template_search.svg";
import plusIcon from "../../images/plus.svg";
import {
  searchImportedTemplates,
  deleteImported,
} from "../../redux/actions/templates";
import { TEMPLATE_MANAGEMENT_PATH } from "../../types/paths";
import {
  TEMPLATE_YEAR_FORM_ALL,
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_YEAR_FORM_2,
  TEMPLATE_YEAR_FORM_3,
  TEMPLATE_LANG_ALL,
  TEMPLATE_LANG_EN,
  TEMPLATE_LANG_ZH_HANT,
  TEMPLATE_LANG_ZH_HANS,
} from "../../types/template";
import {
  ADMIN_DASHBOARD_PAGE_SIZE,
  ADMIN_DASHBOARD_DEBOUNCE_TIME_MS,
} from "../../utils/constants";
import type { RootState, Dispatch } from "../../types/store";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import type { PageInfo } from "../../types/request";
import type { HeaderTitle } from "../../components/AdminHeader/AdminHeader";
import type {
  Template,
  TEMPLATE_LANG,
  TEMPLATE_YEAR_FORM,
} from "../../types/template";
import styles from "./TemplateManagement.module.scss";

type OwnProps = {
  +history: RouterHistory,
  +location: Location,
};

type ConnectedProps = {
  +keywords: string,
  +currentPage: number, // 1 based
  +lang: TEMPLATE_LANG,
  +form: TEMPLATE_YEAR_FORM,
  +isRequesting: boolean,
  +templatesById: { [id: string]: ?Template },
  +templatesError: mixed,
  +searchImportedTemplates: (data: {
    keyword: string,
    year: TEMPLATE_YEAR_FORM,
    lang: TEMPLATE_LANG,
    pageSize: number,
    pageNumber: number,
  }) => Promise<{
    templates: $ReadOnlyArray<Template>,
    pageInfo: PageInfo,
  }>,
  +deleteImported: string => void,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {|
  pageInfo: PageInfo,
  templateIds: $ReadOnlyArray<string>,
|};

const headerTitles: $ReadOnlyArray<HeaderTitle> = [
  {
    text: "admin.template.header.id",
    width: "10%",
  },
  {
    text: "admin.template.header.name",
    width: "50%",
  },
  {
    text: "admin.template.header.created_on",
    width: "12.5%",
  },
];

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// admin header: 56px
// total count row margin top: 18px;
// total count row: 25px;
// total count row margin bottom: 10;
// actions row: 34px;
// actions row margin-bottom: 33px;
// template management header: 31px;
// layout pagination footer: 77px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 422;

class TemplateManagement extends PureComponent<Props, LocalState> {
  state = {
    templateIds: [],
    pageInfo: {
      totalCount: 0,
      totalPage: 0,
    },
  };

  searchImportedTemplates: () => void;

  constructor(props: Props) {
    super(props);

    this.searchImportedTemplates = debounce(
      this.searchImportedTemplates,
      ADMIN_DASHBOARD_DEBOUNCE_TIME_MS
    );
  }

  componentDidMount() {
    this.searchImportedTemplates();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.lang !== this.props.lang ||
      nextProps.form !== this.props.form ||
      nextProps.keywords !== this.props.keywords ||
      nextProps.currentPage !== this.props.currentPage
    ) {
      this.searchImportedTemplates();
    }
  }

  onFormChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const formUnsafe = event.target.value;
    if (
      formUnsafe === "all" ||
      formUnsafe === "form1" ||
      formUnsafe === "form2" ||
      formUnsafe === "form3"
    ) {
      const value: TEMPLATE_YEAR_FORM = (formUnsafe: TEMPLATE_YEAR_FORM);
      const { keywords, currentPage, lang } = this.props;
      this.onReplaceHistory({
        pageIdx: currentPage,
        keywords,
        lang,
        form: value,
      });
    }
  };

  onLangChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const langUnsafe = event.target.value;
    if (
      langUnsafe === "all" ||
      langUnsafe === "en" ||
      langUnsafe === "zh-Hant" ||
      langUnsafe === "zh-Hans"
    ) {
      const value: TEMPLATE_LANG = (langUnsafe: TEMPLATE_LANG);
      const { keywords, currentPage, form } = this.props;
      this.onReplaceHistory({
        pageIdx: currentPage,
        keywords,
        lang: value,
        form,
      });
    }
  };

  onKeywordsChange = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    if (target instanceof HTMLInputElement) {
      const { lang, form } = this.props;
      this.onReplaceHistory({ pageIdx: 1, keywords: target.value, lang, form });
    }
  };

  onPageChange = (pageIdx: number) => {
    const { keywords, lang, form } = this.props;
    this.onReplaceHistory({ pageIdx, keywords, lang, form });
  };

  onReplaceHistory(data: {
    pageIdx: number,
    keywords: string,
    lang: TEMPLATE_LANG,
    form: TEMPLATE_YEAR_FORM,
  }) {
    const { pageIdx, keywords, lang, form } = data;
    this.props.history.replace({
      pathname: TEMPLATE_MANAGEMENT_PATH,
      search: qs.stringify({ currentPage: pageIdx, keywords, lang, form }),
    });
  }

  onDeleteTemplate = templateId => {
    this.props.deleteImported(templateId);
  };

  renderFromSelector() {
    const { form } = this.props;
    const formOptions = [
      TEMPLATE_YEAR_FORM_ALL,
      TEMPLATE_YEAR_FORM_1,
      TEMPLATE_YEAR_FORM_2,
      TEMPLATE_YEAR_FORM_3,
    ].map(l => (
      <Option translationKey={`admin.template.form_${l}`} value={l} key={l} />
    ));
    return (
      <div className={styles.selectorContainer}>
        <Select
          name="template-form-selector"
          className={styles.selector}
          value={form}
          onChange={this.onFormChange}
        >
          <p className={styles.selectOptionText}>
            <Text translationKey={`admin.template.form_${form}`} />
          </p>
          {formOptions}
        </Select>
      </div>
    );
  }

  renderLanguageSelector() {
    const { lang } = this.props;
    const langOptions = [
      TEMPLATE_LANG_ALL,
      TEMPLATE_LANG_ZH_HANT,
      TEMPLATE_LANG_ZH_HANS,
      TEMPLATE_LANG_EN,
    ].map(l => (
      <Option
        translationKey={`admin.template.language_${l}`}
        value={l}
        key={l}
      />
    ));
    return (
      <div className={styles.selectorContainer}>
        <Select
          name="template-language-selector"
          className={styles.selector}
          value={lang}
          onChange={this.onLangChange}
        >
          <p className={styles.selectOptionText}>
            <Text translationKey={`admin.template.language_${lang}`} />
          </p>
          {langOptions}
        </Select>
      </div>
    );
  }

  renderSearchInput() {
    const { keywords } = this.props;
    return (
      <div className={styles.searchInputContainer}>
        <img className={styles.searchIcon} src={searchIcon} alt="search icon" />
        <Input
          placeholderId="admin.template.header.search_template"
          labelId="admin.template.header.search_template"
          type="text"
          value={keywords}
          className={styles.searchInput}
          onChange={this.onKeywordsChange}
        />
      </div>
    );
  }

  renderCreateTemplateButton() {
    return (
      <CreateImportedTemplateLink>
        <div className={styles.addButtonContainer}>
          <IconButton
            className={styles.addButton}
            icon={plusIcon}
            labelTranslationKey="admin.template.header.add_new_template"
          />
        </div>
      </CreateImportedTemplateLink>
    );
  }

  renderHeader() {
    const { totalCount } = this.state.pageInfo;

    return (
      <Fragment>
        <p className={styles.countText}>
          <Text translationKey="admin.template.header.count" />
          <span className={styles.countNumberText}> {totalCount}</span>
        </p>
        <div className={styles.actionsRow}>
          {this.renderFromSelector()}
          {this.renderLanguageSelector()}
          {this.renderSearchInput()}
          {this.renderCreateTemplateButton()}
        </div>
      </Fragment>
    );
  }

  render() {
    const templates = this.getTemplates();
    const { isRequesting, currentPage, templatesError } = this.props;
    const { pageInfo } = this.state;

    return (
      <div>
        {this.renderHeader()}
        <AdminHeader titles={headerTitles} />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
            return (
              <div style={{ height: h + "px", overflow: "scroll" }}>
                {isRequesting && <LoadingIndicator />}
                {!isRequesting &&
                  templates.map((template, idx) => (
                    <ImportedTemplateItem
                      templateIdx={idx}
                      template={template}
                      onDeleteTemplate={this.onDeleteTemplate}
                      key={template.id}
                    />
                  ))}
                {!isRequesting &&
                  templates.length === 0 && (
                    <p className={styles.noRelevantRecordsText}>
                      <Text translationKey="admin.no_relevant_records" />
                    </p>
                  )}
              </div>
            );
          }}
        </WindowSize>
        <Pagination
          currentPage={currentPage}
          totalPage={pageInfo.totalPage}
          onPageChange={this.onPageChange}
        />
        <ErrorModal error={templatesError} />
      </div>
    );
  }

  getTemplates(): $ReadOnlyArray<Template> {
    const output = [];
    for (const id of this.state.templateIds) {
      const template = this.props.templatesById[id];
      if (template != null) {
        output.push(template);
      }
    }
    return output;
  }

  searchImportedTemplates() {
    this.props
      .searchImportedTemplates({
        lang: this.props.lang,
        year: this.props.form,
        keyword: this.props.keywords,
        pageSize: ADMIN_DASHBOARD_PAGE_SIZE,
        pageNumber: this.props.currentPage,
      })
      .then(result => {
        this.setState({
          templateIds: result.templates.map(s => s.id),
          pageInfo: result.pageInfo,
        });
      });
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const { search } = ownProps.location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    templatesById: state.templates.templatesById,
    isRequesting: state.templates.isRequesting,
    keywords: parsed.keywords || "",
    currentPage:
      parsed.currentPage != null ? parseInt(parsed.currentPage, 10) : 1,
    lang: parsed.lang || TEMPLATE_LANG_ALL,
    form: parsed.form || TEMPLATE_YEAR_FORM_ALL,
    templatesError: state.templates.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    searchImportedTemplates: bindActionCreators(
      searchImportedTemplates,
      dispatch
    ),
    deleteImported: bindActionCreators(deleteImported, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);
