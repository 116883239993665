// @flow
import React, { PureComponent, Fragment } from "react";
import ActivityRecordPanelRenderer from "./ActivityRecordPanelRenderer";
import type { RecordProps } from "./ActivityRecordType";
import type { ParticipantAnswer } from "../../utils/session";
import { toParticipantAnswerArray } from "../TeacherSessionActivityResult/utils";
import ModalContainer from "../Modal/ModalContainer";
import PagerModal from "../Modal/PagerModal";
import AlertModal from "../Modal/AlertModal";
import SingleImageModal from "../Modal/SingleImageModal";
import Text from "../Text/Text";
import Image from "../Image/Image";
import Button from "../Button/Button";
import styles from "./ActivityRecordWhiteboardPanel.module.scss";

type LocalState = {
  isAnswerItemModalShown: boolean,
  isDeleteAnswerModalShown: boolean,
  answerItemModalInitialIndex: number,
};

class ParticipantAnswerPagerModal extends PagerModal<ParticipantAnswer> {}

class ActivityRecordWhiteboardPanel extends PureComponent<
  RecordProps,
  LocalState
> {
  state = {
    isAnswerItemModalShown: false,
    isDeleteAnswerModalShown: false,
    answerItemModalInitialIndex: 0,
  };

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isAnswerItemModalShown: false,
      isDeleteAnswerModalShown: false,
    });
  };

  onDeleteAnswer = (e: Event, idx: mixed) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof idx === "number") {
      this.setState({
        isAnswerItemModalShown: false,
        isDeleteAnswerModalShown: true,
        answerItemModalInitialIndex: idx,
      });
    }
  };

  onConfirmDelete = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isAnswerItemModalShown: false,
      isDeleteAnswerModalShown: false,
    });
    const { onDeleteAnswer } = this.props;
    if (onDeleteAnswer != null) {
      const { session, activityState } = this.props;
      const { answerItemModalInitialIndex } = this.state;
      const participantAnswers = toParticipantAnswerArray(
        session.participants,
        activityState
      );
      const participantAnswer = participantAnswers[answerItemModalInitialIndex];
      onDeleteAnswer(participantAnswer.participant.id);
    }
  };

  renderDeleteAnswerModal() {
    if (!this.state.isDeleteAnswerModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.sure_to_delete_answer"
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmDelete}
          onClose={this.onModalClose}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderItem = (participantAnswer: ParticipantAnswer, index: number) => {
    return (
      <li
        key={participantAnswer.participant.id}
        className={styles.answerItem}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({
            isAnswerItemModalShown: true,
            isDeleteAnswerModalShown: false,
            answerItemModalInitialIndex: index,
          });
        }}
      >
        <Image
          className={styles.answer}
          imageUrl={participantAnswer.answer.whiteboardImageUrl || ""}
          width="100%"
          height="170px"
          placeholderWidth="100%"
          placeholderHeight="170px"
        >
          {this.props.onDeleteAnswer && (
            <Button
              className={styles.whiteboardAnswerItemDeleteButton}
              color="gray3"
              onClickInfo={index}
              onClick={this.onDeleteAnswer}
            />
          )}
        </Image>
      </li>
    );
  };

  renderExtra() {
    const { session, activityState } = this.props;
    const participantAnswers = toParticipantAnswerArray(
      session.participants,
      activityState
    );
    return (
      <Fragment>
        <p className={styles.titleText}>
          <Text
            translationKey="teacher.student_answers"
            values={{
              numberOfAnswers: toParticipantAnswerArray(
                session.participants,
                activityState
              ).length,
            }}
          />
        </p>
        <ul className={styles.answersContainer}>
          {participantAnswers.map(this.renderItem)}
        </ul>
      </Fragment>
    );
  }

  renderModal() {
    const { isAnswerItemModalShown, answerItemModalInitialIndex } = this.state;
    const { session, activityState } = this.props;
    const participantAnswers = toParticipantAnswerArray(
      session.participants,
      activityState
    );
    if (!isAnswerItemModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <ParticipantAnswerPagerModal
          className={styles.pagerModal}
          initialIndex={answerItemModalInitialIndex}
          data={participantAnswers}
        >
          {(data: ParticipantAnswer, index: number) => {
            return (
              <SingleImageModal
                className=""
                imageURL={data.answer.whiteboardImageUrl || ""}
                width="100%"
                height="100%"
                onClose={this.onModalClose}
              >
                {this.props.onDeleteAnswer && (
                  <Button
                    className={styles.whiteboardAnswerItemDeleteButton}
                    color="white"
                    onClickInfo={index}
                    onClick={this.onDeleteAnswer}
                  />
                )}
              </SingleImageModal>
            );
          }}
        </ParticipantAnswerPagerModal>
      </ModalContainer>
    );
  }

  render() {
    return (
      <ActivityRecordPanelRenderer {...this.props}>
        {this.renderExtra()}
        {this.renderModal()}
        {this.renderDeleteAnswerModal()}
      </ActivityRecordPanelRenderer>
    );
  }
}

export default ActivityRecordWhiteboardPanel;
