// @flow
import { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import type { IntlShape } from "react-intl";
import type { AppLocale } from "../types/app";
import type { RootState, Dispatch } from "../types/store";
import { syncIntl } from "../redux/actions/app";

type Props = {
  locale: AppLocale,
  syncIntl: IntlShape => void,
};

// This component solves the problem that
// React Context (< v16.3) and react-redux
// and react-intl does not play well with
// each other.
//
// react-redux's connect by default assumes
// our components are pure with respect to
// redux store's state. This assumption
// no longer holds when we have another
// library that use the unstable context
// API.
//
// To work around change react-intl's context
// does not trigger render, we have this following
// approach.
//
// SyncContextIntlToReduxStore is supposed to be mounted
// as a immediate child of IntlProvider, which should
// be able to received updated context when locale
// changes.
// SyncContextIntlToReduxStore then simply sync the
// intl context to redux store.
//
// Components like Text then can receive the intl context
// from redux store (as oppose to receiving from IntlProvider).
// Then those components forward the intl context to
// their immediate children like FormattedMessage
// who rely on this.context.intl
//
// If later on we encounter that FormattedXXX does not update,
// we employ the same approach to forward the intl context from
// redux store to the components provided by react-intl.
class SyncContextIntlToReduxStore extends PureComponent<Props> {
  static contextTypes = {
    intl: PropTypes.any,
  };

  componentDidMount() {
    if (this.context.intl) {
      this.props.syncIntl(this.context.intl);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.props.syncIntl(this.context.intl);
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.app.locale,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    syncIntl: bindActionCreators(syncIntl, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SyncContextIntlToReduxStore
);
