// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import styles from "./AlertModal.module.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import closeIcon from "../../images/modal_close.svg";
import type { Node } from "react";

type Props = {
  colorVariant: "green" | "blue" | "red" | "pink" | "orange",
  titleKey: string,
  // eslint-disable-next-line flowtype/no-weak-types
  titleValues?: Object,
  messageKey?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  messageValues?: Object,
  children?: Node,
  buttonKey: string,
  buttonClassName: ?string,
  onButtonClick: (Event, mixed) => void,
  onClose: Event => void,
  closeButtonStyle: "close" | "cancel",
  isForm: boolean,
};

export default class AlertModal extends PureComponent<Props> {
  static defaultProps = {
    buttonClassName: null,
    closeButtonStyle: "close",
    isForm: false,
  };

  onClickModal = (e: Event) => {
    e.stopPropagation();
  };

  render() {
    const DomElement = this.props.isForm ? "form" : "div";
    return (
      <DomElement
        className={classnames(styles.alertContainer, {
          [styles.green]: this.props.colorVariant === "green",
          [styles.blue]: this.props.colorVariant === "blue",
          [styles.red]: this.props.colorVariant === "red",
          [styles.pink]: this.props.colorVariant === "pink",
          [styles.orange]: this.props.colorVariant === "orange",
        })}
        onClick={this.onClickModal}
        onSubmit={this.props.isForm ? this.props.onButtonClick : null}
      >
        <p className={styles.title}>
          <Text
            translationKey={this.props.titleKey}
            values={this.props.titleValues}
          />
        </p>
        {typeof this.props.messageKey === "string" && (
          <p className={styles.message}>
            <Text
              translationKey={this.props.messageKey}
              values={this.props.messageValues}
            />
          </p>
        )}
        {this.props.children}
        <div
          className={classnames(styles.buttonsContainer, {
            [styles.buttonsDefaultMarginTop]: this.props.messageKey == null,
          })}
        >
          <Button
            color={this.props.colorVariant}
            className={classnames(styles.button, this.props.buttonClassName)}
            onClick={this.props.onButtonClick}
            type={this.props.isForm ? "submit" : "button"}
            autoFocus
          >
            <Text translationKey={this.props.buttonKey} />
          </Button>
          {this.props.closeButtonStyle === "cancel" && (
            <Button
              color="gray"
              colorApplication="border"
              className={styles.cancelButton}
              onClick={this.props.onClose}
            >
              <Text translationKey="phrase.cancel" />
            </Button>
          )}
        </div>
        {this.props.closeButtonStyle === "close" && (
          <button className={styles.closeButton} onClick={this.props.onClose}>
            <img className={styles.closeIcon} src={closeIcon} alt="close" />
          </button>
        )}
      </DomElement>
    );
  }
}
