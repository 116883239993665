// @flow
import React from "react";
import type { Node } from "react";
import type { TEMPLATE_YEAR } from "../../types/template";
import Link from "./Link";

export default function TemplatesLink(props: {
  className?: string,
  children?: Node,
  year: TEMPLATE_YEAR,
}) {
  const { className, children, year } = props;
  return (
    <Link
      className={className}
      to={{
        pathname: "/templates",
        state: { year: year },
      }}
      altId="teacher.unit_templates"
    >
      {children}
    </Link>
  );
}
