// @flow
import type { RequestState } from "./request";

export const CUSTOM_TEMPLATE_MAX_NUMBER: "CUSTOM_TEMPLATE_MAX_NUMBER" =
  "CUSTOM_TEMPLATE_MAX_NUMBER";
export const FORGOT_PASSWORD_EMAIL_LIST: "FORGOT_PASSWORD_EMAIL_LIST" =
  "FORGOT_PASSWORD_EMAIL_LIST";
export const ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH: "ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH" =
  "ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH";
export const DEFAULT_STICKER_LIST: "DEFAULT_STICKER_LIST" =
  "DEFAULT_STICKER_LIST";
export type SYS_CONF_ID =
  | typeof CUSTOM_TEMPLATE_MAX_NUMBER
  | typeof FORGOT_PASSWORD_EMAIL_LIST
  | typeof ACTIVITY_RECORDS_VISITABLE_DURATION_BY_MONTH
  | typeof DEFAULT_STICKER_LIST;

export type SysConf = {|
  +id: SYS_CONF_ID,
  +content: any,
|};

export type SysConfResponse = {|
  +_id: SYS_CONF_ID,
  +content: any,
|};

export type SysConfsState = {
  +sysConfById: { [id: SYS_CONF_ID]: SysConf },
  +getAllSysConfsRequest: ?RequestState<void>,
  +setSysConfsRequest: ?RequestState<void>,
};

export const GET_ALL_SYS_CONFS_REQUESTING: "SYS_CONFS.GET_ALL.REQUESTING" =
  "SYS_CONFS.GET_ALL.REQUESTING";
export const GET_ALL_SYS_CONFS_SUCCESS: "SYS_CONFS.GET_ALL.SUCCESS" =
  "SYS_CONFS.GET_ALL.SUCCESS";
export const GET_ALL_SYS_CONFS_FAILURE: "SYS_CONFS.GET_ALL.FAILURE" =
  "SYS_CONFS.GET_ALL.FAILURE";

export type GetAllSysConfsRequesting = {|
  +type: typeof GET_ALL_SYS_CONFS_REQUESTING,
|};
export type GetAllSysConfsSuccess = {|
  +type: typeof GET_ALL_SYS_CONFS_SUCCESS,
  +payload: $ReadOnlyArray<SysConf>,
|};
export type GetAllSysConfsFailure = {|
  +type: typeof GET_ALL_SYS_CONFS_FAILURE,
  +payload: mixed,
|};

export const SET_SYS_CONFS_REQUESTING: "AUDIT_TRAILS.SET.REQUESTING" =
  "AUDIT_TRAILS.SET.REQUESTING";
export const SET_SYS_CONFS_SUCCESS: "AUDIT_TRAILS.SET.SUCCESS" =
  "AUDIT_TRAILS.SET.SUCCESS";
export const SET_SYS_CONFS_FAILURE: "AUDIT_TRAILS.SET.FAILURE" =
  "AUDIT_TRAILS.SET.FAILURE";

export type SetSysConfsRequesting = {|
  +type: typeof SET_SYS_CONFS_REQUESTING,
|};
export type SetSysConfsSuccess = {|
  +type: typeof SET_SYS_CONFS_SUCCESS,
  +payload: $ReadOnlyArray<SysConf>,
|};
export type SetSysConfsFailure = {|
  +type: typeof SET_SYS_CONFS_FAILURE,
  +payload: mixed,
|};
