// @flow
import skygear from "skygear";
import type { Answer } from "../../types/activity";
import type {
  CreateSessionPayload,
  CreateSessionRequesting,
  CreateSessionSuccess,
  CreateSessionFailure,
  PauseSessionRequesting,
  PauseSessionSuccess,
  PauseSessionFailure,
  UnpauseSessionRequesting,
  UnpauseSessionSuccess,
  UnpauseSessionFailure,
  StartSessionRequesting,
  StartSessionSuccess,
  StartSessionFailure,
  EndSessionRequesting,
  EndSessionSuccess,
  EndSessionFailure,
  JoinSessionRequesting,
  JoinSessionSuccess,
  JoinSessionFailure,
  SeekActivityRequesting,
  SeekActivitySuccess,
  SeekActivityFailure,
  ResetActivityRequesting,
  ResetActivitySuccess,
  ResetActivityFailure,
  EndActivityRequesting,
  EndActivitySuccess,
  EndActivityFailure,
  SubmitAnswerRequesting,
  SubmitAnswerSuccess,
  SubmitAnswerFailure,
  DeleteAnswerRequesting,
  DeleteAnswerSuccess,
  DeleteAnswerFailure,
  QueryActiveSessionsRequesting,
  QueryActiveSessionsSuccess,
  QueryActiveSessionsFailure,
  QuerySingleSessionRequesting,
  QuerySingleSessionSuccess,
  QuerySingleSessionFailure,
  SyncSessionFromPubsub,
  Session,
  SessionAndParticipant,
  ParticipationInfo,
  UnitTemplateOption,
  SchoolOption,
  FetchActivityRecordsRequesting,
  FetchActivityRecordsSuccess,
  FetchActivityRecordsFailure,
  FetchSelectOptionsRequesting,
  FetchSelectOptionsSuccess,
  FetchSelectOptionsFailure,
  SearchActivityRecordsRequesting,
  SearchActivityRecordsSuccess,
  SearchActivityRecordsFailure,
} from "../../types/session";
import {
  CREATE_SESSION_REQUESTING,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  PAUSE_SESSION_REQUESTING,
  PAUSE_SESSION_SUCCESS,
  PAUSE_SESSION_FAILURE,
  UNPAUSE_SESSION_REQUESTING,
  UNPAUSE_SESSION_SUCCESS,
  UNPAUSE_SESSION_FAILURE,
  START_SESSION_REQUESTING,
  START_SESSION_SUCCESS,
  START_SESSION_FAILURE,
  END_SESSION_REQUESTING,
  END_SESSION_SUCCESS,
  END_SESSION_FAILURE,
  JOIN_SESSION_REQUESTING,
  JOIN_SESSION_SUCCESS,
  JOIN_SESSION_FAILURE,
  SEEK_ACTIVITY_REQUESTING,
  SEEK_ACTIVITY_SUCCESS,
  SEEK_ACTIVITY_FAILURE,
  RESET_ACTIVITY_REQUESTING,
  RESET_ACTIVITY_SUCCESS,
  RESET_ACTIVITY_FAILURE,
  END_ACTIVITY_REQUESTING,
  END_ACTIVITY_SUCCESS,
  END_ACTIVITY_FAILURE,
  SUBMIT_ANSWER_REQUESTING,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAILURE,
  DELETE_ANSWER_REQUESTING,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAILURE,
  QUERY_ACTIVE_SESSIONS_REQUESTING,
  QUERY_ACTIVE_SESSIONS_SUCCESS,
  QUERY_ACTIVE_SESSIONS_FAILURE,
  QUERY_SINGLE_SESSION_REQUESTING,
  QUERY_SINGLE_SESSION_SUCCESS,
  QUERY_SINGLE_SESSION_FAILURE,
  SYNC_SESSION_FROM_PUBSUB,
  FETCH_ACTIVITY_RECORDS_REQUESTING,
  FETCH_ACTIVITY_RECORDS_SUCCESS,
  FETCH_ACTIVITY_RECORDS_FAILURE,
  FETCH_SELECT_OPTIONS_REQUESTING,
  FETCH_SELECT_OPTIONS_SUCCESS,
  FETCH_SELECT_OPTIONS_FAILURE,
  SEARCH_ACTIVITY_RECORDS_REQUESTING,
  SEARCH_ACTIVITY_RECORDS_SUCCESS,
  SEARCH_ACTIVITY_RECORDS_FAILURE,
} from "../../types/session";
import type { Dispatch, GetState } from "../../types/store";
import type { PageInfo } from "../../types/request";
import type { ActivityRecordOption } from "../../types/session";
import {
  deserializeSession,
  deserializeSchoolOption,
  deserializeUnitTemplateOption,
  deserializePageInfo,
  deserializeActivityRecordOption,
} from "../../utils/deserializer";
import { postJSON } from "../../utils/skygear";
import { downloadResponse } from "../../utils/fetch";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";
import { jsTimezoneOffsetToISO8601 } from "../../utils/dateTime";

export function syncSessionFromPubsub(session: Session): SyncSessionFromPubsub {
  return {
    type: SYNC_SESSION_FROM_PUBSUB,
    payload: session,
  };
}

function createSessionRequesting(): CreateSessionRequesting {
  return {
    type: CREATE_SESSION_REQUESTING,
  };
}

function createSessionFailure(error: mixed): CreateSessionFailure {
  return {
    type: CREATE_SESSION_FAILURE,
    payload: error,
  };
}

function createSessionSuccess(session: Session): CreateSessionSuccess {
  return {
    type: CREATE_SESSION_SUCCESS,
    payload: session,
  };
}

function pauseSessionRequesting(): PauseSessionRequesting {
  return {
    type: PAUSE_SESSION_REQUESTING,
  };
}

function pauseSessionFailure(error: mixed): PauseSessionFailure {
  return {
    type: PAUSE_SESSION_FAILURE,
    payload: error,
  };
}

function pauseSessionSuccess(session: Session): PauseSessionSuccess {
  return {
    type: PAUSE_SESSION_SUCCESS,
    payload: session,
  };
}

function unpauseSessionRequesting(): UnpauseSessionRequesting {
  return {
    type: UNPAUSE_SESSION_REQUESTING,
  };
}

function unpauseSessionSuccess(session: Session): UnpauseSessionSuccess {
  return {
    type: UNPAUSE_SESSION_SUCCESS,
    payload: session,
  };
}

function unpauseSessionFailure(error: mixed): UnpauseSessionFailure {
  return {
    type: UNPAUSE_SESSION_FAILURE,
    payload: error,
  };
}

function startSessionRequesting(): StartSessionRequesting {
  return {
    type: START_SESSION_REQUESTING,
  };
}

function startSessionSuccess(session: Session): StartSessionSuccess {
  return {
    type: START_SESSION_SUCCESS,
    payload: session,
  };
}

function startSessionFailure(error: mixed): StartSessionFailure {
  return {
    type: START_SESSION_FAILURE,
    payload: error,
  };
}

function endSessionRequesting(): EndSessionRequesting {
  return {
    type: END_SESSION_REQUESTING,
  };
}

function endSessionSuccess(session: Session): EndSessionSuccess {
  return {
    type: END_SESSION_SUCCESS,
    payload: session,
  };
}

function endSessionFailure(error: mixed): EndSessionFailure {
  return {
    type: END_SESSION_FAILURE,
    payload: error,
  };
}

function joinSessionRequesting(): JoinSessionRequesting {
  return {
    type: JOIN_SESSION_REQUESTING,
  };
}

function joinSessionSuccess(sna: SessionAndParticipant): JoinSessionSuccess {
  return {
    type: JOIN_SESSION_SUCCESS,
    payload: sna,
  };
}

function joinSessionFailure(error: mixed): JoinSessionFailure {
  return {
    type: JOIN_SESSION_FAILURE,
    payload: error,
  };
}

function seekActivityRequesting(): SeekActivityRequesting {
  return {
    type: SEEK_ACTIVITY_REQUESTING,
  };
}

function seekActivitySuccess(session: Session): SeekActivitySuccess {
  return {
    type: SEEK_ACTIVITY_SUCCESS,
    payload: session,
  };
}

function seekActivityFailure(error: mixed): SeekActivityFailure {
  return {
    type: SEEK_ACTIVITY_FAILURE,
    payload: error,
  };
}

function resetActivityRequesting(): ResetActivityRequesting {
  return {
    type: RESET_ACTIVITY_REQUESTING,
  };
}

function resetActivitySuccess(session: Session): ResetActivitySuccess {
  return {
    type: RESET_ACTIVITY_SUCCESS,
    payload: session,
  };
}

function resetActivityFailure(error: mixed): ResetActivityFailure {
  return {
    type: RESET_ACTIVITY_FAILURE,
    payload: error,
  };
}

function endActivityRequesting(): EndActivityRequesting {
  return {
    type: END_ACTIVITY_REQUESTING,
  };
}

function endActivitySuccess(session: Session): EndActivitySuccess {
  return {
    type: END_ACTIVITY_SUCCESS,
    payload: session,
  };
}

function endActivityFailure(error: mixed): EndActivityFailure {
  return {
    type: END_ACTIVITY_FAILURE,
    payload: error,
  };
}

function submitAnswerRequesting(): SubmitAnswerRequesting {
  return {
    type: SUBMIT_ANSWER_REQUESTING,
  };
}

function submitAnswerSuccess(session: Session): SubmitAnswerSuccess {
  return {
    type: SUBMIT_ANSWER_SUCCESS,
    payload: session,
  };
}

function submitAnswerFailure(error: mixed): SubmitAnswerFailure {
  return {
    type: SUBMIT_ANSWER_FAILURE,
    payload: error,
  };
}

function deleteAnswerRequesting(): DeleteAnswerRequesting {
  return {
    type: DELETE_ANSWER_REQUESTING,
  };
}

function deleteAnswerSuccess(session: Session): DeleteAnswerSuccess {
  return {
    type: DELETE_ANSWER_SUCCESS,
    payload: session,
  };
}

function deleteAnswerFailure(error: mixed): DeleteAnswerFailure {
  return {
    type: DELETE_ANSWER_FAILURE,
    payload: error,
  };
}

function queryActiveSessionsRequesting(): QueryActiveSessionsRequesting {
  return {
    type: QUERY_ACTIVE_SESSIONS_REQUESTING,
  };
}

function queryActiveSessionsSuccess(
  sessions: $ReadOnlyArray<Session>
): QueryActiveSessionsSuccess {
  return {
    type: QUERY_ACTIVE_SESSIONS_SUCCESS,
    payload: sessions,
  };
}

function queryActiveSessionsFailure(error: mixed): QueryActiveSessionsFailure {
  return {
    type: QUERY_ACTIVE_SESSIONS_FAILURE,
    payload: error,
  };
}

function querySingleSessionRequesting(): QuerySingleSessionRequesting {
  return {
    type: QUERY_SINGLE_SESSION_REQUESTING,
  };
}

function querySingleSessionSuccess(
  session: Session
): QuerySingleSessionSuccess {
  return {
    type: QUERY_SINGLE_SESSION_SUCCESS,
    payload: session,
  };
}

function querySingleSessionFailure(error: mixed): QuerySingleSessionFailure {
  return {
    type: QUERY_SINGLE_SESSION_FAILURE,
    payload: error,
  };
}

function fetchActivityRecordsRequesting(): FetchActivityRecordsRequesting {
  return {
    type: FETCH_ACTIVITY_RECORDS_REQUESTING,
  };
}

function fetchActivityRecordsSuccess(
  templateId: string,
  records: $ReadOnlyArray<Session>
): FetchActivityRecordsSuccess {
  return {
    type: FETCH_ACTIVITY_RECORDS_SUCCESS,
    payload: {
      templateId: templateId,
      records: records,
    },
  };
}

function fetchActivityRecordsFailure(
  error: mixed
): FetchActivityRecordsFailure {
  return {
    type: FETCH_ACTIVITY_RECORDS_FAILURE,
    payload: error,
  };
}

function fetchSelectOptionsRequesting(): FetchSelectOptionsRequesting {
  return {
    type: FETCH_SELECT_OPTIONS_REQUESTING,
  };
}

function fetchSelectOptionsSuccess(
  templateOptions: $ReadOnlyArray<UnitTemplateOption>,
  schoolOptions: $ReadOnlyArray<SchoolOption>
): FetchSelectOptionsSuccess {
  return {
    type: FETCH_SELECT_OPTIONS_SUCCESS,
    payload: {
      templateOptions,
      schoolOptions,
    },
  };
}

function fetchSelectOptionsFailure(error: mixed): FetchSelectOptionsFailure {
  return {
    type: FETCH_SELECT_OPTIONS_FAILURE,
    payload: error,
  };
}

function searchActivityRecordsRequesting(): SearchActivityRecordsRequesting {
  return {
    type: SEARCH_ACTIVITY_RECORDS_REQUESTING,
  };
}

function searchActivityRecordsSuccess(
  sessions: $ReadOnlyArray<Session>
): SearchActivityRecordsSuccess {
  return {
    type: SEARCH_ACTIVITY_RECORDS_SUCCESS,
    payload: sessions,
  };
}

function searchActivityRecordsFailure(
  error: mixed
): SearchActivityRecordsFailure {
  return {
    type: SEARCH_ACTIVITY_RECORDS_FAILURE,
    payload: error,
  };
}

export function createSession({
  mode,
  templateId,
  teacherName,
}: CreateSessionPayload) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.createSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(createSessionRequesting());
    const args = {
      mode: mode,
      template_id: templateId,
      teacher_name: teacherName,
    };
    return skygear
      .lambda("polyupaths:teaching_session:create", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(createSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(createSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function startSession(sessionId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.startSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(startSessionRequesting());
    const args = {
      session_id: sessionId,
    };
    return skygear
      .lambda("polyupaths:teaching_session:start", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(startSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(startSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function endSession(sessionId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.endSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(endSessionRequesting());
    const args = {
      session_id: sessionId,
    };
    return skygear
      .lambda("polyupaths:teaching_session:end", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(endSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(endSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function joinSession(participationCode: string, name: string) {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<SessionAndParticipant> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.joinSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(joinSessionRequesting());
    const args = {
      name,
      session_code: participationCode,
    };
    return skygear
      .lambda("polyupaths:teaching_session:join_session", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        const participantId = result.participant_id;
        const participant = {
          name,
          id: participantId,
        };
        const sna = {
          session,
          participant,
        };
        dispatch(joinSessionSuccess(sna));
        return sna;
      })
      .catch(error => {
        dispatch(joinSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function pauseSession(sessionId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.pauseOrUnpauseSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(pauseSessionRequesting());
    const args = {
      session_id: sessionId,
    };
    return skygear
      .lambda("polyupaths:teaching_session:pause", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(pauseSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(pauseSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function unpauseSession(sessionId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.pauseOrUnpauseSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(unpauseSessionRequesting());
    const args = {
      session_id: sessionId,
    };
    return skygear
      .lambda("polyupaths:teaching_session:unpause", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(unpauseSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(unpauseSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function seekActivity(sessionId: string, activityIndex: number) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.seekActivityRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(seekActivityRequesting());
    const args = {
      session_id: sessionId,
      activity_index: activityIndex,
    };
    return skygear
      .lambda("polyupaths:teaching_session:seek_activity", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(seekActivitySuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(seekActivityFailure(error));
        return Promise.reject(error);
      });
  };
}

export function resetActivity(sessionId: string, activityIndex: number) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.resetActivityRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(resetActivityRequesting());
    const args = {
      session_id: sessionId,
      activity_index: activityIndex,
    };
    return skygear
      .lambda("polyupaths:teaching_session:reset_activity", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(resetActivitySuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(resetActivityFailure(error));
        return Promise.reject(error);
      });
  };
}

export function endActivity(sessionId: string, activityIndex: number) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.endActivityRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(endActivityRequesting());
    const args = {
      session_id: sessionId,
      activity_index: activityIndex,
    };
    return skygear
      .lambda("polyupaths:teaching_session:end_activity", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(endActivitySuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(endActivityFailure(error));
        return Promise.reject(error);
      });
  };
}

export function submitAnswer(
  sessionId: string,
  activityIndex: number,
  participationInfo: ParticipationInfo,
  answer: Answer
) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.submitAnswerRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(submitAnswerRequesting());
    const args = {
      session_id: sessionId,
      activity_index: activityIndex,
      participant_id: participationInfo.participantId,
      qa_text: answer.qaText,
      whiteboard_image_id: answer.whiteboardImageId,
      polling_option_indice: answer.pollingOptionIndice,
    };

    return skygear
      .lambda("polyupaths:teaching_session:submit_answer", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(submitAnswerSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(submitAnswerFailure(error));
        return Promise.reject(error);
      });
  };
}

export function deleteAnswer(data: {
  sessionId: string,
  activityIndex: number,
  participantId: string,
}) {
  const { sessionId, activityIndex, participantId } = data;
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.deleteAnswerRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(deleteAnswerRequesting());
    const args = {
      session_id: sessionId,
      activity_index: activityIndex,
      participant_id: participantId,
    };

    return skygear
      .lambda("polyupaths:teaching_session:delete_answer", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(deleteAnswerSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(deleteAnswerFailure(error));
        return Promise.reject(error);
      });
  };
}

export function queryActiveSessions() {
  return (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.activeSessionsRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(queryActiveSessionsRequesting());
    const args = {};
    return skygear
      .lambda("polyupaths:teaching_session:query_active", [args])
      .then(result => {
        const sessions = result.teaching_sessions.map(deserializeSession);
        dispatch(queryActiveSessionsSuccess(sessions));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(queryActiveSessionsFailure(error));
        return Promise.reject(error);
      });
  };
}

export function querySingleSession(sessionId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<Session> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.singleSessionRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(querySingleSessionRequesting());
    const args = {
      session_id: sessionId,
    };
    return skygear
      .lambda("polyupaths:teaching_session:query_one", [args])
      .then(result => {
        const session = deserializeSession(result.teaching_session);
        dispatch(querySingleSessionSuccess(session));
        return session;
      })
      .catch(error => {
        dispatch(querySingleSessionFailure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchActivityRecords(templateId: string) {
  return (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.fetchActivityRecordsRequest)) {
      return Promise.reject(ongoingRequestError);
    }
    const args = {
      template_id: templateId,
    };
    dispatch(fetchActivityRecordsRequesting());
    return skygear
      .lambda("polyupaths:teaching_session:query_activity_records", [args])
      .then(result => {
        dispatch(
          fetchActivityRecordsSuccess(
            templateId,
            result.activity_records.map(deserializeSession)
          )
        );
      })
      .catch(error => {
        dispatch(fetchActivityRecordsFailure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchSelectOptions() {
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{
    templateOptions: $ReadOnlyArray<UnitTemplateOption>,
    schoolOptions: $ReadOnlyArray<SchoolOption>,
  }> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.fetchSelectOptionsRequest)) {
      return Promise.reject(ongoingRequestError);
    }
    const args = {};
    dispatch(fetchSelectOptionsRequesting());
    return skygear
      .lambda("polyupaths:teaching_session:query_select_options", [args])
      .then(result => {
        const templateOptions = result.template_options.map(
          deserializeUnitTemplateOption
        );
        const schoolOptions = result.school_options.map(
          deserializeSchoolOption
        );
        dispatch(fetchSelectOptionsSuccess(templateOptions, schoolOptions));
        return {
          templateOptions,
          schoolOptions,
        };
      })
      .catch(error => {
        dispatch(fetchSelectOptionsFailure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchActivityRecordOptions(data: {
  templateID: string,
  schoolID: string,
}): Promise<$ReadOnlyArray<ActivityRecordOption>> {
  const { templateID, schoolID } = data;
  const args = {
    template_id: templateID,
    school_id: schoolID,
  };
  return skygear
    .lambda("polyupaths:teaching_session:query_activity_record_options", [args])
    .then(result => {
      return result.activity_record_options.map(
        deserializeActivityRecordOption
      );
    });
}

export function searchActivityRecords(data: {
  pageNumber: number,
  pageSize: number,
  templateID: ?string,
  schoolID: ?string,
  fromDate: Date,
  toDate: Date,
}) {
  const { pageNumber, pageSize, templateID, schoolID, fromDate, toDate } = data;
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{
    pageInfo: PageInfo,
    sessions: $ReadOnlyArray<Session>,
  }> => {
    const currentState = getState();
    if (isRequesting(currentState.sessions.searchActivityRecordsRequest)) {
      return Promise.reject(ongoingRequestError);
    }
    const iso8601TimezoneOffset = jsTimezoneOffsetToISO8601(
      fromDate.getTimezoneOffset()
    );
    const args = {
      page_args: {
        page_number: pageNumber,
        page_size: pageSize,
      },
      template_id: templateID,
      school_id: schoolID,
      from_date: fromDate.toISOString(),
      to_date: toDate.toISOString(),
      timezone_offset: iso8601TimezoneOffset,
    };
    dispatch(searchActivityRecordsRequesting());
    return skygear
      .lambda("polyupaths:teaching_session:search_activity_records", [args])
      .then(result => {
        const sessions = result.activity_records.map(deserializeSession);
        const pageInfo = deserializePageInfo(result.page_info);
        dispatch(searchActivityRecordsSuccess(sessions));
        return {
          sessions,
          pageInfo,
        };
      })
      .catch(error => {
        dispatch(searchActivityRecordsFailure(error));
        return Promise.reject(error);
      });
  };
}

export function deleteActivityRecord(sessionID: string): Promise<void> {
  const args = {
    session_id: sessionID,
  };
  return skygear.lambda("polyupaths:teaching_session:delete_activity_record", [
    args,
  ]);
}

export function exportActivityRecordsAsync(data: {
  templateID: ?string,
  schoolID: ?string,
  fromDate: Date,
  toDate: Date,
}): Promise<$ReadOnlyArray<string>> {
  const { templateID, schoolID, fromDate, toDate } = data;
  const iso8601TimezoneOffset = jsTimezoneOffsetToISO8601(
    fromDate.getTimezoneOffset()
  );
  const args = {
    template_id: templateID,
    school_id: schoolID,
    from_date: fromDate.toISOString(),
    to_date: toDate.toISOString(),
    timezone_offset: iso8601TimezoneOffset,
  };
  return skygear
    .lambda("polyupaths:teaching_session:export_activity_records_async", [args])
    .then(result => {
      const sessionIDs = result.session_ids;
      return sessionIDs;
    });
}

export function exportActivityRecords(
  ids: $ReadOnlyArray<string>
): Promise<void> {
  const args = {
    session_ids: ids,
  };
  return postJSON(
    "/polyupaths/teaching_session/export_activity_records",
    args
  ).then(downloadResponse);
}
