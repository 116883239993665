// @flow
import type { DragSourceConnector, DragSourceMonitor } from "react-dnd";
import type { Props } from "./ActivityPanelItem";

export const dragSource = {
  beginDrag(props: Props) {
    return {
      index: props.index,
    };
  },

  canDrag(props: Props) {
    return props.canDrag === true;
  },
};

export function collectSource(
  connect: DragSourceConnector,
  monitor: DragSourceMonitor
) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}
