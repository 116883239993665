// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import type { RootState, Dispatch } from "../../types/store";
import type { RequestState } from "../../types/request";
import type { Session, Participant } from "../../types/session";
import type { Activity, ActivityState } from "../../types/activity";
import type { ParticipantAnswer } from "../../utils/session";
import { deleteAnswer } from "../../redux/actions/sessions";
import { extractError } from "../../utils/request";
import Div from "../Div";
import Text from "../Text/Text";
import ModalContainer from "../Modal/ModalContainer";
import SingleImageModal from "../Modal/SingleImageModal";
import ErrorModal from "../ErrorModal";
import PagerModal from "../Modal/PagerModal";
import PollingChart from "../PollingChart/PollingChart";
import RichContent from "../RichEditor/RichContent";
import Button from "../Button/Button";
import Image from "../Image/Image";
import styles from "./TeacherSessionActivityResult.module.scss";
import {
  questionContainerPadding,
  getMaxHeightForQuestion,
  getExactHeightForContent,
  getHeightForContentContainer,
  toParticipantAnswerArray,
} from "./utils";

class ParticipantAnswerPagerModal extends PagerModal<ParticipantAnswer> {}

type OwnProps = {
  parentHeight: number,
  sessionID: ?string,
  participants: $ReadOnlyArray<Participant>,
  currentActivityIndex: number,
  activity: Activity,
  activityState: ActivityState,
};

type ConnectedProps = {
  deleteAnswerRequest: ?RequestState<void>,
  deleteAnswer: (data: {
    sessionId: string,
    activityIndex: number,
    participantId: string,
  }) => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  questionHeight: number,
  participantAnswers: $ReadOnlyArray<ParticipantAnswer>,
  isQuestionImageModalShown: boolean,
  isQAItemModalShown: boolean,
  isWhiteboardItemModalShown: boolean,
  answerItemModalInitialIndex: number,
};

function getDerivedStateFromProps(props: Props) {
  const { participants, activityState } = props;
  const participantAnswers = toParticipantAnswerArray(
    participants,
    activityState
  );
  return {
    participantAnswers,
  };
}

class TeacherSessionActivityResult extends PureComponent<Props, LocalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questionHeight: 0,
      isQuestionImageModalShown: false,
      isQAItemModalShown: false,
      isWhiteboardItemModalShown: false,
      answerItemModalInitialIndex: 0,
      ...getDerivedStateFromProps(props),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      ...getDerivedStateFromProps(nextProps),
    });
  }

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isQuestionImageModalShown: false,
      isQAItemModalShown: false,
      isWhiteboardItemModalShown: false,
    });
  };

  onQuestionResize = (width: number, height: number) => {
    this.setState({
      questionHeight: height,
    });
  };

  onClickModalContent = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onQuestionClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { activity } = this.props;
    const { imageUrl } = activity;
    if (imageUrl == null) {
      return;
    }
    this.setState({
      isQuestionImageModalShown: true,
    });
  };

  onDeleteAnswer = (e: Event, info: mixed) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isQAItemModalShown: false,
      isWhiteboardItemModalShown: false,
    });
    const { sessionID, currentActivityIndex } = this.props;
    if (sessionID != null) {
      const participantAnswer = ((info: any): ParticipantAnswer);
      this.props.deleteAnswer({
        sessionId: sessionID,
        activityIndex: currentActivityIndex,
        participantId: participantAnswer.participant.id,
      });
    }
  };

  renderQuestion() {
    const { activity } = this.props;
    const { imageUrl } = activity;
    const maxHeight = getMaxHeightForQuestion(this.props.parentHeight);
    return (
      <Div
        style={{ maxHeight }}
        className={styles.questionContainer}
        onResize={this.onQuestionResize}
      >
        <div
          onClick={this.onQuestionClick}
          style={{ maxHeight: maxHeight - 2 * questionContainerPadding + "px" }}
          className={styles.questionContentContainer}
        >
          <div
            className={classnames(styles.questionText, {
              [styles.withImage]: imageUrl != null,
            })}
          >
            <RichContent rawContentString={activity.question} />
          </div>
        </div>
      </Div>
    );
  }

  renderQAAnswerItem = (
    participantAnswer: ParticipantAnswer,
    index: number
  ) => {
    const { participant, answer } = participantAnswer;
    return (
      <li
        key={participant.id}
        className={styles.qaAnswerListItem}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({
            isQAItemModalShown: true,
            answerItemModalInitialIndex: index,
          });
        }}
      >
        <div className={styles.qaAnswerItemContainer}>
          <p className={styles.qaAnswerItemNameText}>{participant.name}</p>
          <p className={styles.qaAnswerItemAnswerText}>{answer.qaText || ""}</p>
        </div>
        <Button
          className={styles.qaAnswerItemDeleteButton}
          color="white"
          onClickInfo={participantAnswer}
          onClick={this.onDeleteAnswer}
        />
      </li>
    );
  };

  renderWhiteboardAnswerItem = (
    participantAnswer: ParticipantAnswer,
    index: number
  ) => {
    const { participant, answer } = participantAnswer;
    const { whiteboardImageUrl } = answer;
    return (
      <li
        key={participant.id}
        className={styles.whiteboardAnswerListItem}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({
            isWhiteboardItemModalShown: true,
            answerItemModalInitialIndex: index,
          });
        }}
      >
        <Image
          imageUrl={whiteboardImageUrl != null ? whiteboardImageUrl : ""}
          width="100%"
          height={220}
          placeholderWidth="100%"
          placeholderHeight={220}
          className={styles.whiteboardAnswerItemImage}
        >
          <p className={styles.whiteboardAnswerItemNameText}>
            {participant.name}
          </p>
          <Button
            className={styles.whiteboardAnswerItemDeleteButton}
            color="gray3"
            onClickInfo={participantAnswer}
            onClick={this.onDeleteAnswer}
          />
        </Image>
      </li>
    );
  };

  renderContent() {
    const { parentHeight, activity, participants } = this.props;
    const { type, pollingOptions, pollingNamed } = activity;
    const { participantAnswers } = this.state;
    const participantCount = participants.length;
    const height = getExactHeightForContent(
      parentHeight,
      this.state.questionHeight
    );

    if (type === "qa") {
      return (
        <div style={{ height }} className={styles.qaAnswerListContainer}>
          {participantAnswers.length !== 0 && (
            <ul className={styles.qaAnswerList}>
              {participantAnswers.map(this.renderQAAnswerItem)}
            </ul>
          )}
          {participantAnswers.length === 0 && (
            <p className={styles.noAnswersText}>
              <Text translationKey="teacher.no_answers" />
            </p>
          )}
        </div>
      );
    }

    if (type === "polling" && pollingOptions != null) {
      const height = getExactHeightForContent(
        parentHeight,
        this.state.questionHeight
      );
      return (
        <div style={{ height }} className={styles.pollingChartContainer}>
          <PollingChart
            className={styles.pollingChart}
            showParticipantName={pollingNamed != null ? pollingNamed : false}
            imageDisplayVariant="inline"
            participantCount={participantCount}
            pollingOptions={pollingOptions}
            participantAnswers={participantAnswers}
          />
        </div>
      );
    }

    if (type === "whiteboard") {
      return (
        <div
          style={{ height }}
          className={styles.whiteboardAnswerListContainer}
        >
          {participantAnswers.length !== 0 && (
            <ul className={styles.whiteboardAnswerList}>
              {participantAnswers.map(this.renderWhiteboardAnswerItem)}
            </ul>
          )}
          {participantAnswers.length === 0 && (
            <p className={styles.noAnswersText}>
              <Text translationKey="teacher.no_answers" />
            </p>
          )}
        </div>
      );
    }

    return null;
  }

  renderQAAnswerItemModal() {
    const {
      isQAItemModalShown,
      answerItemModalInitialIndex,
      participantAnswers,
    } = this.state;
    if (!isQAItemModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <ParticipantAnswerPagerModal
          className={styles.qaAnswerItemPagerModal}
          initialIndex={answerItemModalInitialIndex}
          data={participantAnswers}
        >
          {(data: ParticipantAnswer, index: number) => {
            const { participant, answer } = data;
            return (
              <div
                onClick={this.onClickModalContent}
                className={styles.qaAnswerItemPagerModalContent}
              >
                <p className={styles.qaAnswerItemPagerModalNameText}>
                  {participant.name}
                </p>
                <p className={styles.qaAnswerItemPagerModalAnswerText}>
                  {answer.qaText || ""}
                </p>
                <div className={styles.qaAnswerItemPagerModalFooter}>
                  <Button
                    className={styles.qaAnswerItemPagerModalDeleteButton}
                    applyBorderRadius={false}
                    applyHoverColor={false}
                    color="white"
                    onClickInfo={data}
                    onClick={this.onDeleteAnswer}
                  >
                    <span
                      className={styles.qaAnswerItemPagerModalDeleteButtonText}
                    >
                      <Text translationKey="teacher.delete_answer" />
                    </span>
                  </Button>
                  <p className={styles.qaAnswerItemPagerModalIndexText}>
                    <Text
                      translationKey="student.answer_modal_index"
                      values={{
                        index: index + 1,
                        total: participantAnswers.length,
                      }}
                    />
                  </p>
                </div>
                <div
                  className={styles.qaAnswerItemPagerModalCloseButton}
                  onClick={this.onModalClose}
                />
              </div>
            );
          }}
        </ParticipantAnswerPagerModal>
      </ModalContainer>
    );
  }

  renderWhiteboardAnswerItemModal() {
    const {
      isWhiteboardItemModalShown,
      answerItemModalInitialIndex,
      participantAnswers,
    } = this.state;
    if (!isWhiteboardItemModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <ParticipantAnswerPagerModal
          className={styles.whiteboardAnswerItemPagerModal}
          initialIndex={answerItemModalInitialIndex}
          data={participantAnswers}
        >
          {(data: ParticipantAnswer, index: number) => {
            const { participant, answer } = data;
            const { whiteboardImageUrl } = answer;
            return (
              <div
                onClick={this.onClickModalContent}
                className={styles.whiteboardAnswerItemPagerModalContent}
              >
                <p className={styles.whiteboardAnswerItemPagerModalNameText}>
                  {participant.name}
                </p>
                <Image
                  imageUrl={
                    whiteboardImageUrl != null ? whiteboardImageUrl : ""
                  }
                  className={styles.whiteboardAnswerItemPagerModalImage}
                  width="100%"
                  height={512}
                  placeholderWidth="100%"
                  placeholderHeight={512}
                />
                <div className={styles.whiteboardAnswerItemPagerModalFooter}>
                  <Button
                    className={
                      styles.whiteboardAnswerItemPagerModalDeleteButton
                    }
                    color="white"
                    applyBorderRadius={false}
                    applyHoverColor={false}
                    onClickInfo={data}
                    onClick={this.onDeleteAnswer}
                  >
                    <span
                      className={
                        styles.whiteboardAnswerItemPagerModalDeleteButtonText
                      }
                    >
                      <Text translationKey="teacher.delete_answer" />
                    </span>
                  </Button>
                  <p className={styles.whiteboardAnswerItemPagerModalIndexText}>
                    <Text
                      translationKey="student.answer_modal_index"
                      values={{
                        index: index + 1,
                        total: participantAnswers.length,
                      }}
                    />
                  </p>
                </div>
                <div
                  className={styles.whiteboardAnswerItemPagerModalCloseButton}
                  onClick={this.onModalClose}
                />
              </div>
            );
          }}
        </ParticipantAnswerPagerModal>
      </ModalContainer>
    );
  }

  renderQuestionImageModal() {
    const { isQuestionImageModalShown } = this.state;
    if (!isQuestionImageModalShown) {
      return false;
    }
    const { activity } = this.props;
    const { imageUrl } = activity;
    if (imageUrl == null) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <SingleImageModal
          className={styles.questionImage}
          imageURL={imageUrl}
          width={781}
          height={594}
          onClose={this.onModalClose}
        />
      </ModalContainer>
    );
  }

  render() {
    const contentHeight = getHeightForContentContainer(this.props.parentHeight);
    return (
      <div
        style={{ height: this.props.parentHeight }}
        className={styles.rootContainer}
      >
        <div style={{ height: contentHeight }} className="">
          {this.renderQuestion()}
          {this.renderContent()}
        </div>
        {this.renderQAAnswerItemModal()}
        {this.renderWhiteboardAnswerItemModal()}
        {this.renderQuestionImageModal()}
        <ErrorModal error={extractError(this.props.deleteAnswerRequest)} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    deleteAnswerRequest: state.sessions.deleteAnswerRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteAnswer: bindActionCreators(deleteAnswer, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  TeacherSessionActivityResult
);
