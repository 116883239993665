// @flow
import React, { PureComponent, Fragment } from "react";
import ActivityRecordPanelRenderer from "./ActivityRecordPanelRenderer";
import type { RecordProps } from "./ActivityRecordType";
import { toParticipantAnswerArray } from "../TeacherSessionActivityResult/utils";
import Text from "../Text/Text";
import ActivityRecordPollingChart from "../PollingChart/ActivityRecordPollingChart";
import styles from "./ActivityRecordPollingPanel.module.scss";

class ActivityRecordPollingPanel extends PureComponent<RecordProps> {
  renderExtra() {
    const { pollingOptions, pollingMultipleChoice } = this.props.activity;
    const { session, activityState } = this.props;
    const answers = toParticipantAnswerArray(
      session.participants,
      activityState
    );

    if (!pollingOptions) {
      return <div />;
    }

    return (
      <Fragment>
        <div className={styles.titleContainer}>
          <p className={styles.titleText}>
            <Text
              translationKey="teacher.student_answers"
              values={{
                numberOfAnswers: toParticipantAnswerArray(
                  session.participants,
                  activityState
                ).length,
              }}
            />
          </p>
          <p className={styles.optionDescriptionText}>
            <Text
              translationKey="teacher.polling.option_description"
              values={{
                MULTIPLE: !!pollingMultipleChoice,
              }}
            />
          </p>
        </div>
        <div className={styles.pollingContainer}>
          <ActivityRecordPollingChart
            className={styles.pollingChart}
            participantCount={answers.length}
            pollingOptions={pollingOptions}
            participantAnswers={answers}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <ActivityRecordPanelRenderer {...this.props}>
        {this.renderExtra()}
      </ActivityRecordPanelRenderer>
    );
  }
}

export default ActivityRecordPollingPanel;
