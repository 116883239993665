// @flow
import React, { PureComponent } from "react";
import TextArea from "./TextArea";

type Props = {
  +placeholderId: string,
  +paddingTop: number,
  +paddingBottom: number,
  +lineHeight: number,
  +maxNumberOfLines: number,
  +onRef?: (r: HTMLTextAreaElement | null) => void,
  +onChange?: (e: SyntheticInputEvent<HTMLTextAreaElement>) => void,
};

type LocalState = {
  height: number,
};

function getInitialHeight(data: {
  paddingTop: number,
  paddingBottom: number,
  lineHeight: number,
}): number {
  const { paddingTop, paddingBottom, lineHeight } = data;
  return paddingTop + lineHeight + paddingBottom;
}

function guessNumberOfLines(data: {
  scrollHeight: number,
  paddingTop: number,
  paddingBottom: number,
  lineHeight: number,
}): number {
  const { scrollHeight, paddingTop, paddingBottom, lineHeight } = data;
  const contentHeight = scrollHeight - paddingTop - paddingBottom;
  return Math.round(contentHeight / lineHeight);
}

function buildHeight(data: {
  numberOfLines: number,
  paddingTop: number,
  paddingBottom: number,
  lineHeight: number,
}): number {
  const { numberOfLines, paddingTop, paddingBottom, lineHeight } = data;
  return paddingTop + paddingBottom + lineHeight * numberOfLines;
}

export default class AutoExpandTextArea extends PureComponent<
  Props,
  LocalState
> {
  textArea: ?HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);
    const { paddingTop, paddingBottom, lineHeight } = props;
    this.state = {
      height: getInitialHeight({
        paddingTop,
        paddingBottom,
        lineHeight,
      }),
    };
  }

  onRef = (r: HTMLTextAreaElement | null) => {
    this.textArea = r;
    if (this.props.onRef != null) {
      this.props.onRef(r);
    }
  };

  onChange = (e: SyntheticInputEvent<HTMLTextAreaElement>) => {
    const { textArea } = this;
    if (textArea != null) {
      const originalHeight = textArea.style.height;
      const {
        paddingTop,
        paddingBottom,
        lineHeight,
        maxNumberOfLines,
      } = this.props;
      // First we reset the height
      textArea.style.height =
        getInitialHeight({
          paddingTop,
          paddingBottom,
          lineHeight,
        }) + "px";
      const numberOfLines = guessNumberOfLines({
        paddingTop,
        paddingBottom,
        lineHeight,
        scrollHeight: textArea.scrollHeight,
      });
      const height = buildHeight({
        paddingTop,
        paddingBottom,
        lineHeight,
        numberOfLines: Math.min(numberOfLines, maxNumberOfLines),
      });
      // Recover the height
      textArea.style.height = originalHeight;
      if (this.state.height !== height) {
        this.setState({
          height,
        });
      }
    }
    if (this.props.onChange != null) {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      placeholderId,
      lineHeight,
      paddingTop,
      paddingBottom,
      maxNumberOfLines, // avoid react warning
      ...rest
    } = this.props;
    const { height } = this.state;
    return (
      <TextArea
        {...rest}
        style={{
          height,
          lineHeight: lineHeight + "px",
          paddingTop: paddingTop + "px",
          paddingBottom: paddingBottom + "px",
          overflow: "auto",
          border: "0",
        }}
        onRef={this.onRef}
        placeholderId={placeholderId}
        onChange={this.onChange}
      />
    );
  }
}
