// @flow
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { RootState, Dispatch } from "../../types/store";
import type { Session, SessionResponse } from "../../types/session";
import OnActive from "../OnActive";
import SkygearPubsub from "../../pubsub/SkygearPubsub";
import {
  sessionIdToChannelName,
  schoolIdToChannelName,
} from "../../utils/pubsub";
import {
  querySingleSession,
  syncSessionFromPubsub,
} from "../../redux/actions/sessions";
import { deserializeSession } from "../../utils/deserializer";

type OwnProps = {
  sessionId?: string,
};

type ConnectedProps = {
  schoolID: ?string,
  querySingleSession: string => Promise<void>,
  syncSessionFromPubsub?: Session => void,
};

type Props = OwnProps & ConnectedProps;

class Synchronization extends PureComponent<Props> {
  onMessage = (data: mixed) => {
    const { syncSessionFromPubsub } = this.props;
    if (
      data != null &&
      data.teaching_session != null &&
      syncSessionFromPubsub != null
    ) {
      const ts = ((data.teaching_session: any): SessionResponse);
      syncSessionFromPubsub(deserializeSession(ts));
    }
  };

  render() {
    const { schoolID, sessionId } = this.props;
    return (
      <Fragment>
        <OnActive onActive={this._fetch} />
        {sessionId == null ? null : (
          <SkygearPubsub
            channel={sessionIdToChannelName(sessionId)}
            onOpen={this._fetch}
            onMessage={this.onMessage}
          />
        )}
        {schoolID == null ? null : (
          <SkygearPubsub
            channel={schoolIdToChannelName(schoolID)}
            onOpen={this._fetch}
            onMessage={this.onMessage}
          />
        )}
      </Fragment>
    );
  }

  _fetch = () => {
    const { sessionId } = this.props;
    if (sessionId != null) {
      this.props.querySingleSession(sessionId).catch(e => {
        // ignore this error
        // since it is probably ongoingRequestError
      });
    }
  };
}

function mapStateToProps(state: RootState) {
  const school = state.app.school;
  const schoolID = school != null ? school.id : null;
  return {
    schoolID,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    syncSessionFromPubsub: bindActionCreators(syncSessionFromPubsub, dispatch),
    querySingleSession: bindActionCreators(querySingleSession, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  Synchronization
): any): Class<React$Component<OwnProps>>);
