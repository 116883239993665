// @flow
import React from "react";
import type { Node } from "react";
import Link from "./Link";

export default function ForgotPasswordLink(props: {
  className: string,
  type: "teacher" | "admin",
  children?: Node,
}) {
  const { className, type, children } = props;
  const base = type === "admin" ? "/admin" : "";
  return (
    <Link
      className={className}
      to={`${base}/forgot-password`}
      altId="phrase.forgot_password"
    >
      {children}
    </Link>
  );
}
