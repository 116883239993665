// @flow
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import type { IntlShape } from "react-intl";
import type { RootState } from "../../types/store";

type Props = {|
  intl: IntlShape,
  translationKey: string,
  // eslint-disable-next-line flowtype/no-weak-types
  values?: Object,
|};

class Text extends PureComponent<Props> {
  static childContextTypes = {
    intl: PropTypes.any,
  };

  getChildContext() {
    return {
      intl: this.props.intl,
    };
  }

  render() {
    const { translationKey, values } = this.props;

    if (!translationKey) {
      return "UNDEFINED";
    }

    return (
      <FormattedMessage
        id={translationKey}
        values={values}
        defaultMessage={translationKey || "UNDEFINED"}
      />
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    intl: state.app.intl,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);
