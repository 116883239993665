// @flow
import React, { PureComponent, Fragment } from "react";
import ActivityRecordQAPanel from "./ActivityRecordQAPanel";
import ActivityRecordPollingPanel from "./ActivityRecordPollingPanel";
import ActivityRecordWhiteboardPanel from "./ActivityRecordWhiteboardPanel";
import type { RecordProps } from "./ActivityRecordType";

class ActivityRecordPanel extends PureComponent<RecordProps> {
  render() {
    const { activity } = this.props;

    return (
      <Fragment>
        {activity &&
          activity.type === "qa" && <ActivityRecordQAPanel {...this.props} />}
        {activity &&
          activity.type === "polling" && (
            <ActivityRecordPollingPanel {...this.props} />
          )}
        {activity &&
          activity.type === "whiteboard" && (
            <ActivityRecordWhiteboardPanel {...this.props} />
          )}
      </Fragment>
    );
  }
}

export default ActivityRecordPanel;
