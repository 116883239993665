// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import type { Node } from "react";
import ActivityPanelRenderer from "./ActivityPanelRenderer";
import Text from "../Text/Text";
import type { CommonProps } from "./types/ActivityPanelProps";
import type { Sticker } from "../../types/activity";
import ImageUploadButton from "../ImageUploadButton/ImageUploadButton";
import ImageChooser from "../ImageChooser/ImageChooser";
import Image from "../Image/Image";
import Select from "../Select/Select";
import Option from "../Option/Option";
import {
  WHITEBOARD_BACKGROUND_HEIGHT,
  WHITEBOARD_STICKER_SIZE,
  STICKER_FIXED_SIZE,
} from "../../utils/constants";
import {
  ErrorActivityMissingBackgroundImage,
  MAX_STICKERS_COUNT,
} from "../../utils/validate";
import styles from "./ActivityWhiteboardPanel.module.scss";

const SUGGEST_BACKGROUND_IMAGE_WIDTH = 1600;
const SUGGEST_BACKGROUND_IMAGE_HEIGHT = 900;

function RadioButtonGroup(props: {
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  checked: boolean,
  children: Node,
}) {
  const { checked, children, onChange } = props;
  return (
    <div className={styles.radioButton}>
      <input
        className={styles.radioButtonInput}
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <div className={styles.radioButtonContent}>{children}</div>
    </div>
  );
}

class ActivityWhiteboardItem extends PureComponent<CommonProps> {
  onBackgroundImageUploaded = (id: string, url: string) => {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        whiteboardImageId: id,
        whiteboardImageUrl: url,
      });
    }
  };

  onStickerImageUploaded = (id: string, url: string) => {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        whiteboardStickers: [
          ...activity.whiteboardStickers,
          { imageId: id, imageUrl: url },
        ],
      });
    }
  };

  onBackgroundUnSelect = () => {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        whiteboardImageId: null,
        whiteboardImageUrl: null,
      });
    }
  };

  onStickerUnSelect = (idx: mixed) => {
    if (typeof idx === "number") {
      const { activity, onActivityChange } = this.props;

      if (onActivityChange) {
        const whiteboardStickers = Array.from(activity.whiteboardStickers);
        whiteboardStickers.splice(idx, 1);
        onActivityChange({
          ...activity,
          whiteboardStickers,
        });
      }
    }
  };

  onChangeBackgroundToEmpty = () => {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        whiteboardBackground: "empty",
      });
    }
  };

  onChangeBackgroundToImage = () => {
    const { activity, onActivityChange } = this.props;

    if (onActivityChange) {
      onActivityChange({
        ...activity,
        whiteboardBackground: "image",
      });
    }
  };

  onChangeOrientation = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { activity, onActivityChange } = this.props;
    const rawValue = e.target.value;
    if (rawValue === "landscape" || rawValue === "portrait") {
      if (onActivityChange) {
        onActivityChange({
          ...activity,
          whiteboardOrientation: rawValue,
        });
      }
    }
  };

  renderWhiteboard() {
    const {
      whiteboardBackground,
      whiteboardImageUrl,
      whiteboardOrientation,
      whiteboardStickers,
    } = this.props.activity;
    return (
      <Fragment>
        <p className={styles.editTitle}>
          <Text
            translationKey={
              whiteboardBackground === "image"
                ? "teacher.whiteboard_background.selected_image"
                : whiteboardOrientation === "landscape"
                  ? "teacher.whiteboard_background.selected_empty.landscape"
                  : "teacher.whiteboard_background.selected_empty.portrait"
            }
          />
        </p>
        {whiteboardBackground === "image" &&
          whiteboardImageUrl != null && (
            <Image
              imageUrl={whiteboardImageUrl}
              className={styles.backgroundImage}
              height={WHITEBOARD_BACKGROUND_HEIGHT}
              placeholderWidth={WHITEBOARD_BACKGROUND_HEIGHT * 1.33}
              placeholderHeight={WHITEBOARD_BACKGROUND_HEIGHT}
            />
          )}
        <p className={styles.editTitle}>
          <Text translationKey="teacher.whiteboard_stickers" />
        </p>
        <div className={styles.stickerImgs}>
          {this.renderStickers(whiteboardStickers)}
        </div>
      </Fragment>
    );
  }

  renderEmptyBackgroundSelect() {
    const { whiteboardBackground, whiteboardOrientation } = this.props.activity;
    const disabled = whiteboardBackground !== "empty";
    return (
      <div className={styles.selectContainer}>
        <Select
          className={classnames(styles.select, {
            [styles.disabled]: disabled,
          })}
          value={whiteboardOrientation}
          onChange={this.onChangeOrientation}
          disabled={disabled}
        >
          <p className={styles.selectOptionText}>
            <Text
              translationKey={`teacher.whiteboard_background.empty.${whiteboardOrientation}`}
            />
          </p>
          <Option
            translationKey="teacher.whiteboard_background.empty.landscape"
            value="landscape"
          />
          <Option
            translationKey="teacher.whiteboard_background.empty.portrait"
            value="portrait"
          />
        </Select>
      </div>
    );
  }

  renderEmptyBackgroundRadioButton() {
    const { whiteboardBackground } = this.props.activity;
    return (
      <RadioButtonGroup
        checked={whiteboardBackground === "empty"}
        onChange={this.onChangeBackgroundToEmpty}
      >
        <p className={styles.radioButtonLabel}>
          <Text translationKey="teacher.whiteboard_background.empty" />
        </p>
        {this.renderEmptyBackgroundSelect()}
      </RadioButtonGroup>
    );
  }

  renderImageBackgroundRadioButton() {
    const { validationErrors } = this.props;
    const { whiteboardImageUrl, whiteboardBackground } = this.props.activity;
    const checked = whiteboardBackground === "image";
    return (
      <RadioButtonGroup
        checked={checked}
        onChange={this.onChangeBackgroundToImage}
      >
        <p className={styles.radioButtonLabel}>
          <Text translationKey="teacher.whiteboard_background.image" />
        </p>
        {whiteboardImageUrl == null && (
          <ImageUploadButton
            type="resize"
            disabled={!checked}
            onUploaded={this.onBackgroundImageUploaded}
          />
        )}
        {whiteboardImageUrl != null && (
          <ImageChooser
            imageUrl={whiteboardImageUrl}
            onUnSelect={this.onBackgroundUnSelect}
            height={WHITEBOARD_BACKGROUND_HEIGHT}
            placeholderWidth={WHITEBOARD_BACKGROUND_HEIGHT * 1.33}
            placeholderHeight={WHITEBOARD_BACKGROUND_HEIGHT}
          />
        )}
        {validationErrors != null &&
          validationErrors.includes(ErrorActivityMissingBackgroundImage) && (
            <p className={styles.errorText}>
              <Text translationKey="error.activity_whiteboard_missing_background_image" />
            </p>
          )}
        <p className={styles.hintText}>
          <Text
            translationKey="teacher.whiteboard_suggest_size"
            values={{
              width: SUGGEST_BACKGROUND_IMAGE_WIDTH,
              height: SUGGEST_BACKGROUND_IMAGE_HEIGHT,
            }}
          />
        </p>
      </RadioButtonGroup>
    );
  }

  renderStickers(whiteboardStickers: $ReadOnlyArray<Sticker>) {
    if (whiteboardStickers.length === 0) {
      return null;
    }

    return whiteboardStickers.map((s, i) => (
      <Image
        imageUrl={s.imageUrl}
        key={i}
        width={WHITEBOARD_STICKER_SIZE}
        height={WHITEBOARD_STICKER_SIZE}
        placeholderWidth={WHITEBOARD_STICKER_SIZE}
        placeholderHeight={WHITEBOARD_STICKER_SIZE}
        className={styles.stickerImg}
      />
    ));
  }

  renderImageChooserStickers(whiteboardStickers: $ReadOnlyArray<Sticker>) {
    if (whiteboardStickers.length === 0) {
      return null;
    }

    return whiteboardStickers.map((s, i) => (
      <ImageChooser
        imageUrl={s.imageUrl}
        onUnSelect={this.onStickerUnSelect}
        onUnSelectInfo={i}
        key={i}
        width={WHITEBOARD_STICKER_SIZE}
        height={WHITEBOARD_STICKER_SIZE}
        placeholderWidth={WHITEBOARD_STICKER_SIZE}
        placeholderHeight={WHITEBOARD_STICKER_SIZE}
        className={styles.stickerImg}
      />
    ));
  }

  renderStickersRegion() {
    const { whiteboardStickers } = this.props.activity;
    const checked = whiteboardStickers.length < MAX_STICKERS_COUNT;

    return (
      <div>
        <p className={styles.editTitle}>
          <Text translationKey="teacher.whiteboard_stickers" />
        </p>
        <div className={styles.stickerImgs}>
          {this.renderImageChooserStickers(whiteboardStickers)}
        </div>
        <ImageUploadButton
          type="resizeCrop"
          accept="image/png"
          onUploaded={this.onStickerImageUploaded}
          width={STICKER_FIXED_SIZE}
          height={STICKER_FIXED_SIZE}
          disabled={!checked}
        />
        <p className={styles.hintText}>
          <Text
            translationKey="teacher.whiteboard_sticker_limitation"
            values={{
              max: MAX_STICKERS_COUNT,
            }}
          />
        </p>
      </div>
    );
  }

  renderEditableWhiteboard() {
    return (
      <Fragment>
        <p className={styles.editTitle}>
          <Text translationKey="teacher.whiteboard_background" />
        </p>
        <div className={styles.radioButtonsContainer}>
          {this.renderEmptyBackgroundRadioButton()}
          {this.renderImageBackgroundRadioButton()}
        </div>
        {this.renderStickersRegion()}
      </Fragment>
    );
  }

  renderExtra() {
    const { editable } = this.props;

    if (!editable) {
      return this.renderWhiteboard();
    }

    return this.renderEditableWhiteboard();
  }

  render() {
    return (
      <ActivityPanelRenderer {...this.props}>
        {this.renderExtra()}
      </ActivityPanelRenderer>
    );
  }
}

export default ActivityWhiteboardItem;
