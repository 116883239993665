// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import InputWithErrorMessage from "../Input/InputWithErrorMessage";
import Text from "../Text/Text";
import checkIcon from "../../images/edit_template_check.svg";
import Option from "../Option/Option";
import Select from "../Select/Select";
import {
  TEMPLATE_YEAR_FORM_1,
  TEMPLATE_YEAR_FORM_2,
  TEMPLATE_YEAR_FORM_3,
  TEMPLATE_LANG_EN,
  TEMPLATE_LANG_ZH_HANT,
  TEMPLATE_LANG_ZH_HANS,
} from "../../types/template";
import type { TEMPLATE_LOCALE, TEMPLATE_YEAR } from "../../types/template";
import styles from "./ImportedTemplateHeader.module.scss";

type Props = {
  year: TEMPLATE_YEAR,
  lang: TEMPLATE_LOCALE,
  displayCode: string,
  name: string,
  onSave: () => void,
  onCancel: () => void,
  onNameChange: string => void,
  onDisplayCodeChange: string => void,
  onYearChange: TEMPLATE_YEAR => void,
  onLangChange: TEMPLATE_LOCALE => void,
  templateDisplayCodeError: boolean,
  templateNameEmptyError: boolean,
};

class ImportedTemplateHeader extends PureComponent<Props> {
  onSave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onSave();
  };

  onCancel = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onCancel();
  };

  onNameChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.props.onNameChange(target.value);
    }
  };

  onDisplayCodeChange = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;

    if (target instanceof HTMLInputElement) {
      this.props.onDisplayCodeChange(target.value);
    }
  };

  onYearChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const yearUnsafe = event.target.value;
    if (
      yearUnsafe === "form1" ||
      yearUnsafe === "form2" ||
      yearUnsafe === "form3"
    ) {
      const value: TEMPLATE_YEAR = (yearUnsafe: TEMPLATE_YEAR);
      this.props.onYearChange(value);
    }
  };

  onLangChange = (event: SyntheticInputEvent<HTMLSelectElement>) => {
    const langUnsafe = event.target.value;
    if (
      langUnsafe === "en" ||
      langUnsafe === "zh-Hant" ||
      langUnsafe === "zh-Hans"
    ) {
      const value: TEMPLATE_LOCALE = (langUnsafe: TEMPLATE_LOCALE);
      this.props.onLangChange(value);
    }
  };

  renderYearSelector() {
    const { year } = this.props;
    const formOptions = [
      TEMPLATE_YEAR_FORM_1,
      TEMPLATE_YEAR_FORM_2,
      TEMPLATE_YEAR_FORM_3,
    ].map(l => (
      <Option translationKey={`admin.template.form_${l}`} value={l} key={l} />
    ));
    return (
      <div className={styles.selectorContainer}>
        <Select
          name="template-year-selector"
          className={classnames(styles.selector, styles.yearSelector)}
          value={year}
          onChange={this.onYearChange}
        >
          <p className={styles.selectOptionText}>
            <Text translationKey={`admin.template.form_${year}`} />
          </p>
          {formOptions}
        </Select>
      </div>
    );
  }

  renderLangSelector() {
    const { lang } = this.props;
    const formOptions = [
      TEMPLATE_LANG_EN,
      TEMPLATE_LANG_ZH_HANT,
      TEMPLATE_LANG_ZH_HANS,
    ].map(l => (
      <Option
        translationKey={`admin.template.language_${l}`}
        value={l}
        key={l}
      />
    ));
    return (
      <div className={styles.selectorContainer}>
        <Select
          name="template-lang-selector"
          className={styles.selector}
          value={lang}
          onChange={this.onLangChange}
        >
          <p className={styles.selectOptionText}>
            <Text translationKey={`admin.template.language_${lang}`} />
          </p>
          {formOptions}
        </Select>
      </div>
    );
  }

  renderActions() {
    const {
      displayCode,
      name,
      templateNameEmptyError,
      templateDisplayCodeError,
    } = this.props;

    return (
      <div className={styles.actionsContainer}>
        {this.renderYearSelector()}
        {this.renderLangSelector()}
        <div className={styles.inputContainer}>
          <InputWithErrorMessage
            placeholderId="admin.template.template_display_code_placeholder"
            labelId="admin.template.template_display_code_placeholder"
            value={displayCode}
            className={styles.inputText}
            type="text"
            onChange={this.onDisplayCodeChange}
            errorTextKey={
              templateDisplayCodeError
                ? "error.template_display_code_empty"
                : null
            }
          />
        </div>
        <div className={styles.inputContainer}>
          <InputWithErrorMessage
            placeholderId="admin.template.template_name_placeholder"
            labelId="admin.template.template_name_placeholder"
            value={name}
            className={styles.inputText}
            type="text"
            onChange={this.onNameChange}
            errorTextKey={
              templateNameEmptyError ? "error.template_name_empty" : null
            }
          />
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className={styles.buttonsContainer}>
        <IconButton
          className={styles.finishEditTemplateButton}
          icon={checkIcon}
          labelTranslationKey="teacher.finish_edit"
          onClick={this.onSave}
        />
        <Button
          color="gray"
          colorApplication="border"
          className={styles.cancelButton}
          onClick={this.onCancel}
        >
          <Text translationKey="phrase.cancel" />
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.headerContainer}>
        {this.renderActions()}
        {this.renderButtons()}
      </div>
    );
  }
}

export default ImportedTemplateHeader;
