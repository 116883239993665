// @flow
import contentDisposition from "content-disposition";
import { downloadBlob } from "./blob";

export function downloadResponse(response: Response): Promise<void> {
  const headers = response.headers;
  let filename = "file";
  if (headers.has("content-disposition")) {
    const value = headers.get("content-disposition");
    const parsed = contentDisposition.parse(value);
    if (parsed.parameters["filename"]) {
      filename = parsed.parameters["filename"];
    }
  }
  return response.blob().then(blob => {
    downloadBlob(blob, filename);
  });
}
