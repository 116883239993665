// @flow
import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import qs from "qs";
import { configureSkygear, getSchool } from "../redux/actions/app";
import JoinSessionScreen from "./session/JoinSessionScreen";
import TeacherSessionRenderer from "./session/TeacherSessionRenderer";
import StudentSession from "./session/StudentSession";
import Text from "../components/Text/Text";
import {
  AuthRoute,
  Dashboard,
  Login,
  Layout,
  Template,
  EditTemplate,
  ActivityRecords,
  ForgotPassword,
} from "./teacher";
import AdminRoute from "./admin/AdminRoute";
import type { Config } from "skygear";
import type { AppLocale } from "../types/app";
import type { School } from "../types/school";
import type { RootState, Dispatch } from "../types/store";
import type { RequestState } from "../types/request";
import localeData from "../locale-data";
import config from "../config";
import { isSuccess, isFailure } from "../utils/request";
import {
  ACTIVE_TAB_SESSIONS,
  ACTIVE_TAB_UNIT_TEMPLATES,
} from "./teacher/Dashboard";
import SyncContextIntlToReduxStore from "../intl/SyncContextIntlToReduxStore";
import { changeLocale } from "../redux/actions/app";
import { getLocale } from "../utils/localStorage";
import { checkBrowserSupport } from "../utils/browser";
import BrowserNotSupportModal from "../components/Modal/BrowserNotSupportModal";

type Props = {
  +locale: AppLocale,
  +configureSkygear: Config => Promise<boolean>,
  +getSchool: () => Promise<School>,
  +skygearInitRequest: ?RequestState<void>,
  +changeLocale: AppLocale => void,
};

type LocalState = {
  isModalShown: boolean,
};

// react-intl renders text with <span> by default
// however, react 16 supports rendering bare string
function renderText(props: { children: string }) {
  return props.children;
}

class App extends PureComponent<Props, LocalState> {
  state = {
    isModalShown: false,
  };

  componentDidMount() {
    this.setState({ isModalShown: !checkBrowserSupport() });
    this.props.changeLocale(getLocale());
    this.props
      .configureSkygear({
        apiKey: config.apiKey,
        endPoint: config.endPoint,
      })
      .then(authUser => {
        if (authUser != null && authUser.userType === "PolyupathsSchool") {
          this.props.getSchool();
        }
      });
  }

  renderError() {
    return <Text translationKey="app.skygear.init.error" />;
  }

  renderInitializing() {
    return <div />;
  }

  renderContent() {
    const r = this.props.skygearInitRequest;
    if (isSuccess(r)) {
      return this.renderMainContent();
    }
    if (isFailure(r)) {
      return this.renderError();
    }
    return this.renderInitializing();
  }

  renderMainContent() {
    return (
      <Fragment>
        {this.renderModal()}
        {this.renderRouter()}
      </Fragment>
    );
  }

  renderRouter() {
    return (
      <BrowserRouter>
        <Switch>
          <AuthRoute exact path="/" useDefaultPath />
          <AuthRoute
            exact
            path="/sessions"
            render={props => (
              <Layout {...props}>
                <Dashboard tab={ACTIVE_TAB_SESSIONS} {...props} />
              </Layout>
            )}
          />
          <AuthRoute
            exact
            path="/templates"
            render={props => (
              <Layout {...props}>
                <Dashboard tab={ACTIVE_TAB_UNIT_TEMPLATES} {...props} />
              </Layout>
            )}
          />
          <AuthRoute
            exact
            path="/session/:id"
            render={props => (
              <TeacherSessionRenderer sessionID={props.match.params.id} />
            )}
          />
          <AuthRoute
            exact
            path="/preview_session"
            render={props => <TeacherSessionRenderer useEncodedSessionInfo />}
          />
          <AuthRoute
            exact
            path="/template/:id"
            render={props => (
              <Layout {...props}>
                <Template {...props} />
              </Layout>
            )}
          />
          <AuthRoute
            exact
            path="/template/:id/edit"
            render={props => (
              <Layout {...props}>
                <EditTemplate {...props} />
              </Layout>
            )}
          />
          <AuthRoute
            exact
            path="/template/:id/records"
            render={props => (
              <Layout {...props}>
                <ActivityRecords {...props} />
              </Layout>
            )}
          />
          <AuthRoute
            exact
            authOnly={false}
            path="/login"
            render={props => (
              <Layout {...props}>
                <Login {...props} />
              </Layout>
            )}
          />
          <Route
            exact
            path="/student"
            render={props => {
              let prefill = "";
              if (props.location.search) {
                // remove leading question mark
                const queryString = props.location.search.slice(1);
                const parsed = qs.parse(queryString);
                if (parsed["code"]) {
                  prefill = parsed["code"];
                }
              }
              return <JoinSessionScreen prefillCode={prefill} />;
            }}
          />
          <Route
            exact
            path="/student/session/:code"
            render={props => {
              const code = ((props.match.params.code: any): string);
              return <StudentSession participationCode={code} />;
            }}
          />
          <AuthRoute
            exact
            authOnly={false}
            path="/forgot-password"
            render={props => (
              <Layout {...props}>
                <ForgotPassword {...props} />
              </Layout>
            )}
          />
          <Route path="/admin" component={AdminRoute} />
        </Switch>
      </BrowserRouter>
    );
  }

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isModalShown: false });
  };

  renderModal() {
    const { isModalShown } = this.state;
    if (!isModalShown) {
      return null;
    }
    return <BrowserNotSupportModal onClose={this.onModalClose} />;
  }

  render() {
    const messages = localeData[this.props.locale];
    return (
      <IntlProvider
        locale={this.props.locale}
        messages={messages}
        textComponent={renderText}
      >
        <Fragment>
          <SyncContextIntlToReduxStore />
          {this.renderContent()}
        </Fragment>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.app.locale,
    skygearInitRequest: state.app.skygearInitRequest,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    configureSkygear: bindActionCreators(configureSkygear, dispatch),
    getSchool: bindActionCreators(getSchool, dispatch),
    changeLocale: bindActionCreators(changeLocale, dispatch),
  };
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectedApp;
