// @flow
import { PureComponent } from "react";
import type { Node } from "react";

type Props = {
  children: Node,
};

type LocalState = {
  renderChildren: boolean,
};

export default class CloseWindowOrRedirect extends PureComponent<
  Props,
  LocalState
> {
  state = {
    renderChildren: false,
  };

  componentDidMount() {
    window.close();
    // If the window is closed, then our job is done.
    this.setState({
      renderChildren: true,
    });
  }

  render() {
    const { renderChildren } = this.state;
    const { children } = this.props;
    if (renderChildren) {
      return children;
    }
    return null;
  }
}
