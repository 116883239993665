// @flow
export function downloadBlob(blob: Blob, filename: string): void {
  if (window.navigator.msSaveOrOpenBlob) {
    // Handle IE
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Other browsers implement <a download>
    const objectURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = objectURL;
    anchor.download = filename;
    // Disable target="_blank" if user enables "Block pop-up windows"
    // anchor.target = "_blank";
    anchor.style.display = "none";
    const body = document.body;
    if (body != null) {
      body.appendChild(anchor);
      anchor.click();
      body.removeChild(anchor);
    }
  }
}
