// @flow

const primitivesTypes = [Number, String, Boolean];

// ref: https://stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript
export function deepClone(item: any): any {
  if (item === null || item === undefined) {
    return item;
  }

  let result = undefined;

  primitivesTypes.forEach(type => {
    if (item instanceof type) {
      result = type(item);
    }
  });

  if (typeof result === "undefined") {
    if (Array.isArray(item)) {
      result = item.map(deepClone);
    } else if (typeof item === "object") {
      // check that this is a literal
      if (item.prototype != null) {
        if (item instanceof Date) {
          result = new Date(item);
        } else {
          result = Object.keys(item).reduce(
            (acc, cur) => Object.assign(acc, { [cur]: deepClone(item[cur]) }),
            {}
          );
        }
      } else {
        result = item;
      }
    } else {
      result = item;
    }
  }

  return result;
}
