// @flow
import React, { PureComponent } from "react";
import type { User } from "../../types/user";
import Text from "../Text/Text";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import moreIcon from "../../images/template_item_more.svg";
import customIcon from "../../images/template_edit.svg";
import { UserTypeSuperAdmin, UserTypeEditor } from "../../types/user";
import styles from "./AdminUserItem.module.scss";

type Props = {
  +userIdx: number,
  +user: User,
  +onEdit: (userIdx: number) => void,
  +onEnableDisable: (userIdx: number) => void,
};

type LocalState = {|
  isPopupMenuOpen: boolean,
  alertModalShown: boolean,
|};

class AdminUserItem extends PureComponent<Props, LocalState> {
  state = {
    isPopupMenuOpen: false,
    alertModalShown: false,
  };

  onClickMoreActionsButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({
      isPopupMenuOpen: !prevState.isPopupMenuOpen,
    }));
  };

  onClickMoreActionsOutside = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isPopupMenuOpen: false });
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
  };

  onDisableEnable = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: true, isPopupMenuOpen: false });
  };

  onConfirmDisableEnable = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onEnableDisable(this.props.userIdx);
    this.setState({ alertModalShown: false });
  };

  renderAlertModal() {
    const { disabled } = this.props.user;
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="admin.admin_user.sure_to_disable_enable_user"
          titleValues={{ DISABLED: disabled }}
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmDisableEnable}
          onClose={this.onCloseAlertModal}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderPopupMenu() {
    const { disabled } = this.props.user;
    const { isPopupMenuOpen } = this.state;

    if (!isPopupMenuOpen) {
      return null;
    }

    return (
      <PopupWrapper onClickOutside={this.onClickMoreActionsOutside}>
        <div className={styles.moreActionsPopupMenu}>
          <Button
            className={styles.popupMenuItem}
            onClick={this.onDisableEnable}
            color={null}
          >
            {!disabled && <Text translationKey="phrase.disable" />}
            {disabled && <Text translationKey="phrase.enable" />}
          </Button>
        </div>
      </PopupWrapper>
    );
  }

  onEditButtonClicked = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onEdit(this.props.userIdx);
  };

  renderEditButton() {
    return (
      <div className={styles.editButtonContainer}>
        <IconButton
          className={styles.editButton}
          icon={customIcon}
          labelTranslationKey="admin.item.edit_button"
          onClick={this.onEditButtonClicked}
        />
      </div>
    );
  }

  renderMoreActionsButton() {
    const { isPopupMenuOpen } = this.state;

    return (
      <Button
        styled={false}
        color={null}
        className={styles.moreButton}
        onClick={this.onClickMoreActionsButton}
        disabled={isPopupMenuOpen}
      >
        <img
          src={moreIcon}
          className={styles.moreButtonIcon}
          alt="button form more"
        />
      </Button>
    );
  }

  render() {
    const { user } = this.props;
    return (
      <div className={styles.adminUserContainer}>
        <div className={styles.name}>{user.name}</div>
        <div className={styles.email}>{user.email}</div>
        <div className={styles.type}>
          {user.userType === UserTypeSuperAdmin && (
            <Text translationKey="admin.admin_user.super_admin" />
          )}
          {user.userType === UserTypeEditor && (
            <Text translationKey="admin.admin_user.editor" />
          )}
        </div>
        {this.renderEditButton()}
        {this.renderMoreActionsButton()}
        {this.renderPopupMenu()}
        {this.renderAlertModal()}
      </div>
    );
  }
}

export default AdminUserItem;
