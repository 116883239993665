// @flow

import { findDOMNode } from "react-dom";
import type { Component, Fragment } from "react";
import type { DropTargetConnector, DropTargetMonitor } from "react-dnd";
import type { Props } from "./ActivityPanelItem";

export function collectTarget(connect: DropTargetConnector) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export const dragTarget = {
  hover(props: Props, monitor: DropTargetMonitor, component: Component<*>) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const node: Fragment = findDOMNode(component);

    if (!node) {
      return;
    }

    const hoverBoundingRect = node.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if (props.onChangeOrder) {
      props.onChangeOrder(dragIndex, hoverIndex);
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};
