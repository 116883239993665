// @flow
import React, { PureComponent, Fragment } from "react";
import classnames from "classnames";
import type { Node } from "react";
import WindowSize from "../../components/WindowSize";
import styles from "./TeacherLayout.module.scss";

type Props = {
  className: string,
  children: ?Node,
};

export default class TeacherLayout extends PureComponent<Props> {
  render() {
    return (
      <Fragment>
        <div className={styles.background} />
        <WindowSize>
          {props => {
            const h1 = props.height;
            const h2 = props.height - 30;
            return (
              <div
                style={{
                  minHeight: h1 + "px",
                }}
                className={styles.contentContainerContainer}
              >
                <div
                  style={{
                    minHeight: h2 + "px",
                  }}
                  className={classnames(
                    styles.contentContainer,
                    this.props.className
                  )}
                >
                  {this.props.children}
                </div>
              </div>
            );
          }}
        </WindowSize>
      </Fragment>
    );
  }
}
