// @flow
import skygear from "skygear";
import {
  SEARCH_USERS_REQUESTING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  CREATE_USER_REQUESTING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_REQUESTING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  ENABLE_DISABLE_USER_REQUESTING,
  ENABLE_DISABLE_USER_SUCCESS,
  ENABLE_DISABLE_USER_FAILURE,
} from "../../types/user";
import type {
  User,
  UserType,
  SearchUsersRequesting,
  SearchUsersSuccess,
  SearchUsersFailure,
  CreateUserRequesting,
  CreateUserSuccess,
  CreateUserFailure,
  EditUserRequesting,
  EditUserSuccess,
  EditUserFailure,
  EnableDisableUserRequesting,
  EnableDisableUserSuccess,
  EnableDisableUserFailure,
} from "../../types/user";
import type { Dispatch, GetState } from "../../types/store";
import type { PageInfo } from "../../types/request";
import { deserializeUser, deserializePageInfo } from "../../utils/deserializer";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";

function searchUsersRequesting(): SearchUsersRequesting {
  return {
    type: SEARCH_USERS_REQUESTING,
  };
}

function searchUsersSuccess(users: $ReadOnlyArray<User>): SearchUsersSuccess {
  return {
    type: SEARCH_USERS_SUCCESS,
    payload: users,
  };
}

function searchUsersFailure(error: mixed): SearchUsersFailure {
  return {
    type: SEARCH_USERS_FAILURE,
    payload: error,
  };
}

export function searchUsers(data: {
  keyword: string,
  pageSize: number,
  pageNumber: number,
}) {
  const { keyword, pageSize, pageNumber } = data;
  return (
    dispatch: Dispatch,
    getState: GetState
  ): Promise<{
    users: $ReadOnlyArray<User>,
    pageInfo: PageInfo,
  }> => {
    const currentState = getState();
    if (isRequesting(currentState.users.searchUsersRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(searchUsersRequesting());
    const args = {
      keyword,
      page_args: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    };

    return skygear
      .lambda("polyupaths:admin:query_all", [args])
      .then(result => {
        const users = result.users.map(deserializeUser);
        const pageInfo = deserializePageInfo(result.page_info);
        dispatch(searchUsersSuccess(users));
        return { users, pageInfo };
      })
      .catch(error => {
        dispatch(searchUsersFailure(error));
        return Promise.reject(error);
      });
  };
}

function createUserRequesting(): CreateUserRequesting {
  return {
    type: CREATE_USER_REQUESTING,
  };
}

function createUserSuccess(user: User): CreateUserSuccess {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
}

function createUserFailure(error: mixed): CreateUserFailure {
  return {
    type: CREATE_USER_FAILURE,
    payload: error,
  };
}

export function createUser(
  name: string,
  userType: UserType,
  email: string,
  password: string
) {
  return (dispatch: Dispatch, getState: GetState): Promise<User> => {
    const currentState = getState();
    if (isRequesting(currentState.users.createUserRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(createUserRequesting());
    const args = {
      name,
      user_type: userType,
      email,
      password,
    };
    return skygear
      .lambda("polyupaths:admin:create", [args])
      .then(result => {
        const user = deserializeUser(result.user);
        dispatch(createUserSuccess(user));
        return Promise.resolve(user);
      })
      .catch(error => {
        dispatch(createUserFailure(error));
        return Promise.reject(error);
      });
  };
}

function editUserRequesting(): EditUserRequesting {
  return {
    type: EDIT_USER_REQUESTING,
  };
}

function editUserSuccess(user: User): EditUserSuccess {
  return {
    type: EDIT_USER_SUCCESS,
    payload: user,
  };
}

function editUserFailure(error: mixed): EditUserFailure {
  return {
    type: EDIT_USER_FAILURE,
    payload: error,
  };
}

export function editUser(
  userId: string,
  name: string,
  userType: UserType,
  email: string,
  password: string
) {
  return (dispatch: Dispatch, getState: GetState): Promise<User> => {
    const currentState = getState();
    if (isRequesting(currentState.users.editUserRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(editUserRequesting());
    const args = {
      user_id: userId,
      name,
      user_type: userType,
      email,
      password: password.length !== 0 ? password : null,
    };
    return skygear
      .lambda("polyupaths:admin:edit", [args])
      .then(result => {
        const user = deserializeUser(result.user);
        dispatch(editUserSuccess(user));
        return Promise.resolve(user);
      })
      .catch(error => {
        dispatch(editUserFailure(error));
        return Promise.reject(error);
      });
  };
}

function enableDisableUserRequesting(): EnableDisableUserRequesting {
  return {
    type: ENABLE_DISABLE_USER_REQUESTING,
  };
}

function enableDisableUserSuccess(user: User): EnableDisableUserSuccess {
  return {
    type: ENABLE_DISABLE_USER_SUCCESS,
    payload: user,
  };
}

function enableDisableUserFailure(error: mixed): EnableDisableUserFailure {
  return {
    type: ENABLE_DISABLE_USER_FAILURE,
    payload: error,
  };
}

export function enableDisableUser(userId: string, disable: boolean) {
  return (dispatch: Dispatch, getState: GetState): Promise<User> => {
    const currentState = getState();
    if (isRequesting(currentState.users.enableDisableUserRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(enableDisableUserRequesting());
    const args = {
      user_id: userId,
      disable,
    };
    return skygear
      .lambda("polyupaths:admin:enable_disable", [args])
      .then(result => {
        const user = deserializeUser(result.user);
        dispatch(enableDisableUserSuccess(user));
        return Promise.resolve(user);
      })
      .catch(error => {
        dispatch(enableDisableUserFailure(error));
        return Promise.reject(error);
      });
  };
}
