// @flow
import type { Node } from "react";
import React from "react";
import Media from "react-media";
import cssVariables from "../grid.module.scss";

type Props = {
  children: (
    deviceType: "desktop" | "mobilePortrait" | "mobileLandscape"
  ) => Node,
};

// Layout responsive in react
// You should not use this component unless
// the layout requires changing html element
// source order to achieve.

const ResponsiveLayout = ({ children }: Props) => (
  <Media query={`(max-width: ${cssVariables.contentminwidth})`}>
    {isMobile => (
      <Media query="(orientation: landscape)">
        {isLandscape =>
          children(
            isMobile
              ? isLandscape ? "mobileLandscape" : "mobilePortrait"
              : "desktop"
          )
        }
      </Media>
    )}
  </Media>
);

export default ResponsiveLayout;
