// @flow
export function countNumberOfCodePoints(s: string): number {
  let n = 0;
  // eslint-disable-next-line no-unused-vars
  for (const ch of s) {
    n += 1;
  }
  return n;
}
export function toCodePoints(s: string): $ReadOnlyArray<string> {
  const output = [];
  for (const ch of s) {
    output.push(ch);
  }
  return output;
}
