// @flow
import React, { PureComponent, Fragment } from "react";
import type { Node } from "react";
import classnames from "classnames";
import Image from "../Image/Image";
import styles from "./SingleImageModal.module.scss";

type Props = {
  className: string,
  imageURL: string,
  width: number | string | typeof undefined,
  height: number | string | typeof undefined,
  children?: Node,
  onClose: (e: Event) => void,
};

export default class SingleImageModal extends PureComponent<Props> {
  render() {
    const { className, imageURL, onClose, width, height } = this.props;
    return (
      <Image
        className={classnames(styles.image, className)}
        imageUrl={imageURL}
        width={width}
        height={height}
        placeholderWidth={width != null ? width : "100%"}
        placeholderHeight={height != null ? height : "75%"}
      >
        <Fragment>
          <div onClick={onClose} className={styles.closeButton} />
          {this.props.children}
        </Fragment>
      </Image>
    );
  }
}
