// @flow
import React, { PureComponent, Fragment } from "react";
import debounce from "debounce";
import qs from "qs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SchoolAccountItem from "../../components/SchoolAccountItem/SchoolAccountItem";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import ErrorModal from "../../components/ErrorModal";
import IconButton from "../../components/IconButton/IconButton";
import SchoolAccountModal from "../../components/Modal/SchoolAccountModal";
import searchIcon from "../../images/template_search.svg";
import plusIcon from "../../images/plus.svg";
import Pagination from "../../components/Pagination/Pagination";
import {
  searchSchool,
  createSchool,
  editSchool,
  enableDisableSchool,
} from "../../redux/actions/schools";
import { isRequesting, extractError } from "../../utils/request";
import {
  ADMIN_DASHBOARD_PAGE_SIZE,
  ADMIN_DASHBOARD_DEBOUNCE_TIME_MS,
} from "../../utils/constants";
import { SCHOOL_ACCOUNT_MANAGEMENT_PATH } from "../../types/paths";
import WindowSize from "../../components/WindowSize";
import type { PageInfo } from "../../types/request";
import type { RootState, Dispatch } from "../../types/store";
import type { School } from "../../types/school";
import type { HeaderTitle } from "../../components/AdminHeader/AdminHeader";
import type { Location } from "react-router";
import type { RouterHistory } from "react-router-dom";
import styles from "./SchoolAccountManagement.module.scss";

type OwnProps = {
  +history: RouterHistory,
  +location: Location,
};

type ConnectedProps = {
  +schoolByID: { [id: string]: ?School },
  +isRequesting: boolean,
  +searchSchoolError: mixed,
  +searchSchool: (data: {
    keyword: string,
    pageSize: number,
    pageNumber: number,
  }) => Promise<{
    schools: $ReadOnlyArray<School>,
    pageInfo: PageInfo,
  }>,
  +createSchoolError: mixed,
  +editSchoolError: mixed,
  +enableDisableSchoolError: mixed,
  +createSchool: (
    name: string,
    engName: string,
    email: string,
    password: string
  ) => Promise<School>,
  +editSchool: (
    id: string,
    name: string,
    engName: string,
    email: string,
    password: string
  ) => Promise<School>,
  +enableDisableSchool: (id: string, disable: boolean) => Promise<School>,
  +keywords: string,
  +currentPage: number, // 1 based
};

type Props = OwnProps & ConnectedProps;

type LocalState = {|
  pageInfo: PageInfo,
  schoolIDs: $ReadOnlyArray<string>,
  editAccountIdx: number,
  editAccountModalShown: boolean,
  addAccountModalShown: boolean,
|};

const headerTitles: $ReadOnlyArray<HeaderTitle> = [
  {
    text: "admin.school_account.header.name",
    width: "50%",
  },
  {
    text: "admin.school_account.header.email",
    width: "25%",
  },
  {
    text: "admin.school_account.header.created_on",
    width: "12.5%",
  },
];

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// admin header: 56px
// total count row margin top: 18px;
// total count row: 25px;
// total count row margin bottom: 10;
// actions row: 34px;
// actions row margin-bottom: 33px;
// school accounts header: 31px;
// layout pagination footer: 77px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 422;

class SchoolAccountManagement extends PureComponent<Props, LocalState> {
  state = {
    schoolIDs: [],
    pageInfo: {
      totalCount: 0,
      totalPage: 0,
    },
    editAccountIdx: -1,
    addAccountModalShown: false,
    editAccountModalShown: false,
  };

  searchSchool: () => void;

  constructor(props: Props) {
    super(props);

    this.searchSchool = debounce(
      this.searchSchool,
      ADMIN_DASHBOARD_DEBOUNCE_TIME_MS
    );
  }

  componentDidMount() {
    this.searchSchool();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.keywords !== this.props.keywords ||
      nextProps.currentPage !== this.props.currentPage
    ) {
      this.searchSchool();
    }
  }

  onKeywordsChange = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    if (target instanceof HTMLInputElement) {
      this.onReplaceHistory(1, target.value);
    }
  };

  onAddAccountButtonClicked = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ addAccountModalShown: true });
  };

  onCloseAddAccountModal = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ addAccountModalShown: false });
  };

  onCloseEditAccountModal = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ editAccountModalShown: false });
  };

  onEditButtonClick = (schoolIdx: number) => {
    this.setState({ editAccountIdx: schoolIdx, editAccountModalShown: true });
  };

  onEnableDisableClick = (schoolIdx: number) => {
    const id = this.state.schoolIDs[schoolIdx];
    const schools = this.getSchools();
    const school = schools[schoolIdx];
    this.props.enableDisableSchool(id, !school.disabled);
  };

  onAddAccount = (school: School, password: string) => {
    this.props
      .createSchool(school.name, school.engName, school.email, password)
      .then(school => {
        this.setState({ schoolIDs: [school.id, ...this.state.schoolIDs] });
      });
    this.setState({ addAccountModalShown: false });
  };

  onEditAccount = (school: School, password: string) => {
    const { editAccountIdx } = this.state;
    const id = this.state.schoolIDs[editAccountIdx];
    this.props.editSchool(
      id,
      school.name,
      school.engName,
      school.email,
      password
    );
    this.setState({ editAccountModalShown: false });
  };

  onPageChange = (pageIdx: number) => {
    const { keywords } = this.props;
    this.onReplaceHistory(pageIdx, keywords);
  };

  onReplaceHistory = (pageIdx: number, keywords: string) => {
    this.props.history.replace({
      pathname: SCHOOL_ACCOUNT_MANAGEMENT_PATH,
      search: qs.stringify({ currentPage: pageIdx, keywords }),
    });
  };

  renderSearchInput() {
    const { keywords } = this.props;
    return (
      <div className={styles.searchInputContainer}>
        <img className={styles.searchIcon} src={searchIcon} alt="search icon" />
        <Input
          placeholderId="admin.school_account.header.search_school"
          labelId="admin.school_account.header.search_school"
          type="text"
          value={keywords}
          className={styles.searchInput}
          onChange={this.onKeywordsChange}
        />
      </div>
    );
  }

  renderAddAccountButton() {
    return (
      <div className={styles.addButtonContainer}>
        <IconButton
          className={styles.addButton}
          icon={plusIcon}
          labelTranslationKey="admin.school_account.header.add_new_account"
          onClick={this.onAddAccountButtonClicked}
        />
      </div>
    );
  }

  renderHeader() {
    const { totalCount } = this.state.pageInfo;

    return (
      <Fragment>
        <p className={styles.countText}>
          <Text translationKey="admin.school_account.header.count" />
          <span className={styles.countNumberText}> {totalCount}</span>
        </p>
        <div className={styles.actionsRow}>
          {this.renderSearchInput()}
          {this.renderAddAccountButton()}
        </div>
      </Fragment>
    );
  }

  renderAddAccountModal() {
    const { addAccountModalShown } = this.state;

    if (!addAccountModalShown) {
      return null;
    }

    return (
      <SchoolAccountModal
        type="add"
        onClose={this.onCloseAddAccountModal}
        onSave={this.onAddAccount}
      />
    );
  }

  renderEditAccountModal() {
    const schools = this.getSchools();
    const { editAccountModalShown, editAccountIdx } = this.state;

    if (!editAccountModalShown) {
      return null;
    }

    return (
      <SchoolAccountModal
        type="edit"
        school={schools[editAccountIdx]}
        onClose={this.onCloseEditAccountModal}
        onSave={this.onEditAccount}
      />
    );
  }

  render() {
    const schools = this.getSchools();
    const {
      isRequesting,
      searchSchoolError,
      createSchoolError,
      editSchoolError,
      enableDisableSchoolError,
      currentPage,
    } = this.props;
    const { pageInfo } = this.state;

    return (
      <div>
        {this.renderHeader()}
        <AdminHeader titles={headerTitles} />
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
            return (
              <div style={{ height: h + "px", overflow: "scroll" }}>
                {isRequesting && <LoadingIndicator />}
                {!isRequesting &&
                  schools.map((school, idx) => (
                    <SchoolAccountItem
                      key={school.id}
                      school={school}
                      onEdit={this.onEditButtonClick}
                      onEnableDisable={this.onEnableDisableClick}
                      schoolIdx={idx}
                    />
                  ))}
                {!isRequesting &&
                  schools.length === 0 && (
                    <p className={styles.noRelevantRecordsText}>
                      <Text translationKey="admin.no_relevant_records" />
                    </p>
                  )}
              </div>
            );
          }}
        </WindowSize>
        <Pagination
          currentPage={currentPage}
          totalPage={pageInfo.totalPage}
          onPageChange={this.onPageChange}
        />
        {this.renderAddAccountModal()}
        {this.renderEditAccountModal()}
        <Fragment>
          <ErrorModal error={searchSchoolError} />
          <ErrorModal error={createSchoolError} />
          <ErrorModal error={editSchoolError} />
          <ErrorModal error={enableDisableSchoolError} />
        </Fragment>
      </div>
    );
  }

  getSchools(): $ReadOnlyArray<School> {
    const output = [];
    for (const id of this.state.schoolIDs) {
      const school = this.props.schoolByID[id];
      if (school != null) {
        output.push(school);
      }
    }
    return output;
  }

  searchSchool() {
    this.props
      .searchSchool({
        keyword: this.props.keywords,
        pageSize: ADMIN_DASHBOARD_PAGE_SIZE,
        pageNumber: this.props.currentPage,
      })
      .then(result => {
        this.setState({
          schoolIDs: result.schools.map(s => s.id),
          pageInfo: result.pageInfo,
        });
      });
  }
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
  const schoolsState = state.schools;
  const { search } = ownProps.location;
  const parsed = qs.parse(search, { ignoreQueryPrefix: true });
  return {
    schoolByID: schoolsState.schoolByID,
    isRequesting:
      isRequesting(schoolsState.searchSchoolRequest) ||
      isRequesting(schoolsState.createSchoolRequest) ||
      isRequesting(schoolsState.editSchoolRequest) ||
      isRequesting(schoolsState.enableDisableSchoolRequest),
    searchSchoolError: extractError(schoolsState.searchSchoolRequest),
    createSchoolError: extractError(schoolsState.createSchoolRequest),
    editSchoolError: extractError(schoolsState.editSchoolRequest),
    enableDisableSchoolError: extractError(
      schoolsState.enableDisableSchoolRequest
    ),
    keywords: parsed.keywords || "",
    currentPage:
      parsed.currentPage != null ? parseInt(parsed.currentPage, 10) : 1,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    searchSchool: bindActionCreators(searchSchool, dispatch),
    createSchool: bindActionCreators(createSchool, dispatch),
    editSchool: bindActionCreators(editSchool, dispatch),
    enableDisableSchool: bindActionCreators(enableDisableSchool, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SchoolAccountManagement
);
