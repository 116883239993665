// @flow

import skygear from "skygear";
import type { IntlShape } from "react-intl";
import type { Config } from "skygear";
import type { Dispatch, GetState } from "../../types/store";
import type {
  AppLocale,
  AppChangeLocale,
  AppSyncIntl,
  SkygearInitRequesting,
  SkygearInitSuccess,
  SkygearInitFailure,
  GetSchoolRequesting,
  GetSchoolSuccess,
  GetSchoolFailure,
  ResetSchoolInfo,
} from "../../types/app";
import type { School } from "../../types/school";
import type { AuthUser } from "../../types/auth";
import {
  APP_CHANGE_LOCALE,
  APP_SYNC_INTL,
  SKYGEAR_INIT_REQUESTING,
  SKYGEAR_INIT_SUCCESS,
  SKYGEAR_INIT_FAILURE,
  GET_SCHOOL_REQUESTING,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAILURE,
  RESET_SCHOOL_INFO,
} from "../../types/app";
import { authSuccess } from "../actions/auth";
import { ongoingRequestError } from "../../types/error";
import { isRequesting } from "../../utils/request";
import {
  deserializeSchool,
  deserializeAuthUser,
} from "../../utils/deserializer";

export function changeLocale(locale: AppLocale): AppChangeLocale {
  return {
    type: APP_CHANGE_LOCALE,
    payload: locale,
  };
}

export function syncIntl(intl: IntlShape): AppSyncIntl {
  return {
    type: APP_SYNC_INTL,
    payload: intl,
  };
}

function skygearInitRequesting(): SkygearInitRequesting {
  return {
    type: SKYGEAR_INIT_REQUESTING,
  };
}

function skygearInitSuccess(): SkygearInitSuccess {
  return {
    type: SKYGEAR_INIT_SUCCESS,
  };
}

function skygearInitFailure(e: mixed): SkygearInitFailure {
  return {
    type: SKYGEAR_INIT_FAILURE,
    payload: e,
  };
}

function getSchoolRequesting(): GetSchoolRequesting {
  return {
    type: GET_SCHOOL_REQUESTING,
  };
}

function getSchoolSuccess(school: School): GetSchoolSuccess {
  return {
    type: GET_SCHOOL_SUCCESS,
    payload: school,
  };
}

function getSchoolFailure(error: mixed): GetSchoolFailure {
  return {
    type: GET_SCHOOL_FAILURE,
    payload: error,
  };
}

export function resetSchoolInfo(): ResetSchoolInfo {
  return {
    type: RESET_SCHOOL_INFO,
  };
}

export function configureSkygear(config: Config) {
  return (dispatch: Dispatch, getState: GetState): Promise<?AuthUser> => {
    const currentState = getState();
    if (isRequesting(currentState.app.skygearInitRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(skygearInitRequesting());
    return skygear.config(config).then(
      () => {
        const currentUser = skygear.auth.currentUser;
        const authUser = deserializeAuthUser(currentUser);
        dispatch(authSuccess(authUser));
        dispatch(skygearInitSuccess());
        return authUser;
      },
      error => {
        // eslint-disable-next-line no-console
        console.error("Failed to config skygear", error);
        dispatch(skygearInitFailure(error));
        return Promise.reject(error);
      }
    );
  };
}

export function getSchool() {
  return (dispatch: Dispatch, getState: GetState): Promise<School> => {
    const currentState = getState();
    if (isRequesting(currentState.app.getSchoolRequest)) {
      return Promise.reject(ongoingRequestError);
    }

    dispatch(getSchoolRequesting());
    return skygear
      .lambda("polyupaths:school:get_self", [{}])
      .then(result => {
        const school = deserializeSchool(result.school);
        dispatch(getSchoolSuccess(school));
        return school;
      })
      .catch(error => {
        dispatch(getSchoolFailure(error));
        return Promise.reject(error);
      });
  };
}
