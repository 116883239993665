// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Session, SessionState } from "../../types/session";
import type { RootState, Dispatch } from "../../types/store";
import {
  pauseSession,
  unpauseSession,
  endSession,
} from "../../redux/actions/sessions";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import Text from "../Text/Text";
import JoinSessionLink from "../Link/JoinSessionLink";
import VerticalIconButton from "../VerticalIconButton/VerticalIconButton";
import logoURL from "../../images/app_logo.svg";
import TruncatedText from "../TruncatedText/TruncatedText";
import { extractError } from "../../utils/request";
import ErrorModal from "../ErrorModal";
import styles from "./TeacherSessionHeader.module.scss";

type OwnProps = {
  className: string,
  sessionID: ?string,
  sessionIsPaused: boolean,
  sessionState: SessionState,
  sessionCode: string,
  templateName: string,
  templateDisplayCode: string,
};

type ConnectedProps = {
  pauseOrUnpauseSessionError: mixed,
  pauseSession: (sessionId: string) => Promise<Session>,
  unpauseSession: (sessionId: string) => Promise<Session>,
  endSession: (sessionId: string) => Promise<Session>,
};

type Props = OwnProps & ConnectedProps;

type LocalState = {
  isHovering: boolean,
  isModalShown: boolean,
};

class TeacherSessionHeader extends PureComponent<Props, LocalState> {
  state = {
    isHovering: false,
    isModalShown: false,
  };

  onTogglePause = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    const { sessionIsPaused, sessionID } = this.props;
    if (sessionID != null) {
      if (sessionIsPaused) {
        this.props.unpauseSession(sessionID);
      } else {
        this.props.pauseSession(sessionID);
      }
    }
  };

  onEndButtonClick = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: true,
    });
  };

  onModalClose = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: false,
    });
  };

  onConfirmEnd = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isModalShown: false,
    });
    const { sessionID } = this.props;
    if (sessionID != null) {
      this.props.endSession(sessionID);
    }
  };

  onMouseEnter = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isHovering: true });
  };

  onMouseLeave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isHovering: false });
  };

  renderSessionCode() {
    const { sessionCode, sessionState } = this.props;
    const showSessionCode = sessionState === "started";
    if (!showSessionCode) {
      return null;
    }
    return (
      <div className={styles.sessionCodeContainer}>
        <Text
          translationKey="teacher.session.header.participation"
          values={{
            code: <span className={styles.sessionCodeText}>{sessionCode}</span>,
            url: <JoinSessionLink sessionCode={sessionCode} />,
          }}
        />
      </div>
    );
  }

  renderPauseButtonTooltip() {
    const { sessionIsPaused } = this.props;
    const { isHovering } = this.state;
    if (isHovering && !sessionIsPaused) {
      return (
        <div className={styles.pauseButtonToolip}>
          <Text translationKey="teacher.session.header.button.pause.tooltip" />
        </div>
      );
    }
    return null;
  }

  renderRightButtons() {
    const { sessionState, sessionIsPaused } = this.props;
    const showRightButtons = sessionState === "started";
    if (!showRightButtons) {
      return null;
    }
    const disabled = this.props.sessionID == null;
    return (
      <div className={styles.rightContainer}>
        <div
          className={styles.pauseButton}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <VerticalIconButton
            className=""
            variant={sessionIsPaused ? "paused" : "pause"}
            onClick={this.onTogglePause}
            disabled={disabled}
          />
          {this.renderPauseButtonTooltip()}
        </div>
        <VerticalIconButton
          className={styles.endButton}
          variant="end"
          onClick={this.onEndButtonClick}
          disabled={disabled}
        />
      </div>
    );
  }

  renderModal() {
    const { isModalShown } = this.state;
    if (!isModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onModalClose}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.session.end_session_modal_title"
          buttonKey="phrase.confirm"
          onClose={this.onModalClose}
          onButtonClick={this.onConfirmEnd}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  render() {
    const {
      sessionState,
      templateDisplayCode,
      templateName,
      pauseOrUnpauseSessionError,
    } = this.props;
    const showRightButtons = sessionState === "started";
    return (
      <div className={classnames(styles.rootContainer, this.props.className)}>
        <img className={styles.logo} src={logoURL} alt="logo" />
        <div
          className={classnames(styles.leftContainer, {
            [styles.leftContainerWithRightButtons]: showRightButtons,
          })}
        >
          <span className={styles.templateCodeText}>{templateDisplayCode}</span>
          <TruncatedText
            lines={showRightButtons ? 1 : 2}
            lineHeight={33}
            fontSize={24}
            fontWeight={500}
            className={styles.templateNameText}
          >
            {templateName}
          </TruncatedText>
          {this.renderSessionCode()}
        </div>
        {this.renderRightButtons()}
        {this.renderModal()}
        <ErrorModal error={pauseOrUnpauseSessionError} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    pauseOrUnpauseSessionError: extractError(
      state.sessions.pauseOrUnpauseSessionRequest
    ),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    pauseSession: bindActionCreators(pauseSession, dispatch),
    unpauseSession: bindActionCreators(unpauseSession, dispatch),
    endSession: bindActionCreators(endSession, dispatch),
  };
}

export default ((connect(mapStateToProps, mapDispatchToProps)(
  TeacherSessionHeader
): any): Class<React$Component<OwnProps>>);
