// @flow
import type { Activity, ActivityType, PollingOption } from "../types/activity";

export function generateEmptyActivity(): Activity {
  return {
    type: "qa",
    name: "",
    question: "",
    imageId: null,
    imageUrl: null,
    whiteboardBackground: "empty",
    whiteboardOrientation: "landscape",
    whiteboardImageId: null,
    whiteboardImageUrl: null,
    whiteboardStickers: [],
    pollingNamed: null,
    pollingMultipleChoice: null,
    pollingOptions: null,
  };
}

function makeEmptyPollingOption(): PollingOption {
  return {
    text: "",
    imageUrl: null,
    imageId: null,
  };
}

export function changeType(
  activity: Activity,
  activityType: ActivityType
): Activity {
  switch (activityType) {
    case "qa":
      // no special handling is needed
      return {
        ...activity,
        type: "qa",
      };
    case "whiteboard":
      // no special handling is needed
      return {
        ...activity,
        type: "whiteboard",
        whiteboardBackground: "empty",
        whiteboardOrientation: "landscape",
      };
    case "polling":
      return {
        ...activity,
        type: "polling",
        pollingNamed:
          activity.pollingNamed != null ? activity.pollingNamed : false,
        pollingMultipleChoice:
          activity.pollingMultipleChoice != null
            ? activity.pollingMultipleChoice
            : false,
        pollingOptions:
          activity.pollingOptions != null
            ? activity.pollingOptions
            : [makeEmptyPollingOption(), makeEmptyPollingOption()],
      };
    default:
      return activity;
  }
}

export function getBackgroundImageURL(activity: Activity): ?string {
  if (
    activity.type === "whiteboard" &&
    activity.whiteboardBackground === "image"
  ) {
    return activity.whiteboardImageUrl;
  }
  return null;
}
