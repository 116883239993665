// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import Text from "../Text/Text";
import FormattedDate from "../FormattedDate/FormattedDate";
import Button from "../Button/Button";
import PopupWrapper from "../PopupWrapper/PopupWrapper";
import IconButton from "../IconButton/IconButton";
import CreateTemplateButton from "../CreateTemplateButton/CreateTemplateButton";
import CreateSessionButton from "../CreateSessionButton/CreateSessionButton";
import viewIcon from "../../images/template_item_view.svg";
import modifyIcon from "../../images/template_item_modify.svg";
import moreIcon from "../../images/template_item_more.svg";
import activityRecordsIcon from "../../images/template_activities.svg";
import deleteIcon from "../../images/template_item_delete.svg";
import ViewTemplateLink from "../Link/ViewTemplateLink";
import ActivityRecordsLink from "../Link/ActivityRecordsLink";
import ModalContainer from "../Modal/ModalContainer";
import AlertModal from "../Modal/AlertModal";
import type { Template } from "../../types/template";
import styles from "./TemplateItem.module.scss";

type Props = {
  +template: Template,
  +customTemplates: $ReadOnlyArray<Template>,
  +onDeleteTemplate: string => void,
  +onToggleDisplayCustomTemplates: string => void,
  +displayCustomTemplates: boolean,
  +onCreateTemplate: (templateId: string, newName: string) => void,
};

type LocalState = {|
  isPopupMenuOpen: boolean,
  alertModalShown: boolean,
|};

class TemplateItem extends PureComponent<Props, LocalState> {
  static defaultProps = {
    displayCustomTemplates: false,
  };

  state = {
    isPopupMenuOpen: false,
    alertModalShown: false,
  };

  onClickMoreActionsButton = (e: Event) => {
    e.stopPropagation();
    this.setState(prevState => ({
      isPopupMenuOpen: !prevState.isPopupMenuOpen,
    }));
  };

  onClickMoreActionsOutside = (e: Event) => {
    this.setState({ isPopupMenuOpen: false });
  };

  onToggleDisplayCustomTemplates = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onToggleDisplayCustomTemplates(this.props.template.id);
  };

  onDeleteTemplate = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ alertModalShown: true, isPopupMenuOpen: false });
  };

  onCloseAlertModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ alertModalShown: false });
  };

  onConfirmDeleteTemplate = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { template } = this.props;
    this.props.onDeleteTemplate(template.id);
    this.setState({ alertModalShown: false });
  };

  renderAlertModal() {
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey="teacher.sure_to_delete_template"
          buttonKey="phrase.confirm"
          onButtonClick={this.onConfirmDeleteTemplate}
          onClose={this.onCloseAlertModal}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderImportedTemplateAdditionalInfo() {
    const { customTemplates, displayCustomTemplates } = this.props;

    if (customTemplates.length === 0) {
      return null;
    }

    return (
      <span
        className={classnames(styles.importedTemplateAdditionInfo, {
          [styles.expanded]: displayCustomTemplates,
          [styles.collapsed]: !displayCustomTemplates,
        })}
      >
        <span>．</span>
        <Text
          translationKey="teacher.number_of_custom_templates"
          values={{
            numberOfCustomTemplates: customTemplates.length,
          }}
        />
      </span>
    );
  }

  renderCustomTemplateAdditionalInfo() {
    const { template } = this.props;
    return [
      <span key="dot">．</span>,
      <FormattedDate
        key="templateCreatedAt"
        value={template.createdAt}
        hour12={false}
        hour="2-digit"
        minute="2-digit"
        second="2-digit"
        year="numeric"
        month="long"
        day="numeric"
      />,
    ];
  }

  renderBasicInfo() {
    const { template } = this.props;

    return (
      <div className={styles.templateInfo}>
        <p className={styles.name}>{template.name}</p>
        <p className={styles.numberOfActivities}>
          <Text
            translationKey="teacher.number_of_activities"
            values={{ numberOfActivities: template.activities.length }}
          />
          {template.isImported && this.renderImportedTemplateAdditionalInfo()}
          {!template.isImported && this.renderCustomTemplateAdditionalInfo()}
        </p>
      </div>
    );
  }

  renderViewTemplateButton() {
    const { template } = this.props;
    return (
      <ViewTemplateLink templateID={this.props.template.id}>
        {template.isImported && (
          <IconButton
            className={styles.viewTemplateIconButton}
            icon={viewIcon}
            labelTranslationKey="teacher.view_template"
          />
        )}
        {!template.isImported && (
          <IconButton
            className={styles.viewTemplateIconButton}
            icon={modifyIcon}
            labelTranslationKey="teacher.view_modify_template"
          />
        )}
      </ViewTemplateLink>
    );
  }

  renderCustomTemplateButton() {
    return (
      <CreateTemplateButton
        template={this.props.template}
        className={styles.createTemplateButton}
        onCreateTemplate={this.props.onCreateTemplate}
      />
    );
  }

  renderStartPreviewButton() {
    return <CreateSessionButton template={this.props.template} />;
  }

  renderPopupMenu() {
    const { template } = this.props;
    const { isPopupMenuOpen } = this.state;

    if (!isPopupMenuOpen) {
      return null;
    }

    return (
      <PopupWrapper onClickOutside={this.onClickMoreActionsOutside}>
        <div className={styles.moreActionsPopupMenu}>
          <ActivityRecordsLink
            templateId={template.id}
            disabled={template.activityRecordsCount === 0}
          >
            <IconButton
              className={classnames(styles.popupMenuItem, {
                [styles.disabledButton]: template.activityRecordsCount === 0,
              })}
              icon={activityRecordsIcon}
              labelTranslationKey="teacher.activity_records"
            />
          </ActivityRecordsLink>
          {!template.isImported && (
            <IconButton
              className={styles.popupMenuItem}
              icon={deleteIcon}
              labelTranslationKey="teacher.delete_template"
              onClick={this.onDeleteTemplate}
            />
          )}
        </div>
      </PopupWrapper>
    );
  }

  renderMoreActionsButton() {
    const { isPopupMenuOpen } = this.state;

    return (
      <Button
        styled={false}
        color={null}
        className={styles.moreButton}
        onClick={this.onClickMoreActionsButton}
        disabled={isPopupMenuOpen}
      >
        <img
          src={moreIcon}
          className={styles.moreButtonIcon}
          alt="button form more"
        />
      </Button>
    );
  }

  renderButtons() {
    const { template } = this.props;
    return (
      <div className={styles.buttons}>
        {template.isImported && this.renderCustomTemplateButton()}
        {this.renderViewTemplateButton()}
        {this.renderStartPreviewButton()}
        {this.renderMoreActionsButton()}
        {this.renderPopupMenu()}
      </div>
    );
  }

  renderTemplate() {
    const { template } = this.props;
    return (
      <div
        className={classnames(styles.templateRow, {
          [styles.customTemplateRow]: !template.isImported,
        })}
        key={template.id}
        onClick={this.onToggleDisplayCustomTemplates}
      >
        <p className={styles.displayCode}>{template.displayCode}</p>
        {this.renderBasicInfo()}
        {this.renderButtons()}
        {this.renderAlertModal()}
      </div>
    );
  }

  renderCustomTemplates() {
    const {
      customTemplates,
      onDeleteTemplate,
      displayCustomTemplates,
      onToggleDisplayCustomTemplates,
    } = this.props;

    if (!displayCustomTemplates) {
      return [];
    }

    return customTemplates.map(customTemplate => (
      <TemplateItem
        key={customTemplate.id}
        template={customTemplate}
        customTemplates={[]}
        onDeleteTemplate={onDeleteTemplate}
        onToggleDisplayCustomTemplates={onToggleDisplayCustomTemplates}
        onCreateTemplate={this.props.onCreateTemplate}
      />
    ));
  }

  render() {
    return [this.renderTemplate(), ...this.renderCustomTemplates()];
  }
}

export default TemplateItem;
