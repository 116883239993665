// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { openLinkInNewWindow } from "../../utils/window";
import CreateSessionModal from "../Modal/CreateSessionModal";
import IconButton from "../IconButton/IconButton";
import previewIcon from "../../images/session_preview.svg";
import startIcon from "../../images/session_start.svg";
import { createSession } from "../../redux/actions/sessions";
import type { Template } from "../../types/template";
import type {
  CreateSessionPayload,
  SessionMode,
  Session,
} from "../../types/session";
import type { Dispatch } from "../../types/store";
import styles from "./CreateSessionButton.module.scss";

type Props = {
  +className: string,
  +template: Template,
  +createSession: CreateSessionPayload => Promise<Session>,
  +createSessionError: mixed,
};

type LocalState = {|
  createSessionModalShown: boolean,
  createSessionMode: SessionMode,
|};

class CreateSessionButton extends PureComponent<Props, LocalState> {
  state = {
    createSessionModalShown: false,
    createSessionMode: "test",
  };

  onCloseCreateSessionModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ createSessionModalShown: false });
  };

  onClickPreviewButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      createSessionModalShown: true,
      createSessionMode: "test",
    });
  };

  onClickStartButton = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      createSessionModalShown: true,
      createSessionMode: "live",
    });
  };

  onConfirmCreateSession = ({ name, mode }) => {
    const newWindow = openLinkInNewWindow();
    this.setState({
      createSessionModalShown: false,
    });
    this.props
      .createSession({
        mode,
        teacherName: name,
        templateId: this.props.template.id,
      })
      .then(session => {
        newWindow.location.href = `${window.location.origin}/session/${
          session.id
        }`;
      })
      .catch(error => newWindow.close());
  };

  renderPreviewButton() {
    return (
      <IconButton
        className={styles.previewIconButton}
        icon={previewIcon}
        labelTranslationKey="teacher.preview"
        onClick={this.onClickPreviewButton}
      />
    );
  }

  renderStartButton() {
    return (
      <IconButton
        className={styles.startIconButton}
        icon={startIcon}
        labelTranslationKey="teacher.start"
        onClick={this.onClickStartButton}
      />
    );
  }

  renderCreateSessionModal() {
    if (!this.state.createSessionModalShown) {
      return null;
    }

    return (
      <CreateSessionModal
        onConfirm={this.onConfirmCreateSession}
        onClose={this.onCloseCreateSessionModal}
        mode={this.state.createSessionMode}
        templateName={this.props.template.name}
      />
    );
  }

  render() {
    return (
      <div
        className={classnames(
          styles.createSessionButtonContainer,
          this.props.className
        )}
      >
        {this.renderPreviewButton()}
        {this.renderStartButton()}
        {this.renderCreateSessionModal()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createSession: bindActionCreators(createSession, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(CreateSessionButton);
