// @flow
import type { Action } from "../../types/store";
import type { TemplatesState } from "../../types/template";
import {
  FETCH_ALL_REQUESTING,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_FAILURE,
  FETCH_ONE_REQUESTING,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  EDIT_CUSTOM_REQUESTING,
  EDIT_CUSTOM_SUCCESS,
  EDIT_CUSTOM_FAILURE,
  DELETE_CUSTOM_REQUESTING,
  DELETE_CUSTOM_SUCCESS,
  DELETE_CUSTOM_FAILURE,
  SEARCH_IMPORTED_REQUESTING,
  SEARCH_IMPORTED_SUCCESS,
  SEARCH_IMPORTED_FAILURE,
  CREATE_IMPORTED_REQUESTING,
  CREATE_IMPORTED_SUCCESS,
  CREATE_IMPORTED_FAILURE,
  EDIT_IMPORTED_REQUESTING,
  EDIT_IMPORTED_SUCCESS,
  EDIT_IMPORTED_FAILURE,
  DELETE_IMPORTED_REQUESTING,
  DELETE_IMPORTED_SUCCESS,
  DELETE_IMPORTED_FAILURE,
} from "../actions/templates";
import {
  CREATE_TEMPLATE_REQUESTING,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
} from "../actions/createTemplate";

const INITIAL_STATE: TemplatesState = {
  isRequesting: false,
  templatesById: {},
  form1TemplateIds: [],
  form2TemplateIds: [],
  form3TemplateIds: [],
  error: null,
};

export function reducer(state: TemplatesState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case FETCH_ALL_REQUESTING:
    case FETCH_ONE_REQUESTING:
    case CREATE_TEMPLATE_REQUESTING:
    case EDIT_CUSTOM_REQUESTING:
    case DELETE_CUSTOM_REQUESTING:
    case SEARCH_IMPORTED_REQUESTING:
    case CREATE_IMPORTED_REQUESTING:
    case EDIT_IMPORTED_REQUESTING:
    case DELETE_IMPORTED_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: null,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isRequesting: false,
        error: null,
      };
    case FETCH_ALL_FAILURE:
    case CREATE_TEMPLATE_FAILURE:
    case FETCH_ONE_FAILURE:
    case EDIT_CUSTOM_FAILURE:
    case DELETE_CUSTOM_FAILURE:
    case SEARCH_IMPORTED_FAILURE:
    case CREATE_IMPORTED_FAILURE:
    case EDIT_IMPORTED_FAILURE:
    case DELETE_IMPORTED_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.payload,
      };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: null,
        templatesById: {
          ...state.templatesById,
          [action.payload.baseTemplate.id]: action.payload.baseTemplate,
          [action.payload.createdTemplate.id]: action.payload.createdTemplate,
        },
        [`${action.payload.createdTemplate.year}TemplateIds`]: [
          ...state[`${action.payload.createdTemplate.year}TemplateIds`],
          action.payload.createdTemplate.id,
        ],
      };
    case FETCH_ONE_SUCCESS:
    case EDIT_CUSTOM_SUCCESS:
    case CREATE_IMPORTED_SUCCESS:
    case EDIT_IMPORTED_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        error: null,
        templatesById: {
          ...state.templatesById,
          [action.payload.id]: action.payload,
        },
        [`${action.payload.year}TemplateIds`]: Array.from(
          new Set([
            ...state[`${action.payload.year}TemplateIds`],
            action.payload.id,
          ])
        ),
      };
    case DELETE_CUSTOM_SUCCESS:
    case DELETE_IMPORTED_SUCCESS:
      const {
        [action.payload.id]: template,
        ...restTemplatesById
      } = state.templatesById;
      const templateIds = Array.from(
        state[`${action.payload.year}TemplateIds`]
      );
      const idx = templateIds.indexOf(action.payload.id);
      templateIds.splice(idx, 1);
      return {
        ...state,
        isRequesting: false,
        error: null,
        templatesById: {
          ...restTemplatesById,
        },
        [`${action.payload.year}TemplateIds`]: [...templateIds],
      };
    case SEARCH_IMPORTED_SUCCESS: {
      const cloned = { ...state.templatesById };
      for (const s of action.payload) {
        cloned[s.id] = s;
      }
      return {
        ...state,
        isRequesting: false,
        templatesById: cloned,
      };
    }
    default:
      return state;
  }
}
