// @flow
import React, { PureComponent } from "react";
import type { PollingOption } from "../../types/activity";
import type {
  ParticipantAnswer,
  PollingOptionResultItem,
} from "../../utils/session";
import Text from "../Text/Text";
import Image from "../Image/Image";
import { POLLING_OPTION_DIAGRAM_HEIGHT } from "../../utils/constants";
import { indexToAlpha, getPollingOptionResultItems } from "../../utils/session";
import styles from "./ActivityRecordPollingChart.module.scss";

type Props = {
  className?: string,
  participantCount: number,
  pollingOptions: $ReadOnlyArray<PollingOption>,
  participantAnswers: $ReadOnlyArray<ParticipantAnswer>,
};

type LocalState = {
  items: $ReadOnlyArray<PollingOptionResultItem>,
};

const barMaxPercentageWidth = 0.8;

export default class ActivityRecordPollingChart extends PureComponent<
  Props,
  LocalState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: getPollingOptionResultItems(
        props.pollingOptions,
        props.participantAnswers
      ),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      items: getPollingOptionResultItems(
        nextProps.pollingOptions,
        nextProps.participantAnswers
      ),
    });
  }

  renderItem = (item: PollingOptionResultItem, index: number) => {
    const alpha = indexToAlpha(index);
    const { participantAnswers } = this.props;
    const answeredParticipantCount = participantAnswers.length;
    let percentage = 0;
    // Avoid division by zero
    if (answeredParticipantCount !== 0) {
      percentage = item.participants.length / answeredParticipantCount;
    }
    const percentageText = Math.round(percentage * 100) + "%";

    const barPercentageWidth = barMaxPercentageWidth * percentage;

    return (
      <div key={index} className={styles.optionRow}>
        <div className={styles.optionAlpha}>{alpha}</div>
        <div className={styles.optionContent}>
          <p className={styles.text}>
            {item.text} {percentageText}
          </p>
          <div className={styles.barAndStats}>
            {barPercentageWidth !== 0 && (
              <div
                style={{ width: 100 * barPercentageWidth + "%" }}
                className={styles.bar}
              />
            )}
            <div className={styles.statsContainer}>
              <p className={styles.countText}>
                <Text
                  translationKey="student.number_of_answers"
                  values={{
                    n: item.participants.length,
                  }}
                />
              </p>
            </div>
          </div>
          {item.imageUrl != null && (
            <Image
              imageUrl={item.imageUrl}
              height={POLLING_OPTION_DIAGRAM_HEIGHT}
              placeholderWidth={POLLING_OPTION_DIAGRAM_HEIGHT * 1.33}
              placeholderHeight={POLLING_OPTION_DIAGRAM_HEIGHT}
              className={styles.optionDiagram}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { items } = this.state;
    return (
      <div className={this.props.className}>{items.map(this.renderItem)}</div>
    );
  }
}
