// @flow
import React from "react";
import { injectIntl } from "react-intl";
import type { IntlShape } from "react-intl";

type Props = {
  +onRef?: (r: HTMLTextAreaElement | null) => void,
  +placeholderId: string,
  +intl: IntlShape,
};

const TextArea = ({ placeholderId, intl, onRef, ...rest }: Props) => (
  <textarea
    ref={onRef}
    placeholder={intl.formatMessage({ id: placeholderId })}
    {...rest}
  />
);

export default injectIntl(TextArea);
