// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import styles from "./Pagination.module.scss";

type Props = {
  +pageIdx: number,
  +isCurrentPage: boolean,
  +onClickPage: (e: Event, pageIdx: number) => void,
};

class Page extends PureComponent<Props> {
  onClickPage = (e: Event) => {
    this.props.onClickPage(e, this.props.pageIdx);
  };

  render() {
    const { pageIdx, isCurrentPage } = this.props;
    return (
      <button
        className={classnames(styles.pageNav, {
          [styles.currentPage]: isCurrentPage,
        })}
        onClick={this.onClickPage}
      >
        {pageIdx}
      </button>
    );
  }
}

export default Page;
