// @flow

const DEFAULT_WIDTH = 1440;
const DEFAULT_HEIGHT = 931;

export function openLinkInNewWindow(
  options?: {
    path?: string,
    width?: number,
    height?: number,
  } = {}
): any {
  const { path } = options;
  let { width, height } = options;
  if (width == null) {
    width = DEFAULT_WIDTH;
  }
  if (height == null) {
    height = DEFAULT_HEIGHT;
  }
  const href = path != null ? `${window.location.origin}${path}` : "";
  const windowOptions = `width=${width},height=${height}`;
  return window.open(href, "_blank", windowOptions);
}
