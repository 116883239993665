// @flow
import fabric from "fabric";
import type { WhiteboardOrientation } from "../../types/activity";

// Static configuration of toolbox
export const AVAILABLE_COLORS = [
  "#F0453A",
  "#E91E63",
  "#FF7CA2",
  "#9C27B0",
  "#3F51B5",
  "#0F30A0",
  "#00AFFF",
  "#06D000",
  "#009F42",
  "#006B58",
  "#FFEB3B",
  "#F8AB45",
  "#795548",
  "#000000",
  "#9B9B9B",
  "#FFFFFF",
  "transparent",
];
// Don't support transparent color for pencil
export const PENCIL_AVAILABLE_COLORS = [
  "#F0453A",
  "#E91E63",
  "#FF7CA2",
  "#9C27B0",
  "#3F51B5",
  "#0F30A0",
  "#00AFFF",
  "#06D000",
  "#009F42",
  "#006B58",
  "#FFEB3B",
  "#F8AB45",
  "#795548",
  "#000000",
  "#9B9B9B",
  "#FFFFFF",
];
export const AVAILABLE_STROKE_WIDTHS = [10, 20, 30, 40];

export const ERASER_DISPLAY_COLOR = "rgba(255, 255, 255, 0.4)";
export const ERASER_BRUSH_COLOR = "rgba(255, 255, 255, 0.2)";

export type Shape =
  | "circle"
  | "rectangle"
  | "heart"
  | "star"
  | "triangle"
  | "pentagon";

export const AVAILABLE_SHAPES: $ReadOnlyArray<Shape> = [
  "circle",
  "rectangle",
  "heart",
  "star",
  "triangle",
  "pentagon",
];

export type DrawingState = {
  isBackgroundImageLoading: boolean,
  readOnly: boolean,
  backgroundColor: string,
  backgroundImageURL: ?string,
  stickerImageURLs: $ReadOnlyArray<string>,
  orientation: WhiteboardOrientation,
  // eslint-disable-next-line flowtype/no-weak-types
  selectedObject: ?Object,
  selectedTool: "pencil" | "eraser" | null,
  pencilColorIndex: number,
  strokeColorIndex: number,
  fillColorIndex: number,
  strokeWidthIndex: number,
  selectedTextObjectContent: ?string,
};

export function makeInitialState(
  orientation: WhiteboardOrientation,
  stickerImageURLs: $ReadOnlyArray<string>
): DrawingState {
  return {
    orientation,
    stickerImageURLs,
    isBackgroundImageLoading: false,
    readOnly: false,
    backgroundImageURL: null,
    backgroundColor: "white",
    selectedObject: null,
    selectedTool: "pencil",
    pencilColorIndex: 10,
    strokeColorIndex: 10,
    fillColorIndex: 14,
    strokeWidthIndex: 2,
    selectedTextObjectContent: null,
  };
}

export function setIsBackgroundImageLoading(
  state: DrawingState,
  loading: boolean
): DrawingState {
  return {
    ...state,
    isBackgroundImageLoading: loading,
  };
}

export function setReadOnly(
  state: DrawingState,
  readOnly: boolean
): DrawingState {
  if (readOnly) {
    return {
      ...state,
      readOnly: true,
      selectedObject: null,
      selectedTool: null,
    };
  }
  return {
    ...state,
    readOnly: false,
    selectedTool: "pencil",
  };
}

export function setBackgroundImageURL(
  state: DrawingState,
  url: ?string
): DrawingState {
  return {
    ...state,
    backgroundImageURL: url,
  };
}

export function setStickerImageURLs(
  state: DrawingState,
  stickerImageURLs: $ReadOnlyArray<string>
): DrawingState {
  return {
    ...state,
    stickerImageURLs,
  };
}

export function setOrientation(
  state: DrawingState,
  orientation: WhiteboardOrientation
): DrawingState {
  return {
    ...state,
    orientation,
  };
}

export function selectStrokeColorIndex(
  state: DrawingState,
  i: number
): DrawingState {
  if (state.readOnly) {
    return state;
  }
  const color = AVAILABLE_COLORS[i];
  const otherColor = AVAILABLE_COLORS[state.fillColorIndex];
  // Do not allow both colors to be transparent
  if (color === otherColor && otherColor === "transparent") {
    // Swap them
    return {
      ...state,
      fillColorIndex: state.strokeColorIndex,
      strokeColorIndex: i,
    };
  } else {
    return {
      ...state,
      strokeColorIndex: i,
    };
  }
}

export function selectFillColorIndex(
  state: DrawingState,
  i: number
): DrawingState {
  if (state.readOnly) {
    return state;
  }
  const color = AVAILABLE_COLORS[i];
  const otherColor = AVAILABLE_COLORS[state.strokeColorIndex];
  // Do not allow both colors to be transparent
  if (color === otherColor && otherColor === "transparent") {
    // Swap them
    return {
      ...state,
      strokeColorIndex: state.fillColorIndex,
      fillColorIndex: i,
    };
  } else {
    return {
      ...state,
      fillColorIndex: i,
    };
  }
}

export function selectPencilColorIndex(
  state: DrawingState,
  i: number
): DrawingState {
  return {
    ...state,
    pencilColorIndex: i,
  };
}

export function selectStrokeWidthIndex(
  state: DrawingState,
  i: number
): DrawingState {
  if (state.readOnly) {
    return state;
  }
  return {
    ...state,
    strokeWidthIndex: i,
  };
}

export function selectTool(
  state: DrawingState,
  tool: "pencil" | "eraser" | null
): DrawingState {
  if (state.readOnly) {
    return state;
  }
  const { selectedTool } = state;
  if (selectedTool === tool) {
    return state;
  }
  return {
    ...state,
    selectedTool: tool,
  };
}

export function deselectTool(state: DrawingState): DrawingState {
  if (state.readOnly) {
    return state;
  }
  return {
    ...state,
    selectedTool: null,
  };
}

// eslint-disable-next-line flowtype/no-weak-types
export function selectObject(state: DrawingState, obj: Object): DrawingState {
  if (state.readOnly) {
    return state;
  }
  return {
    ...state,
    selectedObject: obj,
    selectedTextObjectContent: obj instanceof fabric.Text ? obj.text : null,
  };
}

export function deselectObject(state: DrawingState): DrawingState {
  if (state.readOnly) {
    return state;
  }
  return {
    ...state,
    selectedObject: null,
    selectedTextObjectContent: null,
  };
}

export function setSelectedTextObjectContent(
  state: DrawingState,
  text: string
): DrawingState {
  if (state.readOnly) {
    return state;
  }
  return {
    ...state,
    selectedTextObjectContent: text,
  };
}

export function getPencilColor(state: DrawingState): string {
  return PENCIL_AVAILABLE_COLORS[state.pencilColorIndex];
}

export function getStrokeWidthButtonColor(state: DrawingState): string {
  if (state.selectedTool === "eraser") {
    return ERASER_DISPLAY_COLOR;
  }
  return getPencilColor(state);
}

export function getStrokeColor(state: DrawingState): string {
  const strokeColor = AVAILABLE_COLORS[state.strokeColorIndex];
  return strokeColor;
}

export function getFillColor(state: DrawingState): string {
  const fillColor = AVAILABLE_COLORS[state.fillColorIndex];
  return fillColor;
}

export function getPencilWidth(state: DrawingState): number {
  return AVAILABLE_STROKE_WIDTHS[state.strokeWidthIndex];
}

export function isSelectedObjectText(state: DrawingState): boolean {
  const { selectedObject } = state;
  return selectedObject instanceof fabric.Text;
}

export function getAvailableColors(
  variant: "fill" | "stroke" | "pencil"
): $ReadOnlyArray<string> {
  if (variant === "pencil") {
    return PENCIL_AVAILABLE_COLORS;
  } else {
    return AVAILABLE_COLORS;
  }
}
