// @flow
import React, { PureComponent } from "react";
import { Surface, Shape, Path } from "react-art";

type Props = {
  className: string,
  // The width of the bounding rect, in px
  width: number,
  // The height of the bounding rect, in px
  height: number,
  strokeColor: string,
  strokeWidth: number,
  direction: "left" | "right",
};

// Draw an arrow with canvas
export default class Arrow extends PureComponent<Props> {
  render() {
    const {
      className,
      width,
      height,
      strokeColor,
      strokeWidth,
      direction,
    } = this.props;
    const path = new Path();
    switch (direction) {
      case "left":
        path
          .moveTo(width, 0)
          .lineTo(0, height / 2)
          .lineTo(width, height);
        break;
      case "right":
        path
          .moveTo(0, 0)
          .lineTo(width, height / 2)
          .lineTo(0, height);
        break;
      default:
        break;
    }
    return (
      <Surface className={className} width={width} height={height}>
        <Shape stroke={strokeColor} strokeWidth={strokeWidth} d={path} />
      </Surface>
    );
  }
}
