// @flow
import React from "react";
import type { Node } from "react";
import Link from "./Link";

export default function AdminViewActivityRecordLink(props: {
  className?: string,
  sessionID: string,
  children?: Node,
}) {
  const { className, children, sessionID } = props;
  return (
    <Link
      className={className}
      to={`/admin/records_management/view?sessionID=${sessionID}`}
      altId="admin.activity_record.button.view_record"
    >
      {children}
    </Link>
  );
}
