// @flow
import React, { PureComponent } from "react";
import ModalContainer from "../Modal/ModalContainer";
import Text from "../Text/Text";
import styles from "./StudentLockScreen.module.scss";

// A list of event that are considered
// user interactions
// This is compiled manually from the full list
// See https://developer.mozilla.org/en-US/docs/Web/Events
const EVENT_NAMES = [
  "keydown",
  "keypress",
  "keyup",
  "mouseenter",
  "mouseover",
  // This event is excluded because it fires too often
  // "mousemove",
  "mousedown",
  "mouseup",
  "auxclick",
  "click",
  "dblclick",
  "contextmenu",
  "wheel",
  "mouseleave",
  "mouseout",
  "select",
  "pointerlockchange",
  "pointerlockerror",
  "dragstart",
  "drag",
  "dragend",
  "dragenter",
  "dragover",
  "dragleave",
  "drop",
];

export default class StudentLockScreen extends PureComponent<{}> {
  componentDidMount() {
    // https://developer.mozilla.org/en-US/docs/Web/API/DocumentOrShadowRoot/activeElement
    const activeElement = document.activeElement;
    if (activeElement != null) {
      try {
        activeElement.blur();
      } catch (e) {}
    }
    for (const eventName of EVENT_NAMES) {
      window.addEventListener(eventName, this.noopEventHandler, {
        capture: true,
      });
    }
  }

  componentWillUnmount() {
    for (const eventName of EVENT_NAMES) {
      window.removeEventListener(eventName, this.noopEventHandler, {
        capture: true,
      });
    }
  }

  noopEventHandler = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  renderLock() {
    return (
      <p className={styles.lockText}>
        <Text translationKey="student.screen_locked" />
      </p>
    );
  }

  render() {
    return <ModalContainer>{this.renderLock()}</ModalContainer>;
  }
}
