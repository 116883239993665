// @flow
import React, { PureComponent } from "react";
import classnames from "classnames";
import type { Session, ParticipationInfo } from "../../types/session";
import styles from "./StudentSessionHeader.module.scss";

type Props = {
  className: string,
  session: Session,
  participationInfo: ParticipationInfo,
};

export default class StudentSessionHeader extends PureComponent<Props> {
  render() {
    const { session, participationInfo } = this.props;
    const { participantName } = participationInfo;
    return (
      <div className={classnames(styles.rootContainer, this.props.className)}>
        <div className={styles.rightContainer}>
          <p className={styles.participantNameText}>{participantName}</p>
        </div>
        <div className={styles.leftContainer}>
          <span className={styles.templateCodeText}>
            {session.templateDisplayCode}
          </span>
          <span className={styles.templateNameText}>
            {session.templateName}
          </span>
        </div>
      </div>
    );
  }
}
