// @flow
import type { RequestState } from "./request";
export const UserTypeSuperAdmin: "PolyupathsSuperAdmin" =
  "PolyupathsSuperAdmin";
export const UserTypeEditor: "PolyupathsEditor" = "PolyupathsEditor";
export const UserTypeSchool: "PolyupathsSchool" = "PolyupathsSchool";

export type UserType =
  | typeof UserTypeSuperAdmin
  | typeof UserTypeEditor
  | typeof UserTypeSchool;

export type UserResponse = {|
  +_id: string,
  +email: string,
  +name: string,
  +user_type: UserType,
  +disabled: boolean,
|};

export type User = {
  +id: string,
  +email: string,
  +name: string,
  +userType: UserType,
  +disabled: boolean,
};

export type UsersState = {
  +userById: { [id: string]: User },
  +searchUsersRequest: ?RequestState<void>,
  +createUserRequest: ?RequestState<void>,
  +editUserRequest: ?RequestState<void>,
  +enableDisableUserRequest: ?RequestState<void>,
};

export const SEARCH_USERS_REQUESTING: "USERS.SEARCH.REQUESTING" =
  "USERS.SEARCH.REQUESTING";
export const SEARCH_USERS_SUCCESS: "USERS.SEARCH.SUCCESS" =
  "USERS.SEARCH.SUCCESS";
export const SEARCH_USERS_FAILURE: "USERS.SEARCH.FAILURE" =
  "USERS.SEARCH.FAILURE";

export type SearchUsersRequesting = {|
  +type: typeof SEARCH_USERS_REQUESTING,
|};
export type SearchUsersSuccess = {|
  +type: typeof SEARCH_USERS_SUCCESS,
  +payload: $ReadOnlyArray<User>,
|};
export type SearchUsersFailure = {|
  +type: typeof SEARCH_USERS_FAILURE,
  +payload: mixed,
|};

export const CREATE_USER_REQUESTING: "USERS.CREATE_USER.REQUESTING" =
  "USERS.CREATE_USER.REQUESTING";
export const CREATE_USER_SUCCESS: "USERS.CREATE_USER.SUCCESS" =
  "USERS.CREATE_USER.SUCCESS";
export const CREATE_USER_FAILURE: "USERS.CREATE_USER.FAILURE" =
  "USERS.CREATE_USER.FAILURE";

export type CreateUserRequesting = {|
  +type: typeof CREATE_USER_REQUESTING,
|};
export type CreateUserSuccess = {|
  +type: typeof CREATE_USER_SUCCESS,
  +payload: User,
|};
export type CreateUserFailure = {|
  +type: typeof CREATE_USER_FAILURE,
  +payload: mixed,
|};

export const EDIT_USER_REQUESTING: "USERS.EDIT_USER.REQUESTING" =
  "USERS.EDIT_USER.REQUESTING";
export const EDIT_USER_SUCCESS: "USERS.EDIT_USER.SUCCESS" =
  "USERS.EDIT_USER.SUCCESS";
export const EDIT_USER_FAILURE: "USERS.EDIT_USER.FAILURE" =
  "USERS.EDIT_USER.FAILURE";

export type EditUserRequesting = {|
  +type: typeof EDIT_USER_REQUESTING,
|};
export type EditUserSuccess = {|
  +type: typeof EDIT_USER_SUCCESS,
  +payload: User,
|};
export type EditUserFailure = {|
  +type: typeof EDIT_USER_FAILURE,
  +payload: mixed,
|};

export const ENABLE_DISABLE_USER_REQUESTING: "USERS.ENABLE_DISABLE.REQUESTING" =
  "USERS.ENABLE_DISABLE.REQUESTING";
export const ENABLE_DISABLE_USER_SUCCESS: "USERS.ENABLE_DISABLE.SUCCESS" =
  "USERS.ENABLE_DISABLE.SUCCESS";
export const ENABLE_DISABLE_USER_FAILURE: "USERS.ENABLE_DISABLE.FAILURE" =
  "USERS.ENABLE_DISABLE.FAILURE";

export type EnableDisableUserRequesting = {|
  +type: typeof ENABLE_DISABLE_USER_REQUESTING,
|};
export type EnableDisableUserSuccess = {|
  +type: typeof ENABLE_DISABLE_USER_SUCCESS,
  +payload: User,
|};
export type EnableDisableUserFailure = {|
  +type: typeof ENABLE_DISABLE_USER_FAILURE,
  +payload: mixed,
|};
