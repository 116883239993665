// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SessionItem from "../../components/SessionItem/SessionItem";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Text from "../../components/Text/Text";
import { queryActiveSessions } from "../../redux/actions/sessions";
import type { RootState, Dispatch } from "../../types/store";
import type { Session } from "../../types/session";
import { isRequesting, extractError } from "../../utils/request";
import ErrorModal from "../../components/ErrorModal";
import styles from "./Sessions.module.scss";

type Props = {
  +isRequesting: boolean,
  +sessions: $ReadOnlyArray<Session>,
  +fetchSessionsError: mixed,
  +queryActiveSessions: () => void,
};

class Sessions extends PureComponent<Props> {
  componentDidMount() {
    this.props.queryActiveSessions();
  }

  render() {
    const { sessions, isRequesting, fetchSessionsError } = this.props;

    if (isRequesting) {
      return <LoadingIndicator />;
    }

    return (
      <div className={styles.sessionsContainer}>
        {sessions.length !== 0 &&
          sessions.map(session => (
            <SessionItem key={session.id} session={session} />
          ))}
        {sessions.length === 0 && (
          <p className={styles.noInProgressSessionsText}>
            <Text translationKey="teacher.no_in_progress_sessions" />
          </p>
        )}
        <ErrorModal error={fetchSessionsError} />
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  const idmap = state.sessions.sessionById;
  const sessions = state.sessions.activeSessionIds.map(id => idmap[id]);
  return {
    sessions,
    isRequesting: isRequesting(state.sessions.activeSessionsRequest),
    fetchSessionsError: extractError(state.sessions.activeSessionsRequest),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    queryActiveSessions: bindActionCreators(queryActiveSessions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
