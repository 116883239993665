// @flow
import React, { PureComponent } from "react";
import WindowSize from "../../components/WindowSize";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import ImportedTemplateHeader from "../../components/ImportedTemplateHeader/ImportedTemplateHeader";
import ActivitiesPanel from "../../components/ActivitiesPanel/ActivitiesPanel";
import ActivityPanel from "../../components/ActivityPanel/ActivityPanel";
import ErrorModal from "../../components/ErrorModal";
import ModalContainer from "../../components/Modal/ModalContainer";
import AlertModal from "../../components/Modal/AlertModal";
import Text from "../../components/Text/Text";
import addIcon from "../../images/add.svg";
import IconButton from "../../components/IconButton/IconButton";
import {
  validateTemplate,
  validateActivity,
  ErrorTemplateNameEmpty,
  ErrorTemplateDisplayCodeEmpty,
} from "../../utils/validate";
import { deepClone } from "../../utils/deepClone";
import { generateEmptyActivity } from "../../utils/activity";
import type {
  Template,
  TEMPLATE_LOCALE,
  TEMPLATE_YEAR,
} from "../../types/template";
import type { Activity } from "../../types/activity";
import type { RouterHistory } from "react-router-dom";
import type {
  ValidateTemplateError,
  ValidateActivityError,
} from "../../utils/validate";
import styles from "./ImportedTemplate.module.scss";

type Props = {
  +history: RouterHistory,
  +isRequesting: boolean,
  +originalTemplate: ?Template,
  +error: mixed,
  +save: Template => Promise<Template>,
};

type LocalState = {|
  activeActivityIdx: number,
  editedTemplate: ?Template,
  alertModalShown: boolean,
  alertModalTitleKey: string,
  alertButtonClick: () => void,
  saving: boolean,
  templateErrors: $ReadOnlyArray<ValidateTemplateError>,
  activityErrors: $ReadOnlyArray<$ReadOnlyArray<ValidateActivityError>>,
|};

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
const MAGIC_HEIGHT_OFFSET = 138;

// layout top margin: 19px;
// layout header: 100px;
// layout header bottom margin: 19px;
// templateContainer border-top: 8px
// templateContainer header: 96px
// templateContainer header border-bottom: 1px
// templateContainer paddingTop: 28px;
const MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER = 271;

class ImportedTemplate extends PureComponent<Props, LocalState> {
  state = {
    activeActivityIdx: 0,
    editedTemplate: null,
    alertModalShown: false,
    alertModalTitleKey: "",
    alertButtonClick: this.back,
    saving: false,
    templateErrors: [],
    activityErrors: [],
  };

  componentDidMount() {
    if (this.props.originalTemplate != null) {
      this.onResetEditedTemplate(this.props.originalTemplate);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      // case1: the template become non-null
      (this.props.originalTemplate == null &&
        nextProps.originalTemplate != null) ||
      // case2: the template id changes
      (this.props.originalTemplate != null &&
        nextProps.originalTemplate != null &&
        this.props.originalTemplate.id !== nextProps.originalTemplate.id)
    ) {
      this.onResetEditedTemplate(nextProps.originalTemplate);
    }
  }

  onSave = () => {
    const originalTemplate = this.props.originalTemplate;
    const currentTemplate = this.state.editedTemplate;

    if (
      originalTemplate != null &&
      currentTemplate != null &&
      this.ifAnyChanges(originalTemplate, currentTemplate)
    ) {
      const templateErrors = validateTemplate(currentTemplate);
      const activityErrors = currentTemplate.activities.map(validateActivity);
      const activityNoError = activityErrors.every(arr => arr.length === 0);
      if (templateErrors.length === 0 && activityNoError) {
        this.setState({ saving: true }, this.save);
      } else {
        this.setState({
          templateErrors: templateErrors,
          activityErrors: activityErrors,
        });
      }
    } else {
      this.back();
    }
  };

  save() {
    const currentTemplate = this.state.editedTemplate;

    if (currentTemplate) {
      this.props.save(currentTemplate).then(template => this.back());
    }
  }

  onCancel = () => {
    const originalTemplate = this.props.originalTemplate;
    const currentTemplate = this.state.editedTemplate;

    // Due to draft-js converts text node to html format
    // ex: "Some text" will be converted to "<p>Some text</p>"
    // So user may see alert even there is no change
    if (
      originalTemplate != null &&
      currentTemplate != null &&
      this.ifAnyChanges(originalTemplate, currentTemplate)
    ) {
      this.setState({
        alertModalShown: true,
        alertButtonClick: this.back,
        alertModalTitleKey: "teacher.lost_changes",
      });
    } else {
      this.back();
    }
  };

  back = () => {
    this.props.history.goBack();
  };

  onCloseAlertModal = () => {
    this.setState({ alertModalShown: false });
  };

  onNameChange = (name: string) => {
    this.setState(prevState => ({
      editedTemplate: {
        ...prevState.editedTemplate,
        name,
      },
    }));
  };

  onDisplayCodeChange = (displayCode: string) => {
    this.setState(prevState => ({
      editedTemplate: {
        ...prevState.editedTemplate,
        displayCode,
      },
    }));
  };

  onLangChange = (lang: TEMPLATE_LOCALE) => {
    this.setState(prevState => ({
      editedTemplate: {
        ...prevState.editedTemplate,
        lang,
      },
    }));
  };

  onYearChange = (year: TEMPLATE_YEAR) => {
    this.setState(prevState => ({
      editedTemplate: {
        ...prevState.editedTemplate,
        year,
      },
    }));
  };

  onResetEditedTemplate(template: Template) {
    this.setState({
      editedTemplate: deepClone(template),
    });
  }

  onClickActivityPanelItem = (idx: number) => {
    const { editedTemplate } = this.state;

    if (editedTemplate && idx >= 0 && idx < editedTemplate.activities.length) {
      this.setState({ activeActivityIdx: idx });
    }
  };

  onChangeActivityOrder = (dragIndex: number, hoverIndex: number) => {
    const { editedTemplate } = this.state;

    if (editedTemplate) {
      const activities = Array.from(editedTemplate.activities);
      const dragActivity = activities[dragIndex];

      activities.splice(dragIndex, 1);
      activities.splice(hoverIndex, 0, dragActivity);

      this.setState({
        activeActivityIdx: hoverIndex,
        editedTemplate: {
          ...editedTemplate,
          activities,
        },
      });
    }
  };

  onActivityChange = (activity: Activity) => {
    const { editedTemplate, activeActivityIdx } = this.state;

    if (editedTemplate) {
      const activities = Array.from(editedTemplate.activities);
      activities[activeActivityIdx] = activity;
      this.setState({
        editedTemplate: {
          ...editedTemplate,
          activities,
        },
      });
    }
  };

  onAddActivity = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    const { editedTemplate } = this.state;

    if (editedTemplate) {
      this.setState({
        editedTemplate: {
          ...editedTemplate,
          activities: [...editedTemplate.activities, generateEmptyActivity()],
        },
        activeActivityIdx: editedTemplate.activities.length,
      });
    }
  };

  onRemoveActivity = () => {
    const { editedTemplate, activeActivityIdx } = this.state;
    if (editedTemplate) {
      const activities = Array.from(editedTemplate.activities);
      activities.splice(activeActivityIdx, 1);

      if (activities.length > 0) {
        this.setState({
          editedTemplate: {
            ...editedTemplate,
            activities,
          },
          activeActivityIdx: Math.min(activeActivityIdx, activities.length - 1),
        });
      } else {
        this.setState({
          alertModalShown: true,
          alertButtonClick: this.onCloseAlertModal,
          alertModalTitleKey: "teacher.need_at_least_one_activity",
        });
      }
    }
  };

  ifAnyChanges(template1: Template, template2: Template) {
    return JSON.stringify(template1) !== JSON.stringify(template2);
  }

  renderAlertModal() {
    if (!this.state.alertModalShown) {
      return null;
    }
    return (
      <ModalContainer onClose={this.onCloseAlertModal}>
        <AlertModal
          colorVariant="red"
          titleKey={this.state.alertModalTitleKey}
          buttonKey="phrase.confirm"
          onButtonClick={this.state.alertButtonClick}
          onClose={this.onCloseAlertModal}
          closeButtonStyle="cancel"
        />
      </ModalContainer>
    );
  }

  renderActivitiesPanel() {
    const { editedTemplate, activityErrors } = this.state;

    if (!editedTemplate) {
      return null;
    }

    const errorActivityIndices = activityErrors.reduce((accu, curr, idx) => {
      if (curr.length !== 0) {
        accu.push(idx);
      }

      return accu;
    }, []);

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.activitiesPanel}
            >
              <p className={styles.activitiesTitle}>
                <Text
                  translationKey="teacher.number_of_activities2"
                  values={{
                    numberOfActivities: editedTemplate.activities.length,
                  }}
                />
              </p>
              <ActivitiesPanel
                variant="teacherPortal"
                activities={editedTemplate.activities}
                activeActivityIdx={this.state.activeActivityIdx}
                errorActivityIndices={errorActivityIndices}
                onClick={this.onClickActivityPanelItem}
                onChangeOrder={this.onChangeActivityOrder}
                canDrag
              />
              <IconButton
                className={styles.addActivity}
                icon={addIcon}
                labelTranslationKey="teacher.add_activity"
                onClick={this.onAddActivity}
              />
            </div>
          );
        }}
      </WindowSize>
    );
  }

  renderActivityDetailPanel() {
    const { editedTemplate } = this.state;
    const { activeActivityIdx, activityErrors } = this.state;

    if (!editedTemplate) {
      return null;
    }

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET_WITH_HEADER_FOOTER;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.activityDetailPanel}
            >
              <ActivityPanel
                templateName={editedTemplate.name}
                templateDisplayCode={editedTemplate.displayCode}
                templateLang={editedTemplate.lang}
                activityIndex={activeActivityIdx}
                activity={editedTemplate.activities[activeActivityIdx]}
                editable
                onActivityChange={this.onActivityChange}
                onRemoveActivity={this.onRemoveActivity}
                validationErrors={activityErrors[activeActivityIdx]}
              />
            </div>
          );
        }}
      </WindowSize>
    );
  }

  render() {
    const { isRequesting, error } = this.props;
    const { editedTemplate, saving, templateErrors } = this.state;

    if (isRequesting || saving || !editedTemplate) {
      return (
        <WindowSize>
          {props => {
            const h = props.height - MAGIC_HEIGHT_OFFSET;
            return (
              <div
                style={{ minHeight: h + "px" }}
                className={styles.templateContainer}
              >
                {(isRequesting || saving) && <LoadingIndicator />}
              </div>
            );
          }}
        </WindowSize>
      );
    }

    return (
      <WindowSize>
        {props => {
          const h = props.height - MAGIC_HEIGHT_OFFSET;
          return (
            <div
              style={{ minHeight: h + "px" }}
              className={styles.templateContainer}
            >
              <ImportedTemplateHeader
                year={editedTemplate.year}
                lang={editedTemplate.lang}
                displayCode={editedTemplate.displayCode}
                name={editedTemplate.name}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onNameChange={this.onNameChange}
                onDisplayCodeChange={this.onDisplayCodeChange}
                onYearChange={this.onYearChange}
                onLangChange={this.onLangChange}
                templateDisplayCodeError={templateErrors.includes(
                  ErrorTemplateDisplayCodeEmpty
                )}
                templateNameEmptyError={templateErrors.includes(
                  ErrorTemplateNameEmpty
                )}
              />
              <div className={styles.activitiesContainer}>
                {this.renderActivitiesPanel()}
                {this.renderActivityDetailPanel()}
              </div>
              {this.renderAlertModal()}
              <ErrorModal error={error} />
            </div>
          );
        }}
      </WindowSize>
    );
  }
}

export default ImportedTemplate;
